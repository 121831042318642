import { Full } from '../helpers/full';

import { Person } from './person';
import { UserStatus } from './user-status';

/**
 * General information about loan officer.
 */
export class LoanOfficerSummary extends Person {

  /**
   * Count of associated realtors.
   */
  public realtorsCount: number;

  /**
   * ID in readable format.
   */
  public friendlyId: string;

  /**
   * Count of associated borrowers.
   */
  public borrowersCount: number;

  /**
   * Count of active offers.
   */
  public activeOffersCount: number;
  
  /**
   * Count of active offers.
   */
  public preApprovalsCount: number;

  /**
   * Status of registration.
   */
  public status: UserStatus;

  /**
   * Update or creation date.
   */
  public updatedAt: Date;

  /**
   * Name of the user who last updated the record.
   */
  public updatedUserName: string;

  public isMapped: boolean;

  public invitedBy: any;

  constructor(data: Full<LoanOfficerSummary>) {
    super({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
    });
    this.realtorsCount = data.realtorsCount;
    this.friendlyId = data.friendlyId;
    this.borrowersCount = data.borrowersCount;
    this.activeOffersCount = data.activeOffersCount;
    this.preApprovalsCount = data.preApprovalsCount;
    this.status = data.status;
    this.updatedAt = data.updatedAt;
    this.updatedUserName = data.updatedUserName;
    this.isMapped = data.isMapped;
    this.invitedBy = data.invitedBy;
  }

}
