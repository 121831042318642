import { Component, ChangeDetectionStrategy,  ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import SignaturePad from 'signature_pad';

/**
 * Only shows some info.
 */
@Component({
  selector: 'arb-signature-pad',
  templateUrl: './signature-pad-dialog.component.html',
  styleUrls: ['./signature-pad-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignaturePadComponent implements OnInit, AfterViewInit {

    @ViewChild('sPad') signaturePadElement;
  signaturePad: any;
  constructor(
      private dialogRef: MatDialogRef<SignaturePadComponent>
  ) { }

  private readonly canvasForWindow = {
    innerWidth: 850,
    innerHeight: 400
  }

  private readonly canvasForMobile = {
    innerWidth: 200,
    innerHeight: 200
  }
  public canvasHeight: number;
  public canvasWidth: number;

  ngOnInit(): void {
    if (window.screen.availWidth < 678) {
      this.canvasHeight = this.canvasForMobile.innerHeight;
      this.canvasWidth = this.canvasForMobile.innerWidth;
    } else {
      this.canvasHeight = this.canvasForWindow.innerHeight;
      this.canvasWidth = this.canvasForWindow.innerWidth;
    }
    console.log("Window height", window.screen.availHeight);
    console.log("Window screen", window.screen)
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        penColor: 'rgb(0, 0, 0)',
        dotSize: 1.5,
        minWidth: 2
      });
  }

  changeColor() {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    const color = 'rgb(' + r + ',' + g + ',' + b + ')';
    this.signaturePad.penColor = color;
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  download(dataURL, filename) {
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      window.open(dataURL);
    } else {
      const blob = this.dataURLToBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  savePNG() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL();
      this.download(dataURL, 'signature.png');
    }
  }

  saveJPG() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL('image/jpeg');
      this.download(dataURL, 'signature.jpg');
    }
  }

  saveSVG() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL('image/svg+xml');
      this.download(dataURL, 'signature.svg');
    }
  }

  onSubmit() {
      const dataURL = this.signaturePad.toDataURL();
      const blob = this.dataURLToBlob(dataURL);
      const resultFile = new File([blob], "Your_Signature.png", { type: 'image/png' });
      this.dialogRef.close(resultFile);
  }

}