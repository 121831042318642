import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
    selector: 'arb-raise-ticket-dialog',
    templateUrl: './raise-ticket-dialog.component.html',
    styleUrls: ['./raise-ticket-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RaiseTicketDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private route: Router,
        private dialogRef: MatDialogRef<RaiseTicketDialogComponent>
      ) { }

      onView(id) {
        console.log(id);
        this.route.navigate(['support', id]);
        this.dialogRef.close();
      }
}