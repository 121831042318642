import { Full } from '../helpers/full';

import { User } from './user';

/**
 * Admin.
 */
export class Admin extends User {

  constructor(data: Full<Admin>) {
    super(data);
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.updatedAt = data.updatedAt;
  }

}
