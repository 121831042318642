import { PDFPageProxy, PDFDocumentProxy, getDocument } from 'pdfjs-dist/webpack';
import { of, Subject, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { switchMap, distinctUntilChanged, tap, takeUntil, skip, concatMap, map } from 'rxjs/operators';

import { weakShareReplay, readFile } from '../../../core';

import { Editor } from './editor';
import { PageInfo } from './page-info';
import { PdfEditorOptions } from './pdf-editor-options';
import { PdfCropperRenderer } from './renderer/pdf-cropper-renderer';
import { PdfEditorRendererOptions } from './renderer/pdf-editor-renderer-options';
import { PdfRectangleRenderer } from './renderer/pdf-rectangle-renderer';
import { PdfCustomPreapproval } from './renderer/pdf-custom-preapproval-renderer';
import { PdfRenderer } from './renderer/pdf-renderer';
import { RendererType } from './renderer/renderer-type';
import { openStdin } from 'process';

/**
 * Pdf editor.
 */
export class PdfEditor extends Editor {

  private readonly upperCanvas: HTMLCanvasElement;
  private readonly upperCanvasContext: CanvasRenderingContext2D;

  /**
   * @inheritdoc
   */
  protected renderer: PdfRenderer;

  /**
   * Array of pages that can be used to perform first page render.
   */
  public readonly pages$: Observable<PDFPageProxy[]>;

  /**
   * @inheritdoc
   */
  public readonly filesChange$: Subject<File[]>;

  /**
   * Current page index stream.
   */
  public readonly currentPageIndex$: BehaviorSubject<number>;

  /**
   * Pdf stream.
   */
  public readonly pdf$: Observable<PDFDocumentProxy>;

  /**
   * Current page stream.
   */
  public readonly currentPage$: BehaviorSubject<PDFPageProxy>;

  /**
   * Rendered pages.
   */
  public readonly renderedPages: Map<number, PageInfo>;
  /**
   * Indexes of files to save stream.
   */
  public readonly indexesOfFilesToSave$: BehaviorSubject<Set<number>>;

  public customParams$: Observable<any>;

  public items :any
  constructor(options: PdfEditorOptions) {
    super(options);
    this.upperCanvas = options.upperCanvas;
    this.upperCanvasContext = options.upperCanvasContext;
    this.customParams$ = options.customParams$;
    this.filesChange$ = new Subject();
    this.currentPageIndex$ = new BehaviorSubject(1);
    this.currentPage$ = new BehaviorSubject(null as any);
    this.renderedPages = new Map();
    this.indexesOfFilesToSave$ = new BehaviorSubject(new Set<number>());
    this.pdf$ = this.createPdfStream(options.file);
    this.pages$ = this.createPagesStream();
    this.items= options.items;
    this.listenCurrentPageChanges();
    this.listenSaveIndexesChange();
  }

  private createPdfStream(file: File): Observable<PDFDocumentProxy> {
    return readFile(file)
      .pipe(
        switchMap(base64 => new Observable<PDFDocumentProxy>(observer => {
          getDocument(base64).promise.then(pdf => {
            observer.next(pdf);
            observer.complete();
          });
        })),
        weakShareReplay(1),
      );
  }

  private createPagesStream(): Observable<PDFPageProxy[]> {
    return this.pdf$
      .pipe(
        switchMap(pdf => {
          const pages$: Observable<PDFPageProxy>[] = [];
          for (let i = 1; i <= pdf.numPages; i++) {
            pages$.push(new Observable<PDFPageProxy>(observer => {
              pdf.getPage(i).then(page => {
                observer.next(page);
                observer.complete();
              });
            }));
          }
          return combineLatest(...pages$);
        }),
      );
  }

  private listenCurrentPageChanges(): void {
    combineLatest(
      this.pdf$,
      this.currentPageIndex$.pipe(distinctUntilChanged()),
    )
      .pipe(
        switchMap(([pdf, pageIndex]) => this.renderer.onCurrentPageChange(pdf, pageIndex)),
        tap(page => this.currentPage$.next(page)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenSaveIndexesChange(): void {
    this.indexesOfFilesToSave$
      .pipe(
        /* Skip first default value of this `BehaviourSubject`. */
        skip(1),
        concatMap(() => this.renderer.convertToFile()),
        map(() => this.renderer.getFilesToSave()),
        tap(files => this.filesChange$.next(files)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * Add info of rendered page not to re-render the page with `pdfjs`.
   * `pdfjs` renders only source page, we need to edit a page and save change file in this page info.
   * @param pageIndex Page index.
   * @param pageInfo Page info.
   */
  public addRenderedPageInfo(pageIndex: number, pageInfo: PageInfo): void {
    this.renderedPages.set(pageIndex, pageInfo);
  }

  /**
   * Sets current page.
   * @param pageNumber Page number.
   */
  public setPage(pageNumber: number): void {
    this.renderer.setPage(pageNumber);
  }

  /**
   * Adds page to save list.
   * @param pageNumber Page number.
   */
  public addPageToSaveList(pageNumber: number): void {
    this.renderer.addPageToSaveList(pageNumber);
  }

  /**
   * Deletes page from save list.
   * @param pageNumber Page number.
   */
  public deletePageFromSaveList(pageNumber: number): void {
    this.renderer.deletePageFromSaveList(pageNumber);
  }

  /**
   * @inheritdoc
   */
  public setRenderer(rendererType: RendererType): void {
    if (this.renderer) {
      this.renderer.destroy();
    }
    switch (rendererType) {
      case RendererType.RectangleRenderer:
        this.renderer = new PdfRectangleRenderer(this, this.rendererOptions);
        break;
      case RendererType.Cropper:
        this.renderer = new PdfCropperRenderer(this, this.rendererOptions);
        break;
      case RendererType.RectangleRendererHighlighter:
        this.renderer = new PdfRectangleRenderer(this, this.rendererOptions, true);
        break;
      case RendererType.PreApprovalContentRenderer:
        this.renderer = new PdfCustomPreapproval(this, this.rendererOptions, false, this.customParams$);
        break;
      case RendererType.None:
        this.renderer.disable();
        break;
      default:
        throw new Error('Unexpected renderer type');
    }
  }

  /**
   * Can deactivate editor.
   */
  public get canDeactivate(): boolean {
    if (this.renderer instanceof PdfCropperRenderer) {
      return !this.renderer.isCroppedAreaChanged;
    }
    return true;
  }

  /**
   * Can deactivate editor.
   */
  public set canDeactivate(value: boolean) {
    if (this.renderer instanceof PdfCropperRenderer) {
      this.renderer.isCroppedAreaChanged = !value;
    }
  }
  private get rendererOptions(): PdfEditorRendererOptions {
    return {
      canvas: this.canvas,
      context: this.context,
      canvasMaxWidth: this.canvasMaxWidth,
      upperCanvas: this.upperCanvas,
      upperCanvasContext: this.upperCanvasContext,
    };
  }

  /**
   * Triggers current renderer to make crop effect.
   */
  public crop(): Observable<void> {
    if (this.renderer instanceof PdfCropperRenderer) {
     return  this.renderer.crop();
    }
    return of(void 0);
  }
}
