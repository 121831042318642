/**
 * All roles that a user can have.
 */
export enum UserRole {
  /**
   * Admin role.
   */
  Admin = 'Admin',

  /**
   * Realtor role.
   */
  Realtor = 'Realtor',

  /**
   * Loan officer role.
   */
  LoanOfficer = 'LoanOfficer',
}

const roles: Record<UserRole, string> = {
  [UserRole.Admin]: 'Admin',
  [UserRole.Realtor]: 'Realtor',
  [UserRole.LoanOfficer]: 'Loan Officer',
};

/**
 * Returns readable user role.
 * @param role Not readable role.
 */
export function toReadableUserRole(role: UserRole): string {
  return roles[role];
}
