import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input, OnInit, ElementRef, ViewContainerRef, ComponentFactoryResolver, Renderer2, HostBinding } from '@angular/core';
import { MatButton, MatSpinner } from '@angular/material';

/**
 * Used to indicate `busy` state of a button.
 */
@Directive({
// tslint:disable-next-line: directive-selector
  selector: 'button[buttonProgress]',
})
export class ButtonProgressDirective implements OnInit {

  private inProgressValue = false;
  private outerDisabled: boolean;

  /**
   * In progress.
   */
  @Input('buttonProgress')
  public set inProgress(value: boolean) {
    this.inProgressValue = value;
    this.updateDisabledState();
  }

  /**
   * Add `in progress` class.
   */
  @HostBinding('class.is-in-progress')
  public get addInProgressClass(): boolean {
    return this.inProgress;
  }

  /**
   * Disabled.
   */
  @Input('disabled')
  public set disabled(value: any) {
    this.outerDisabled = coerceBooleanProperty(value);
    this.updateDisabledState();
  }

  constructor(
    private el: ElementRef,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private button: MatButton,
  ) { }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    const spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatSpinner);
    const spinner = this.viewContainer.createComponent(spinnerFactory);
    const spinnerElement = spinner.location.nativeElement;
    const spinnerComponent = spinner.instance;
    spinnerComponent.diameter -= 10;

    const buttonContent = this.el.nativeElement.getElementsByClassName('mat-button-wrapper')[0] || this.el.nativeElement;
    this.renderer.addClass(spinnerElement, 'in-progress-spinner');
    this.renderer.setAttribute(spinnerElement, 'aria-label', 'Working...');
    this.renderer.appendChild(buttonContent, spinnerElement);
  }

  /**
   * Progress state.
   */
  public get inProgress(): boolean {
    return this.inProgressValue;
  }

  private updateDisabledState(): void {
    this.button.disabled = this.inProgress || this.outerDisabled;
  }

}
