import { Full } from "../../helpers/full";
import { Contact } from "../contact";
import { PersonDetails } from "../person-details";

import { AssociatedBorrower } from "./associated-borrower";
import { AssociatedRealtor } from "./associated-realtor";
import { DownPaymentType } from "./down-payment-type";
import { OfferStatus } from "./offer-status";

/**
 * Offer.
 */
export class Offer {
  /**
   * Id of the offer.
   */
  public id: number;

  /**
   * Readable id.
   */
  public friendlyId: string;

  /**
   * Status.
   */
  public status: OfferStatus;

  /**
   * Letter photo url.
   */
  public letterPhotoUrl: string;

  /**
   * Borrower the offer made to.
   */
  public borrower: AssociatedBorrower;

  /**
   * Co-borrower.
   */
  public coBorrower: Contact;

  /**
   * Associated realtor.
   */
  public realtor: AssociatedRealtor;

  /**
   * Associated loan officer.
   */
  public loanOfficer: PersonDetails;

  /**
   * Name of recipient(s).
   */
  public recipientName: string;

  /**
   * Subject name.
   */
  public subject: string;

  /**
   * Borrower quote.
   */
  public borrowerQuote: string;

  /**
   * Letter.
   */
  public letter: string;

  /**
   * Letter conclusion.
   */
  public letterConclusion: string;

  /**
   * Price.
   */
  public price: number;

  /**
   * Price notes.
   */
  public priceNotes: string;

  /**
   * Deposit.
   */
  public deposit: number;

  /**
   * Deposit notes.
   */
  public depositNotes: string;

  /**
   * Down payment size.
   */
  public downPayment: number;

  /**
   * Down payment type.
   */
  public downPaymentType: DownPaymentType;

  /**
   * Down payment notes.
   */
  public downPaymentNotes: string;

  /**
   * Financing.
   */
  // public financing: string;
  public financing: number;

  /**
   * Total
   */
  public total: string;

  /**
   * Lender.
   */
  public lender: string;

  /**
   * Escrow company.
   */
  public escrowCompany: string;

  /**
   * Response deadline date.
   */
  public responseDeadline: Date;

  /**
   * Contingency Appraisal.
   */
  public contingency: string;

  /**
   * Contingency Loan.
   */
  public contingencyLoan: string;

  /**
   * Contingency General.
   */
  public contingencyGeneral: string;

  /**
   * Total notes.
   */
  public totalNotes: string;

  /**
   * Address.
   */
  public address: string;

  /**
   * City.
   */
  public city: string;

  /**
   * State.
   */
  public state: string;

  /**
   * Zip.
   */
  public zip: string;

  /**
   * Property type.
   */
  public propertyType: number;

  /**
   * RPA URL.
   */
  public rpaUrl: string;

  /**
   * Submitted date.
   */
  public dateSubmitted: Date;

  /**
   * Package url.
   */
  public packageUrl: string;

  /**
   * Pre approval url.
   */
  public preApprovalUrl: string;

  /**
   * Realtor offer signature.
   */
  public realtorOfferSignature: string;

  /**
   * Loan officer offer signature.
   */
  public loanOfficerOfferSignature: string;

  /**
   * `true` if realtor's picture is used.
   */
  public isRealtorPictureUsed: boolean;

  /**
   * Offer pdf url.
   */
  public pdfUrl: string;

  /**
   * PackageBitlyUrl pdf url.
   */
  public packageBitlyUrl: string;

  public analytics: any;

  public buyerType: number;

  /** Listing agent information*/

  public agentName: string;

  /**
   * email.
   */
  public agentEmail: string;

  /**
   * phone Number.
   */
  public agentPhoneNo: string;
  
  constructor(data: Full<Offer>) {
    this.id = data.id;
    this.friendlyId = data.friendlyId;
    this.status = data.status;
    this.letterPhotoUrl = data.letterPhotoUrl;
    this.borrower = data.borrower;
    this.coBorrower = data.coBorrower;
    this.realtor = data.realtor;
    this.loanOfficer = data.loanOfficer;
    this.recipientName = data.recipientName;
    this.subject = data.subject;
    this.borrowerQuote = data.borrowerQuote;
    this.letter = data.letter;
    this.letterConclusion = data.letterConclusion;
    this.price = data.price;
    this.priceNotes = data.priceNotes;
    this.deposit = data.deposit;
    this.depositNotes = data.depositNotes;
    this.downPayment = data.downPayment;
    this.downPaymentType = data.downPaymentType;
    this.downPaymentNotes = data.downPaymentNotes;
    this.financing = data.financing;
    this.total = data.total;
    this.lender = data.lender;
    this.escrowCompany = data.escrowCompany;
    this.responseDeadline = data.responseDeadline;
    this.contingency = data.contingency;
    this.contingencyLoan = data.contingencyLoan;
    this.contingencyGeneral = data.contingencyGeneral;
    this.totalNotes = data.totalNotes;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.propertyType = data.propertyType;
    this.agentName=data.agentName;
    this.agentEmail=data.agentEmail;
    this.agentPhoneNo=data.agentPhoneNo;
    this.rpaUrl = data.rpaUrl;
    this.dateSubmitted = data.dateSubmitted;
    this.packageUrl = data.packageUrl;
    this.isRealtorPictureUsed = data.isRealtorPictureUsed;
    this.preApprovalUrl = data.preApprovalUrl;
    this.realtorOfferSignature = data.realtorOfferSignature;
    this.loanOfficerOfferSignature = data.loanOfficerOfferSignature;
    this.pdfUrl = data.pdfUrl;
    this.packageBitlyUrl = data.packageBitlyUrl;
    this.analytics = data.analytics;
    this.buyerType = data.buyerType;
  }
}
