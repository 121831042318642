import { Full } from '../helpers/full';

import { Person } from './person';

/**
 * User contact info.
 */
export class PersonDetails extends Person {

  /**
   * Readable id.
   */
  public friendlyId: string;

  /**
   * Company name.
   */
  public companyName: string;

  /**
   * Email.
   */
  public email: string;

  /**
   * Phone number.
   */
  public phoneNumber: string;

  /**
   * Signature person.
   */
  public signatureUrl?: string;

  
  /**
   * Signature person.
   */
  public companyLogoUrl?: string;

  
  /**
   * Title.
   */
  public title?: string;

  public nmlsId?: string;

  /**
   * headerColor.
   */
  public headerColor?: string;

  /**
   * headerColor.
   */
  public footerColor?: string;
  
  /**
   * headerColor.
   */
  public timeZone?: string;

  constructor(data: Full<PersonDetails>) {
    super({
      firstName: data.firstName,
      lastName: data.lastName,
      id: data.id,
    });
    this.friendlyId = data.friendlyId;
    this.companyName = data.companyName;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.signatureUrl = data.signatureUrl;
    this.companyLogoUrl = data.companyLogoUrl;
    this.title = data.title ? data.title : "";
    this.nmlsId = data.nmlsId ;
    this.headerColor = data.headerColor;
    this.footerColor = data.footerColor;
    this.timeZone = data.timeZone;
  }

}
