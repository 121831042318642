/**
 * Pagination page.
 */
export class Page {

  /**
   * Index.
   */
  public index: number;

  /**
   * Selected.
   */
  public selected: boolean;

  constructor(index: number, selected: boolean) {
    this.index = index;
    this.selected = selected;
  }

}
