import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { PDFPageProxy } from 'pdfjs-dist';
import { ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { DestroyableBase } from '../../../../core';

/**
 * Pdf page viewer.
 */
@Component({
  selector: 'arb-viewer-pdf-page',
  templateUrl: './viewer-pdf-page.component.html',
  styleUrls: ['./viewer-pdf-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerPdfPageComponent extends DestroyableBase implements AfterViewInit {

  /**
   * Image src stream.
   */
  public readonly imageSrc$ = new Subject<string>();

  private readonly page$ = new ReplaySubject<PDFPageProxy>(1);

  /**
   * Pdf page.
   */
  @Input()
  public set pdfPageProxy(page: PDFPageProxy) {
    this.page$.next(page);
  }

  /**
   * Reference to canvas.
   */
  @ViewChild('canvas')
  public canvasRef: ElementRef<HTMLCanvasElement>;

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.subscribeToChangePage();
  }

  private subscribeToChangePage(): void {
    const canvas = this.canvasRef.nativeElement;
    const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
    this.page$.pipe(
      switchMap(page => {
        const viewport = page.getViewport({ scale: 1 });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        return page.render({ viewport, canvasContext }).promise as unknown as Promise<PDFPageProxy>;
      }),
      tap(() => this.imageSrc$.next(canvas.toDataURL())),
      takeUntil(this.destroy$),
    ).subscribe();
  }
}
