import { Full } from '../../helpers/full';

import { Named } from '../named';
import { OfferStatus } from '../offers/offer-status';
import { Person } from '../person';
import { PreApprovalStatus } from '../pre-approval-status';

/**
 * Borrower summary.
 */
export class BorrowerSummary extends Person {

  /**
   * Friendly id.
   */
  public friendlyId: string;

  /**
   * Borrower’s “nickname” for identification.
   */
  public nickname: string;

  /**
   * Co-borrower.
   */
  public coBorrower: Named;

  /**
   * Associated realtor.
   */
  public realtor: Person;

  /**
   * Associated loan officer.
   */
  public loanOfficer: Person;

  /**
   * Pre approval status.
   */
  public preApprovalStatus: PreApprovalStatus;

  /**
   * Last updated date.
   */
  public updatedAt: Date;

  /**
   * Last updated user name.
   */
  public updatedByUserName: string;

  /**
   * Offer status.
   */
  public offerStatus: OfferStatus;

   /**
   * Borrower’s deletedRole for identification.
   */
  public deletedRole: any;

  /**
   * @constructor.
   */
  constructor(data: Full<BorrowerSummary>) {
    super(data);
    this.friendlyId = data.friendlyId;
    this.nickname = data.nickname;
    this.coBorrower = data.coBorrower;
    this.realtor = data.realtor;
    this.loanOfficer = data.loanOfficer;
    this.preApprovalStatus = data.preApprovalStatus;
    this.offerStatus = data.offerStatus;
    this.updatedAt = data.updatedAt;
    this.updatedByUserName = data.updatedByUserName;
    this.deletedRole = data.deletedRole || null;
  }

}
