import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * Component to render a page layout for authorized users.
 */
@Component({
  selector: 'arb-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizedLayoutComponent { }
