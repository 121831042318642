import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, combineLatest, ReplaySubject } from "rxjs";
import { take, map, switchMap, tap } from "rxjs/operators";

import {
  Offer,
  Realtor,
  RealtorsService,
  Borrower,
  BorrowerService,
  OfferService,
  DownPaymentType,
  AssetType,
  Asset,
} from "../../../../core";

import { OfferDetails } from "./offer-details";

const DEFAULT_COLOR = "ef7824";
const OFFER_LETTER_LINES_TO_SHOW = 39;
const OFFER_CONCLUSION_LETTER_LINES_TO_SHOW = 10;
const LETTER_WIDTH_IN_PT = 309;
const MAXIMUM_WIDTH_OF_LETTERS = 12;
export const OFFER_MAXIMUM_LETTERS_OF_LETTER =
  (OFFER_LETTER_LINES_TO_SHOW * LETTER_WIDTH_IN_PT) / MAXIMUM_WIDTH_OF_LETTERS;
export const OFFER_MAXIMUM_LETTERS_OF_CONCLUSION_LETTER =
  (OFFER_CONCLUSION_LETTER_LINES_TO_SHOW * LETTER_WIDTH_IN_PT) /
  MAXIMUM_WIDTH_OF_LETTERS;

/**
 * Offer template page component.
 */
@Component({
  selector: "arb-offer-template-page",
  templateUrl: "./offer-template-page.component.html",
  styleUrls: ["./offer-template-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OfferTemplatePageComponent {
  /**
   * Stream with all related data (offer, realtor, borrower).
   */
  public readonly data$ = this.createOfferDetailsStream();

  /**
   * Rpa extension.
   */
  public readonly rpaExtension$ = new ReplaySubject<string>(1);

  /**
   * Current date.
   */
  public readonly currentDate = new Date();

  /**
   * All downpayment types.
   */
  public readonly downPaymentTypes = DownPaymentType;

  /**
   * Asset type.
   */
  public readonly assetType = AssetType;

/**
 * PreApproval Asset
 */
  public readonly preapprovalAsset: Asset[];




  public fontSize: string = "9pt";
  public buyerTypeText: string;
  public buyersNameText='';
  constructor(
    private route: ActivatedRoute,
    private realtorsService: RealtorsService,
    private offerService: OfferService,
    private borrowersService: BorrowerService
  ) {}

  private createOfferDetailsStream(): any {
    var that = this;
    return this.route.data.pipe(
      take(1),
      map(({ offer }) => offer as Offer),
      switchMap((offer) =>
        combineLatest(
          this.getRealtorById(offer.realtor.id),
          this.getBorrower(offer.borrower.id),
          this.getOfferAssets(offer.id)
        ).pipe(
          map(([Realtor, Borrower,offerAssets]) => ({
            offer,
            realtor: Realtor,
            borrower: Borrower,
            offerAssets: offerAssets
          })),
          tap((data) => {
            console.log("data", data);
            that.setFontSize(data);
            // console.log("Offer template", data);
            this.buyerTypeText =
              data.offer.buyerType == 1 ? "homebuyers" :data.offer.buyerType == 2 ? "" :data.offer.buyerType == 3 ? "" : "homebuyer";
          
              this.buyersNameText = !data.offer.isRealtorPictureUsed && (data.offer.buyerType == 3) ? data.offer.address
              : (data.offer.buyerType == 2) ?data.offer.realtor.firstName+' '+data.offer.realtor.lastName : data.borrower.nickname;
            
              this.rpaExtension$.next(this.getRpaExtension(data.offer.rpaUrl));

              let sortedAssetsAsPerSequence: Asset[] = [];
              if (data.borrower.preApproval.id != null) {
                let preApprovalAsset: any[] = data.borrower.preApprovalAssets;
                preApprovalAsset.sort((a,b) => {
                  return b.sequence - a.sequence;
                });
                preApprovalAsset.forEach(asset => {
                  if (asset.assetTypeId != AssetType.PreApprovalCustom && asset.sequence != null) {
                    sortedAssetsAsPerSequence.unshift(asset);
                  } else {
                    if (asset.assetTypeId != AssetType.PreApprovalCustom) {
                      sortedAssetsAsPerSequence.push(asset);
                    }
                  }
                });
                if (preApprovalAsset.length != sortedAssetsAsPerSequence.length) {
                  let customePreApprovalObject = preApprovalAsset.filter(asset => asset.assetTypeId == AssetType.PreApprovalCustom);
                  sortedAssetsAsPerSequence.push(customePreApprovalObject[0]);
                }
                data.borrower.preApprovalAssets = sortedAssetsAsPerSequence;
              }
          })
        )
      )
    );
  }
  private setFontSize(data) {
    const size = data.realtor.fullName.length + data.realtor.companyName.length;
    this.fontSize = size < 50 ? "9pt" : size < 70 ? "7.5pt" : "7pt";
  }

  private getRpaExtension(fileName: string): string {
    const arrayOfLinesSeparatedByDots = fileName.split(".");
    return arrayOfLinesSeparatedByDots[arrayOfLinesSeparatedByDots.length - 1];
  }

  private getRealtor(): Observable<Realtor> {
    return this.realtorsService.getProfile().pipe(
      tap((realtor) => {
        realtor.headerColor = `#${realtor.headerColor || DEFAULT_COLOR}`;
        realtor.footerColor = `#${realtor.footerColor || DEFAULT_COLOR}`;
        realtor.iconsColor = `#${realtor.iconsColor || DEFAULT_COLOR}`;
      })
    );
  }

  private getRealtorById(realtorId): any {
    return this.realtorsService.getById(realtorId).pipe(
      tap((realtor) => {
        realtor.headerColor = `#${realtor.headerColor || DEFAULT_COLOR}`;
        realtor.footerColor = `#${realtor.footerColor || DEFAULT_COLOR}`;
        realtor.iconsColor = `#${realtor.iconsColor || DEFAULT_COLOR}`;
      })
    );
  }

  private getBorrower(id: number): Observable<Borrower> {
    return this.borrowersService.getById(id);
  }

  private getOfferAssets(id: number): Observable<Borrower> {
    return this.offerService.getOfferAssets(id);
  }

  repositionAssets(preApprovalAssets) {
    preApprovalAssets.forEach(assetItem => {
      if((assetItem.assetTypeId == 6 || assetItem.assetTypeId == 7) && assetItem.fileUrl != "" && assetItem.sequence == null) {
        preApprovalAssets.splice(preApprovalAssets.indexOf(assetItem),1);
        preApprovalAssets.unshift(assetItem);
    }
  })
    preApprovalAssets.sort(function(a,b) {
      return a.sequence - b.sequence;
    })
    return preApprovalAssets;
  }

  public isNotPdf(url) {
    var ext = url.split('.').pop();
    if(ext == "pdf" || ext == "PDF") {
      console.log("Extension :", ext);
      return false;
    }
    return true;
  }
  
  
}
