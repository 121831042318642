import { Full } from '../../helpers/full';
import { Asset } from '../assets/asset';
import { Contact } from '../contact';
import { Person } from '../person';
import { PersonDetails } from '../person-details';
import { PersonDetailsLoanOfficer } from '../person-details-loanofficer';

import { BorrowerNote } from './borrower-note';
import { BorrowerPreApproval } from './borrower-pre-approval';

/**
 * Borrower.
 */
export class Borrower extends Person {

  /**
   * Email.
   */
  public email: string;

  /**
   * Phone number.
   */
  public phoneNumber: string;

  /**
   * Friendly id.
   */
  public friendlyId: string;

  /**
   * Borrower’s “nickname” for identification.
   */
  public nickname: string;

  /**
   * Borrower’s deletedRole for identification.
   */
  public deletedRole: any;

  /**
   * Co-borrower info.
   */
  public coBorrower: Contact;

  /**
   * Associated realtor.
   */
  public realtor?: PersonDetails;

  /**
   * Associated loan officer.
   */
  public loanOfficer: PersonDetailsLoanOfficer;

  /**
   * Pre approval information.
   */
  public preApproval: BorrowerPreApproval;

  /**
   * Pre-Approval asset list.
   */
  public preApprovalAssets: Asset[];

  /**
   * Notes.
   */
  public notes: BorrowerNote[];

  public copyOfCustomPreApproval:string;

  public isCustom;

  public fieldCordinates: any;
  
  public isLoanOfficerIndependent
  
  constructor(data: Full<Borrower>) {
    super({
      firstName: data.firstName,
      lastName: data.lastName,
      id: data.id,
    });
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.friendlyId = data.friendlyId;
    this.nickname = data.nickname;
    this.coBorrower = data.coBorrower;
    this.realtor = data.realtor;
    this.loanOfficer = data.loanOfficer;
    this.preApproval = data.preApproval;
    this.preApprovalAssets = data.preApprovalAssets;
    this.notes = data.notes;
    this.copyOfCustomPreApproval=data.copyOfCustomPreApproval
    this.isCustom=data.isCustom;
    this.fieldCordinates=data.fieldCordinates;
    this.isLoanOfficerIndependent = data.isLoanOfficerIndependent
  }
}
