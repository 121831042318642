import { MatButtonColor } from './../../../models/mat-button-color';
import { ConfirmDialogComponent } from './../../dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from './../../../models/confirm-dialog-data';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Sort } from "@angular/material";
import { combineLatest, BehaviorSubject, Observable } from "rxjs";
import { map, takeUntil, tap, debounceTime } from "rxjs/operators";
import { CurrentUserService } from './../../../../core/services/current-user.service';

import {
  PaginationMetadata,
  PageEvent,
  UserStatus,
  LoanOfficerSummary,
  LoanOfficersSearchParams,
  DestroyableBase,
  listenControlChanges,
  SortConfig,
  SortDirection,
  UpdateUserStatusDto,
  Table,
  partitionTable,
  RolesService,
  UsersService
} from "../../../../core";
import { LoanOfficersTableColumn } from "../../../models/loan-officers-table-column";
import { LoanOfficersTableSecondColumn } from "../../../models/loan-officers-table-second-column";
import { UserStatusConfig } from "../../../models/user-status-config";

const COLUMNS: LoanOfficersTableColumn[] = [
  "id",
  "name",
  "borrowers",
  "realtors",
  "offers",
  "preapprovals",
  "status",
  "isMapped",
  "lastupdated",
];
const SECOND_HEADER_COLUMNS: LoanOfficersTableSecondColumn[] = [
  "empty",
  "search_name",
  "empty",
  "empty",
  "empty",
  "empty",
  "select_status",
];

/**
 * Loan officer table.
 */
@Component({
  selector: "arb-loan-officers-table",
  templateUrl: "./loan-officers-table.component.html",
  styleUrls: ["./loan-officers-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LoanOfficersTableComponent extends DestroyableBase
  implements OnInit {
  private sortConfigValue = new SortConfig<LoanOfficersTableColumn>(
    "lastupdated",
    SortDirection.Desc
  );
  private readonly pageChange$ = new BehaviorSubject(1);
  private readonly sort$ = new BehaviorSubject(
    SortConfig.fromConfig(this.sortConfigValue)
  );

  /**
   * All statuses.
   */
  public readonly statuses = UserStatus;

  /**
   * Value for all statuses.
   * If it will be `null` then mat-select doesn't set it as default value.
   */
  public readonly allStatuses = 0;

  public tableIsEmpty=false;

  /**
   * Form control for searching by loan officer's name.
   */
  public loanOfficerName = new FormControl("");

  /**
   * Form control for searching by loan officer's status.
   */
  public loanOfficerStatus = new FormControl(this.allStatuses);

  /**
   * Loan officers.
   */
  public loanOfficers$: Observable<LoanOfficerSummary[]>;

  /**
   * Metadata.
   */
  public metadata$: Observable<PaginationMetadata>;

  /**
   * Sort config.
   */
  @Input()
  public set sortConfig(value: SortConfig<LoanOfficersTableColumn>) {
    this.sortConfigValue = value;
    this.sort$.next(SortConfig.fromConfig(value));
  }

  /**
   * Status config.
   */
  @Input()
  public statusConfig: UserStatusConfig;

  /**
   * Stream with pagination and loan officers' data.
   */
  @Input()
  public table$: Observable<Table<LoanOfficerSummary>>;

  /**
   * It sends updated status to update the changed status.
   */
  @Input()
  public updatedStatus$: Observable<UserStatus>;

  /**
   * Displayed columns.
   */
  @Input()
  public displayedColumns = COLUMNS;

  /**
   * Second header columns.
   */
  @Input()
  public secondHeaderColumns = SECOND_HEADER_COLUMNS;

  /**
   * True if will be used a small version of the table.
   */
  @Input()
  public small: boolean;

  /**
   * If `true` then pagination component will be displayed.
   */
  @Input()
  public pagination: boolean;

  /**
   * If `true` then the view will be for admin in viewing associated LOs for a realtor
   */
   @Input()
   public admin: boolean;

  /**
   * Emits search event for making new search request.
   */
  @Output()
  public searchChange = new EventEmitter<LoanOfficersSearchParams>();

  /**
   * Emits new status.
   */
  @Output()
  public statusChange = new EventEmitter<UpdateUserStatusDto>();

    /**
   * Emits new mapping and realtor object.
   */
  @Output()
  public changeMappingStatus = new EventEmitter<any>();

  /**
   * Emits loan officer's id to resend invite.
   */
  @Output()
  public resendInvite = new EventEmitter<number>();

  public readonly isAdmin$ = this.rolesService.isAdmin$;

  public currentUserType = null;

  public currentUser = {};

  constructor(private rolesService: RolesService, 
    private userService: UsersService,
    private currentUserService: CurrentUserService,
    private cdr: ChangeDetectorRef,
    private matDialog:MatDialog
    ) {
    super();
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    if (typeof this.table$ === "undefined") {
      throw new Error("Table stream is not provided");
    }

    this.rolesService.isAdmin$.subscribe((isAdmin) => {
      if (!isAdmin) {
        const removeColumnIfNotAdmin = ["id","borrowers","realtors","offers","preapprovals"];
        removeColumnIfNotAdmin.forEach(
          (columnName: LoanOfficersTableColumn) => {
            var index = this.displayedColumns.indexOf(columnName);
            if (index > -1) {
              this.displayedColumns.splice(index, 1);
              this.secondHeaderColumns.splice(index, 1);
            }
          }
        );
      } else {
        let checkIndexOfMappingStatus = this.displayedColumns.findIndex(name => name == 'isMapped');
        if(checkIndexOfMappingStatus>-1) {
          this.displayedColumns.splice(checkIndexOfMappingStatus);
          this.secondHeaderColumns.splice(checkIndexOfMappingStatus);
        }
      }
    });

    [this.loanOfficers$, this.metadata$] = partitionTable(this.table$);
    this.listenSearchParamsChange();
   
    this.userService.getUserType().subscribe(res => {
      this.currentUserType = res;
    })

    this.currentUserService.currentUser$.subscribe(data => {
      console.log("table check ad", data.role);
      this.currentUser = data;
      this.cdr.detectChanges();
    });
  }

  public ngOnChanges(): void {
    this.table$.subscribe(res =>{

      if(res.data && (res.data.length>0))
      {
        this.tableIsEmpty=false;
      }
      else
      {
        this.tableIsEmpty=true;
      }


        
    });
  
  }

  private listenSearchParamsChange(): void {
    const search$ = this.createSearchStream();
    combineLatest(search$, this.pageChange$)
      .pipe(
        /* To prevent twice emiting when `search$` was changed.
      When it emits at first time the parent component starts loading and new search request.
      At the second time parent component cancells prev request (switchMap) and `finalize` operator turns off progress bar immediately.*/
        debounceTime(0),
        map(([[loanOfficerName, status, orderBy], page]) => ({
          loanOfficerName,
          status,
          page,
          orderBy,
        })),
        tap((params) => this.searchChange.emit(params)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private createSearchStream(): Observable<[string, UserStatus, string]> {
    return combineLatest(
      listenControlChanges<string>(this.loanOfficerName),
      listenControlChanges<UserStatus>(this.loanOfficerStatus, 0),
      this.sort$
    ).pipe(tap(() => this.pageChange$.next(1)));
  }

  /**
   * On page change.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.pageChange$.next(event.pageIndex);
  }

  /**
   * Emits sort event.
   * @param event Sort event.
   */
  public onSortChange(event: Sort): void {
    this.sort$.next(SortConfig.fromEvent(event));
  }

  public changeUserMappingStatus(event: any, loanOfficer: LoanOfficerSummary): void {
    event.preventDefault();
    const r = JSON.parse(JSON.stringify(loanOfficer));
    if (r.isMapped) {
      let data = new ConfirmDialogData({
        title: "Deactivate User Status",
        subtitle: "",
        rejectButtonText: "Cancel",
        acceptButtonText: "Deactivate",
        acceptButtonColor: MatButtonColor.Primary,
      })
      const dialogRef = this.openConfirmDialog(data)
      dialogRef.subscribe(value=>{
       if(value)this.changeMappingStatus.emit(r);
      })
    }else{
      this.changeMappingStatus.emit(r);
    }
  }

  /**
   * Returns sort config's instance.
   */
  public get sortConfig(): SortConfig<LoanOfficersTableColumn> {
    return this.sortConfigValue;
  }

  public onStatusChange(status: UserStatus, realtor: LoanOfficerSummary): void {
    this.statusChange.emit({ status, user: realtor });
  }

  /**
   * Emits id to resend invite.
   * @param id Loan officer's id.
   */
  public onResendInvite(id: number): void {
    this.resendInvite.emit(id);
  }

  /**
   * Open confirm dialog.
   * @param data Confirm dialog data.
   */
   public openConfirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.matDialog
      .open(ConfirmDialogComponent, { data, autoFocus: false })
      .afterClosed();
      
  }
}
