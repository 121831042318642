import { BorrowersPreApprovalService } from './../../../../borrowers/borrower-page/services/borrowers-pre-approval.service';
import { BorrowerService } from './../../../../core/services/borrower.service';
import { Point } from './../../../models/document-editor/point';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { createImage } from 'src/app/core';
import * as  pdfjsLib from 'pdfjs-dist'

@Component({
  selector: 'arb-borrower-custom-letter',
  templateUrl: './borrower-custom-letter.component.html',
  styleUrls: ['./borrower-custom-letter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BorrowerCustomLetterComponent implements OnInit {


  constructor(private route: ActivatedRoute, private bs: BorrowerService, private borrowersPreApprovalService: BorrowersPreApprovalService,) { }
  public borrowerPdf: any;
  imageArray
  borrower
  private image: HTMLImageElement;
  public customFields$: any = new BehaviorSubject([]);
  public sortedProgramType = [
    { name: "30 Year Fixed", value: 1 },
    { name: "15 Year Fixed", value: 2 },
    { name: "30 Year Fixed Conventional", value: 10 },
    { name: "5/1 ARM", value: 3 },
    { name: "7/1 ARM", value: 4 },
    { name: "10/1 ARM", value: 5 },
    { name: "15/1 ARM", value: 11 },
    { name: "6-Month ARM", value: 16 },
    { name: "DSCR Investor", value: 14 },
    { name: "FHA 30 Year Fixed", value: 6 },
    { name: "FHA 15 Year Fixed", value: 7 },
    { name: "Non-QM", value: 13 },
    { name: "USDA 30 Year Fixed", value: 12 },
    { name: "VA 30 Year Fixed", value: 8 },
    { name: "VA 15 Year Fixed", value: 9 },
    { name: "CALHFA Dream For All", value: 15 }
  ];

  public sortedPropertyType = [
    { name: "Detached", value: 1 },
    { name: "Attached", value: 2 },
    { name: "Condominium", value: 3 },
    { name: "High Rise Condo", value: 4 },
    { name: "Detached Condo", value: 5 },
    { name: "PUD", value: 6 },
    { name: "Cooperative", value: 7 },
    { name: "Manufactured", value: 8 },
    { name: "ManufacturedCondo/PUD/CoOp", value: 9 },
    { name: "Manufactured Singlewide", value: 10 },
    { name: "Manufactured Multiwide", value: 11 },
    { name: "Other", value: 12 },
    { name: "Vacant Land", value: 13 },
    { name: "Manufactured MH Advantage", value: 14 }
  ];

  public sortedOccupancy = [
    { name: "Primary Residence", value: 1 },
    { name: "Second Home", value: 2 },
    { name: "Investment Property", value: 3 },
  ];


  ngOnInit() {
    this.initBorrower()
  }

  private initBorrower(): void {
    this.route.data
      .pipe(
        tap(({ borrower }) => {


        }),
      ).subscribe(async ({ borrower }) => {
        console.log("borrower", borrower)
        this.bs.getBorrowerCustomFields(borrower.id).subscribe(res => {
          this.customFields$.next(res)
          console.log("Custom Fields : ", res);
          this.createCustomePreApproval(borrower.preApproval, borrower)
        })
      })

  }


  public async createCustomePreApproval(PreApproval, borrowerDetails) {
    // const canvasforImage = document.createElement("canvas");
    let scale = 1;
    // this.getBorrowerById(borrowerId).subscribe(res => {
    this.borrower = borrowerDetails;
    this.imageArray = [];
    let src = this.borrower.copyOfCustomPreApproval;
    let defaultCordinates: any = []

    let ProgramType: any = this.sortedProgramType.find(type => type.value == PreApproval.program)
    let PropertyType: any = this.sortedPropertyType.find(type => type.value == PreApproval.propertyType)
    let Occupancy: any = this.sortedOccupancy.find(type => type.value == PreApproval.occupancy)
    var file: File

    defaultCordinates = JSON.parse(this.borrower.fieldCordinates);
    if (PreApproval) {
      await this.getFile(src)
        .then(async (result) => {
          file = result;
          var validImageTypes = ["image/gif", "image/jpeg", , "image/png"];
          if (validImageTypes.includes(file.type)) {
            let divisionElm = document.createElement("section");
            let createCanvas = document.createElement("canvas");
            createCanvas.setAttribute("id", "customPreApprovalstatusButton");
            let body = document.getElementsByClassName("canvas-container")[0];
            body.appendChild(divisionElm);
            divisionElm.appendChild(createCanvas);
            //document.getElementsByTagName("main")[0].style.padding = "0px";
            const canvasforImage = document.getElementById("customPreApprovalstatusButton") as HTMLCanvasElement
            const contextforImage = canvasforImage.getContext('2d') as CanvasRenderingContext2D;
            // this.uploaded.emit(new RpaFileData({ file, removeRpaFile: this.removeRpaFile, rpaFileExists: true }));
            createImage(file)
              .pipe(
                tap(img => {
                  canvasforImage.width = 816;
                  canvasforImage.height = 1056;
                  this.image = img;
                  // scale = img.width.toString().length < 4 ? +(Number(img.width) * 0.01).toFixed(1) : +(Number(img.width) * 0.001).toFixed(1)
                }),
                tap(async () => {
                  contextforImage.drawImage(this.image, 0, 0, 816, 1056);
                  contextforImage.beginPath();
                  let customFields = this.customFields$.getValue();
                  const aspectRatio = {
                    x: canvasforImage.width / this.image.width, y: canvasforImage.height / this.image.height
                  }
                  for (let i = 0; i < defaultCordinates.length; i++) {
                    const element = defaultCordinates[i];
                    let keys = Object.keys(element)
                    let key = keys[0];
                    let topLeft = new Point(element[key][0] * aspectRatio.x, element[key][1] * aspectRatio.y);
                    let topRight = new Point(element[key][2] * aspectRatio.x, element[key][3] * aspectRatio.y);
                    let bottomRight = new Point(element[key][4] * aspectRatio.x, element[key][5] * aspectRatio.y);
                    let bottomLeft = new Point(element[key][6] * aspectRatio.x, element[key][7] * aspectRatio.y);
                    let width = topRight.x - topLeft.x
                    let height = bottomLeft.y - topLeft.y
                    contextforImage.fillStyle = "#ffffff";
                    contextforImage.fillRect(topLeft.x - 2, topLeft.y - 2, width + 3.25, height + 2)
                  }
                  for (let i = 0; i < defaultCordinates.length; i++) {
                    const element = defaultCordinates[i];
                    let keys = Object.keys(element)
                    let key = keys[0];
                    let topLeft = new Point(element[key][0] * aspectRatio.x, element[key][1] * aspectRatio.y);
                    let topRight = new Point(element[key][2] * aspectRatio.x, element[key][3] * aspectRatio.y);
                    let bottomRight = new Point(element[key][4] * aspectRatio.x, element[key][5] * aspectRatio.y);
                    let bottomLeft = new Point(element[key][6] * aspectRatio.x, element[key][7] * aspectRatio.y);
                    let width = topRight.x - topLeft.x
                    let height = bottomLeft.y - topLeft.y
                    let field = customFields.find(cf => cf.code.replace(/[<#%>_]/g, '').toLowerCase() == key.toLowerCase()
                      || (key.toLowerCase() == "ltv" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "itv")
                      || (key.toLowerCase() == "today'sdate" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "date")
                      || (key.toLowerCase() == "ltv" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "loantovalue")
                      || (key.toLowerCase() == "maxltv" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "maxloantovalue")
                      || (key.toLowerCase() == "cltv" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "combinedloantovalue")
                      || (key.toLowerCase() == "borrower" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "borrower-first-name-and-last-name")
                      || (key.toLowerCase() == "coborrower" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "coborrower-first-name-and-last-name")
                      || (key.toLowerCase() == "combinedname" && cf.code.replace(/[<%#_>]/g, '').toLowerCase() == "borrower-and-coborrower-first-name-and-last-name")) || {};
                    if (!Object.keys(field).length && !field.isChecked) {
                      continue;
                    }
                    var fontSize: number = field.fontSize;
                    var fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                    var fontColor = field.fontColor ? field.fontColor : "#000"
                    var fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                    var fontColor = field.fontColor ? field.fontColor : "#000"
                    contextforImage.fillStyle = fontColor;
                    contextforImage.textBaseline = "top";
                    let t = (bottomLeft.y - topLeft.y).toFixed(0);
                    topLeft.y = topLeft.y + (((bottomLeft.y - topLeft.y) - (fontSize * 1.333)) / 2)
                    contextforImage.font = `${fontSize * 1.333}px ${fontFamily}`;
                    //console.log("pt-", fontSize,"px-",fontSize *1.333);
                    if ((field.code.replace(/[<%#_>]/g, '').toLowerCase() == key.toLowerCase())
                      || (key.toLowerCase() == "ltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "loantovalue")
                      || (key.toLowerCase() == "maxltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "maxloantovalue")
                      || (key.toLowerCase() == "cltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "combinedloantovalue")
                      || (key.toLowerCase() == "today'sdate" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "date")
                      || (key.toLowerCase() == "borrower" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "borrower-first-name-and-last-name")
                      || (key.toLowerCase() == "coborrower" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "coborrower-first-name-and-last-name")
                      || (key.toLowerCase() == "combinedname" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "borrower-and-coborrower-first-name-and-last-name"))
                      if (field.customeFieldValue) {
                        contextforImage.fillText(this.showCustomField(field.customeFieldValue, field.inputType), topLeft.x, topLeft.y);
                      }
                      else {
                        if (key.toLowerCase() == "address") {
                          if (PreApproval["address"] && PreApproval["address"].length) {
                            contextforImage.fillText(PreApproval["address"], topLeft.x, topLeft.y);
                          }
                          else
                            contextforImage.fillText("TBD", topLeft.x, topLeft.y);
                          contextforImage.stroke();
                        }
                        else if (key.toLowerCase() == "downpayment" && PreApproval["downPayment"]) {
                          if (PreApproval["downPaymentType"] == 2) {
                            contextforImage.fillText(this.showCustomField(PreApproval["downPayment"], 'price'), topLeft.x, topLeft.y);
                          } else {
                            contextforImage.fillText(this.showCustomField(PreApproval["downPayment"], 'percentage'), topLeft.x, topLeft.y);
                          }
                          contextforImage.stroke();
                        }
                        else if (key.toLowerCase() == "fico" && PreApproval["fico"]) {
                          contextforImage.fillText(PreApproval["fico"], topLeft.x, topLeft.y);
                          contextforImage.stroke();
                        }
                        else if (key.toLowerCase() == "occupancy" && PreApproval["occupancy"]) {
                          contextforImage.fillText(Occupancy.name, topLeft.x, topLeft.y);

                          contextforImage.stroke();

                        }
                        else if (key.toLowerCase() == "programtype" && PreApproval["program"]) {
                          contextforImage.fillText(ProgramType.name, topLeft.x, topLeft.y);
                          contextforImage.stroke();
                        }
                        else if (key.toLowerCase() == "propertytype" && PreApproval.propertyType) {
                          contextforImage.fillText(PropertyType.name, topLeft.x, topLeft.y);
                          contextforImage.stroke();

                        }
                        else if (key.toLowerCase() == "purchaseprice") {
                          if (PreApproval.purchasePrice)
                            contextforImage.fillText(this.showCustomField(PreApproval["purchasePrice"], 'price'), topLeft.x, topLeft.y);
                          else {
                            contextforImage.fillText(this.showCustomField(PreApproval["maxPurchasePrice"], 'price'), topLeft.x, topLeft.y);
                          }
                          contextforImage.stroke();

                        }
                        else if (key.toLowerCase() == "borrower") {
                          contextforImage.fillText(this.borrower.firstName + " " + this.borrower.lastName, topLeft.x, topLeft.y);
                          contextforImage.stroke();
                        }
                        else if ((key.toLowerCase() == "coborrower" && this.borrower.coBorrower.firstName != null) || (key.toLowerCase() == "coborrower" && this.borrower.coBorrower.lastName != null)) {
                          contextforImage.fillText(this.borrower.coBorrower.firstName + " " + this.borrower.coBorrower.lastName, topLeft.x, topLeft.y);
                          contextforImage.stroke();

                        }
                        else if ((key.toLowerCase() == "combinedname")) {
                          if (this.borrower.coBorrower.firstName.length > 0 || this.borrower.coBorrower.lastName.length > 0) {
                            contextforImage.fillText(this.borrower.firstName + " " + this.borrower.lastName + " and " +
                              this.borrower.coBorrower.firstName + " " + this.borrower.coBorrower.lastName + ",", topLeft.x, topLeft.y);
                            contextforImage.stroke();
                          }
                          else {
                            contextforImage.fillText(this.borrower.firstName + " " + this.borrower.lastName + ",", topLeft.x, topLeft.y);
                            contextforImage.stroke();
                          }
                        }
                        else if (field.customeFieldValue) {
                          contextforImage.fillText(this.showCustomField(field.customeFieldValue, field.inputType), topLeft.x, topLeft.y);
                          contextforImage.stroke();
                        }
                      }
                  }
                  contextforImage.stroke();
                  canvasforImage.toBlob((blob: Blob) => {
                    let image = new File([blob], "custom-pre-approval.png", { type: "image/png" })
                    this.borrowersPreApprovalService.uploadAsset({ id: this.borrower.id, assetTypeId: 7, files: [image] }, this.borrower.id).subscribe(responseFromUploadAsset => {
                      //   this.updateBorrowerData(this.borrower.id);
                      //   this.getPreApprovalAssetsPdf(this.borrower.id, this.borrower.id).subscribe()
                    })
                  })
                }))
              .subscribe();
          } else {
            let totalPages;
            this.imageArray = [];
            await pdfjsLib.getDocument(src).promise.then(async (doc) => {
              let numpages = doc.numPages;
              totalPages = numpages
              for (let index = 1; index <= numpages; index++) {
                const page = await doc.getPage(index)
                let pageNumer = page.pageNumber;
                let divisionElm = document.createElement("section");
                let createCanvas = document.createElement("canvas");
                createCanvas.setAttribute("id", "canvas-mergefields-" + pageNumer);
                let body = document.getElementsByClassName("canvas-container")[0];
                body.appendChild(divisionElm);
                divisionElm.appendChild(createCanvas);
                let canvas = <HTMLCanvasElement>document.getElementById("canvas-mergefields-" + pageNumer);
                let context: any = canvas.getContext("2d");
                canvas.width = 816;
                canvas.height = 1056;
                var unscaledViewport = page.getViewport({ scale: 1 });
                var scale = Math.min((canvas.height / unscaledViewport.height), (canvas.width / unscaledViewport.width));
                let viewPort = page.getViewport({ scale: scale });
                // let viewPort = page.getViewport({ scale: canvas.width / page.getViewport({ scale: 1 }).width });
                //canvas.height = viewPort.height;
                console.log("----viewPort---", viewPort)
                context.clearRect(0, 0, canvas.width, canvas.height);
                await page.render({
                  canvasContext: context,
                  viewport: viewPort,
                })

                let imageData = canvas.toDataURL("image/png")
                this.getFile(imageData).then((image) => {
                  createImage(image).subscribe(res => {
                    context = canvas.getContext('2d') as CanvasRenderingContext2D;
                    if (context) {
                      context.clearRect(0, 0, canvas.width, canvas.height)
                      context.drawImage(res, 0, 0, canvas.width, canvas.height)
                      context.beginPath()
                      let viewPortHeightDiff = 72 * viewPort.scale;

                      for (let index = 0; index < defaultCordinates.length; index++) {
                        const element = defaultCordinates[index];
                        let keys = Object.keys(element);
                        let key = keys[0]
                        let data = element[key].cordinates;
                        let pageNo = element[key].pageNo
                        if (data && pageNo == pageNumer) {
                          let topLeft = new Point((data[0] * viewPortHeightDiff), (data[1] * viewPortHeightDiff));
                          let topRight = new Point((data[2] * viewPortHeightDiff), (data[3] * viewPortHeightDiff));
                          let bottomRight = new Point((data[4] * viewPortHeightDiff), (data[5] * viewPortHeightDiff));
                          let bottomLeft = new Point((data[6] * viewPortHeightDiff), (data[7] * viewPortHeightDiff));
                          let width = topRight.x - topLeft.x
                          let height = bottomLeft.y - topLeft.y
                          context.fillStyle = "#ffffff";
                          context.fillRect(topLeft.x - 2, topLeft.y - 2, width + 3.25, height + 2)
                        }
                      }

                      for (let index = 0; index < defaultCordinates.length; index++) {
                        const element = defaultCordinates[index];
                        let keys = Object.keys(element);
                        let key = keys[0]
                        let data = element[key].cordinates;
                        let pageNo = element[key].pageNo;
                        if (data && pageNo == pageNumer) {
                          let topLeft = new Point((data[0] * viewPortHeightDiff), (data[1] * viewPortHeightDiff));
                          let originalTopLeft = new Point((data[0] * viewPortHeightDiff), (data[1] * viewPortHeightDiff));
                          let topRight = new Point((data[2] * viewPortHeightDiff), (data[3] * viewPortHeightDiff));
                          let bottomRight = new Point((data[4] * viewPortHeightDiff), (data[5] * viewPortHeightDiff));
                          let bottomLeft = new Point((data[6] * viewPortHeightDiff), (data[7] * viewPortHeightDiff));
                          let width = topRight.x - topLeft.x
                          let height = bottomLeft.y - topLeft.y
                          let newTopLeftY = topLeft.y;
                          //get the custom fields
                          let customFields = this.customFields$.getValue();
                          customFields.map(field => {
                            let fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                            let fontColor = field.fontColor ? field.fontColor : "black"
                            let fontSize: number = field.fontSize;
                            // let fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                            // let fontColor = field.fontColor ? field.fontColor : "black"
                            context.fillStyle = fontColor;
                            // context.textBaseline = "center";
                            context.textBaseline = 'top';
                            //context.textAlign = 'middle';
                            let t = ((bottomLeft.y - newTopLeftY)).toFixed(2);
                            var thresholdDate = new Date("Thu, 21 Jul 2022 00:00:00 GMT");
                            // date check added
                            let adjustFontBy = 0;
                            if (PreApproval.createdAt && (getTimeOfCreatedDate(PreApproval.createdAt) >= thresholdDate.getTime())) {
                              context.font = `${fontSize * 1.33333}px ${fontFamily}` // `${+t/1.333}pt ${fontFamily}`;

                              try {
                                adjustFontBy = (((bottomLeft.y - originalTopLeft.y) - (fontSize * 1.333)) / 2)
                                if (adjustFontBy < 0) {
                                  adjustFontBy = 0;
                                  console.log("adjust font size less than 0");
                                }
                              }
                              catch (ex) {
                                console.log(ex)
                              }
                            }
                            else {
                              context.font = `${t}px Ariel`;
                              adjustFontBy = 0;
                            }
                            //topLeft.y = topLeft.y + (((bottomLeft.y - topLeft.y) - (fontSize * 1.333))/2)
                            //console.log(bottomLeft.y, topLeft.y, (((bottomLeft.y - topLeft.y) - (fontSize * 1.333))/2))
                            newTopLeftY = topLeft.y;
                            console.log("pt-", fontSize, "px-", fontSize * 1.333);
                            if ((field.code.replace(/[<%#_>]/g, '').toLowerCase() == key.toLowerCase())
                              || (key.toLowerCase() == "ltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "itv")
                              || (key.toLowerCase() == "maxltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "maxloantovalue")
                              || (key.toLowerCase() == "today'sdate" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "date")
                              || (key.toLowerCase() == "ltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "loantovalue")
                              || (key.toLowerCase() == "cltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "combinedloantovalue")
                              || (key.toLowerCase() == "borrower" && field.code.replace(/[<#>]/g, '').toLowerCase() == "borrower-first-name-and-last-name")
                              || (key.toLowerCase() == "coborrower" && field.code.replace(/[<#>]/g, '').toLowerCase() == "coborrower-first-name-and-last-name")
                              || (key.toLowerCase() == "combinedname" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "borrower-and-coborrower-first-name-and-last-name")) {
                              if (field.customeFieldValue) {
                                // console.log("---context.font---",field.code, `${fontSize}`,bottomLeft.y - newTopLeftY,(fontSize * 1.33));
                                console.log(key, originalTopLeft.y, bottomLeft.y, (((bottomLeft.y - originalTopLeft.y) - (fontSize * 1.333)) / 2))
                                context.fillText(this.showCustomField(field.customeFieldValue, field.inputType), topLeft.x, newTopLeftY + adjustFontBy);
                              }
                              else {
                                if (key.toLowerCase() == "address") {
                                  if (PreApproval["address"] && PreApproval["address"].length) {
                                    context.fillText(PreApproval["address"], topLeft.x, newTopLeftY + adjustFontBy);
                                  }
                                  else
                                    context.fillText("TBD", topLeft.x, newTopLeftY + adjustFontBy);
                                }
                                if (key.toLowerCase() == "downpayment" && PreApproval["downPayment"]) {
                                  if (PreApproval["downPaymentType"] == 2) {
                                    context.fillText(this.showCustomField(PreApproval["downPayment"], 'price'), topLeft.x, newTopLeftY + adjustFontBy);
                                  } else {
                                    context.fillText(this.showCustomField(PreApproval["downPayment"], 'percentage'), topLeft.x, newTopLeftY + adjustFontBy);
                                  }
                                }
                                if (key.toLowerCase() == "fico" && PreApproval["fico"]) {
                                  context.fillText(PreApproval["fico"], topLeft.x, newTopLeftY + adjustFontBy);
                                }
                                if (key.toLowerCase() == "occupancy" && PreApproval["occupancy"]) {
                                  context.fillText(Occupancy.name, topLeft.x, newTopLeftY + adjustFontBy);
                                }
                                if (key.toLowerCase() == "programtype" && PreApproval["program"]) {
                                  context.fillText(ProgramType.name, topLeft.x, newTopLeftY + adjustFontBy);
                                }
                                if (key.toLowerCase() == "propertytype" && PreApproval.propertyType) {
                                  context.fillText(PropertyType.name, topLeft.x, newTopLeftY + adjustFontBy);
                                }
                                if (key.toLowerCase() == "purchaseprice") {

                                  if (PreApproval.purchasePrice)
                                    context.fillText(this.showCustomField(PreApproval["purchasePrice"], 'price'), topLeft.x, newTopLeftY + adjustFontBy);
                                  else {
                                    context.fillText(this.showCustomField(PreApproval["maxPurchasePrice"], 'price'), topLeft.x, newTopLeftY + adjustFontBy);
                                  }
                                }
                                if (key.toLowerCase() == "borrower") {
                                  context.fillText(this.borrower.firstName + " " + this.borrower.lastName, topLeft.x, newTopLeftY + adjustFontBy);
                                }
                                if ((key.toLowerCase() == "coborrower" && this.borrower.coBorrower.firstName != null) || (key.toLowerCase() == "coborrower" && this.borrower.coBorrower.lastName != null)) {
                                  context.fillText(this.borrower.coBorrower.firstName + " " + this.borrower.coBorrower.lastName, topLeft.x, newTopLeftY + adjustFontBy);
                                }
                                if (key.toLowerCase() == "combinedname") {
                                  if (this.borrower.coBorrower.firstName.length > 0 || this.borrower.coBorrower.lastName.length > 0) {
                                    context.fillText(this.borrower.firstName + " " + this.borrower.lastName + " and " +
                                      this.borrower.coBorrower.firstName + " " + this.borrower.coBorrower.lastName + ",", topLeft.x, newTopLeftY + adjustFontBy);
                                  }
                                  else {
                                    context.fillText(this.borrower.firstName + " " + this.borrower.lastName + ",", topLeft.x, newTopLeftY + adjustFontBy);
                                  }
                                }
                              }
                            }
                          })
                        }
                      }

                      context.stroke();
                      canvas.toBlob(async (blob: Blob) => {
                        this.imageArray.push(new File([blob], index + ".png", { type: "image/png" }))
                        //console.log("Image Array",this.imageArray);
                        //console.log("Image[0]",this.imageArray[0].name.split('.').slice(0, -1).join('.'));
                        let sortedArray = this.imageArray.sort((a, b) => (Number(a.name.split(".")[0]) < Number(b.name.split(".")[0]) ? -1 : 1));
                        //console.log("Sorted Array", sortedArray);
                        if (this.imageArray.length == numpages) {
                          await this.borrowersPreApprovalService.uploadAsset({ id: this.borrower.id, assetTypeId: 7, files: sortedArray }, this.borrower.id).subscribe(responseFromUploadAsset => {
                            // this.updateBorrower(this.borrower.id).subscribe()
                            // this.getPreApprovalAssetsPdf(this.borrower.id, this.borrower.id).subscribe()
                          })
                        }
                      })
                    }
                  })
                })
              }
            }
            )
          }
        })
        .catch(err => console.log(err))

    }

    function getTimeOfCreatedDate(params: any) {
      if (params && params.length) {
        return (new Date(params)).getTime();
      }
      return 0;
    }
    // });
  }



  private async getFile(src: string) {
    let file;
    try {
      const res = await fetch(src)
      const resData = await res.blob()
      let data = resData.type.split("/")
      let metadata = {
        type: resData.type
      };
      file = new File([resData], "preapproval_letter." + data[1], metadata);
      return file;
    } catch (error) {
      console.log("Error while updating supporting doc title", error);
    }
  }

  showCustomField(value, inputType) {
    if (inputType == "percentage" && value) {
      return `${value}%`
    } if (inputType == "price" && value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    } else if (inputType == "date" && value) {
      return value
      // new Date().toLocaleDateString('en-us', {  year:"numeric", month:"short", day:"numeric"})
    } else if (inputType == "text" && value) {
      return value
    } else {
      return "N/A"
    }
  }

  loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    })
  }

}
