/**
 * Pre-approval program type.
 */
export enum ProgramType {

 
  /**
   * Thirty year fixed.
   */
  ThirtyYearFixed=1,

  /**
   * Fifteen year fixed.
   */
  FifteenYearFixed,

  /**
   * Five one arm.
   */
  FiveOneARM,

  /**
   * Seven one arm.
   */
  SevenOneARM,

  /**
   * Ten one arm.
   */
  TenOneARM,

  /**
   * FHA thirty year fixed.
   */
  FHAThirtyYearFixed,

  /**
   * FHA fifteen year fixed.
   */
  FHAFifteenYearFixed,
  
  /**
   * VA thirty year fixed.
   */
  VAThirtyYearFixed,

  /**
   * VA fifteen year fixed.
   */
  VAFifteenYearFixed,
 
  /* 
 Thirty Year Fixed Conventional, 
  */ 

 ThirtyYearFixedConventional,
 /**
  15/1 ARM
  */
 FifteenOneARM,
 
 /**
  6-Month ARM
  */
 SixMonthARM,

  /**
   * USDA thirty year fixed.
  */
  USDAThirtyYearFixed,

  /**
   * Non-QM.
  */
  NonQM,
  /**
  * DSCR invenstor
  */
  DSCRinvestor,
  
  /**
  * CALHFA Dream For All
  */
  CALHFADreamForAll,

}

const types: Record<ProgramType, string> = {
  [ProgramType.ThirtyYearFixed]: '30 Year Fixed',
  [ProgramType.FifteenYearFixed]: '15 Year Fixed',
  [ProgramType.FiveOneARM]: '5/1 ARM',
  [ProgramType.SevenOneARM]: '7/1 ARM',
  [ProgramType.TenOneARM]: '10/1 ARM',
  [ProgramType.DSCRinvestor]:'DSCR Investor',
  [ProgramType.FHAThirtyYearFixed]: 'FHA 30 Year Fixed',
  [ProgramType.FHAFifteenYearFixed]: 'FHA 15 Year Fixed',
  [ProgramType.USDAThirtyYearFixed]:"USDA 30 Year Fixed",
  [ProgramType.VAThirtyYearFixed]: 'VA 30 Year Fixed',
  [ProgramType.VAFifteenYearFixed]: 'VA 15 Year Fixed',
  [ProgramType.ThirtyYearFixedConventional]: '30 Year Fixed Conventional',
  [ProgramType.FifteenOneARM]: '15/1 ARM',
  [ProgramType.SixMonthARM]: '6-Month ARM',
  [ProgramType.NonQM]:'Non-QM',
  [ProgramType.CALHFADreamForAll]:'CALHFA Dream For All',
  
};

/**
 * Returns program type in readable format.
 * @param type Type.
 */
export function toReadableProgramType(type: ProgramType): string {
  return types[type];
}
