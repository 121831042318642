import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/webpack';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap, concatMap, map, mapTo, skip } from 'rxjs/operators';

import { createImage } from '../../../../core';
import { PageInfo } from '../page-info';
import { PdfEditor } from '../pdf-editor';
import { Rectangle } from '../rectangle';

import { EditorRenderer } from './editor-renderer';
import { PdfEditorRendererOptions } from './pdf-editor-renderer-options';

/**
 * Pdf renderer.
 */
export class PdfRenderer extends EditorRenderer {

  /**
   * Reference to current editor.
   */
  protected readonly editor: PdfEditor;

  /**
   * Scale of the current viewport to render.
   */
  protected readonly scale: number;

  /**
   * A reference to the upper canvas.
   */
  protected readonly upperCanvas: HTMLCanvasElement;

  /**
   * Context of the upper canvas.
   */
  protected readonly upperCanvasContext: CanvasRenderingContext2D;

  /**
   * Mouse up stream.
   */
  protected readonly mouseUp$: Subject<void>;

  constructor(editor: PdfEditor, options: PdfEditorRendererOptions) {
    super(options);
    this.upperCanvas = options.upperCanvas;
    this.upperCanvasContext = options.upperCanvasContext;
    this.editor = editor;
    this.scale = 1.5;
    this.mouseUp$ = new Subject();
  }

  /**
   * Renders clean current page.
   * @param pdf Pdf's object to get current page.
   * @param pageIndex Current page's index.
   */
  protected getAndrenderCleanCurrentPdfPage(pdf: PDFDocumentProxy, pageIndex: number): Observable<PDFPageProxy> {
    return this.getPageFromPdf(pdf, pageIndex)
      .pipe(
        switchMap(page => this.renderPdfPage(page, this.context, this.canvas, this.upperCanvas)
          .pipe(
            mapTo(page),
          ),
        ),
      );
  }

  /**
   * Renders pdf page.
   * @param page Page to render.
   * @param context Canvas context.
   * @param canvas Main canvas.
   * @param upperCanvas Upper canvas.
   */
  protected renderPdfPage(
    page: PDFPageProxy,
    context: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    upperCanvas: HTMLCanvasElement,
  ): Observable<void> {
    return new Observable<void>(observer => {
      const viewport = page.getViewport({ scale: this.scale });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      upperCanvas.width = viewport.width;
      upperCanvas.height = viewport.height;
      if (viewport.width > this.canvasMaxWidth) {
        this.diffX = Number((viewport.width / this.canvasMaxWidth).toFixed(2));
        const realHeight = (this.canvasMaxWidth * viewport.height) / viewport.width;
        this.diffY = Number((viewport.height / realHeight).toFixed(2));
      }
      page.render({ viewport, canvasContext: context }).promise.then(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  private getPageFromPdf(pdf: PDFDocumentProxy, pageIndex: number): Observable<PDFPageProxy> {
    return new Observable<PDFPageProxy>(observer => {
      pdf.getPage(pageIndex).then(page => {
          observer.next(page);
          observer.complete();
        },
        error => observer.error(error));
    });
  }

  /**
   * Returns rectangles of current page.
   */
  protected get rectangles(): Rectangle[] {
    return this.currentPageInfo.rectangles;
  }

  protected set rectangles(value: Rectangle[]) {
    this.currentPageInfo = {
      ...this.currentPageInfo,
      rectangles: value,
    };
  }

  /**
   * Current page info.
   */
  public get currentPageInfo(): PageInfo {
    return this.renderedPages.get(this.currentPageIndex) as PageInfo;
  }

  public set currentPageInfo(value: PageInfo) {
    this.renderedPages.set(this.currentPageIndex, value);
  }

  /**
   * Current page index.
   */
  protected get currentPageIndex(): number {
    return this.editor.currentPageIndex$.value;
  }

  /**
   * Context of the upper canvas of the page preview.
   */
  protected get previewUpperContext(): CanvasRenderingContext2D {
    return this.currentPageInfo.previewUpperContext;
  }

  /**
   * A reference to the upper canvas element of the page preview.
   */
  protected get previewUpperCanvas(): HTMLCanvasElement {
    return this.currentPageInfo.previewUpperCanvas;
  }

  /**
   * Context of the main canvas of the page preview.
   */
  protected get previewContext(): CanvasRenderingContext2D {
    return this.currentPageInfo.previewContext;
  }

  /**
   * A reference to the main canvas of the page preview.
   */
  protected get previewCanvas(): HTMLCanvasElement {
    return this.currentPageInfo.previewCanvas;
  }

  /**
   * Returns sorted array of files of selected pages.
   */
  public getFilesToSave(): File[] {
    const files: File[] = [];
    const sorted = Array.from(this.renderedPages.entries()).sort(([index1], [index2]) => index1 - index2);
    for (const [index, { file }] of sorted) {
      if (this.editor.indexesOfFilesToSave$.value.has(index)) {
        files.push(file);
      }
    }
    return files;
  }

  /**
   * Rendered pages.
   */
  protected get renderedPages(): Map<number, PageInfo> {
    return this.editor.renderedPages;
  }

  /**
   * @inheritdoc
   */
  protected get fileName(): string {
    return this.editor.file.name.replace('.pdf', '.png');
  }

  /**
   * @inheritdoc
   */
  protected get fileType(): string {
    return 'image/png';
  }

  /**
   * @inheritdoc
   */
  public disable(): void {
    this.disabled = true;
  }

  /**
   * Sets current page.
   * @param pageNumber Page number.
   */
  public setPage(pageNumber: number): void {
    this.editor.currentPageIndex$.next(pageNumber);
  }

  /**
   * Adds page to save list.
   * @param pageNumber Page number.
   */
  public addPageToSaveList(pageNumber: number): void {
    const indexes = this.editor.indexesOfFilesToSave$.value;
    indexes.add(pageNumber);
    this.editor.indexesOfFilesToSave$.next(indexes);
  }

  /**
   * Deletes page from save list.
   * @param pageNumber Page number.
   */
  public deletePageFromSaveList(pageNumber: number): void {
    const indexes = this.editor.indexesOfFilesToSave$.value;
    indexes.delete(pageNumber);
    this.editor.indexesOfFilesToSave$.next(indexes);
  }

  /**
   * @inheritdoc
   */
  public onMouseUp(): void {
    /* Override in child class. */
  }

  /**
   * On current page change.
   * @param pdf Pdf.
   * @param pageIndex Current page index.
   */
  public onCurrentPageChange(pdf: PDFDocumentProxy, pageIndex: number): Observable<PDFPageProxy> {
    /* Clean upper canvas (remove all rectangles from it). */
    this.upperCanvasContext.clearRect(0, 0, this.upperCanvas.width, this.upperCanvas.height);
    /* Make first clean page render with `pdfjs` and then render edited page with rendered file. */
    /* `pdf-page-preview` component sets current page info after its `onInit`. */
    /* But this code runs before `pdf-page-preview` is rendered, so for the first time we need to render with `pdfjs`. */
    if (this.currentPageInfo && this.currentPageInfo.file) {
      return createImage(this.currentPageInfo.file)
        .pipe(
          tap(img => {
            this.canvas.width = img.width;
            this.canvas.height = img.height;
            this.upperCanvas.width = img.width;
            this.upperCanvas.height = img.height;
            this.context.drawImage(img, 0, 0);
          }),
          switchMap(() => this.getPageFromPdf(pdf, pageIndex)),
        );
    }
    return this.getAndrenderCleanCurrentPdfPage(pdf, pageIndex);
  }

}
