import { Point } from './point';

/**
 * Rectangle.
 */
export class RectangleCustom {

  private styleValue: string;

  /**
   * Start x coordinate.
   */
  public x: number;

  /**
   * Start y coordinate.
   */
  public y: number;


  constructor(x:number, y: number, style: string = '#000000') {
    this.styleValue = style;
    this.x = x;
    this.y = y;
  }

  /**
   * Style.
   */
  public get style(): string {
    return this.styleValue;
  }

}
