import { Subject, fromEvent, combineLatest } from 'rxjs';
import { tap, takeUntil, filter, startWith, map, concatMap } from 'rxjs/operators';

import { createImage } from '../../../../core';
import { ImageEditor } from '../image-editor';
import { Rectangle } from '../rectangle';

import { EditorRenderer } from './editor-renderer';
import { EditorRendererOptions } from './editor-renderer-options';

/**
 * Image rectangle renderer.
 */
export class ImageRectangleRenderer extends EditorRenderer {

  private readonly mouseUp$: Subject<void>;
  private rectangles: Rectangle[];
  private image: HTMLImageElement;
  private isHighlighter: Boolean;

  /**
   * @inheritdoc
   */
  protected readonly editor: ImageEditor;

  constructor(editor: ImageEditor, options: EditorRendererOptions, isHighlighter: Boolean = false) {
    super(options);
    this.rectangles = [];
    this.editor = editor;
    this.isHighlighter = isHighlighter;
    this.mouseUp$ = new Subject();
    this.init(editor.file);
    this.listenAreaSelecting();
    this.listenFilesChanges();
  }

  private init(file: File): void {
    createImage(file)
      .pipe(
        tap(img => {
          this.canvas.width = img.width;
          this.canvas.height = img.height;
          this.image = img;
        }),
        tap(() => this.context.drawImage(this.image, 0, 0)),
        tap(() => this.calculateDifference()),
        /* To trigger `filesChange$` to emit renderer file. */
        tap(() => this.mouseUp$.next()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private calculateDifference(): void {
    if (this.canvas.width > this.canvasMaxWidth) {
      this.diffX = Number((this.canvas.width / this.canvasMaxWidth).toFixed(2));
      const realHeight = (this.canvasMaxWidth * this.canvas.height) / this.canvas.width;
      this.diffY = Number((this.canvas.height / realHeight).toFixed(2));
      this.diffX = this.diffX < 1 ? 1 : this.diffX;
      this.diffY = this.diffY < 1 ? 1 : this.diffY;
    }
  }

  private listenAreaSelecting(): void {
    this.mouseMove$
      .pipe(
        filter(() => !this.disabled && this.isSelectingInProgress),
        tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
        tap(() => this.context.drawImage(this.image, 0, 0)),
        tap(() => this.renderRectangles()),
        tap(endPoint => this.endPoint = endPoint),
        tap(() => (this.isHighlighter ? this.drawSelection(this.context, "#ffff00") : this.drawSelection(this.context))),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenFilesChanges(): void {
    const undo$ = this.undo$
      .pipe(
        filter(() => this.rectangles.length > 0),
        tap(() => this.rectangles.pop()),
        tap(() => this.render()),
        startWith(null),
      );
    combineLatest(
      undo$,
      this.mouseUp$,
    )
      .pipe(
        concatMap(() => this.convertToFile()),
        tap(file => this.editor.file = file),
        tap(file => this.editor.filesChange$.next([file])),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private render(): void {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.drawImage(this.image, 0, 0);
    this.renderRectangles();
  }

  private renderRectangles(): void {
    for (const rect of this.rectangles) {
      this.renderRect(this.context, rect);
    }
  }

  /**
   * @inheritdoc
   */
  public disable(): void {
    this.disabled = true;
  }

  /**
   * @inheritdoc
   */
  public onMouseUp(): void {
    if (!this.disabled && this.isSelectingInProgress) {
      this.isSelectingInProgress = false;
      const rectYellow = new Rectangle(this.startPoint, this.endPoint, "#ffff00");
      const rect = new Rectangle(this.startPoint, this.endPoint);
      if ( this.isHighlighter) {
        this.rectangles.push(rectYellow);
        this.renderRect(this.context, rectYellow);
      } else {
        this.rectangles.push(rect);
        this.renderRect(this.context, rect);

      }
      this.mouseUp$.next();
    }
  }

}
