import { Injectable } from '@angular/core';
import { NgxRolesObject, NgxRolesService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserRole } from '../models/user-role';
import { weakShareReplay } from '../rxjs-operators/public_api';

/**
 * Roles service.
 */
@Injectable({
  providedIn: 'root',
})
export class RolesService {

  private isLoggedIn = false;

  /**
   * Returns current role stream.
   */
  public readonly currentRole$ = this.createCurrentRoleStream();

  /**
   * Returns `true` if current user is admin.
   */
  public readonly isAdmin$ = this.createIsAdminStream();

  /**
   * Returns `true` if current user is loan officer.
   */
  public readonly isLoanOfficer$ = this.createIsLoanOfficerStream();

  /**
   * Returns `true` if current user is realtor.
   */
  public readonly isRealtor$ = this.createIsRealtorStream();

  constructor(
    private ngxRolesService: NgxRolesService,
  ) { }

  private createCurrentRoleStream(): Observable<UserRole> {
    return this.ngxRolesService.roles$
      .pipe(
        /* `roles$` stream emits empty object when `flushRoles` has been called.
            So `getFirstRole` method throws an error. */
        filter(() => this.isLoggedIn),
        map(roles => this.getFirstRole(roles)),
        weakShareReplay(1),
      );
  }

  private getFirstRole(roles: NgxRolesObject): UserRole {
    for (const role of Object.keys(roles)) {
      return role as UserRole;
    }
    throw new Error('Current user doesn\'t have role');
  }

  private createIsAdminStream(): Observable<boolean> {
    return this.currentRole$
      .pipe(
        map(role => role === UserRole.Admin),
        weakShareReplay(1),
      );
  }

  private createIsLoanOfficerStream(): Observable<boolean> {
    return this.currentRole$
      .pipe(
        map(role => role === UserRole.LoanOfficer),
        weakShareReplay(1),
      );
  }

  private createIsRealtorStream(): Observable<boolean> {
    return this.currentRole$
      .pipe(
        map(role => role === UserRole.Realtor),
        weakShareReplay(1),
      );
  }

  /**
   * Add role.
   * @param role Role.
   */
  public addRole(role: string): void {
    this.isLoggedIn = true;
    this.ngxRolesService.addRole(role, []);
  }

  /**
   * Remove all roles.
   */
  public flushRoles(): void {
    this.isLoggedIn = false;
    this.ngxRolesService.flushRoles();
  }

  /**
   * Returns current role.
   */
  public getRole(): UserRole {
    const roles = this.ngxRolesService.getRoles();
    return this.getFirstRole(roles);
  }

}
