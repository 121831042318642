/**
 * Info that required for changing the forgotten password.
 */
export class ForgotPasswordDto {

  /**
   * Email.
   */
  public email: string;

  constructor(email: string) {
    this.email = email.trim();
  }
}
