import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

import { PaginationMetadata, PageEvent } from '../../../../core';

const COLUMNS: string[] = ['id', 'name', 'permissions', 'updatedAt'];
const SECOND_HEADER_COLUMNS: string[] = ['search_id', 'search_name', 'select_permission', 'empty'];

/**
 * Assistants table component.
 */
@Component({
  selector: 'arb-assistants-table',
  templateUrl: './assistants-table.component.html',
  styleUrls: ['./assistants-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AssistantsTableComponent {

  /**
   * Data source.
   */
  @Input()
  public dataSource: unknown[];

  /**
   * Displayed columns.
   */
  public displayedColumns = COLUMNS;

  /**
   * Second header columns.
   */
  public secondHeaderColumns = SECOND_HEADER_COLUMNS;

  /**
   * Metadata.
   * @todo It should be removed when API will be ready.
   */
  public dummyMetadata: PaginationMetadata = {
    page: 1,
    totalPages: 50,
    totalCount: 500,
    isFirstPage: false,
    isLastPage: false,
    pageSize: 10,
  };

  /**
   * Formats permissions.
   * @param permissions Permissions.
   */
  public formatPermissions(permissions: string[]): string {
    return permissions.join('/');
  }

  /**
   * On page change.
   * @param page Page.
   * @todo It should be removed when API will be ready.
   */
  public onPageChange(pageEvent: PageEvent): void {
    this.dummyMetadata = {
      ...this.dummyMetadata,
      page: pageEvent.pageIndex,
    };
  }

}
