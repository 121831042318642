
import { SupportingDocumentDialogComponent } from './../components/dialogs/supporting-document-dialog/supporting-document-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

import { PreApprovalStatus, OfferStatus, UpdateUserStatusDto, UserStatus, Offer } from '../../core';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import { DeactivateUserDialogComponent } from '../components/dialogs/deactivate-user-dialog/deactivate-user-dialog.component';
import { DenyPreApprovalDialogData } from '../components/dialogs/deny-pre-approval-dialog/deny-pre-approval-dialog-data';
import { DenyPreApprovalDialogComponent } from '../components/dialogs/deny-pre-approval-dialog/deny-pre-approval-dialog.component';
import { AccountDocumentEditorDialogComponent } from '../components/dialogs/account-document-editor-dialog/account-document-editor-dialog.component';
import { DocumentEditorDialogComponent } from '../components/dialogs/document-editor-dialog/document-editor-dialog.component';
import { InfoDialogComponent } from '../components/dialogs/info-dialog/info-dialog.component';
import { UrlInviteComponent } from '../components/dialogs/url-invite/url-invite.component';
import { QueryItemAddDialogComponent } from '../components/dialogs/query-item-add-dialog/query-item-add-dialog.component';
import { QueryItemEditDialogComponent } from '../components/dialogs/query-item-edit-dialog/query-item-edit-dialog.component';

import { OfferPdfUrlDialogComponent } from '../components/dialogs/offer-pdf-url-dialog/offer-pdf-url-dialog.component';
import { OfferAnalyticsDialogComponent } from '../components/dialogs/offer-analytics-dialog/offer-analytics-dialog.component';
import { OfferSavedDialogComponent } from '../components/dialogs/offer-saved-dialog/offer-saved-dialog.component';
import {
  PreApprovalChangeStatusDialogData,
} from '../components/dialogs/pre-approval-change-status-gialog/pre-approval-change-status-dialog-data';
import {
  PreApprovalChangeStatusDialogComponent,
} from '../components/dialogs/pre-approval-change-status-gialog/pre-approval-change-status-dialog.component';
import { ToolboxItemAddDialogComponent } from '../components/dialogs/toolbox-item-add-dialog/toolbox-item-add-dialog.component';
import { ToolboxItemAdminAddDialogComponent } from '../components/dialogs/toolbox-item-add-dialog/toolbox-item-admin-add-dialog.component';

import { ToolboxItemEditDialogComponent } from '../components/dialogs/toolbox-item-edit-dialog/toolbox-item-edit-dialog.component';
import { UpdateOfferStatusDialogComponent } from '../components/dialogs/update-offer-status-dialog/update-offer-status-dialog.component';
import { RaiseTicketDialogComponent } from '../components/dialogs/raise-ticket-dialog/raise-ticket-dialog.component';
import { ConfirmDialogData } from '../models/confirm-dialog-data';
import { InfoDialogData } from '../models/info-dialog-data';
import { PermissionDialogComponent } from '../components/dialogs/permission-dialog/permission-dialog.component';
import { SignaturePadComponent } from '../components/dialogs/signature-pad-dialog/signature-pad-dialog.component';
import {ToolboxItemAdminEditDialogComponent} from '../components/dialogs/toolbox-item-edit-dialog/toolbox-item-admin-edit-dialog.component';
import { DocumentEditorDialogCustomPreapprovalComponent } from '../components/dialogs/document-editor-dialog-custom-preapproval/document-editor-dialog-custom-preapproval.component';
import { EditOfferDialogComponent } from '../components/public_api';
import { ProductsConfigDialogComponent } from '../components/dialogs/products-config-dialog/products-config-dialog.component';
import { CustomFieldInputComponent } from '../components/dialogs/custom-field-input/custom-field-input.component';

/**
 * Dialog service.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {

  /**
   * .ctor
   * @param matDialog Dialog logic.
   */
  constructor(
    private matDialog: MatDialog,
  ) { }

  /**
   * Open info dialog.
   * @param data Info dialog data.
   */
  public openInfoDialog(data: InfoDialogData): Observable<void> {
    return this.matDialog
      .open(InfoDialogComponent, { data, autoFocus: false })
      .afterClosed();
  }

  /**
   * Open confirm dialog.
   * @param data Confirm dialog data.
   */
  public openConfirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.matDialog
      .open(ConfirmDialogComponent, { data, autoFocus: false })
      .afterClosed();
  }

  public openSignaturePad():Observable<File | undefined> {
    return this.matDialog.open(SignaturePadComponent)
    .afterClosed();
  }

  
  /**
   * Open confirm dialog.
   * @param url Confirm dialog data.
   */
  public openCopyDialog(packageUrl:any){
    return this.matDialog
    .open(UrlInviteComponent, {data:packageUrl} )
    .afterClosed();
  }
  /**
   * Open pre approval change status dialog.
   * @param data Pre approval change status dialog data.
   */
  public openPreApprovalChangeStatusDialogData(data: PreApprovalChangeStatusDialogData): Observable<PreApprovalStatus | null> {
    return this.matDialog
      .open(PreApprovalChangeStatusDialogComponent, { data, autoFocus: false })
      .afterClosed();
  }

  /**
   * Open deny pre approval dialog.
   * @param data Deny pre approval dialog data.
   */
  public openDenyPreApprovalDialog(data: DenyPreApprovalDialogData): Observable<PreApprovalStatus | null> {
    return this.matDialog
      .open(DenyPreApprovalDialogComponent, { data, autoFocus: false })
      .afterClosed();
  }

  /**
   * Open document editor dialog.
   * @param data Document editor dialog data.
   */
  public openDocumentEditorDialog(data: Blob): Observable<Blob[] | null> {
    return this.matDialog
      .open(DocumentEditorDialogComponent, { 
            data, 
            autoFocus: false, 
            panelClass: 'document-editor-dialog' 
          })
      .afterClosed();
  }

  /**
   * Open document editor dialog Custom.
   * @param data Document editor dialog data.
   */
   public openDocumentEditorDialogCustom(data: Blob, customParams: any): Observable<Blob[] | null> {
    return this.matDialog
      .open(DocumentEditorDialogCustomPreapprovalComponent, { 
            data:{file:data , customParams},
            autoFocus: false, 
            panelClass: 'document-editor-dialog' 
          })
      .afterClosed();
  }
  
  /**
   * Open document editor dialog.
   * @param data Document editor dialog data.
   */
  public openDocumentEditorDialogWithUIParams(file: Blob, params: any): Observable<Blob[] | null> {
    return this.matDialog
      .open(AccountDocumentEditorDialogComponent, 
        { data: 
          {
          file, 
          params
        }, autoFocus: false, 
          panelClass: 'document-editor-dialog' 
        })
      .afterClosed();
  }

  /**
   * Opens dialog for updating offer's status.
   * @param status Current status.
   */
   
  public openUpdateOfferStatusDialog(status: OfferStatus): Observable<OfferStatus | null> {
    const data = status === OfferStatus.Draft ? null : status;
    return this.matDialog
      .open(UpdateOfferStatusDialogComponent, { data,width:"300px" })
      .afterClosed();
  }

  /**
   * Opens dialog for displaying package url.
   * @param data Offer with package url and pdf url.
   */
  public openOfferPdfUrlDialog(data: Offer): Observable<void> {
    return this.matDialog
      .open(OfferPdfUrlDialogComponent, { data, panelClass: 'offer-pdf-url-dialog', autoFocus: false })
      .afterClosed();
  }

    /**
   * Opens dialog for displaying Email analytics url.
   * @param data Offer with Email analytics url and pdf url.
   */
  public openOfferAnalyticsDialog(data: Offer): Observable<void> {
    return this.matDialog
      .open(OfferAnalyticsDialogComponent, { data, panelClass: 'offer-analytics-dialog', autoFocus: false })
      .afterClosed();
  }

  /**
   * Opens the add a new toolbox item dialog.
   */
  public openToolboxAddDialog(): Observable<boolean> {
    return this.matDialog
      .open(ToolboxItemAddDialogComponent)
      .afterClosed();
  }
  public openAdminToolboxAddDialog(userId:number):Observable<boolean> {
    return this.matDialog
      .open(ToolboxItemAdminAddDialogComponent,{data:userId})
      .afterClosed();
  }
  /**
   * Opens the edit toolbox item dialog.
   */
  public openToolboxEditDialog(itemId:number, itemText: string, itemCategoryId: number): Observable<boolean> {
    return this.matDialog
      .open(ToolboxItemEditDialogComponent, { data: {itemId: itemId, itemText: itemText, itemCategoryId: itemCategoryId} })
      .afterClosed();
  }
  public openToolboxAdminEditDialog(itemId:number, itemText: string, itemCategoryId: number):Observable<boolean> {
    return this.matDialog
      .open(ToolboxItemAdminEditDialogComponent, { data: {itemId: itemId, itemText: itemText, itemCategoryId: itemCategoryId} })
      .afterClosed();
  }
  /**
   * Opens dialog that informs that offer has been saved.
   * @param offerId Offer id.
   */
  public openOfferSavedDialog(offerId: number): Observable<void> {
    return this.matDialog
      .open(OfferSavedDialogComponent, { data: offerId })
      .afterClosed();
  }

  /**
   * Opens deactivate user dialog.
   * @param data Update user status dto.
   */
  public openDeactivateUserDialog(data: UpdateUserStatusDto): Observable<boolean> {
    const { status, user } = data;
    if (status === UserStatus.Deactivated) {
      const fullName = `${user.firstName} ${user.lastName}`;
      return this.matDialog
        .open(DeactivateUserDialogComponent, { data: fullName, autoFocus: false })
        .afterClosed();
    }
    return of(true);
  }

   /**
   * Opens the add a new toolbox item dialog.
   */
  public openToolboxAddQueryType(): Observable<boolean> {
    return this.matDialog
      .open(QueryItemAddDialogComponent)
      .afterClosed();
  }

  public openToolboxEditQueryType(itemId, itemText: any): Observable<boolean> {
    return this.matDialog
      .open(QueryItemEditDialogComponent, {data: {itemId: itemId, itemText: itemText}})
      .afterClosed()
  }

  // public openEditoffer(itemId, itemText: any): Observable<boolean> {
  //   return this.matDialog
  //     .open(EditOfferDialogComponent )
  //     .afterClosed()
  // }

  // Open raise ticket dialog
  public openRaiseTicket(refId: any): Observable<boolean> {
    return this.matDialog.open(RaiseTicketDialogComponent, {data: refId})
    .afterClosed();
  }

  public openPermission(): Observable<boolean> {
    return this.matDialog.open(PermissionDialogComponent)
    .afterClosed();
  }

  public openProductConfig(action,editdata,selectedplan): Observable<boolean> {
    return this.matDialog.open(ProductsConfigDialogComponent,{data: {action: action, editdata: editdata,selectedplan:selectedplan}})
    .afterClosed();
  }

  /**
   * Open supporting doc edit dialog.
   * @param data supporting doc edit dialog data.
   */
   public openSupportingDocEditDialog(data): Observable<any> {
    return this.matDialog
      .open(SupportingDocumentDialogComponent, { data, autoFocus: false })
      .afterClosed();
  }
  

  /**
   * @param inputdata
   */

   public openCustomFieldInputComponent(field: string,loId:number,customFields): Observable<boolean> {
    return this.matDialog.open(CustomFieldInputComponent, {data: {field,loId,customFields,code :""}})
    .afterClosed();
  }
}
