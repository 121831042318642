import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { exhaustMap, subscribeOn, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DestroyableBase, OfferService } from '../../../../core';

/**
 * Dialog that appears after offer has been sasved.
 */
@Component({
  selector: 'arb-offer-saved-dialog',
  templateUrl: './offer-saved-dialog.component.html',
  styleUrls: ['./offer-saved-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferSavedDialogComponent extends DestroyableBase {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private offerId: number,
    private offerService: OfferService,
    private dialogRef: MatDialogRef<OfferSavedDialogComponent>,
    private router: Router,
  ) {
    super();
  }

  /**
   * Closes the dialog and redirects to offer page.
   */
  public viewOffer(): void {
    this.offerService.getById(this.offerId).pipe(
      tap(offer => {
        window.open(offer.pdfUrl);
        this.router.navigate(['offers', this.offerId]);
        this.dialogRef.close();
        console.log("offer data",offer);
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  /**
   * Closes the dialog and redirects to dashboard.
   */
  public openDashboard(): void {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
}
