import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef } from '@angular/core';
import { getDocument, PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import { forkJoin, from, Observable, ReplaySubject} from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Pdf viewer.
 */
@Component({
  selector: 'arb-viewer-pdf',
  templateUrl: './viewer-pdf.component.html',
  styleUrls: ['./viewer-pdf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerPdfComponent {

  private readonly pdfUrl$ = new ReplaySubject<string>(1);

  /**
   * Pdf pages stream.
   */
  public readonly pages$ = this.createPdfStream();

  /**
   * Header page to work at ngFor.
   */
  @ContentChild('pageHeaderTemplate')
  public pageHeaderTemplate: TemplateRef<any>;

  /**
   * Url pdf.
   */
  @Input()
  public set url(url: string) {
    this.pdfUrl$.next(url);
  }

  private createPdfStream(): Observable<PDFPageProxy[]> {
   return this.pdfUrl$
      .pipe(
        switchMap(url => getDocument(url).promise as PromiseLike<PDFDocumentProxy>),
        switchMap(pdf => {
          const pages$: Observable<PDFPageProxy>[] = [];
          for (let i = 1; i <= pdf.numPages; i++) {
            pages$.push(from(pdf.getPage(i) as PromiseLike<PDFPageProxy>));
          }
          return forkJoin(pages$);
        }),
      );
  }
}
