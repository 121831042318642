import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

/**
 * Core module.
 */
@NgModule({
  imports: [
    RouterModule,
  ],
})
export class CoreModule {

  /**
   * Generate core module.
   */
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }

}
