import { Component, ChangeDetectionStrategy } from '@angular/core';


@Component({

  selector: 'arb-cancle-subscription-dialog',
  templateUrl: './cancle-subscription-dialog.component.html',
  styleUrls: ['./cancle-subscription-dialog.component.scss'],

})

export class CancleSubscriptionDialogComponent {


 
  }
