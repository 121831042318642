/**
 * Pre approval program type.
 */
export enum Occupancy {

  /**
   * Primary residence.
   */
  PrimaryResidence = 1,

  /**
   * Second home.
   */
  SecondHome,

  /**
   * Investment property.
   */
  InvestmentProperty,

}

const readableOccupancy: Record<Occupancy, string> = {
  [Occupancy.PrimaryResidence]: 'Primary Residence',
  [Occupancy.SecondHome]: 'Second Home',
  [Occupancy.InvestmentProperty]: 'Investment Property',
};

/**
 * Returns occupancy in readable format.
 * @param value Occupancy value.
 */
export function toReadableOccupancy(value: Occupancy): string {
  return readableOccupancy[value];
}
