import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

/**
 * Calls callback function when a stream has been succesfully completed or failed.
 * If it has been failed then error will be thrown.
 * @param callback Callback function.
 */
export const completedOrFailed = <T>(callback: Function) =>
  (source$: Observable<T>) => source$
    .pipe(
      tap(() => callback()),
      catchError(e => {
        callback();
        return throwError(e);
      }),
    );
