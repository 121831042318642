import { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { listenControlChanges, LoanOfficerService } from 'src/app/core';
@Component({
  selector: 'arb-custom-field-input',
  templateUrl: './custom-field-input.component.html',
  styleUrls: ['./custom-field-input.component.scss'],
})
export class CustomFieldInputComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {field:string,loId:number,customFields:any,code:string},
    private dialogRef: MatDialogRef<CustomFieldInputComponent>,
    private loanOfficerService:LoanOfficerService
    ) {
  }

  cities = [
    //{ id: 1, name: 'LTV' ,disabled:false,dataType:'number',inputType:'percentage',code:'#ITV#'},
    { id: 1, name: 'LTV' ,disabled:false,dataType:'number',inputType:'percentage',code:'#LOANTOVALUE#'},
    { id: 2, name: 'Interest Rate' ,disabled:false,dataType:'number',inputType:'percentage',code:'#INTERESTRATE#'},
    { id: 3, name: "Today's Date",  disabled:false,dataType:'date',inputType:'date',code:'#DATE#'},
    { id: 4, name: 'Loan Amount',disabled:false,dataType:'number',inputType:'price',code:'#LOANAMOUNT#'},
    { id: 5, name: 'Term', disabled:false,dataType:'string',inputType:'text',code:'#TERM#'},
    { id: 6, name: 'Max LTV', disabled:false,dataType:'number',inputType:'percentage',code:'#MAXLOANTOVALUE#'},
    { id: 6, name: 'CLTV', disabled:false,dataType:'number',inputType:'percentage',code:'#COMBINEDLOANTOVALUE#'},
    { id: 7, name: 'Exp Date', disabled:false,dataType:'date',inputType:'date',code:'#EXPDATE#'},
    { id: 8, name: 'Seller Credit', disabled:false,dataType:'number',inputType:'price',code:'#SELLERCREDIT#'}
  ];
selectedCityId: number;

ngOnInit() {
    if(this.data.customFields.length){
      this.cities.map(i => {
        if(this.data.customFields.find(j => j.name == i.name)){
          i.disabled=true;
        }
        return i 
      }) 
    }
}
  public loading:Boolean=false;
  public errMsg:string='';
  private capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private dataType:string="";
  private inputType:string="";
  onOptionSelected(e){
    // this.dataType=this.inputType="";
    this.data.field=e.name;
    this.data.code = e.code;
    this.dataType=e.dataType;
    this.inputType=e.inputType;
  }

  addUpdateField() {

    if(!this.data.field) {
      return
    }
    let obj ={
      "module": "PREAPPROVAL",
      "name": this.data.field,
      "userId": this.data.loId,
      "isChecked": true,
      "code":  this.data.code ,//`#${this.data.field.toLocaleUpperCase().split(" ").join("")}#`,
      "isDefault": false,
      "fontFamily": "Arial",
      "fontSize": 10,
      "fontColor": "#000",
      "isShow": true,
      "dataType":this.dataType,
      "inputType":this.inputType
    }
    this.loading=true;
    this.errMsg =""
    this.loanOfficerService.addCustomFieldForLo(obj).subscribe(response=>{
      this.loading= false
      if (!response.isError) {
        obj['id'] = response.id;
        this.dialogRef.close(obj);
      } else {
        this.errMsg = response.error;
      }
    })
    
  }
}
