/**
 * Pre approval status.
 */

export enum PreApprovalStatus {

  /**
   * Draft.
   */
  Draft = 1,

  /**
   * Requested.
   */
  Requested,

  /**
   * Saved.
   */
  Saved,

  /**
   * Pre-Approval in Process.
   */
  PreApprovalInProcess,

  /**
   * Pre-Approved.
   */
  PreApproved,

  /**
   * Denied.
   */
  Denied,

}

const PRE_APPROVAL_STATUS_TEXT: Record<PreApprovalStatus, string> = {
  [PreApprovalStatus.Draft]: 'Draft',
  [PreApprovalStatus.Requested]: 'Requested',
  [PreApprovalStatus.Saved]: 'Pre-Approval pending',
  [PreApprovalStatus.PreApprovalInProcess]: 'Pre-Approval in Process',
  [PreApprovalStatus.PreApproved]: 'Pre-Approved',
  [PreApprovalStatus.Denied]: 'Denied',
};

/**
 * Get a readable representation of a status.
 * @param value Borrowers status.
 */
export function toReadableBorrowerStatus(value: PreApprovalStatus): string {
  return PRE_APPROVAL_STATUS_TEXT[value] || '';
}
