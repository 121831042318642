import { AssetType } from './../../../../core/models/assets/asset-type';
import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Observable,
  combineLatest,
  ReplaySubject,
  BehaviorSubject,
} from "rxjs";
import { tap, map, first, takeUntil, filter, switchMap } from "rxjs/operators";

import { BorrowerPageService } from "./../../../../borrowers/borrower-page/services/borrower-page.service";
///services/borrower-page.service

import { RolesService, RealtorsService } from "../../../../core";

const DEFAULT_COLOR = "ef7824";
/**
 * Offer template page component.
 */
@Component({
  selector: "arb-offer-template-page",
  templateUrl: "./preapproval-document-template-page.component.html",
  styleUrls: ["./preapproval-document-template-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [BorrowerPageService],
})
export class PreApprovalDocumentTemplatePageComponent implements OnInit {
  public readonly borrower$ = this.borrowerPageService.borrower$;
  public readonly isAdmin$ = this.rolesService.isAdmin$;
  public readonly error$ = this.borrowerPageService.error$;
  public realtor$ = new BehaviorSubject({});
  public fontSize = "9pt";
  /**
   * If `true` then pre approval form is valid.
   */
  public readonly isPreApprovalFormValid$ = new BehaviorSubject(false);

  /**
   * Shows progress bar when request is in progress.
   */
  public readonly loading$ = this.borrowerPageService.loading$;

  constructor(
    private activatedRoute: ActivatedRoute,
    private borrowerPageService: BorrowerPageService,
    private rolesService: RolesService,
    private realtorsService: RealtorsService
  ) {}

  public ngOnInit(): void {
    this.initBorrower();
    // this.getRealtor();
  }

  private initBorrower(): void {
    var that = this;
    that.activatedRoute.data
      .pipe(
        first(),
        tap(({ borrower }) => that.borrowerPageService.initBorrower(borrower)),
        tap(({ borrower }) => {
          // console.log("{ borrower }", borrower);
          if(!borrower.realtor){
            let data ={
              headerColor:borrower.loanOfficer.headerColor?`#${borrower.loanOfficer.headerColor}`:`#${DEFAULT_COLOR}`,
              footerColor:borrower.loanOfficer.footerColor?`#${borrower.loanOfficer.footerColor}`:`#${DEFAULT_COLOR}`,
              title:borrower.loanOfficer.title,
              email:borrower.loanOfficer.email,
              officeAddress:'',
              firstName:borrower.loanOfficer.firstName,
              lastName:borrower.loanOfficer.lastName,
              companyName:borrower.loanOfficer.companyName,
              companyLogoUrl:borrower.loanOfficer.companyLogoUrl,
              website:'',
              phoneNumber:borrower.loanOfficer.phoneNumber
            }
            that.setFontSize(data);
            that.realtor$.next(data);
            return data
          }else{
            that.getRealtorById(borrower.realtor.id).subscribe((data) => {
              data = { ...data };
              that.setFontSize(data);
              that.realtor$.next(data);
              return data;
            });
          }
        })
        //  takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setFontSize(data) {
    const size =
      data.firstName.length + data.lastName.length + data.companyName.length;
    this.fontSize = size < 50 ? "9pt" : size < 70 ? "7.5pt" : "7pt";
  }
  private getRealtorById(realtorId): any {
    return this.realtorsService.getById(realtorId).pipe(
      tap((realtor) => {
        realtor.headerColor = `#${realtor.headerColor || DEFAULT_COLOR}`;
        realtor.footerColor = `#${realtor.footerColor || DEFAULT_COLOR}`;
      })
    );
  }

  public isShowLetter(asset: any, isCustomLetter: boolean) {
    if(isCustomLetter) {
        if(asset.assetTypeId == 6) {
          return false;
        }
        else if(asset.assetTypeId == 7)
        return true;
    }
    if(asset.assetTypeId == 7) {
        return false;
      }
      else {
        return true;
    }
  }

  public rePosition(borrowerData) {
    let preApprovalAssets = borrowerData.preApprovalAssets;
    preApprovalAssets.forEach(assetItem => {
    //   if((assetItem.assetTypeId == 6 || assetItem.assetTypeId == 7) && assetItem.fileUrl != "" && assetItem.sequence == null) {
    //     preApprovalAssets.splice(preApprovalAssets.indexOf(assetItem),1);
    //     preApprovalAssets.unshift(assetItem);
    // }
      if(assetItem.assetTypeId == 4  && assetItem.fileUrl != "" && assetItem.sequence == null) {
        preApprovalAssets.splice(preApprovalAssets.indexOf(assetItem),1);
        preApprovalAssets.unshift(assetItem);
    }
  })
    preApprovalAssets.sort(function(a,b){
      return a.sequence - b.sequence;
    });

    // if(borrowerData.isCustom) {
    //   const dpaItem = preApprovalAssets.find(ast => ast.AssetTypeId == 7);
    //   if(dpaItem && !dpaItem.sequence) {
    //     const dpaIndex = preApprovalAssets.findIndex(ast => ast.AssetTypeId == 7);
    //     if(dpaIndex > -1) {
    //       preApprovalAssets.slice(dpaIndex, 1);
    //       preApprovalAssets.upshift(dpaItem);
    //     }
    //   }
    // }
    // else {
    //   const cpaItem = preApprovalAssets.find(ast => ast.AssetTypeId == 6);
    //   if(cpaItem && !cpaItem.sequence) {
    //     const cpaIndex = preApprovalAssets.findIndex(ast => ast.AssetTypeId == 6);
    //     if(cpaIndex > -1) {
    //       preApprovalAssets.slice(cpaIndex, 1);
    //       preApprovalAssets.upshift(cpaItem);
    //     }
    //   }
    // }

    borrowerData.preApprovalAssets = preApprovalAssets;
    console.log("Preapproval Assets", preApprovalAssets);
    return borrowerData;
  }

  public isNotPdf(url){
    try {
      if(url.indexOf("pdf") > - 1 || url.indexOf("PDF") > -1) {
        return false;
      }
      return true;
    }
    catch(ex) {
      console.log("isnotpdf catch",ex);
      return true;
    }
  }
}
