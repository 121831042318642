import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { Subject, BehaviorSubject, Observable, combineLatest } from "rxjs";
import {
  exhaustMap,
  filter,
  takeUntil,
  tap,
  switchMap,
  map,
  timeout,
} from "rxjs/operators";

import {
  OfferStatus,
  DestroyableBase,
  OfferService,
  completedOrFailed,
  Message,
  catchHttpErrorMessage,
  SuccessMessage,
  Offer,
  GlobalProgressService,
} from "../../../../core";

/**
 * Offer package url dialog.
 */
@Component({
  selector: "arb-offer-analytics-dialog",
  templateUrl: "./offer-analytics-dialog.component.html",
  styleUrls: ["./offer-analytics-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferAnalyticsDialogComponent extends DestroyableBase
  implements OnInit {
  public message$: Subject<Message | null>;
  private readonly submit$ = new Subject<void>();
  public analytics: any;
  constructor(
    private offersService: OfferService,
    @Inject(MAT_DIALOG_DATA)
    public offer: Offer
  ) {
    super();
  }

  public ngOnInit(): void {
    this.listenOfferSubmit();
    // this.analytics = {};
    setTimeout(() => {
      this.submitOffer();
    }, 1000);
    var response = this.getAnalyticsByOfferId().subscribe();
    console.log("response", response);
  }

  public formatDate(datestring: string ): string {
    return (new Date(datestring).toLocaleDateString()) + " " + (new Date(datestring).toLocaleTimeString()) 


  }
  private listenOfferSubmit(): void {
    this.submit$
      .pipe(
        tap(() => this.message$.next(null)),
        switchMap(() => this.getAnalyticsByOfferId()),

        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public submitOffer(): void {
    // this.submit$.next();
  }

  //   /**
  //    * Opens dialog to update status.
  //    */
  //   public onUpdateStatus(): void {
  //     this.updateStatus$.next();
  //   }
  private getAnalyticsByOfferId(): Observable<void> {
    return this.offersService.getAnalyticsByOfferId(this.offer.id).pipe(
      tap((data) => {
        this.analytics = data;
      }),
      catchHttpErrorMessage({ message$: this.message$ })
    );
  }
}
