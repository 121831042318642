import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './core/app.component';
import { CoreModule } from './core/core.module';
import { AddTokenInterceptor } from './core/interceptors/add-token-interceptor';
import { SharedModule } from './shared/shared.module';
import { DocRedirectPageComponent } from './borrowers/borrower-page/components/doc-redirect-page/doc-redirect-page.component';
import { AssetFilesResolver } from './asset-template/services/asset-files.resolver';


/**
 * Root module.
 */
@NgModule({
  declarations: [
    AppComponent,
    DocRedirectPageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    CoreModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true,
    },
    AssetFilesResolver
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
