/**
 * Sort direction.
 */
export enum SortDirection {

  /**
   * Ascending.
   */
  Asc = 'asc',

  /**
   * Descending.
   */
  Desc = 'desc',

}
