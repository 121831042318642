import { Subject, merge } from 'rxjs';
import { tap, takeUntil, filter, switchMap, mapTo, startWith } from 'rxjs/operators';

import { createImage } from '../../../../core';
import { ImageEditor } from '../image-editor';
import { Point } from '../point';
import { Rectangle } from '../rectangle';

import { EditorRenderer } from './editor-renderer';
import { EditorRendererOptions } from './editor-renderer-options';

/**
 * Image cropper renderer.
 */
export class ImageCropperRenderer extends EditorRenderer {

  private readonly circleRadius: number;
  private readonly cropperStyle: string;
  private readonly crop$: Subject<void>;
  private readonly disable$: Subject<void>;
  private cropArea: Rectangle;
  private currentImage: HTMLImageElement;
  private prevImage: HTMLImageElement;
  private topLeftSelected: boolean;
  private topRightSelected: boolean;
  private bottomLeftSelected: boolean;
  private bottomRightSelected: boolean;

  /**
   * @inheritdoc
   */
  protected readonly editor: ImageEditor;

  constructor(editor: ImageEditor, options: EditorRendererOptions) {
    super(options);
    this.editor = editor;
    this.circleRadius = 10;
    this.cropperStyle = 'orange';
    this.crop$ = new Subject();
    this.disable$ = new Subject();
    this.init(editor.file);
    this.listenMouseDown();
    this.listenMouseMove();
    this.listenFilesChange();
    this.listenDisableChange();
  }

  private init(file: File): void {
    createImage(file)
      .pipe(
        tap(img => {
          this.cropArea = new Rectangle(new Point(0, 0), new Point(img.width, img.height));
          this.prevImage = img;
          this.currentImage = img;
          this.canvas.width = this.cropArea.width;
          this.canvas.height = this.cropArea.height;
        }),
        tap(() => this.calculateDifference()),
        tap(() => this.render()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenMouseDown(): void {
    this.mouseDown$
      .pipe(
        tap(point => {
          this.topLeftSelected = this.isCloseEnought(point.x, this.cropArea.x)
            && this.isCloseEnought(point.y, this.cropArea.y);
          this.topRightSelected = this.isCloseEnought(point.x, this.cropArea.x + this.cropArea.width)
            && this.isCloseEnought(point.y, this.cropArea.y);
          this.bottomLeftSelected = this.isCloseEnought(point.x, this.cropArea.x)
            && this.isCloseEnought(point.y, this.cropArea.y + this.cropArea.height);
          this.bottomRightSelected = this.isCloseEnought(point.x, this.cropArea.x + this.cropArea.width)
            && this.isCloseEnought(point.y, this.cropArea.y + this.cropArea.height);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private isCloseEnought(p1: number, p2: number): boolean {
    return Math.abs(p1 - p2) < this.circleRadius;
  }

  private listenMouseMove(): void {
    this.mouseMove$
      .pipe(
        filter(() => this.topLeftSelected || this.topRightSelected || this.bottomLeftSelected || this.bottomRightSelected),
        tap(point => {
          if (this.topLeftSelected) {
            this.cropArea.width += this.cropArea.x - point.x;
            this.cropArea.height += this.cropArea.y - point.y;
            this.cropArea.x = point.x;
            this.cropArea.y = point.y;
          }
          if (this.topRightSelected) {
            this.cropArea.width = Math.abs(this.cropArea.x - point.x);
            this.cropArea.height += this.cropArea.y - point.y;
            this.cropArea.y = point.y;
          }
          if (this.bottomLeftSelected) {
            this.cropArea.width += this.cropArea.x - point.x;
            this.cropArea.height = Math.abs(this.cropArea.y - point.y);
            this.cropArea.x = point.x;
          }
          if (this.bottomRightSelected) {
            this.cropArea.width = Math.abs(this.cropArea.x - point.x);
            this.cropArea.height = Math.abs(this.cropArea.y - point.y);
          }
        }),
        /* If you decrease width and height of the rectangle less than `circleRadius` pixels and unpress your mouse */
        /* Then the rectangle becomes not resizible. But if you keep resizing, everything is fine. */
        filter(() => this.cropArea.width >= this.circleRadius && this.cropArea.height >= this.circleRadius),
        tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
        tap(() => this.render()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private render(): void {
    this.context.drawImage(this.currentImage, 0, 0);
    this.drawCropArea();
    this.drawCircles();
  }

  private listenFilesChange(): void {
    const cropped$ = this.crop$
      .pipe(
        tap(() => {
          this.canvas.width = this.cropArea.width;
          this.canvas.height = this.cropArea.height;
        }),
        tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
        tap(() => this.calculateDifference()),
        /* Crop selected area. */
        tap(() => this.context.drawImage(
          this.currentImage,
          this.cropArea.x,
          this.cropArea.y,
          this.cropArea.width,
          this.cropArea.height,
          0,
          0,
          this.cropArea.width,
          this.cropArea.height,
        )),
        /* If we do `this.prevImage = this.currentImage` and later `this.currentImage = someNewImage` */
        /* `this.prevImage` will link to `someNewImage`. This is a hack to create new link. */
        tap(() => {
          const img = new Image(this.currentImage.width, this.currentImage.height);
          img.src = this.currentImage.src;
          this.prevImage = img;
        }),
        switchMap(() => this.convertToFile()),
        switchMap(file => createImage(file)
          .pipe(
            tap(img => this.currentImage = img),
            tap(() => this.cropArea = new Rectangle(new Point(0, 0), new Point(this.canvas.width, this.canvas.height))),
            tap(() => this.drawCropArea()),
            tap(() => this.drawCircles()),
            mapTo(file),
          ),
        ),
      );
    const undo$ = this.undo$
      .pipe(
        tap(() => this.cropArea = new Rectangle(new Point(0, 0), new Point(this.prevImage.width, this.prevImage.height))),
        tap(() => this.currentImage = this.prevImage),
        tap(() => {
          this.canvas.width = this.currentImage.width;
          this.canvas.height = this.currentImage.height;
        }),
        tap(() => this.calculateDifference()),
        tap(() => this.context.drawImage(this.currentImage, 0, 0)),
        switchMap(() => this.convertToFile()),
        tap(() => this.drawCropArea()),
        tap(() => this.drawCircles()),
        startWith(null),
      );
    merge(
      cropped$,
      undo$,
    )
      .pipe(
        /* Because `undo$` starts with `null`. */
        filter(file => !!file),
        tap((file: File) => this.editor.file = file),
        tap((file: File) => this.editor.filesChange$.next([file])),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private calculateDifference(): void {
    this.diffX = Number((this.canvas.width / this.canvasMaxWidth).toFixed(2));
    const realHeight = (this.canvasMaxWidth * this.canvas.height) / this.canvas.width;
    this.diffY = Number((this.canvas.height / realHeight).toFixed(2));
    this.diffX = this.diffX < 1 ? 1 : this.diffX;
    this.diffY = this.diffY < 1 ? 1 : this.diffY;
  }

  private drawCropArea(): void {
    this.context.beginPath();
    this.context.rect(this.cropArea.x, this.cropArea.y, this.cropArea.width, this.cropArea.height);
    this.context.strokeStyle = this.cropperStyle;
    this.context.lineWidth = 4;
    this.context.stroke();
  }

  private drawCircles(): void {
    this.drawCircle(this.cropArea.x, this.cropArea.y);
    this.drawCircle(this.cropArea.x + this.cropArea.width, this.cropArea.y);
    this.drawCircle(this.cropArea.x, this.cropArea.y + this.cropArea.height);
    this.drawCircle(this.cropArea.x + this.cropArea.width, this.cropArea.y + this.cropArea.height);
  }

  private drawCircle(x: number, y: number): void {
    this.context.fillStyle = this.cropperStyle;
    this.context.beginPath();
    this.context.arc(x, y, this.circleRadius, 0, 2 * Math.PI);
    this.context.fill();
  }

  private listenDisableChange(): void {
    this.disable$
      .pipe(
        tap(() => this.disabled = true),
        switchMap(() => createImage(this.editor.file)
          .pipe(
            tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
            tap(img => this.context.drawImage(img, 0, 0)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    super.destroy();
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.drawImage(this.currentImage, 0, 0);
  }

  /**
   * Makes crop effect.
   */
  public crop(): void {
    this.crop$.next();
  }

  /**
   * @inheritdoc
   */
  public disable(): void {
    this.disable$.next();
  }

  /**
   * @inheritdoc
   */
  public onMouseUp(): void {
    this.topLeftSelected = this.topRightSelected = this.bottomLeftSelected = this.bottomRightSelected = false;
  }

  /**
   * @inheritdoc
   */
  public onMouseDown(x: number, y: number): void {
    super.onMouseDown(x, y);
    this.mouseDown$.next(new Point(x * this.diffX, y * this.diffY));
  }

}
