import { Full } from '../../helpers/full';

/**
 * Update admin dto.
 */
export class UpdateAdminDto {

  /**
   * First name.
   */
  public firstName: string;

  /**
   * Last name.
   */
  public lastName: string;

  /**
   * Email.
   */
  public email: string;

  /**
   * Phone number.
   */
  public phoneNumber: string;

  constructor(data: Full<UpdateAdminDto>) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
  }

}
