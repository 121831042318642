import { combineLatest } from 'rxjs';
import { filter, tap, takeUntil, map, withLatestFrom, switchMap, startWith, concatMap } from 'rxjs/operators';

import { PdfEditor } from '../pdf-editor';
import { Rectangle } from '../rectangle';

import { PdfEditorRendererOptions } from './pdf-editor-renderer-options';
import { PdfRenderer } from './pdf-renderer';

/**
 * Pdf rectangle renderer.
 */
export class PdfRectangleRenderer extends PdfRenderer {

  constructor(editor: PdfEditor, options: PdfEditorRendererOptions, isHighlighter: Boolean = false) {
    super(editor, options);
    this.isHighlighter = isHighlighter;

    this.listenMouseMove();
    this.listenFilesChange();
  }
  private isHighlighter: Boolean;

  private listenMouseMove(): void {
    this.mouseMove$
      .pipe(
        filter(() => !this.disabled && this.isSelectingInProgress),
        tap(endPoint => this.endPoint = endPoint),
        tap(() => this.upperCanvasContext.clearRect(0, 0, this.upperCanvas.width, this.upperCanvas.height)),
        tap(() => this.previewUpperContext.clearRect(0, 0, this.previewUpperCanvas.width, this.previewUpperCanvas.height)),
        tap(() => {
          for (const rect of this.rectangles) {
            this.renderRect(this.upperCanvasContext, rect);
            this.renderRect(this.previewUpperContext, rect);
          }
        }),
        tap(() => (this.isHighlighter ? this.drawSelection(this.upperCanvasContext, "#ffff00") : this.drawSelection(this.upperCanvasContext))),
        tap(() => (this.isHighlighter ? this.drawSelection(this.previewUpperContext, "#ffff00") : this.drawSelection(this.previewUpperContext))),

        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenFilesChange(): void {
    const undo$ = this.undo$
      .pipe(
        filter(() => this.rectangles.length > 0),
        tap(() => this.rectangles.pop()),
        withLatestFrom(this.editor.currentPage$),
        switchMap(([, page]) => combineLatest(
          this.renderPdfPage(page, this.context, this.canvas, this.upperCanvas),
          this.renderPdfPage(page, this.previewContext, this.previewCanvas, this.previewUpperCanvas),
        )),
        tap(() => {
          for (const rect of this.rectangles) {
            this.renderRect(this.context, rect);
            this.renderRect(this.previewContext, rect);
          }
        }),
        startWith(null),
      );
    combineLatest(
      undo$,
      this.mouseUp$.pipe(startWith(null)),
      this.editor.currentPage$,
      this.editor.indexesOfFilesToSave$,
    )
      .pipe(
        filter(() => !!this.currentPageInfo),
        concatMap(() => this.convertToFile()),
        tap(file => this.currentPageInfo = { ...this.currentPageInfo, file }),
        map(() => this.getFilesToSave()),
        tap(files => this.editor.filesChange$.next(files)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * @inheritdoc
   */
  public onMouseUp(): void {
    if (!this.disabled && this.isSelectingInProgress) {
      this.isSelectingInProgress = false;
      const rect = new Rectangle(this.startPoint, this.endPoint);
      const rectYellow = new Rectangle(this.startPoint, this.endPoint, "#ffff00");
      if (this.isHighlighter) {
        this.rectangles.push(rectYellow);
        this.upperCanvasContext.clearRect(0, 0, this.upperCanvas.width, this.upperCanvas.height);
        this.renderRect(this.context, rectYellow);
        this.renderRect(this.previewContext, rectYellow);
      } else {
        this.rectangles.push(rect);
        this.upperCanvasContext.clearRect(0, 0, this.upperCanvas.width, this.upperCanvas.height);
        this.renderRect(this.context, rect);
        this.renderRect(this.previewContext, rect);

      }

      this.mouseUp$.next();
    }
  }

}
