import { Full } from '../helpers/full';

import { Person } from './person';
import { UserStatus } from './user-status';
/**
 * General info about realtor.
 */
export class SupportListSummary  {

  /**
   * Readable id.
   */
  public id: number;


  /**
   * Status.
   */
  public queryType: any;
  
  /**
   * Update or creation date.
   */
  public description: string;
  
  /**
   * Number of associated borrowers.
   */
  public attachmentUrl: string;
  
  /**
   * Number of active offers.
   */
  public priority: number;
  
  /**
   * Username of user who last updated the record.
   */
  public ticketStatus: string;
  public updatedOn: Date;
  public createdOn: Date;
  
  public assignedToUser: any;
  public createdByUser: string;

  constructor(data: Full<SupportListSummary>) {
    
    this.id = data.id;
    this.queryType = data.queryType;
    this.description = data.description;
    this.attachmentUrl = data.attachmentUrl;
    this.priority = data.priority;
    this.ticketStatus = data.ticketStatus;
    this.updatedOn = data.updatedOn;
    this.assignedToUser = data.assignedToUser;
    this.createdByUser = data.createdByUser;
    this.createdOn = data.createdOn;
  }

}
