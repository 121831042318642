import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BorrowerService } from '../../core';
import { AssetPart } from '../../core/models/assets/asset-part';
import { completedOrFailed } from '../../core/rxjs-operators/completed-or-failed';
import { GlobalProgressService } from '../../core/services/global-progress.service';
import { AssetTemplateModule } from '../asset-template.module';

/**
 * Asset resolver.
 */
@Injectable({
  providedIn: 'root',
})
export class AssetFilesResolver implements Resolve<AssetPart[]> {

  constructor(
    private borrowersService: BorrowerService,
    private globalProgressService: GlobalProgressService,
  ) { }

  /**
   * Gets asset files by asset type and borrower id.
   * @param route Current route info.
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AssetPart[]> {
    const borrowerId = Number(route.paramMap.get('borrowerId'));
    const assetTypeId = Number(route.paramMap.get('assetTypeId'));
    this.globalProgressService.startLoading();
    return this.borrowersService.getAssetFiles(borrowerId, assetTypeId)
      .pipe(
        map(files => files.filter(({ url }) => !!url)),
        completedOrFailed(() => this.globalProgressService.stopLoading()),
      );
  }

}
