
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, share ,tap} from 'rxjs/operators';

import { AppConfig } from '../../app.config';
import { QuerySearchParams, QuerySummary,QueryNewItem, Table } from "../models/public_api";
import { weakShareReplay } from '../rxjs-operators/weak-share-replay';
import { PaginationResult } from "../models/pagination-result";
import { QuerySummaryDto } from "./dto/support/query-summary-dto";
import { SupportItem } from "../models/query-type/support-item";
import { SupportListSearchParams } from '../models/support-list-params';
import { SupportListSummary } from '../models/support-list-summary';
import { SupportListDto } from './dto/support-list-dto';


/**
 * Toolbox business logic.
 */
@Injectable({
  providedIn: 'root',
})
export class SupportService {

  public refreshSupportHistory$ = new Subject<boolean>();

  constructor(
    private config: AppConfig,
    private http: HttpClient,
  ) { }

  /**
   * Stores a new item.
   * @param data New item's info.
   */
  public addQuery(data: QueryNewItem): Observable<void> {
    return this.http
      .post<void>(`${this.config.apiUrl}/api/support/createQueryType`, data);
  }

  public search(
    searchParams: QuerySearchParams
  ): Observable<Table<QuerySummary>> {
    const url = `${this.config.apiUrl}/api/support/getQueryTypeAsync`;
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<QuerySummary>>(url, { params })
      .pipe(
        map((res) => ({
          paginationMetadata: res.metadata,
          data: res.data
        }))
      );
  }

  public getQuerysData():Observable<QuerySummary>{
    const url = `${this.config.apiUrl}/api/support/getQueryTypeAsync`;
    return this.http.get<QuerySummaryDto>(url).pipe(
      tap( data=>{ console.log(data);
      })
    )
  }
  
  public GetSupportById( id:any):Observable<any>{
    const url = `${this.config.apiUrl}/api/support/getBySupportID?Id=${id}`;
    return this.http.get<any>(url).pipe(
      tap( data=>{ console.log(data);
      })
    )
  }

  public GetCommentsBySupportId( id:number):Observable<any>{
    const url = `${this.config.apiUrl}/api/support/GetCommentsBySupportId?Id=${id}`;
    return this.http.get<any>(url);
  }
  /**
   * Stores a new item.
   * @param data New item's info.
   */
  public createSupportComment(comments: string, file: File | null, extension: string, ticketId: number): Observable<void> {
    const formData = new FormData();
    formData.append("TicketId", ticketId.toString());
    formData.append("Comments", comments);
    this.setFile(formData, file, 'Files');
    if(extension == undefined || !file) {
      extension = '';
    } 
    formData.append("FileExtension", extension);
    return this.http
      .post<void>(`${this.config.apiUrl}/api/support/createSupportComment`, formData).pipe(
        tap(() => this.refreshSupportHistory$.next(true))
    );
  }
  

  private setFile(formData: FormData, file: File | null, name: string): void {
    if (file) {
        formData.append(name, file[0]);
    }
  }

    /**
   * Searches for all Support requests.
   * @param searchParams Params for search request.
   */
  public GetSupportListTable(searchParams: SupportListSearchParams): Observable<Table<SupportListSummary>> {
    const url = `${this.config.apiUrl}/api/support/GetSupportListTable`;
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<SupportListDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(dto => this.mapToSupportList(dto)),
        })),
      );
  }

  private mapToSupportList(dto: SupportListDto): SupportListSummary {
    return new SupportListSummary({
      id: dto.id,
      queryType: dto.queryType,
      description: dto.description,
      attachmentUrl: dto.attachmentUrl,
      priority: dto.priority,
      ticketStatus: dto.ticketStatus,
      updatedOn: new Date(dto.updatedOn),
      createdOn: new Date(dto.createdOn),
      assignedToUser: dto.assignedToUser,
      createdByUser: dto.createdByUser,
    });
  }
  
  public raiseTicket(data:SupportItem):any {
    const url = `${this.config.apiUrl}/api/support/createSupport`;
    return this.http.post<void>(url,data);
  }

  public getPermissions() {
    const url = `${this.config.apiUrl}/api/support/getPermissionAsync`;
    return this.http.get(url);
  }

  public createPermission(data) {
    const url = `${this.config.apiUrl}/api/support/createPermission`;
    return this.http.post(url, data);
  }

  public editQueryType(data) {
    console.log("data", data);
    const url = `${this.config.apiUrl}/api/support/editQueryType`;
    return this.http.put(url, data)
  }

  public deleteQueryType(id:number) {
    const options = {
      headers: new HttpHeaders(),
      body: {id}
    };
    const url = `${this.config.apiUrl}/api/support/DeleteQueryType`;
    return this.http.delete(url, options)
  }

  public getPermissionUsers() {
    const url = `${this.config.apiUrl}/api/support/getPermissionUsers`;
    return this.http.get(url);
  }

  public updateSupportStatus(data) {
    const url = `${this.config.apiUrl}/api/support/updateSupportRequestStatus`;
    return this.http.put(url, data).pipe(
      tap(() => this.refreshSupportHistory$.next(true))
    );
  }

  public reassignSupportRequest(data) {
    const url = `${this.config.apiUrl}/api/support/reassignSupportRequestItem`;
    return this.http.put(url, data).pipe(
      tap(() => this.refreshSupportHistory$.next(true))
    );
  }

  public deletePermission(id) {
    const url = `${this.config.apiUrl}/api/support/deletePermission?id=${id}`;
    return this.http.delete(url, id)
  }

  public updatePermission(data) {
    const url = `${this.config.apiUrl}/api/support/editPermission`;
    return this.http.put(url, data)
  }

  public updateEmailConfiguration(id, userData, postObj) {
    const formData = new FormData();
    formData.append('emailAddress', userData.email);
    formData.append('userId', userData.id);
    formData.append('defaultTicketAsssignedTo', postObj.emailConfig);
    formData.append('loanOfficePaidPrice', postObj.loPlanPrice);
    formData.append('noOfFreeLO', postObj.noOfFreeLO);
    formData.append('noOfFreeRealor', postObj.noOfFreeRealtor);
    formData.append('realtorPaidPrice', postObj.realtorPlanPrice);
    if(postObj.file){
    formData.append('file', postObj.file, this.mapFileNameToFileNameDto(postObj.file.name));}
    else{
    formData.append('file', postObj.NoFile);
    }
    const url = `${this.config.apiUrl}/api/support/editEmailConfiguration?id=${id}`;
    return this.http.put<void>(url, formData);
  }

  private mapFileNameToFileNameDto(fileName: string): string {
    const maxLength = 58;
    const arrayOfLinesSeparatedByDots = fileName.split('.');
    const name = arrayOfLinesSeparatedByDots.slice(0, -1).join('.');
    const fileExtension = arrayOfLinesSeparatedByDots[arrayOfLinesSeparatedByDots.length - 1];
    return `${name.slice(0, maxLength)}.${fileExtension}`;
  }

  public createEmailConfiguration(userData, formData) {
    const url = `${this.config.apiUrl}/api/support/createEmailConfiguration`;
    return this.http.post<void>(url, this.MapFormData(userData, formData));
  }

  private MapFormData(userData, formData) {
      return {
        emailAddress: userData.email,
        userId: +userData.id,
        defaultTicketAsssignedTo: formData.emailConfig,
        loanOfficePaidPrice: +formData.loPlanPrice,
        noOfFreeLO: +formData.noOfFreeLO,
        noOfFreeRealor: +formData.noOfFreeRealtor,
        realtorPaidPrice: +formData.realtorPlanPrice
      }
  }

  public getEmailConfigurationById(id):Observable<any>{
    const url = `${this.config.apiUrl}/api/support/getByEmailConfigurationID?Id=${id}`;
    return this.http.get<any>(url);
  }
  public getEmailConfigurationAsync() {
    const url = `${this.config.apiUrl}/api/support/getEmailConfigurationAsync`;
    return this.http.get(url);
  }

  public getSupportHistoryByTicketId(id) {
    const url = `${this.config.apiUrl}/api/supportHistory/getSupportHistoryByTicketId?Id=${id}`;
    return this.http.get(url);
  }
}