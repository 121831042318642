
import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, map, takeUntil, filter } from 'rxjs/operators';

import {
  RealtorSummary,
  PaginationMetadata,
  Table,
  partitionTable,
  PageEvent,
  SortConfig,
  SortDirection,
  UserStatus,
  listenControlChanges,
  RealtorsSearchParams,
  SupportListSearchParams,
  DestroyableBase,
  UpdateUserStatusDto,
  RolesService,
  SupportTicketStatus,
  CurrentUserService
} from '../../../../core';
import { UserStatusConfig } from '../../../../shared';
import { SupportTableColumn } from '../../../models/support-table-column';
import { SupportTableSecondColumn } from '../../../models/support-table-second-column';






const COLUMNS: SupportTableColumn[] = ['id', "created_on", 'name', 'borrowers', 'offers', 'status', 'lastUpdated'];
const SECOND_HEADER_COLUMNS: SupportTableSecondColumn[] = [
  'empty',
  'empty',
  'search_by_realtor_name',
  'empty',
  'empty',
  'select_status',
  'search_by_assigned_to',
];


/**
 * Support table.
 */
@Component({
  selector: 'arb-support-list-table',
  templateUrl: './support-table.component.html',
  styleUrls: ['./support-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SupportTableComponent extends DestroyableBase implements OnInit {

  private sortConfigValue = new SortConfig<SupportTableColumn>('id', SortDirection.Desc);
  private readonly pageChange$ = new BehaviorSubject(1);
  private readonly sortChange$ = new BehaviorSubject(SortConfig.fromConfig(this.sortConfigValue));

  /**
   * Value for all statuses.
   * If it is `null` then mat-select doesn't set it as default value.
   */
  public readonly allStatuses = "ALL";

  /**
   * All user statuses.
   */
  public readonly statuses = SupportTicketStatus;

  

  /**
   * Form control for realtor name input.
   */
  public readonly nameControl = new FormControl();


  public readonly assignedToControl = new FormControl();

  /**
   * Form control for realtor status select.
   */
  public readonly realtorStatusControl = new FormControl(this.allStatuses);

  /**
   * Realtors stream.
   */
  public realtors$: Observable<RealtorSummary[]>;

  /**
   * Pagination metadata stream.
   */
  public paginationMetadata$: Observable<PaginationMetadata>;

  /**
   * Realtor status config.
   * 
   * 
   */

   public   tableIsEmpty=false;


  @Input()
  public statusConfig: UserStatusConfig;

  /**
   * If `true` then information about associated loan officer will be displayed.
   */
  @Input()
  public displayLoanOfficerInfo = true;

  /**
   * Sort config.
   */
  @Input()
  public set sortConfig(value: SortConfig<SupportTableColumn>) {
    this.sortConfigValue = value;
    this.sortChange$.next(SortConfig.fromConfig(value));
  }

  public get sortConfig(): SortConfig<SupportTableColumn> {
    return this.sortConfigValue;
  }

  /**
   * Realtors table stream.
   */
  @Input()
  public table$: Observable<Table<RealtorSummary>>;

  /**
   * If `true` then pagination component will be displayed.
   */
  @Input()
  public pagination: boolean;

  /**
   * Displayed columns.
   */
  @Input()
  public displayedColumns = COLUMNS;

  /**
   * Second header columns.
   */
  @Input()
  public secondHeaderColumns = SECOND_HEADER_COLUMNS;

  /**
   * Emits new params for search request.
   */
  @Output()
  public searchChange = new EventEmitter<SupportListSearchParams>();

  /**
   * Emits new status and realtor's id.
   */
  @Output()
  public statusChange = new EventEmitter<UpdateUserStatusDto>();

  /**
   * Emits realtor's id.
   */
  @Output()
  public resendInviteChange = new EventEmitter<number>();

  
 /**
   * Emits realtor's email.
   */
  @Output()
  public generateInviteChange = new EventEmitter<string>();
  /**
   * @inheritdoc
   */

  public readonly isAdmin$ = this.rolesService.isAdmin$;

  @Input() public AssignedToCheckbox;
  @Input() public MyTicketsCheckbox;

  constructor(
    private rolesService: RolesService,
    private currentUserService: CurrentUserService
  ) {
    super();
  }

  public ngOnInit(): void {
    // console.log("Statuss",this.statuses);
    this.currentUserService.currentUser$.subscribe(res => console.log("res is ", res));
    
    if (typeof this.table$ === 'undefined') {
      throw new Error('Realtors table stream is undefined');
    }

    this.rolesService.isAdmin$.subscribe(isAdmin => {
      if(!isAdmin) {
        const removeColumnIfNotAdmin = ["id"]
        removeColumnIfNotAdmin.forEach((columnName:SupportTableColumn) => {
          var index = this.displayedColumns.indexOf(columnName);
          if(index > -1) {
            this.displayedColumns.splice(index,1);
            this.secondHeaderColumns.splice(index, 1)
          }
        })
      }
    });

    [this.realtors$, this.paginationMetadata$] = partitionTable(this.table$);
    this.listenSearchParamsChanges();

    this.realtors$.subscribe(res => {
      console.log(res);
    })

  }

  public ngOnChanges(): void {
    this.table$.subscribe(res =>{

      if(res.data && (res.data.length>0))
      {
        this.tableIsEmpty=false;
      }
      else
      {
        this.tableIsEmpty=true;
      }


        
    });
  
  }


  private listenSearchParamsChanges(): void {
    const search$ = this.createSearchStream();
    combineLatest(
      search$,
      this.pageChange$,
    )
      .pipe(
        map(([[raisedBy,assignedTo, status, orderBy], page]) => ({ raisedBy,assignedTo, status, orderBy, page })),
        tap(params => this.searchChange.emit(params)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private createSearchStream(): Observable<[string, string, SupportTicketStatus, string]> {
    return combineLatest(
      listenControlChanges<string>(this.nameControl),
      listenControlChanges<string>(this.assignedToControl),
      listenControlChanges<SupportTicketStatus>(this.realtorStatusControl),
      this.sortChange$,
    )
      .pipe(
        tap(() => this.pageChange$.next(1)),
      );
  }

  /**
   * Emits current page index.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.pageChange$.next(event.pageIndex);
  }

  /**
   * Emits selected sort config.
   * @param event Sort event.
   */
  public onMatSortChange(event: Sort): void {
    this.sortChange$.next(SortConfig.fromEvent(event));
  }

  /**
   * Emits data for updating status.
   * @param status New status.
   * @param realtor Realtor.
   */
  public onStatusChange(status: UserStatus, realtor: RealtorSummary): void {
    this.statusChange.emit({ status, user: realtor });
  }

  /**
   * Emits realtor's id to resend invite.
   * @param id Realtor's id.
   */
  public onResendInvite(id: number): void {
    this.resendInviteChange.emit(id);
  }

  public onGenerateInvite(email:string):void{
    
    // this.generateInviteChange.emit(email);
  }
}
