import { Full } from '../helpers/full';

/**
 * Auth token info.
 */
export class AuthTokenInfo {

  /**
   * Expiration date.
   */
  public expirationDate: Date;

  /**
   * Access token.
   */
  public accessToken: string;

  /**
   * Refresh token.
   */
  public refreshToken: string;

  constructor(data: Full<AuthTokenInfo>) {
    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;
    this.expirationDate = data.expirationDate;
  }

  /**
   * Returns true if the token expires soon.
   */
  public expiresSoon(): boolean {
    return Date.now() > this.expirationDate.getTime();
  }

}
