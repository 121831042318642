import { Full } from '../helpers/full';

/**
 * Invite realtor data.
 */
export class InviteRealtorData {

  /**
   * Loan officer id.
   */
  public loanOfficerId: number;

  /**
   * First name.
   */
  public firstName: string;

  /**
   * Last name.
   */
  public lastName: string;

  /**
   * Email.
   */
  public email: string;

  /**
   * Message.
   */
  public message: string;

  constructor(data: Full<InviteRealtorData>) {
    this.loanOfficerId = data.loanOfficerId;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.message = data.message;
  }

}
