import { Router } from '@angular/router';

import { OfferService } from './../../../../core/services/offer.service';
import { RpaFileData } from './../../../../offers/models/rpa-file-data';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject, BehaviorSubject, observable } from 'rxjs';
import { switchMap, tap, takeUntil, filter, exhaustMap, map } from 'rxjs/operators';
import { AzureOcrService } from "../../../../core/services/azure-ocr-service"
import { DestroyableBase } from '../../../../core';
import { DialogService } from '../../../services/dialog.service';

/**
 * Account image component.
 * @todo It should be improved when API will be ready.
 */

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
  readonly type: string;
}


@Component({
  selector: 'arb-account-image',
  templateUrl: './account-image.component.html',
  styleUrls: ['./account-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountImageComponent extends DestroyableBase implements OnInit {
  private customFields = [
    ['Address',"<%Address>"],
    ['Downpayment',"Downpayment"],
    ["Fico", "<%Fico>"],
    ["Occupacy", "<%Occupacy>"],
    ["Programtype", "<%Programtype"],
    ["Purchaseprice", "<%Purchaseprice>"],
    ["Propertytype", "<%Propertytype>"],
  ]
    
  
  private customFieldforcordinates = {
    Address: [],
    Downpayment: [],
    Fico: [],
    Occupancy: [],
    Programtype: [],
    Purchaseprice: [],
    Propertytype: [],
  };

  private readonly upload$ = new Subject<File>();
  private imageMaxSize = 5000000 /* bytes (5mb) */;
  public deepExtract: boolean = false;
  /**
   * Show image error.
   */
  public showImageError: boolean;
  /**
 * Extraction errors
 */
  public rpaFileExtractionError$: string = "";
  /**
   * For disabling submit button while a request is in progress.
   */
  public readonly isExtractingCompleted$ = new BehaviorSubject(false);
  /**
   * Loaded image's src.
   */
  public readonly loadedImageSrc$ = new BehaviorSubject<string | null>(null);

  /**
   * Loaded image's name.
   */
  public readonly loadedImageName$ = new BehaviorSubject<string | null>(null);
  /**
   * Emits uploaded file.
   */
  @Output()
  public extractedRpaFileData = new EventEmitter<any>();
  @Output()
  public onGetCordinates = new EventEmitter<any>();

  private readonly uploaded$ = new Subject<File | null>();
  constructor(
    private dialogService: DialogService,
    private azureocrservice: AzureOcrService, private offerService: OfferService,
    private router:Router
  ) {
    super();
  }

  /**
   * Image title.
   */
  @Input()
  public imageTitle: string;
  @Input()
  public loanOfficerId: number;
  /**
   * Image title.
   */
  @Input()
  public isCustom: boolean;

  @Input()
  public uploadCustom: boolean;

  private removeRpaFile = false;

  @Input()
  public isShowDraw: boolean;
  
  /**
   * Image src.
   */
  @Input()
  public set src(value: string) {
    if(value){
      this.loadedImageSrc$.next(value);
      const name = this.getImageNameFromUrl(value);
      this.loadedImageName$.next(name);
    }else{
      if(this.imageTitle == 'Profile Picture' || this.imageTitle =='Realtor Picture'){
        value = "https://i.ibb.co/CPxPNm4/Profile-150-new.png";
      }else if(this.imageTitle == 'Company Logo'){
        value = "https://i.ibb.co/k20Nc8h/company-150.png";
      }else if(this.imageTitle == 'Draw Signature' || this.imageTitle == 'Signature' || this.imageTitle == 'Realtor Signature'){
        value = "https://i.ibb.co/6b3StZS/Signature-150.png";
      }
      this.loadedImageSrc$.next(value);
      this.loadedImageName$.next("");
    }
    
  }

  /**
   * Loaded.
   */
  @Output()
  public loaded = new EventEmitter<File>();

  /**
  * Loaded.
  */
  @Output()
  public getCordinates = new EventEmitter<any>();

  /**
   * Removed.
   */
  @Output()
  public removed = new EventEmitter<void>();
  /**
   * Emits uploaded file.
   */
  @Output()
  public uploaded = new EventEmitter<RpaFileData>();

  @Output()
  public isCustomCheck=new EventEmitter<Boolean>();
  //create CSV file data in an array  

  @ViewChild('fileInput')
  public fileInput: ElementRef<HTMLInputElement>;
  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.listenFileUploading();
  }
  public isCustomChecked(checked) {
    console.log("checked ",checked)
    this.isCustomCheck.emit(checked)
    this.isCustom = checked
  }
  download_csv_file() {  
  
    //define the heading for each row of the data  
    var csv = 'Key,Value\n';  
      
    //merge the data with CSV  
    this.customFields.forEach(function(row) {  
            csv += row.join(',');  
            csv += "\n";  
    });  
   
    var downloadLink = document.createElement("a");
    var blob = new Blob([csv], { type: 'text/csv' });
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "demo";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}  

  openSignaturePad() { 
    this.dialogService.openSignaturePad().subscribe(file => {
      if (file !== undefined) {
        this.loaded.next(file);
        this.upload$.next(file);
      }
    });
  }
  setCordinates(data) {
    this.getCordinates.emit(data)
  }
  public preview(){
    this.router.navigate([`lo-custom-preview-page/{${this.loanOfficerId}`])
  }
  getBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      var fileReader = new FileReader();
      var base64: string;
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent: any) {
        if ((fileLoadedEvent.target != null)) {
          base64 = fileLoadedEvent.target.result;
        }
        // Print data in console
        return resolve(base64);
      };
      // Convert data to base64
      fileReader.readAsDataURL(file);
    });
  }

  private getImageNameFromUrl(url: string): string {
    if (!url) return "";
    const lastSlashPosition = url.lastIndexOf('/');
    return url.slice(lastSlashPosition + 1);
  }
  private clearFileInput(): void {
    this.fileInput.nativeElement.value = '';
  }
  private listenFileUploading(): void {
    this.upload$
      .pipe(
        tap(() => this.showImageError = false),
        filter(file => this.validateFile(file)),
        exhaustMap(file => this.dialogService.openDocumentEditorDialogWithUIParams(file, {
          dialogHeader: "",
          dialogInstruction: "Use Crop button to edit the image",
          isShowAgreementControl: false
        })
          .pipe(
            tap(() => this.clearFileInput()),
            filter(file => !!file),
            map((file: File[]) => ({ file })),
            tap(({file} )  => {
              if(file.length){
                this.loadedImageName$.next(file[0].name)
              }else{
                this.loadedImageName$.next(null)
              }
            }),
            // tap(({file}) => this.loaded.emit(file[0])),
            switchMap(({ file }) => this.readFile(file[0])),
            tap(src => this.loadedImageSrc$.next(src)),
            takeUntil(this.destroy$),
          )
        )
      )
      .subscribe();
  }

  private validateFile(file: File): boolean {
    if (file) {
      if (file.size > this.imageMaxSize) {
        this.showImageError = true;
        return false;
      }
      return true;
    }
    return false;
  }

  private readFile(file: File): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result as string);
    });
  }

  /**
   * On upload.
   * @param file Uploaded file.
   */
  public async onUpload(file: File) {
    this.loaded.next(file)
    this.upload$.next(file);
    // this.loadedImageName$.next(file.name)
    var validImageTypes = ["image/gif", "image/jpeg", , "image/png"];
    let fieldCordinates :any =[]
    if(validImageTypes.includes(file.type)){
      console.log("png")
      let extractedDataList :any = await this.azureocrservice.getExtractedCustomDataFromImage(file)
      for (let key in this.customFieldforcordinates) {
        for (let index = 0; index < extractedDataList.lines.length; index++) {
          let element = extractedDataList.lines[index];
          if (element.text.includes(key)) {
            for (let j = 0; j < element.words.length; j++) {
              let item = element.words[j];
              if (item.text.includes("<%" + key)) {
                let temp = {};
                temp[key] = item.boundingBox
                fieldCordinates.push(temp)
              }
            }
          }
        }
      }
    }
    else{
      let extractedDataList: any = await this.azureocrservice.getExtractedDataforCustomePreApprovalPdf(file)
              for (const OuterKey in extractedDataList) {
                if (Object.prototype.hasOwnProperty.call(extractedDataList, OuterKey)) {
                  const outerelement = extractedDataList[OuterKey].lines;
                  for (let Customkey in this.customFieldforcordinates) {
                    for (const key in outerelement) {
                      const element = outerelement[key];
                      if (element.text.includes(Customkey)) {
                        for (let j = 0; j < element.words.length; j++) {
                          let item = element.words[j];
                          if (item.text.includes("<%" + Customkey)) {
                            let field = {}
                            field[Customkey] = { cordinates: item.boundingBox, pageNo: Number(OuterKey) + 1 }
                            fieldCordinates.push(field)
                          }
                        }
                      }
                    }
                  }
                }
              }
    }
    this.setCordinates(fieldCordinates)
   
  }


  /**
   * Remove.
   */
  public remove(): void {
    let value="";
    if(this.imageTitle == 'Profile Picture'|| this.imageTitle =='Realtor Picture'){

      value = "https://i.ibb.co/CPxPNm4/Profile-150-new.png";
    }else if(this.imageTitle == 'Company Logo'){

      value = "https://i.ibb.co/k20Nc8h/company-150.png";
    }else if(this.imageTitle == 'Draw Signature' || this.imageTitle == 'Signature' || this.imageTitle == 'Realtor Signature' ){

      value = "https://i.ibb.co/6b3StZS/Signature-150.png";
    }
    this.loadedImageSrc$.next(value);
    this.loadedImageName$.next("");
    // this.loadedImageName$.next(null);
    // this.loadedImageSrc$.next(null);
    this.removed.emit();
  }

  /**
   * Returns max size in megabytes.
   */
  public get imageMaxSizeMb(): number {
    return this.imageMaxSize / 1000000;
  }

}
