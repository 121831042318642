import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { switchMap, mapTo, tap } from 'rxjs/operators';
import { readFile } from 'src/app/core';

import { DialogService } from '../../../services/dialog.service';

/**
 * Document editor page component.
 */
@Component({
  selector: 'arb-document-editor-page',
  templateUrl: './document-editor-page.component.html',
  styleUrls: ['./document-editor-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentEditorPageComponent {

  private readonly uploaded$ = new Subject<File>();
  private readonly cropper$ = new Subject<File>();

  /**
   * Src stream.
   */
  public readonly images$ = this.createSrcStream();

  constructor(
    private dialogService: DialogService,
  ) { }

  private createSrcStream(): Observable<string[]> {
    return this.uploaded$
      .pipe(
        switchMap(file => this.dialogService.openDocumentEditorDialog(file)),
        switchMap((files: File[]) => combineLatest(...files.map(file => readFile(file)))),
      );
  }

  /**
   * On file upload.
   * @param file File.
   */
  public onFileUpload(file: File): void {
    this.uploaded$.next(file);
  }

  /**
   * On cropper upload file.
   * @param file File
   */
  public onCropperUpload(file: File): void {
    this.cropper$.next(file);
  }

}
