import { Full } from '../../helpers/full';

/**
 * Update realtor dto.
 */
export class UpdateRealtorDto {

  /**
   * Company name.
   */
  public companyName: string;

  /**
   * Footer color.
   */
  public footerColor: string;

  /**
   * Header color.
   */
  public headerColor: string;

   /**
   * icons color.
   */
    public iconsColor: string;

  /**
   * First name.
   */
  public firstName: string;

  /**
   * Last name.
   */
  public lastName: string;

  /**
   * Email.
   */
  public email: string;

  /**
   * Phone number.
   */
  public phoneNumber: string;

  /**
   * Office address.
   */
  public officeAddress: string;

  /**
   * Office website.
   */
  public website: string;

  /**
   * Realtor logo.
   */
  public realtorLogo: File | null;

  /**
   * Company logo.
   */
  public companyLogo: File | null;

  /**
   * Realtor picture.
   */
  public realtorPicture: File | null;

  /**
   * Signature picture.
   */
  public signaturePicture: File | null;

  /**
   * `true` if realtor picture is removed.
   */
  public pictureRemoved: boolean;

  /**
   * `true` if company logo is removed.
   */
  public companyLogoRemoved: boolean;

  /**
   * `true` if signature is removed.
   */
  public signatureRemoved: boolean;

  /**
   * `true` if logo is removed.
   */
  public logoRemoved: boolean;


  
  /**
   * Email Signature
   */
  public emailSignature: string;
  
  /**
   * Email Signature
   */
  public timeZone?: string | any;
  
  /**
   * licenseNumber
   */
  public licenseNumber?: string | any;

  constructor(data: Full<UpdateRealtorDto>) {
    this.companyName = data.companyName;
    this.footerColor = data.footerColor.replace('#', '');
    this.headerColor = data.headerColor.replace('#', '');
    this.iconsColor =  data.iconsColor.replace('#','');
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.realtorLogo = data.realtorLogo;
    this.companyLogo = data.companyLogo;
    this.realtorPicture = data.realtorPicture;
    this.officeAddress = data.officeAddress;
    this.website = data.website;
    this.signaturePicture = data.signaturePicture;
    this.pictureRemoved = data.pictureRemoved;
    this.companyLogoRemoved = data.companyLogoRemoved;
    this.signatureRemoved = data.signatureRemoved;
    this.logoRemoved = data.logoRemoved;
    this.emailSignature = data.emailSignature;
    this.timeZone = data.timeZone;
    this.licenseNumber = data.licenseNumber;
  }

}
