import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { AppConfig } from '../../../app.config';
import { PreApprovalStatus } from '../../../core';

import { AssetFileDto } from './dto/asset-file-dto';
import { PreApprovalDto } from './dto/pre-approval-dto';

/**
 * Borrower pre approval service.
 */
@Injectable({
  providedIn: 'root',
})
export class BorrowersPreApprovalService {

  constructor(
    private http: HttpClient,
    private config: AppConfig,
  ) {
  }

  /**
   * Updates pre approval status.
   * @param status pre-approval status.
   * @param borrowerId Borrower id.
   */
  public updatePreApprovalStatus(status: PreApprovalStatus, borrowerId: number): Observable<void> {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/pre-approval/status`;
    return this.http
      .put<void>(url, { status })
      .pipe(
        mapTo(void 0),
      );
  }

  /**
   * Updates pre approval borrower.
   * @param preApproval pre-approval borrower.
   * @param borrowerId Borrower id.
   */
  public updatePreApproval(preApproval: PreApprovalDto, borrowerId: number): Observable<void> {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/pre-approval`;
    return this.http
      .put<void>(url, preApproval)
      .pipe(
        mapTo(void 0),
      );
  }

  /**
   * Delete borrower asset by id.
   * @param id asset id
   * @param borrowerId borrower id.
   */
  public deleteAsset(id: number, borrowerId: number): Observable<void> {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/pre-approval/assets/${id}/file`;
    return this.http
      .delete<void>(url)
      .pipe(
        mapTo(void 0),
      );
  }

  /**
   * Upload file in borrower asset.
   * @param assetFileDto uploaded to the server.
   * @param borrowerId Id of the borrower to which this asset belongs.
   */
  public uploadAsset(assetFileDto: AssetFileDto, borrowerId: number): Observable<void> {
    const formData = new FormData();
    formData.append('id', assetFileDto.id.toString());
    formData.append('assetTypeId', assetFileDto.assetTypeId.toString());
    for (const file of assetFileDto.files) {
      formData.append('files', file, this.mapFileNameToFileNameDto(file.name));
    }
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/pre-approval/assets/${assetFileDto.assetTypeId}/file`;
    return this.http.put<void>(url, formData);
  }

  public updateSupportingDocName(asset, borrowerId: number): Observable<void> {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/pre-approval/assets/${asset.assetTypeId}/updateName`;
    return this.http.put<void>(url, asset);
  }

  private mapFileNameToFileNameDto(fileName: string): string {
    const maxLength = 58;
    const arrayOfLinesSeparatedByDots = fileName.split('.');
    const name = arrayOfLinesSeparatedByDots.slice(0, -1).join('.');
    const fileExtension = arrayOfLinesSeparatedByDots[arrayOfLinesSeparatedByDots.length - 1];
    return `${name.slice(0, maxLength)}.${fileExtension}`;
  }

  public getPreApprovalAssestsLink(borrowerId: number, preApprovalId: number)  {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/get-pre-approval-info-pdf/${preApprovalId}`;
    return this.http.get(url);
  }

  public getRealtorPreApprovalDocDemoLink(id: number)  {
    const url = `${this.config.apiUrl}/api/realtors/pre-approval-doc-demo/${id}`;
    return this.http.get(url);
  }

  public getLoanOfficerPreApprovalDocDemoLink(id: number)  {
    const url = `${this.config.apiUrl}/api/loan-officers/pre-approval-doc-demo/${id}`;
    return this.http.get(url);
  }

  public getCustomLoanOfficerPreApprovalDocDemoLink(id: number)  {
    const url = `${this.config.apiUrl}/api/loan-officers/pre-approval-doc-demo-custom/${id}`;
    return this.http.get(url);
  }
  
  public sortPreApprovalAssets(preApprovalData) {
    const url = `${this.config.apiUrl}/api/pre-approvals/sortPreapprovalAsset`;
    return this.http.post(url, preApprovalData);
  }

  public getPreApprovalPdfUrl(borrowerId: number, preApprovalId: number)  {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/get-pre-approval-pdf-url/${preApprovalId}`;
    return this.http.get(url);
  }
}
