import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { readFile } from './read-file';

/**
 * Creates image from uploaded file.
 * @param file Uploaded file.
 */
export function createImage(file: File | Blob): Observable<HTMLImageElement> {
  return readFile(file)
    .pipe(
      switchMap(base64 => new Observable<HTMLImageElement>(observer => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
          observer.next(img);
          observer.complete();
        };
        img.onerror = (e) => observer.error();
      })),
    );
}
