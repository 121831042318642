import { Pipe, PipeTransform } from '@angular/core';

interface KeyValue {
  key: string;
  value: number;
}

/**
 * Enum keys pipe.
 */
@Pipe({
  name: 'enumkeyvalue',
  pure: true,
})
export class EnumKeyValuePipe implements PipeTransform {

  /**
   * Gets enum and returns array of the enum's keys.
   * @param value Enum.
   */
  public transform(object: Object): KeyValue[] {
    return Object.keys(object)
      .reduce((acc, key) => {
        if (Number(key)) {
          return [
            ...acc,
            { key: object[key], value: Number(key) },
          ];
        }
        return acc;
      }, []);
  }

}
