import { Pipe, PipeTransform } from '@angular/core';

import { ToolboxItem } from '../../core/models/toolbox/toolbox-item';

/**
 * Keeps only the items belong to the specified category.
 */
@Pipe({
  name: 'filterByCategory',
})
export class FilterByCategoryPipe implements PipeTransform {
  /**
   * Keeps only the items belong to the specified category.
   * @param items List of all the items.
   * @param categoryId Id of the category to filter by.
   */
  public transform(items: ToolboxItem[] | null, categoryId: number): ToolboxItem[] {
    if (items === null) {
      return [];
    }
    var filterredItemo =  items.filter((item: ToolboxItem) => item.categoryId === categoryId);
    filterredItemo = filterredItemo.sort(function(aItem,bItem) {return aItem.toolboxSequence-bItem.toolboxSequence})
    return filterredItemo;
  }
}
