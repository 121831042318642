import { Full } from '../../core';

/**
 * Rpa file data.
 */
export class RpaFileData {

  /**
   * File.
   */
  public file: File | null;

  /**
   * If `true` then RPA file will be deleted.
   */
  public removeRpaFile: boolean;

  /**
   * If `true` then RPA file exists.
   */
  public rpaFileExists: boolean;

  constructor(data: Full<RpaFileData>) {
    this.file = data.file;
    this.removeRpaFile = data.removeRpaFile;
    this.rpaFileExists = data.rpaFileExists;
  }

}
