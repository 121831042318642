/**
 * Borrower note type.
 */
export enum BorrowerNoteType {

  /**
   * Normal.
   */
  Normal = 1,

  /**
   * Denied pre approval.
   */
  DeniedPreApproval = 2,

}
