/**
 * Base dialog data.
 */
export abstract class BaseDialogData {

  /**
   * Title.
   */
  public title: string;

  /**
   * Subtitle.
   */
  public subtitle: string;

  constructor(title: string, subtitle: string) {
    this.title = title;
    this.subtitle = subtitle;
  }

}
