import { Full } from '../../helpers/full';

import { CreateOffer } from './create-offer';

/**
 * Update offer.
 */
export class UpdateOffer extends CreateOffer {

  /**
   * If `true` then letter photo will be removed.
   */
  public removeCoverLetterPhoto: boolean;

  /**
   * If `true` then RPA file will be deleted.
   */
  public removeRpaFile: boolean;

  constructor(data: Full<UpdateOffer>) {
    super(data);
    this.removeCoverLetterPhoto = data.removeCoverLetterPhoto;
    this.removeRpaFile = data.removeRpaFile;
  }

}
