import { Observable } from 'rxjs';
import { distinctUntilChanged, map, share } from 'rxjs/operators';

import { PaginationMetadata } from '../models/pagination-metadata';
import { Table } from '../models/table';

type TableValueOf<O> = O extends Table<infer T> ? T : never;

/**
 * Static function that split one table observable into two with data and pagination metadata.As rxjs operator partition.
 */
export function partitionTable<T extends Table<TableValueOf<T>>>(
  source$: Observable<T>,
): [
  Observable<TableValueOf<T>[]>,
  Observable<PaginationMetadata>
  ] {
  const shareSource$ = source$.pipe(
    share(),
  );
  return [
    shareSource$.pipe(
      map(table => table.data),
    ),
    shareSource$.pipe(
      map(table => table.paginationMetadata),
      distinctUntilChanged(((paginationFirst, paginationSecond) => {
        return JSON.stringify(paginationFirst) === JSON.stringify(paginationSecond);
      })),
    ),
  ];
}
