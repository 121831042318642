import { Full } from "../../helpers/full";

import { DownPaymentType } from "./down-payment-type";

/**
 * Class for creating offer.
 */
export class CreateOffer {
  /**
   * Borrower id.
   */
  public borrowerId: number;

  /**
   * Use realtor picture or use uploaded one.
   */
  public useRealtorPicture: boolean;

  /**
   * Subject of offer.
   */
  public subject: string;

  /**
   * Borrower's quote.
   */
  public borrowerQuote: string;

  /**
   * Offer's letter.
   */
  public letter: string;

  /**
   * Offer letter's conclusion.
   */
  public letterConclusion: string;

  /**
   * Offer's price.
   */
  public price: number;

  /**
   * Offer's price notes.
   */
  public priceNotes: string;

  /**
   * Deposit.
   */
  public deposit: number;

  /**
   * Deposit notes.
   */
  public depositNotes: string;

  /**
   * Down payment.
   */
  public downPayment: number;

  /**
   * Down payment type.
   */
  public downPaymentType: DownPaymentType;

  /**
   * Down payment notes.
   */
  public downPaymentNotes: string;

  /**
   * Financing.
   */
  public financing: number;

  /**
   * Total.
   */
  public total: string;

  /**
   * Lender.
   */
  public lender: string;

  /**
   * Escrow company.
   */
  public escrowCompany: string;

  /**
   * Response deadline.
   */
  public responseDeadline: Date;

  /**
   * Total notes.
   */
  public totalNotes: string;

  /**
   * Address.
   */
  public address: string;

  /**
   * City.
   */
  public city: string;

  /**
   * State.
   */
  public state: string;

  /**
   * Zip.
   */
  public zip: string;

  /**
   * Property type.
   */
  public propertyType: number;

  /**Listing Agent Information */
  public agentName: string;
  public agentEmail:string;
  public agentPhoneNo:string;
  /**
   * Cover letter photo.
   */
  public coverLetterPhoto: Blob | File | null;

  /**
   * Rpa file.
   */
  public rpaFile: File | null;

  /**
   * Recipient name.
   */
  public recipientName: string;

  /**
   * Contingency Appraisal.
   */
  public contingency: string;

  /**
   * Contingency Loan.
   */
  public contingencyLoan: string;

  /**
   * Contingency General.
   */
  public contingencyGeneral: string;

  /**
   * Buyer Type.
   */
  public buyerType: number;

  // remove asset 1
  public removeAdditionalDocument1: boolean;

  // remove asset 2
  public removeAdditionalDocument2: boolean;

  constructor(data: Full<CreateOffer>) {
    this.agentName=data.agentName;
    this.agentEmail=data.agentEmail;
    this.agentPhoneNo=data.agentPhoneNo;
    this.borrowerId = data.borrowerId;
    this.useRealtorPicture = data.useRealtorPicture;
    this.subject = data.subject;
    this.borrowerQuote = data.borrowerQuote;
    this.letter = data.letter;
    this.letterConclusion = data.letterConclusion;
    this.price = data.price;
    this.priceNotes = data.priceNotes;
    this.deposit = data.deposit;
    this.depositNotes = data.depositNotes;
    this.downPayment = data.downPayment;
    this.downPaymentType = data.downPaymentType;
    this.downPaymentNotes = data.downPaymentNotes;
    this.financing = data.financing;
    this.total = data.total;
    this.lender = data.lender;
    this.escrowCompany = data.escrowCompany;
    this.responseDeadline = data.responseDeadline;
    this.totalNotes = data.totalNotes;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.propertyType = data.propertyType;
    this.coverLetterPhoto = data.coverLetterPhoto;
    this.rpaFile = data.rpaFile;
    this.recipientName = data.recipientName;
    this.contingency = data.contingency;
    this.contingencyLoan = data.contingencyLoan;
    this.contingencyGeneral = data.contingencyGeneral;
    this.buyerType = data.buyerType;
    this.removeAdditionalDocument1 = data.removeAdditionalDocument1;
    this.removeAdditionalDocument2 = data.removeAdditionalDocument2;
  }

  /**
   * Validates required properties and returns `true` if they are valid.
   */
  public get valid(): boolean {
    return (
      !!this.borrowerId &&
      !!this.subject &&
      !!this.downPaymentType &&
      !!this.address &&
      !!this.city
    );
  }
}
