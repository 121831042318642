import { Full } from '../../../../core';
import { ConfirmDialogData } from '../../../models/confirm-dialog-data';

/**
 * Pre approval change status dialog data.
 */
export class PreApprovalChangeStatusDialogData extends ConfirmDialogData {
  constructor(data: Full<PreApprovalChangeStatusDialogData>) {
    super(data);
  }

}
