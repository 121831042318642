import { MatButtonColor } from './../../../models/mat-button-color';
import { ConfirmDialogData } from './../../../models/confirm-dialog-data';
import { CurrentUserService } from './../../../../core/services/current-user.service';
import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort, MatDialog } from '@angular/material';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';

import {
  RealtorSummary,
  PaginationMetadata,
  Table,
  partitionTable,
  PageEvent,
  SortConfig,
  SortDirection,
  UserStatus,
  listenControlChanges,
  RealtorsSearchParams,
  DestroyableBase,
  UpdateUserStatusDto,
  RolesService
} from '../../../../core';
import { UserStatusConfig ,ConfirmDialogComponent} from '../../../../shared';
import { RealtorsTableColumn } from '../../../models/realtors-table-column';
import { RealtorsTableSecondColumn } from '../../../models/realtors-table-second-column';

const COLUMNS: RealtorsTableColumn[] = ['id', 'name', 'borrowers', 'offers', 'status','isMapped', 'lastUpdated'];
const SECOND_HEADER_COLUMNS: RealtorsTableSecondColumn[] = [
  'empty',
  'search_by_name',
  'empty',
  'empty',
  'select_status',
  'empty',
];

/**
 * Realtors table.
 */
@Component({
  selector: 'arb-realtors-table',
  templateUrl: './realtors-table.component.html',
  styleUrls: ['./realtors-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RealtorsTableComponent extends DestroyableBase implements OnInit {

  private sortConfigValue = new SortConfig<RealtorsTableColumn>('lastUpdated', SortDirection.Desc);
  private readonly pageChange$ = new BehaviorSubject(1);
  private readonly sortChange$ = new BehaviorSubject(SortConfig.fromConfig(this.sortConfigValue));

  /**
   * Value for all statuses.
   * If it is `null` then mat-select doesn't set it as default value.
   */
  public readonly allStatuses = 0;

  public tableIsEmpty = false;

  /**
   * All user statuses.
   */
  public readonly statuses = UserStatus;

  /**
   * Form control for realtor name input.
   */
  public readonly nameControl = new FormControl();

  /**
   * Form control for realtor status select.
   */
  public readonly realtorStatusControl = new FormControl(this.allStatuses);

  /**
   * Realtors stream.
   */
  public realtors$: Observable<RealtorSummary[]>;

  public getCurrentUserType: string;


  /**
   * Pagination metadata stream.
   */
  public paginationMetadata$: Observable<PaginationMetadata>;

  /**
   * Realtor status config.
   */
  @Input()
  public statusConfig: UserStatusConfig;

  /**
   * If `true` then information about associated loan officer will be displayed.
   */
  @Input()
  public displayLoanOfficerInfo = true;

  /**
   * Sort config.
   */
  @Input()
  public set sortConfig(value: SortConfig<RealtorsTableColumn>) {
    this.sortConfigValue = value;
    this.sortChange$.next(SortConfig.fromConfig(value));
  }

  public get sortConfig(): SortConfig<RealtorsTableColumn> {
    return this.sortConfigValue;
  }

  /**
   * Realtors table stream.
   */
  @Input()
  public table$: Observable<Table<RealtorSummary>>;

  /**
   * If `true` then pagination component will be displayed.
   */
  @Input()
  public pagination: boolean;

  /**
   * Displayed columns.
   */
  @Input()
  public displayedColumns = COLUMNS;

  /**
   * Second header columns.
   */
  @Input()
  public secondHeaderColumns = SECOND_HEADER_COLUMNS;

  /**
   * Emits new params for search request.
   */
  @Output()
  public searchChange = new EventEmitter<RealtorsSearchParams>();

  /**
   * Emits new status and realtor's id.
  */
  @Output()
  public statusChange = new EventEmitter<UpdateUserStatusDto>();

  /**
   * Emits new mapping and realtor object.
   */
  @Output()
  public changeMappingStatus = new EventEmitter<any>();

  /**
   * Emits realtor's id.
   */
  @Output()
  public resendInviteChange = new EventEmitter<number>();


  /**
    * Emits realtor's email.
    */
  @Output()
  public generateInviteChange = new EventEmitter<string>();
  /**
   * @inheritdoc
   */

  public readonly isAdmin$ = this.rolesService.isAdmin$;

  public currentUser = {};


  constructor(
    private rolesService: RolesService,
    private currentUserService: CurrentUserService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,

  ) {
    super();
  }

  public ngOnInit(): void {

    this.currentUserService.currentUser$.subscribe(data => {
      // console.log("table check ad", data.role);
      this.currentUser = data;
      this.getCurrentUserType = data.role;
      if (this.getCurrentUserType == 'Admin') {
        // this.secondHeaderColumns.unshift('empty');
        let checkIndexOfMappingStatus = this.displayedColumns.findIndex(name => name == 'isMapped');
        if(checkIndexOfMappingStatus>-1) {
          this.displayedColumns.splice(checkIndexOfMappingStatus);
          this.secondHeaderColumns.splice(checkIndexOfMappingStatus);
        }
        this.cdr.markForCheck();
      } else {
        this.cdr.markForCheck();
        // this.secondHeaderColumns.shift();
      }
    });

    if (typeof this.table$ === 'undefined') {
      throw new Error('Realtors table stream is undefined');
    }
    // this.cdr.markForCheck();
    this.rolesService.isAdmin$.subscribe(isAdmin => {
      if (!isAdmin) {
        const removeColumnIfNotAdmin = ["id",'borrowers', 'offers']
        removeColumnIfNotAdmin.forEach((columnName: RealtorsTableColumn) => {
          var index = this.displayedColumns.indexOf(columnName);
          if (index > -1) {
            this.displayedColumns.splice(index, 1);
            this.secondHeaderColumns.splice(index, 1);
            this.cdr.markForCheck();
          }
        })
      }else{
        this.cdr.markForCheck();
      }
      
    });

    [this.realtors$, this.paginationMetadata$] = partitionTable(this.table$);
    this.listenSearchParamsChanges();
  }

  public ngOnChanges(): void {
    this.table$.subscribe(res => {

      if (res.data && (res.data.length > 0)) {
        this.tableIsEmpty = false;
      }
      else {
        this.tableIsEmpty = true;
      }
    });

  }

  private listenSearchParamsChanges(): void {
    const search$ = this.createSearchStream();
    combineLatest(
      search$,
      this.pageChange$,
    )
      .pipe(
        map(([[realtorName, status, orderBy], page]) => ({ realtorName, status, orderBy, page })),
        tap(params => this.searchChange.emit(params)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private createSearchStream(): Observable<[string, UserStatus, string]> {
    return combineLatest(
      listenControlChanges<string>(this.nameControl),
      listenControlChanges<UserStatus>(this.realtorStatusControl),
      this.sortChange$,
    )
      .pipe(
        tap(() => this.pageChange$.next(1)),
      );
  }

  /**
   * Emits current page index.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.pageChange$.next(event.pageIndex);
  }

  /**
   * Emits selected sort config.
   * @param event Sort event.
   */
  public onMatSortChange(event: Sort): void {
    this.sortChange$.next(SortConfig.fromEvent(event));
  }

  /**
   * Emits data for updating status.
   * @param status New status.
   * @param realtor Realtor.
   */
  public onStatusChange(status: UserStatus, realtor: RealtorSummary): void {
    this.statusChange.emit({ status, user: realtor });
  }

  /**
   * Emits data for updating status.
   * @param status New status.
   * @param realtor Realtor.
   */
  public changeUserMappingStatus(realtor: RealtorSummary): void {
    const r = JSON.parse(JSON.stringify(realtor));
    if (r.isMapped) {
      let data = new ConfirmDialogData({
        title: "Deactivate User Status",
        subtitle: "",
        rejectButtonText: "Cancel",
        acceptButtonText: "Deactivate",
        acceptButtonColor: MatButtonColor.Primary,
      })
      const dialogRef = this.openConfirmDialog(data)
      dialogRef.subscribe(value=>{
       if(value)this.changeMappingStatus.emit(r);
      })
    }else{
      this.changeMappingStatus.emit(r);
    }
    
  }

  /**
   * Emits realtor's id to resend invite.
   * @param id Realtor's id.
   */
  public onResendInvite(id: number): void {
    this.resendInviteChange.emit(id);
  }

  public onGenerateInvite(email: string): void {

    // this.generateInviteChange.emit(email);
  }

  /**
   * Open confirm dialog.
   * @param data Confirm dialog data.
   */
   public openConfirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.matDialog
      .open(ConfirmDialogComponent, { data, autoFocus: false })
      .afterClosed();
      
  }
}
