/**
 * Message status.
 */
export enum MessageStatus {

  /**
   * Error.
   */
  Error,

  /**
   * Success.
   */
  Success,

}
