import { ActivatedRoute } from "@angular/router";
import {
  Component,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  BorrowerService,
  NavigationLink,
  RolesService,
  UserRole,
  UsersService,
} from "../../../../core";

/**
 * List of root navigation links.
 */
const BORROWERS_LINK = "/borrowers";

/**
 * List of root navigation links.
 */
const NAVIGATION_LINKS: NavigationLink[] = [
  {
    text: "Dashboard",
    url: "/dashboard",
    userRole: [UserRole.Admin, UserRole.Realtor, UserRole.LoanOfficer],
  },
  {
    text: "Borrowers",
    url: BORROWERS_LINK,
    userRole: [UserRole.LoanOfficer, UserRole.Admin, UserRole.Realtor],
  },
  {
    text: "Realtors",
    url: "/realtors",
    userRole: [UserRole.Admin, UserRole.LoanOfficer],
  },
  { text: "Loan Officers", url: "/loan-officers", userRole: [UserRole.Admin, UserRole.Realtor] },
  {
    text: "Offers",
    url: "/offers",
    userRole: [UserRole.Admin, UserRole.LoanOfficer, UserRole.Realtor],
  },
  {
    text: "Support",
    url: "/support",
    userRole: [UserRole.Admin, UserRole.LoanOfficer, UserRole.Realtor],
  },
  { text: "Toolbox", url: "/toolbox", userRole: [UserRole.Realtor] },
  { text: "Toolbox", url: "/admin-toolbox", userRole: [UserRole.Admin] },
];

/**
 * Header component without onPush because of the bug with long redraw tabs.
 */
@Component({
  selector: "arb-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  public isMobileView: boolean = true;
  public windowWidth;
  public logoUrl = "/assets/logo.png?date=" + new Date();
  @ViewChild("mobileMenu") mobileMenu: ElementRef;
  /**
   * All user roles.
   */
  public readonly userRoles = UserRole;

  /**
   * List of root navigation links.
   */
 

  /**
   * Total borrowers count for loan officer borrowers link.
   * Badge will only loan officer borrowers link.
   */
  public totalBorrowersCount$;
  public totalBorrowersCount;

  public isShowMenu: boolean = false;
  public route;
  public freeLO:boolean = true;

  @Output() public sidenavToggle = new EventEmitter();

  constructor(
    private borrowersService: BorrowerService,
    private rolesService: RolesService,
    private router: ActivatedRoute,
    private userService: UsersService,
    private cdr: ChangeDetectorRef
  ) {
    this.route = router;
    this.windowWidth = window.innerWidth;
    this.isMobileView = this.windowWidth < 1150 ? true : false;
  }
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

 
 
}
