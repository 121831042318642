import { RectangleCustom } from './../rectangleCustomFields';
import { Point } from './../point';
import { combineLatest, Observable } from 'rxjs';

import { filter, tap, takeUntil, map, withLatestFrom, switchMap, startWith, concatMap } from 'rxjs/operators';

import { PdfEditor } from '../pdf-editor';
import { Rectangle } from '../rectangle';

import { PdfEditorRendererOptions } from './pdf-editor-renderer-options';
import { PdfRenderer } from './pdf-renderer';

/**
 * Pdf rectangle renderer.
 */
export class PdfCustomPreapproval extends PdfRenderer {
  public textArray :any=[];
  public undo$;
  public RectangleArray: any=[];
  constructor(editor: PdfEditor, options: PdfEditorRendererOptions, isHighlighter: Boolean = false, customParams$: Observable<any>) {
    super(editor, options);
    this.isHighlighter = isHighlighter;
    this.customParams$ = customParams$;
    customParams$.subscribe((value)=> {
      this.customParams = value;
      if(this.editor.items.length){
        this.editor.items[this.editor.items.length-1].customparams=this.customParams
        this.reRenderCurrentPage()
      }
    });
    this.listenMoseDown();
    this.listenFilesChange();
    this.listenMouseMove();
    
    this.listenCustomParamsChange();
  }

  ngOnInit(){
  }

  private isHighlighter: Boolean;

  private customParams$: Observable<any>;
  private customParams: any = {preApproval:{}};
  
  
  private listenCustomParamsChange(): void {
    this.customParams$
      .pipe(
        map((val) => this.customParams = val),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenMouseMove(): void {
    this.mouseMove$
      .pipe(
        filter(() => !this.disabled && this.isSelectingInProgress),
        tap(endPoint => this.endPoint = endPoint),
        tap(() => this.upperCanvasContext.clearRect(0, 0, this.upperCanvas.width, this.upperCanvas.height)),
        tap(() => this.previewUpperContext.clearRect(0, 0, this.previewUpperCanvas.width, this.previewUpperCanvas.height)),
        tap((res:Point ) => {
          this.startPoint=res;
          const rectText = new RectangleCustom(res.x,res.y,"#000000");
          // this.renderRectImg(this.upperCanvasContext, rectText, this.customParams);
          this.renderRectImg(this.previewUpperContext, rectText,this.customParams);
          if(this.editor.items.length){
            this.editor.items[this.editor.items.length-1].cordinates.X=this.endPoint.x;
            this.editor.items[this.editor.items.length-1].cordinates.Y=this.endPoint.y;
          }
          this.renderRectangles(this.upperCanvasContext);
        }),
        // tap(() => (this.isHighlighter ? this.drawSelection(this.upperCanvasContext, "#ffff00") : this.drawSelection(this.upperCanvasContext))),
        // tap(() => (this.isHighlighter ? this.drawSelection(this.previewUpperContext, "#ffff00") : this.drawSelection(this.previewUpperContext))),

        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenFilesChange(): void {
    let undo$ = this.undo$
      .pipe(
        filter(() => this.editor.items.length > 0),
        tap(()=> this.editor.items.pop()),
        tap(()=>{
          this.reRenderCurrentPage();
        })
      );
    combineLatest(
      undo$,
      this.mouseUp$.pipe(startWith(null)),
      this.editor.currentPage$,
      this.editor.indexesOfFilesToSave$,
    )
      .pipe(
        filter(() => !!this.currentPageInfo),
        concatMap(() => this.convertToFile()),
        tap(file => this.currentPageInfo = { ...this.currentPageInfo, file }),
        map(() => this.getFilesToSave()),
        tap(files => this.editor.filesChange$.next(files)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * @inheritdoc
   */
  public onMouseUp(): void {
    if (!this.disabled && this.isSelectingInProgress) {
      this.isSelectingInProgress = false;
     this.reRenderCurrentPage()
      this.mouseUp$.next();
    }
  }
  private reRenderCurrentPage(): void {  
    this.context.beginPath()
    this.previewContext.beginPath()
    let undo$=this.editor.currentPage$.pipe(
      withLatestFrom(this.editor.currentPage$),
      switchMap(([, page]) => combineLatest(
        this.renderPdfPage(page, this.context, this.canvas, this.upperCanvas),
        this.renderPdfPage(page, this.previewContext, this.previewCanvas, this.previewUpperCanvas),
      )),
      tap(()=>{
       this.renderRectangles(this.context);
      })
    );
    combineLatest(
      undo$,
      this.mouseUp$.pipe(startWith(null)),
      this.editor.currentPage$,
      this.editor.indexesOfFilesToSave$,
    )
      .pipe(
        filter(() => !!this.currentPageInfo),
        concatMap(() => this.convertToFile()),
        tap(file => this.currentPageInfo = { ...this.currentPageInfo, file }),
        map(() => this.getFilesToSave()),
        tap(files => this.editor.filesChange$.next(files)),
        takeUntil(this.destroy$),
      )
      .subscribe();
   
  }
  private listenMoseDown(){
    this.mouseDown$.subscribe(res=>{
     //  let canvasCordinates=this.canvas.getBoundingClientRect()
      console.log("items",this.editor.items)
      let curelement
      for (let i = 0; i < this.editor.items.length; i++) {
        let element = this.editor.items[i];
        if((element.cordinates.X-100 < res.x  && element.cordinates.X+50 > res.x  && element.cordinates.Y-10 < res.y  && element.cordinates.Y+10 > res.y )  ){
           let index=this.editor.items.indexOf(element);
           curelement=this.editor.items.splice(index,1);
           this.editor.items.push(element)
       }
      }
      console.log("items",this.editor.items)
    })
  }
  public renderRectangles(upperCanvasContext){
    
    for (let index = 0; index <  this.editor.items.length; index++) {
      const element =  this.editor.items[index];
      if(this.currentPageIndex==element.pageNo){
        const rectText = new RectangleCustom(element.cordinates.X,element.cordinates.Y);
      this.renderRectImg(upperCanvasContext, rectText, element.customparams);
      this.renderRectImg(this.previewContext, rectText, element.customparams);
      }
      
    }
  }
}
