import { Component, OnInit } from '@angular/core';

import { IconsService } from './services/icons.service';
import { CurrentUserService, GlobalProgressService } from './services/public_api';

/**
 * Root component.
 */
@Component({
  selector: 'arb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  /**
   * If `true` then progress bar will be displayed.
   */
  public readonly isLoading$ = this.globalProgressService.isLoading$;

  constructor(
    private iconsService: IconsService,
    private currentUserService: CurrentUserService,
    private globalProgressService: GlobalProgressService,
  ) {
    this.iconsService.registerMaterialIcons();
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.currentUserService.setUser().subscribe();
  }

}
