import { PersonDetailsLoanOfficer } from './../models/person-details-loanofficer';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, mapTo,tap } from 'rxjs/operators';

import { AppConfig } from '../../app.config';
import { Asset } from '../models/assets/asset';
import { AssetPart } from '../models/assets/asset-part';
import { AssetType } from '../models/assets/asset-type';
import { Borrower } from '../models/borrowers/borrower';
import { BorrowerNote } from '../models/borrowers/borrower-note';
import { BorrowerNoteType } from '../models/borrowers/borrower-note-type';
import { BorrowerPreApproval } from '../models/borrowers/borrower-pre-approval';
import { BorrowerSummary } from '../models/borrowers/borrower-summary';
import { BorrowersSearchParams } from '../models/borrowers/borrowers-search-params';
import { Contact } from '../models/contact';
import { Named } from '../models/named';
import { PaginationResult } from '../models/pagination-result';
import { Person } from '../models/person';
import { PersonDetails } from '../models/person-details';
import { PreApprovalStatus } from '../models/pre-approval-status';
import { Table } from '../models/table';
import { weakShareReplay } from '../rxjs-operators/weak-share-replay';

import { AssetDto } from './dto/asset-dto';
import { AssetPartDto } from './dto/asset-part-dto';
import { BorrowerDto } from './dto/borrower-dto';
import { BorrowerNoteDto } from './dto/borrower-note-dto';
import { BorrowerSummaryDto } from './dto/borrower-summary-dto';
import { ResponseTableDto } from './dto/response-table-dto';
import { UpdateBorrowerDto } from './dto/update-borrower-dto';
import { dirtyParentQueries } from '@angular/core/src/view/query';

/**
 * Defines API layer for working with api borrowers.
 */
@Injectable({
  providedIn: 'root',
})
export class BorrowerService {

  public example=new Subject<any>();
  
  
  /**
   * Total count borrowers (without filter) stream.
   */
  public readonly totalCount$ = this.createTotalCountStream();

  /**
   * @constructor.
   */
  constructor(
    private http: HttpClient,
    private config: AppConfig,
  ) { }

  private createTotalCountStream(): Observable<number> {
    const params = new HttpParams()
      .set('preApprovalStatus', String(PreApprovalStatus.Requested));
    const url = `${this.config.apiUrl}/api/borrowers`;
    return this.http
      .get<ResponseTableDto<BorrowerSummaryDto>>(url, { params })
      .pipe(
        map(response => response.metadata.totalCount),
        // weakShareReplay(1),
        
      );
  }

  /**
   * Search for borrowers.
   * @param searchParams Parameters for search request.
   */
  public search(searchParams: BorrowersSearchParams): Observable<Table<BorrowerSummary>> {
    let params = new HttpParams();
    const url = `${this.config.apiUrl}/api/borrowers`;
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<BorrowerSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(this.mapToBorrowerSummary),
        }) as Table<BorrowerSummary>),
      );
  }

  /**
   * Get borrower by id.
   */
  public getById(id: number): Observable<Borrower> {
    const url = `${this.config.apiUrl}/api/borrowers/${id}`;
    return this.http
      .get<BorrowerDto>(url)
      .pipe(
        map(res => this.mapToBorrower(res)),
      );
  }

  /**
   * Post new borrower.
   */
  public createBorrower(data: UpdateBorrowerDto): Observable<number> {
    const url = `${this.config.apiUrl}/api/borrowers`;
    return this.http
      .post<{ id: number }>(url, data).pipe(
        map(({ id }) => id),
      );
  }

  /**
   * Update borrower by id.
   */
  public updateBorrower(data: UpdateBorrowerDto, borrowerId: number): Observable<number> {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}`;
    return this.http
      .put<{ id: number }>(url, data).pipe(
        mapTo(borrowerId),
      );
  }

  /**
   * Create borrower note.
   * @param borrowerId about whom the letter.
   * @param message content of note.
   * @param noteType denied or default.
   */
  public createNote(
    borrowerId: number,
    message: string,
    noteType: BorrowerNoteType = BorrowerNoteType.Normal,
  ): Observable<number> {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/notes`;
    return this.http
      .post<any>(url, { message, noteType });
  }
  
  public createNoteOnBorrowerForm(
    borrowerId: number,
    message: string,
  ): Observable<number> {
    const url = `${this.config.apiUrl}/api/borrowers/${borrowerId}/notes`;
    return this.http
      .post<any>(url, message);
  }

  private mapToBorrowerSummary(dto: BorrowerSummaryDto): BorrowerSummary {
    return new BorrowerSummary({
      id: dto.id,
      friendlyId: dto.friendlyId,
      nickname: dto.borrowerName,
      firstName: dto.borrowerFirstName,
      lastName: dto.borrowerLastName,
      deletedRole: (dto.deletedRole || ""),
      coBorrower: new Named({
        firstName: dto.coBorrowerFirstName,
        lastName: dto.coBorrowerLastName,
      }),
      realtor: new Person({
        id: dto.realtorId,
        firstName: dto.realtorFirstName,
        lastName: dto.realtorLastName,
      }),
      loanOfficer: new Person({
        id: dto.loanOfficerId,
        firstName: dto.loanOfficerFirstName,
        lastName: dto.loanOfficerLastName,
      }),
      preApprovalStatus: dto.preApprovalStatus,
      offerStatus: dto.offerStatus,
      updatedAt: new Date(dto.updatedAt),
      updatedByUserName: dto.updatedByUserName,
    });
  }

  private mapToBorrower(dto: BorrowerDto): Borrower {
    const assets = dto.preApprovalAssets.map(asset => this.mapToPreApprovalAsset(asset));
    const sortedAssets = this.moveLetterAssetToStart(assets);
    return new Borrower({
      id: dto.id,
      friendlyId: dto.friendlyId,
      nickname: dto.borrowerName,
      firstName: dto.mainBorrowerInfo.firstName,
      deletedRole: (dto.deletedRole || ""),
      lastName: dto.mainBorrowerInfo.lastName,
      phoneNumber: dto.mainBorrowerInfo.phoneNumber,
      email: dto.mainBorrowerInfo.email,
      coBorrower: new Contact(dto.coBorrowerInfo),
      realtor: dto.realtorInfo ? new PersonDetails(dto.realtorInfo) : dto.realtorInfo,
      loanOfficer: dto.loanOfficerInfo ? new PersonDetailsLoanOfficer(dto.loanOfficerInfo) : dto.loanOfficerInfo,
      preApproval: new BorrowerPreApproval(dto.preApproval),
      notes: dto.notes.map(this.mapToBorrowerNote),
      preApprovalAssets: sortedAssets,
      copyOfCustomPreApproval:dto.copyOfCustomPreApproval,
      fieldCordinates:dto.fieldCordinates,
      isCustom:dto.isCustom,
      isLoanOfficerIndependent:dto.isLoanOfficerIndependent
    });
  }

  private mapToPreApprovalAsset(dto: AssetDto): Asset {
    return new Asset({
      id: dto.id,
      assetTypeId: dto.assetTypeId,
      assetTypeName: dto.assetTypeName,
      fileUrl: dto.combineFileUrl,
      fileName: dto.combineFileName,
      lastUpdated: dto.lastUpdated ? new Date(dto.lastUpdated) : null,
      updatedByUserName: dto.updatedByUserName,
      isMultipart: dto.isMultipart,
      files: this.mapToFiles(dto),
      sequence: dto.sequence,
      alternateName: dto.alternateName != null ? dto.alternateName : ""
    });
  }

  private mapToFiles(dto: AssetDto): AssetPart[] {
    /* The backend sends empty `files` array if only one file was uploaded. This causes excess "ifs". */
    return dto.isMultipart
      ? dto.assetFiles.map(({ fileName, fileUrl, id }) => new AssetPart({ name: fileName, url: fileUrl, id }))
      /* It's only one item, so doesn't matter what id is here. */
      : [new AssetPart({ name: dto.combineFileName, url: dto.combineFileUrl, id: 1 })];
  }

  private mapToBorrowerNote(dto: BorrowerNoteDto): BorrowerNote {
    return new BorrowerNote({
      id: dto.id,
      noteType: dto.noteType,
      message: dto.message,
      dateSubmitted: new Date(dto.dateSubmitted),
      userId: dto.userId,
      userFirstName: dto.userFirstName,
      userLastName: dto.userLastName,
    });
  }

  private moveLetterAssetToStart(assets: Asset[]): Asset[] {
    for (let i = 0; i < assets.length; i++) {
      if (assets[i].assetTypeId === AssetType.PreApprovalLetter) {
        assets.unshift(assets[i]);
        assets.splice(i + 1, 1);
        break;
      }
    }
    return assets;
  }

  /**
   * Gets asset files from specified borrower.
   * @param borrowerId Borrower's id.
   * @param assetTypeId Asset type id.
   */
  public getAssetFiles(borrowerId: number, assetTypeId: AssetType): Observable<AssetPart[]> {
    const endPointUrl = `${this.config.apiUrl}/api/borrowers/${borrowerId}/pre-approval/assets/${assetTypeId}`;
    return this.http.get<AssetPartDto[]>(endPointUrl)
      .pipe(
        map(dtos => dtos.map(({ fileName: name, fileUrl: url, id }) => new AssetPart({ name, url, id }))),
      );
  }

  
  /**
   * Delete Offer.
   * @param id Offer id.
   */
  public delete(id: number): Observable<void> {
    return this.http
    .delete<void>(`${this.config.apiUrl}/api/borrowers/${id}`, {});
  }

  public getListOfAllBorrowers(): Observable<any> {
    const url = `${this.config.apiUrl}/api/borrowers`;
    return this.http.get<any>(url);
  }

  public setBorrowerCustomFields(body):Observable<void>{
    const options = {
      headers: new HttpHeaders(),
      responseType: 'text' as 'json'
    };
    const url = `${this.config.apiUrl}/api/borrowers/addCustomFieldsValues`;
    return this.http.post<any>(url,body);
    
  }

  public getBorrowerCustomFields(id):Observable<any>{
    const url = `${this.config.apiUrl}/api/borrowers/getcustomefields/${id}`;
    return this.http.get<any>(url);
  }

  public updateBorrowerCustomFields(data):Observable<any>{
    const options = {
      headers: new HttpHeaders(),
      responseType: 'text' as 'json'
    };
    const url = `${this.config.apiUrl}/api/borrowers/updateCustomFieldsValues`;
    return this.http.put<any>(url,data,options);
  }

  public uploadRealtorPreApproval(data) {
    const id = parseInt(data.get("id"));
    const url = `${this.config.apiUrl}/api/borrowers/${id}/upload-realtor-pre-approval`;
    return this.http
      .put(url, data)
  }
}
