import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import {
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatTabsModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatListModule,
} from "@angular/material";
import {MatStepperModule} from '@angular/material/stepper';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const materialModules = [
  MatDialogModule,
  MatExpansionModule,
  MatButtonModule,
  MatToolbarModule, 
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatTabsModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatRadioModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatListModule,
  MatSlideToggleModule,
  DragDropModule,
  MatStepperModule
];

/**
 * Material module. Contains modules for material components.
 */
@NgModule({
  imports: [...materialModules],
  exports: [...materialModules],
})
export class MaterialModule {}
