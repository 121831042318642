import { Full } from '../../helpers/full';

/**
 * Toolbox item.
 */
export class ToolboxItem {

  /**
   * Id.
   */
  public id: number;

  /**
   * Text.
   */
  public text: string;

  /**
   * Category Id.
   */
  public categoryId: number;
/**
   * Squence.
   */
 public toolboxSequence:number;

  constructor(data: Full<ToolboxItem>) {
    this.id = data.id;
    this.text = data.text;
    this.categoryId = data.categoryId;
   this.toolboxSequence=data.toolboxSequence;
  }
}
