import { ActivatedRoute } from "@angular/router";
import {
  Component,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  BorrowerService,
  LoanOfficerService,
  NavigationLink,
  RealtorsService,
  RolesService,
  UserRole,
  UsersService,
} from "../../../../core";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

/**
 * List of root navigation links.
 */
const BORROWERS_LINK = "/borrowers";

/**
 * List of root navigation links.
 */
const NAVIGATION_LINKS: NavigationLink[] = [
  {
    text: "Dashboard",
    url: "/dashboard",
    userRole: [UserRole.Admin, UserRole.Realtor, UserRole.LoanOfficer],
  },
  {
    text: "Borrowers",
    url: BORROWERS_LINK,
    userRole: [UserRole.LoanOfficer, UserRole.Admin, UserRole.Realtor],
  },
  {
    text: "Realtors",
    url: "/realtors",
    userRole: [UserRole.Admin, UserRole.LoanOfficer],
  },
  { text: "Loan Officers", url: "/loan-officers", userRole: [UserRole.Admin, UserRole.Realtor] },
  {
    text: "Offers",
    url: "/offers",
    userRole: [UserRole.Admin, UserRole.LoanOfficer, UserRole.Realtor],
  },
  // {
  //   text: "Support",
  //   url: "/support",
  //   userRole: [UserRole.Admin, UserRole.LoanOfficer, UserRole.Realtor],
  // },
  { text: "Toolbox", url: "/toolbox", userRole: [UserRole.Realtor] },
  { text: "Toolbox", url: "/admin-toolbox", userRole: [UserRole.Admin] },
];

/**
 * Header component without onPush because of the bug with long redraw tabs.
 */
@Component({
  selector: "arb-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public isMobileView: boolean = true;
  public windowWidth;
  public logoUrl = "/assets/logo.png?date=" + new Date();
  public source:string = "";
  public proStatus:any = {};
  public errorMessage:string;
  @ViewChild("mobileMenu") mobileMenu: ElementRef;
  /**
   * All user roles.
   */
  public readonly userRoles = UserRole;

  /**
   * List of root navigation links.
   */
  public readonly rootLinks = [...NAVIGATION_LINKS];

  /**
   * Total borrowers count for loan officer borrowers link.
   * Badge will only loan officer borrowers link.
   */
  public totalBorrowersCount$;
  public totalBorrowersCount;

  public isShowMenu: boolean = false;
  public route;
  public userType:boolean = true;
  public currRole;

  @Output() public sidenavToggle = new EventEmitter();

  constructor(
    private borrowersService: BorrowerService,
    private rolesService: RolesService,
    private router: ActivatedRoute,
    private userService: UsersService,
    private cdr: ChangeDetectorRef,
    private loanOfficer$: LoanOfficerService,
    private realtor$: RealtorsService
  ) {
    this.route = router;
    this.windowWidth = window.innerWidth;
    this.isMobileView = this.windowWidth < 1150 ? true : false;
  }

  ngOnInit() {
    this.source = new URLSearchParams(window.location.search).get('src')||"";
    // console.log("this.source <><><>",this.source);
    this.totalBorrowersCount$ = this.borrowersService.totalCount$;
    // this.borrowersService.totalCount$.subscribe(res => console.log("Total count ", res))
    this.userService.getUserType().subscribe(res => {
      //to check type of user 1)null= System User 2)Free User 3)Paid User
      this.userType = res;
    })
    this.role();
  }
  ngDoCheck()
  {
    this.totalBorrowersCount=this.borrowersService.example;
    // this.borrowersService.example.subscribe(res=>console.log("total request count",res))
  }

/* fetching current user if LO or RL then will fire perticular api 
    based on user role and will get profile status value depending on that 
    we have given a condition what to show or not
 */
  public role(){
     this.currRole = this.rolesService.getRole();
    // console.log(`CURRENT ROLE HEADER =>${this.currRole}`);
    if(this.currRole == 'LoanOfficer'){
      this.loanOfficer$.getProfile().subscribe(profile => {
        this.proStatus = profile;
        // console.log("Profile =>", this.proStatus);
      })
    }
    if(this.currRole == 'Realtor'){
      this.realtor$.getProfile().subscribe(profile => {
          this.proStatus = profile;
        // console.log("Profile =>", this.proStatus);
      })
    }
  }

 
// throw error when cuurent (user) Profile status == 2
public throwError(event){
  if(this.proStatus.status == 5){
    event.preventDefault();
    this.errorMessage = "*Please Upgrade Your Plan";
    // console.log(this.errorMessage);
  }
} 
  @HostListener("window: change", ['$event'])  
  ngOnDestroy(): void {
    this.errorMessage = "";
  }


  /**
   * A link with the badge.
   */
  public isBadgeLink(link: NavigationLink): Observable<boolean> {
    return this.rolesService.isLoanOfficer$.pipe(
      map((isLo) => isLo && link.url === BORROWERS_LINK)
    );
  }
  @HostListener("document:click", ["$event"]) toggleMenu(e: MouseEvent) {
    if (this.mobileMenu) {
      this.isShowMenu = this.mobileMenu.nativeElement.contains(e.target)
        ? (this.isShowMenu = !this.isShowMenu)
        : false;
    }
  }
  @HostListener("window:resize", ["$event"]) toggleMobileView(windowEvent) {
    this.isMobileView = windowEvent.target.innerWidth < 1150 ? true : false;
  }
}
