import { Observable, Subject } from 'rxjs';

import { EditorOptions } from './editor-options';
import { EditorRenderer } from './renderer/editor-renderer';
import { RendererType } from './renderer/renderer-type';

/**
 * Editor.
 */
export abstract class Editor {

  /**
   * Renderer object that contains logic to crop or rendering rectangles.
   */
  protected abstract renderer: EditorRenderer;

  /**
   * Main canvas reference.
   */
  protected readonly canvas: HTMLCanvasElement;

  /**
   * Context of the main canvas.
   */
  protected readonly context: CanvasRenderingContext2D;

  /**
   * Max width of the canvas.
   */
  protected readonly canvasMaxWidth: number;

  /**
   * Destroy stream.
   */
  protected readonly destroy$: Subject<void>;

  /**
   * Emits changed files.
   */
  public readonly abstract filesChange$: Observable<File[]>;

  /**
   * Uploaded image or pdf file.
   */
  public file: File;

  constructor(options: EditorOptions) {
    this.canvas = options.canvas;
    this.context = options.context;
    this.file = options.file;
    this.canvasMaxWidth = options.canvasMaxWidth;
    this.destroy$ = new Subject();
  }

  /**
   * On mouse down.
   * @param x X coordinate.
   * @param y Y coordinate.
   */
  public onMouseDown(x: number, y: number): void {
    this.renderer.onMouseDown(x, y);
  }

  /**
   * On mouse move.
   * @param x X coordinate.
   * @param y Y coordinate.
   */
  public onMouseMove(x: number, y: number): void {
    this.renderer.onMouseMove(x, y);
  }

  /**
   * On mouse up.
   * @param x X coordinate.
   * @param y Y coordinate.
   */
  public onMouseUp(x: number, y: number): void {
    this.renderer.onMouseUp(x, y);
  }

  /**
   * To unsubscribe from all observables.
   */
  public destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.renderer.destroy();
  }

  /**
   * To switch the current renderer.
   * @param rendererType Renderer type.
   */
  public abstract setRenderer(rendererType: RendererType): void;

  /**
   * Triggers the current renderer to make undo.
   */
  public undo(): void {
    this.renderer.undo();
  }

}
