/**
 * Info that required for changing the password.
 */
export class ResetPasswordDto {

  /**
   * Email.
   */
  public email: string;

  /**
   * Token.
   */
  public token: string;

  /**
   * New password.
   */
  public newPassword: string;

  /**
   * .ctor
   * @param email User's email.
   * @param token Authentication token.
   * @param newPassword User's new password.
   */
  constructor(email: string, token: string, newPassword: string) {
    this.email = email.trim();
    this.token = token;
    this.newPassword = newPassword;
  }
}
