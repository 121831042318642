import { Full } from '../helpers/full';

import { Permission } from './permission';
import { User } from './user';
import { UserRole } from './user-role';

/**
 * Model for current user.
 */
export class CurrentUser extends User {

  /**
   * User role.
   */
  public role: UserRole;

  /**
   * Permissions.
   */
  public permissions: Permission[];

  constructor(data: Full<CurrentUser>) {
    super(data);
    this.role = data.role;
    this.permissions = data.permissions;
  }

}
