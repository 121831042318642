import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

import { OfferStatus } from '../../../../core';

/**
 * Update offer status dialog component.
 */
@Component({
  selector: 'arb-update-offer-status-dialog',
  templateUrl: './update-offer-status-dialog.component.html',
  styleUrls: ['./update-offer-status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateOfferStatusDialogComponent {

  private readonly statuses = OfferStatus;

  /**
   * Form control for offer statuses.
   */
  public readonly statusControl = new FormControl(this.accepted);

  constructor(
    private matDialogRef: MatDialogRef<UpdateOfferStatusDialogComponent>,
  ) { }

  /**
   * Submitted status.
   */
  public get submitted(): OfferStatus {
    return this.statuses.Submitted;
  }

  /**
   * Accepted status.
   */
  public get accepted(): OfferStatus {
    return this.statuses.Accepted;
  }

  /**
   * Rejected status.
   */
  public get rejected(): OfferStatus {
    return this.statuses.Rejected;
  }

  /**
   * Closes the dialog with selected status.
   */
  public onSave(): void {
    this.matDialogRef.close(this.statusControl.value);
  }

}
