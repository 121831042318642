import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Sort } from "@angular/material";
import { Observable, combineLatest, BehaviorSubject } from "rxjs";
import { map, tap, takeUntil } from "rxjs/operators";
import { ConfirmDialogData } from "../../../models/confirm-dialog-data";
import { MatButtonColor } from "../../../models/mat-button-color";
import { DialogService } from "../../../services/dialog.service";
import { ErrorMessage, OfferService } from "../../../../core";

import {
  OfferStatus,
  PaginationMetadata,
  SortDirection,
  SortConfig,
  OffersSearchParams,
  OfferSummary,
  PageEvent,
  DestroyableBase,
  Table,
  Offer,
  partitionTable,
  listenControlChanges,
  RolesService,
  Message,
  SuccessMessage,
} from "../../../../core";
import { OffersTableColumn } from "../../../models/offers-table-column";
import { OffersTableSecondColumn } from "../../../models/offers-table-second-column";
import { Subject } from "rxjs/internal/Subject";

interface SearchParams {
  offerId: number;
  borrowerName: string;
  address: string;
  status: OfferStatus;
  orderBy: string;
}

/**
 * Titles of the offer table.
 */
const COLUMNS: OffersTableColumn[] = [
  "id",
  "dateSubmitted",
  "borrower",
  "address",
  "price",
  "action",
  "status",
];

/**
 * Titles of the offer table For Non Admin user.
 */
const COLUMNS_FOR_NON_ADMIN: OffersTableColumn[] = [
  "dateSubmitted",
  "borrower",
  "address",
  "price",
  "action",
  "status",
];

/**
 * Filters of the offer table.
 */
const SECOND_HEADER_COLUMNS: OffersTableSecondColumn[] = [
  "search_id",
  "empty",
  "search_borrower_name",
  "search_address",
  "empty",
  "empty",
  "select_status",
];

/**
 * Filters of the offer table for Non Admin.
 */
const SECOND_HEADER_COLUMNS_FOR_NON_ADMIN: OffersTableSecondColumn[] = [
  "empty",
  "search_borrower_name",
  "search_address",
  "empty",
  "empty",
  "select_status",
];

/**
 * Offers table.
 */
@Component({
  selector: "arb-offers-table",
  templateUrl: "./offers-table.component.html",
  styleUrls: ["./offers-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OffersTableComponent extends DestroyableBase implements OnInit, OnChanges {
  private sortConfigValue = new SortConfig<OffersTableColumn>(
    'updatedat',
    SortDirection.Desc
  );
  private readonly page$ = new BehaviorSubject(1);
  private readonly sort$ = new BehaviorSubject(
    SortConfig.fromConfig(this.sortConfigValue)
  );

  public readonly message$ = new Subject<Message | null>();

  /**
   * Default offer status.
   * Mat-select doesn't set the default value when it is `null`.
   * `0` or empty string is fine.
   */
  public allStatuses = 0;

  /**
   * Form control for offer id input.
   */
  public offerIdControl = new FormControl();

  /**
   * Form control for borrower name input.
   */
  public borrowerNameControl = new FormControl();

  /**
   * Form control for address.
   */
  public addressControl = new FormControl();

  /**
   * Form control for offer status.
   */
  public offerStatusControl = new FormControl(this.allStatuses);

  /**
   * All offer statuses.
   */
  public statuses = OfferStatus;

  /**
   * Selected offer status.
   */
  public selectedStatus = OfferStatus.Draft;

  /**
   * Offers stream.
   */
  public offers$: Observable<OfferSummary[]>;

  /**
   * Pagination metadata stream.
   */
  public paginationMetadata$: Observable<PaginationMetadata>;

  /**
   * Sort config.
   */
  @Input()
  public set sortConfig(value: SortConfig<OffersTableColumn>) {
    this.sortConfigValue = value;
    this.sort$.next(SortConfig.fromConfig(value));
  }

  /**
   * Returns sort config instance.
   */
  public get sortConfig(): SortConfig<OffersTableColumn> {
    return this.sortConfigValue;
  }

  /**
   * Offers table stream.
   */
  @Input()
  public table$: Observable<Table<OfferSummary>>;

  /**
   * If `true` then pagination will be displayed.
   */
  @Input()
  public pagination: boolean;

  /**
   * Displayed columns.
   */
  @Input()
  public displayedColumns = COLUMNS;

  /**
   * Second header columns.
   */
  @Input()
  public secondHeaderColumns = SECOND_HEADER_COLUMNS;

  /**
   * Sets default offer status.
   */
  @Input()
  public set defaultStatus(value: OfferStatus) {
    if (value) {
      this.offerStatusControl.setValue(value);
    }
  }

  /**
   * Search change.
   */
  @Output()
  public searchChange = new EventEmitter<OffersSearchParams>();

  /**
   * @inheritdoc
   */

  public readonly isAdmin$ = this.rolesService.isAdmin$;
  public showNoOffersMessage: boolean = false;

  @Input()
  public currentPage: any;

  constructor(
    private rolesService: RolesService,
    private dialogService: DialogService,
    public offersService: OfferService
  ) {
    super();
  }

  public showPopup = false;
  public popup_pos_x = "0px";
  public popup_pos_y = "0px";
  public offerMailSendMsg = "";
  public offerMailSendTime = "";
  public offerMailViewedMsg = "";
  public offerMailViewedTime = "";

  public ngOnInit(): void {
    if (typeof this.table$ === "undefined") {
      throw new Error("Offers table stream is undefined");
    }

    this.rolesService.isAdmin$.subscribe((isAdmin) => {
      if (!isAdmin) {
        this.displayedColumns = COLUMNS_FOR_NON_ADMIN;
        this.secondHeaderColumns = SECOND_HEADER_COLUMNS_FOR_NON_ADMIN;
      }
    });

    [this.offers$, this.paginationMetadata$] = partitionTable(this.table$);
    this.listenSearchParamsChange();
  }

  public ngOnChanges(): void {
    const data = this.table$
    this.table$.subscribe(res => {
      if (res.data && (res.data.length > 0)) {
        this.showNoOffersMessage = false;
      } else {
        this.showNoOffersMessage = true;
      }
    }); 
  }

  private listenSearchParamsChange(): void {
    const search$ = this.createSearchStream();
    combineLatest(search$, this.page$)
      .pipe(
        map(([{ offerId, borrowerName, address, status, orderBy }, page]) => ({
          offerId,
          borrowerName,
          address,
          status,
          orderBy,
          page,
        })),
        tap((params) => this.searchChange.emit(params)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private createSearchStream(): Observable<SearchParams> {
    return combineLatest(
      listenControlChanges<number>(this.offerIdControl),
      listenControlChanges<string>(this.borrowerNameControl),
      listenControlChanges<string>(this.addressControl),
      listenControlChanges<OfferStatus>(this.offerStatusControl, 0),
      this.sort$
    ).pipe(
      tap(() => this.page$.next(1)),
      map(([offerId, borrowerName, address, status, orderBy]) => ({
        offerId,
        borrowerName,
        address,
        status,
        orderBy,
      }))
    );
  }

  /**
   * On sort change.
   * @param event Sort event.
   */
  public onSortChange(event: Sort): void {
    this.sort$.next(SortConfig.fromEvent(event));
  }

  /**
   * On page change.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.page$.next(event.pageIndex);
  }

  /**
   * Returns full address.
   * @param offer Offer.
   */
  public getFullAddress(offer: OfferSummary): string {
    return `${offer.address}, ${offer.city}, ${offer.state} ${offer.zip}`;
  }

  /**
   * Delete offer button.
   * @param offer Offer.
   */
  public async handleDeleteOffer(itemId: number) {
    // deleteOfferButton
    const data = this.createDeleteOfferDialogData();
    const deleted = await this.dialogService
      .openConfirmDialog(data)
      .toPromise();
    if (deleted) {
      this.offersService.delete(itemId).subscribe((item) => {  
        this.page$.next(0);
        this.message$.next(new SuccessMessage('Offer deleted successfully'));
        this.clearMessage();
        return item;
      },(err) => {
        this.message$.next(new ErrorMessage('Oops, something went wrong'))
        this.clearMessage();
      });
    }
  }

  clearMessage() {
    setTimeout(() => {
      this.message$.next(null);
    }, 3000);
  }

  public formatDate(datestring: string): string {
    return (
      new Date(datestring).toLocaleDateString() +
      " " +
      new Date(datestring).toLocaleTimeString()
    );
  }

  public timeSince(date): string {
    var now = new Date();
    var diff = now.getTime() - date.getTime();
    var seconds = Math.floor(diff / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  /**
   * Analyze offer button.
   * @param offer Offer.
   */
  public analyzePdfOffer(offer: Offer, e: MouseEvent) {
    console.log("analyzePdfOffer e", e);
    console.log("offer", offer);

    if (e.type == "mouseover") {
      var mailSent = false;
      var mailViewed = false;
      var mailSentAt;
      var mailViewedAt;

      var analytics = offer.analytics;
      if (analytics) {
        mailSent = offer.analytics.mailSent == 1;
        mailViewed = offer.analytics.mailViewed == 1;
      }

      if (mailSent) {
        mailSentAt = this.formatDate(offer.analytics.mailSentAt);
      }
      if (mailViewed) {
        mailViewedAt = this.formatDate(offer.analytics.mailViewedAt);
      }

      this.offerMailSendMsg = mailSent
        ? "Email was sent " +
          this.timeSince(new Date(offer.analytics.mailSentAt)) +
          " ago"
        : "Email not sent";
      this.offerMailSendTime = mailSent ? mailSentAt : "";

      this.offerMailViewedMsg = mailViewed
        ? "Email was Viewed " +
          this.timeSince(new Date(offer.analytics.mailViewedAt)) +
          " ago"
        : "";
      this.offerMailViewedTime = mailViewed ? mailViewedAt : "";

      this.showPopup = true;
      // this.popup_pos_x = (e.clientX - 110) + "px";
      // this.popup_pos_y = (e.clientY - 30) + "px";
      this.popup_pos_x = e.pageX - 270 + "px";
      this.popup_pos_y = e.pageY - 130 + "px";
      var that = this;
      setTimeout(() => {
        that.showPopup = false;
      }, 500);

      // this.offerMailSendMsg = offer.
    } else {
      // do nothing
      this.showPopup = false;
    }

    // this.offersService.delete(itemId).subscribe(item => {
    //   this.page$.next(0);

    // });

    // this.dialogService.openOfferAnalyticsDialog(offer);
  }

  private fetchMailIcon(offer: Offer): string {
    var mailIcon = "mail";
    var analytics = offer.analytics;
    var mailSent;
    var mailViewed;
    if (analytics) {
      mailSent = offer.analytics.mailSent == 1;
      mailViewed = offer.analytics.mailViewed == 1;
    }
    if (mailSent) {
      mailIcon = "mail_sent";
    }

    if (mailViewed) {
      mailIcon = "mail_open";
    }
    return mailIcon;
  }

  private createDeleteOfferDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: "Delete Offer?",
      subtitle: "Are you sure you want to delete this offer?",
      acceptButtonColor: MatButtonColor.Warn,
      acceptButtonText: "Delete",
      rejectButtonText: "Cancel",
    });
  }
}
