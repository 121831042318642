import { Sort } from '@angular/material';

import { SortDirection } from './sort-direction';

/**
 * Sort config.
 */
export class SortConfig<T> {

  /**
   * Column.
   */
  public column: T;

  /**
   * Direction.
   */
  public direction: SortDirection;

  constructor(column: T, direction: SortDirection) {
    this.column = column;
    this.direction = direction;
  }

  /**
   * Creates convenient format from sort config's instance.
   * @param config Sort config.
   */
  public static fromConfig<T>(config: SortConfig<T>): string {
    return SortConfig.config<T>(config.column, config.direction);
  }

  /**
   * Creates convenient format from sort event.
   * @param event Sort event.
   */
  public static fromEvent(event: Sort): string {
    const column = event.active;
    const direction = event.direction === SortDirection.Desc
      ? SortDirection.Desc
      : SortDirection.Asc;
    return SortConfig.config(column, direction);
  }

  /**
   * Returns convenient format for searching by query params.
   * @param column Table column.
   * @param sortDirection Sort direction.
   */
  public static config<T>(column: T, sortDirection: SortDirection): string {
    const direction = sortDirection
      ? `:${sortDirection}`
      : '';
    return `${column}${direction}`;
  }

}
