import { Full } from '../../helpers/full';

import { BorrowerNoteType } from './borrower-note-type';

/**
 * Borrower note.
 */
export class BorrowerNote {

  /**
   * Id.
   */
  public id: number;

  /**
   * Note type.
   */
  public noteType: BorrowerNoteType;

  /**
   * Message.
   */
  public message: string;

  /**
   * Date submitted.
   */
  public dateSubmitted: Date;

  /**
   * Borrower's id.
   */
  public userId: number;

  /**
   * Borrower's first name.
   */
  public userFirstName: string;

  /**
   * Borrower's last name.
   */
  public userLastName: string;

  constructor(data: Full<BorrowerNote>) {
    this.id = data.id;
    this.noteType = data.noteType;
    this.message = data.message;
    this.dateSubmitted = data.dateSubmitted;
    this.userId = data.userId;
    this.userFirstName = data.userFirstName;
    this.userLastName = data.userLastName;
  }

}
