import { ChangeDetectorRef } from '@angular/core';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { PDFPageProxy } from 'pdfjs-dist/webpack';
import { fromEvent, Observable, of } from 'rxjs';
import {
  tap,
  takeUntil,
  distinctUntilChanged,
  skip,
  switchMap,
  take,
  mapTo,
} from 'rxjs/operators';

import { DestroyableBase } from '../../../../core';
import { ConfirmDialogData } from '../../../models/confirm-dialog-data';
import { PdfEditor } from '../../../models/document-editor/pdf-editor';
import { RendererType } from '../../../models/document-editor/renderer/renderer-type';
import { MatButtonColor } from '../../../models/mat-button-color';
import { DialogService } from '../../../services/dialog.service';

/**
 * Pdf editor component.
 */
@Component({
  selector: 'arb-pdf-editor',
  templateUrl: './pdf-editor.component.html',
  styleUrls: ['./pdf-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PdfEditorComponent extends DestroyableBase implements OnInit, AfterViewInit, OnChanges {
  /**
   * Padding of container.
   * In pixels.
   */

  isAllSelected = false;


  public readonly containerPadding = 20;

  public typeFlag: any;

  @Output() typeFlagEvent = new EventEmitter<any>();
  
  @Output()
  public changesCordinateList=new EventEmitter<any>();


  public color: string;
  /**
   * Max width of canvas.
   */
  public readonly canvasMaxWidth = Math.round(window.innerWidth * 0.6);

  /**
   * Reference to pdf editor.
   */
  public editor: PdfEditor;

  /**
   * Array of pages that can be used to perform first page render.
   */
  public pages$: Observable<PDFPageProxy[]>;

  /**
   * Pdf file.
   */
  @Input()
  public file: File;

  /**
   * Pdf file.
   */
  @Input()
  public customParams$: Observable<any>;

  public items: any = []

  /**
   * Undo stream.
   */
  @Input()
  public undo$: Observable<void>;

  /**
   * Renderer type stream.
   */
  @Input()
  public rendererType$: Observable<RendererType>;


  /**
   * Crop stream.
   */
  @Input()
  public crop$: Observable<void>;

  /**
   * Event emitter that emits files to save.
   */
  @Output()
  public filesChange = new EventEmitter<File[]>();

  /**
   * Page changed.
   */
  @Output()
  public pageChanged = new EventEmitter<void>();

  /**
   * Reference to main canvas element.
   */
  @ViewChild('canvas')
  public canvasRef: ElementRef<HTMLCanvasElement>;

  /**
   * Reference to upper canvas that renders only rectangles.
   */
  @ViewChild('upperCanvas')
  public upperCanvasRef: ElementRef<HTMLCanvasElement>;

  /**
   * Reference to container element.
   */
  @ViewChild('container')
  public containerRef: ElementRef<HTMLElement>;


    /**
   * sideScroll.
   */
     @Input()
     public sideScroll: boolean=true;

  constructor(private dialogService: DialogService,private cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.setRectangle();
    this.isAllSelected = this.sideScroll == false? true : false;
    this.cdr.detectChanges()
  }

  public ngOnChanges() {
    // this.setRectangle();
    
  }
 
  public allowDrop(ev) {
    ev.preventDefault();
  }
  public drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
      let DataEelem=document.getElementById(data) as HTMLElement;
      let key=DataEelem.innerText
      let value
      let text
      if(key=="Purchase Price"){
        value={"purchasePrice":"<%Purchaseprice>"}
        text="<%Purchaseprice>";
      }
      else if(key=="Address"){
        value={"address":"<%Address>"}
        text="<%Address>"
      }
      else if(key=="Downpayment"){
        value={"downPayment":"<%Downpayment>"}
        text="<%Downpayment>"
      }
      else if(key=="Property Type"){
        value={propertyType: "<%Propertytype>"}
        text="<%Propertytype>"
      }
      else if(key=="Occupancy"){
        value={occupancy:"<%Occupancy>"}
        text="<%Occupancy>"
      }
      else if(key=="Fico"){
        value={fico:"<%Fico>"}
        text="<%Fico>"
      }
      else if(key=="Program Type"){
        value={program:"<%Programtype>"}
        text="<%Programtype>"
      }
  
    const canvas = this.canvasRef.nativeElement;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    this.customParams$.pipe(take(1)).subscribe(res=>{
      res.preApproval=value;
      var fontSize:number = res.fontSize ? Number(res.fontSize) : 16;
      var fontFamily =  res.fontStyle ?  res.fontStyle : "Verdana";
      var fontColor=res.textColor ? res.textColor:"#00000"
      const rect = canvas.getBoundingClientRect()
      let x = ev.layerX;
      let y = ev.layerY;
      context.fillStyle = fontColor;
      context.font = `${fontSize}px ${fontFamily}`;
      context.fillText(text,x, y);
      this.items.push({customparams :{...res},cordinates:{X:ev.offsetX,Y: ev.offsetY},pageNo:this.editor.currentPageIndex$.value})
      if(this.items.length){
        console.log("items",this.editor.items)
        this.typeFlagEvent.emit(false)
        this.changesCordinateList.emit(this.items)
      }
    })
  }
  
  setRectangle() {
    const canvas = this.canvasRef.nativeElement;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const upperCanvas = this.upperCanvasRef.nativeElement;
    const upperCanvasContext = upperCanvas.getContext('2d') as CanvasRenderingContext2D;
    this.editor = new PdfEditor({
      canvas,
      context,
      upperCanvas,
      upperCanvasContext,
      file: this.file,
      canvasMaxWidth: this.canvasMaxWidth,
      customParams$: this.customParams$,
      items: this.items
    });
    this.pages$ = this.editor.pages$;
    this.editor.currentPageIndex$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        tap(() => this.pageChanged.emit()),
        takeUntil(this.destroy$),
        tap(res=>{
          // this.editor.items.length=0;
        })
      )
      .subscribe();
    this.listenFilesChange();
    this.listenUndo();
    this.listenRendererTypeChange();
    this.listenCropChange();


    this.pages$.subscribe(item => {
      console.log("item", item);
      item.forEach((pitem: any) => pitem.isChecked = false);
    })
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.captureEvents(this.upperCanvasRef.nativeElement);
  }

  private listenFilesChange(): void {
    this.editor
      .filesChange$
      .pipe(
        tap(files => this.filesChange.emit(files)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private createConfirmDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title:
        'Do you want to apply cropper?',
      subtitle: '',
      acceptButtonColor: MatButtonColor.Primary,
      acceptButtonText: 'Yes',
      rejectButtonText: 'No',
    });
  }

  /**
   * Run events outside the angular zone.
   */
  private captureEvents(canvasEl: HTMLCanvasElement): void {
    fromEvent<PointerEvent>(canvasEl, 'pointerdown').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onMouseDown(event));
    fromEvent<PointerEvent>(canvasEl, 'pointermove').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onMouseMove(event));
    fromEvent<PointerEvent>(canvasEl, 'pointerup').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onMouseUp(event));
    fromEvent<PointerEvent>(this.containerRef.nativeElement, 'pointermove').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onContainerMouseMove(event));
  }

  private listenUndo(): void {
    this.undo$
      .pipe(
        tap(() => this.editor.undo()),
        tap(() => this.editor.items.length==0 &&  this.typeFlagEvent.emit(true)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenRendererTypeChange(): void {
    this.rendererType$
      .pipe(
        switchMap(type => {
          this.typeFlag = type;
          if (!this.editor.canDeactivate) {
            return this.dialogService.openConfirmDialog(this.createConfirmDialogData()).pipe(
              switchMap(answer => {
                this.editor.canDeactivate = true;
                if (answer) {
                  return this.editor.crop().pipe(
                    mapTo(type),
                  );
                } else {
                  return of(type);
                }
              }),
              take(1),
              mapTo(type),
            );
          }
          return of(type);
        }),
        tap(type => this.editor.setRenderer(type)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }


  private listenCropChange(): void {
    this.crop$
      .pipe(
        tap(() => this.editor.crop()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * Emits mousemove event.
   * @param event Mouse event.
   */
  public onContainerMouseMove(event: MouseEvent): void {
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseMove(x - this.containerPadding, y - this.containerPadding);
  }

  /**
   * Emits mousemove event.
   * @param event Mouse event.
   */
  public onMouseMove(event: MouseEvent): void {
    event.stopPropagation();
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseMove(x, y);
  }

  /**
   * Emits mousedown event.
   * @param event Mouse event.
   */
  public onMouseDown(event: MouseEvent): void {
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseDown(x, y);
  }

  /**
   * Emits mouseup event.
   * @param event Mouse event.
   */
  public onMouseUp(event: MouseEvent): void {
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseUp(x, y);
    if (this.typeFlag == RendererType.PreApprovalContentRenderer) {
      this.typeFlagEvent.emit(false);
    }
  }

}
