/**
 * Point.
 */
export class Point {

  /**
   * X coordinate.
   */
  public x: number;

  /**
   * Y coordinate.
   */
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

}
