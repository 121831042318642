import { Full } from '../helpers/full';

import { PersonDetails } from './person-details';
import { User } from './user';
import { UserStatus } from './user-status';

/**
 * Detailed information about realtor.
 */
export class Realtor extends User {

  /**
   * Info about realted loan officer.
   */
  public loanOfficer: PersonDetails | null;

  /**
   * Info about user who invited a realtor.
   */
  public invitedBy: PersonDetails | null;

  /**
   * Realtor logo url.
   */
  public logoUrl: string;

  /**
   * Company logo url.
   */
  public companyLogoUrl: string;

  /**
   * Signature url.
   */
  public signatureUrl: string;

  /**
   * Company name.
   */
  public companyName: string;

  /**
   * Office address.
   */
  public officeAddress: string;

  /**
   * Website.
   */
  public website: string;

  /**
   * Footer color.
   */
  public footerColor: string;

  /**
   * Header color.
   */
  public headerColor: string;

   /**
   * Icons color.
   */
    public iconsColor: string;

  /**
   * Status.
   */
  public status: UserStatus;

  /**
   * Readable id.
   */
  public friendlyId: string;


  public emailSignature: string;
  
  public timeZone: string;

  public licenseNumber?: string;

  constructor(data: Full<Realtor>) {
    super(data);
    this.loanOfficer = data.loanOfficer;
    this.invitedBy = data.invitedBy;
    this.companyLogoUrl = data.companyLogoUrl;
    this.companyName = data.companyName;
    this.footerColor = data.footerColor;
    this.iconsColor = data.iconsColor;
    this.headerColor = data.headerColor;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.status = data.status;
    this.updatedAt = data.updatedAt;
    this.friendlyId = data.friendlyId;
    this.signatureUrl = data.signatureUrl;
    this.officeAddress = data.officeAddress;
    this.website = data.website;
    this.logoUrl = data.logoUrl;
    this.emailSignature = data.emailSignature;
    this.timeZone = data.timeZone;
    this.licenseNumber = data.licenseNumber;
  }

}
