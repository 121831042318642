import { Message } from './message';
import { MessageStatus } from './message-status';

/**
 * Success message.
 */
export class SuccessMessage extends Message {

  constructor(message: string) {
    super({
      text: message,
      status: MessageStatus.Success,
    });
  }

}
