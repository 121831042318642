import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { PreApprovalStatus, toReadableBorrowerStatus } from '../../../../core';
import { StatusColor } from '../../../models/status-color';

/**
 * Borrower status.
 */
@Component({
  selector: 'arb-borrower-status',
  templateUrl: './borrower-status.component.html',
  styleUrls: ['./borrower-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorrowerStatusComponent {

  private colors = this.createColors();

  /**
   * Status text value.
   */
  public statusValue: string;

  /**
   * Status color.
   */
  public color: StatusColor;

  /**
   * Status.
   */
  @Input()
  public set status(value: PreApprovalStatus) {
    this.statusValue = toReadableBorrowerStatus(value);
    this.color = this.colors[value];
  }

  private createColors(): Record<PreApprovalStatus, StatusColor> {
    return {
      [PreApprovalStatus.PreApprovalInProcess]: StatusColor.Blue,
      [PreApprovalStatus.PreApproved]: StatusColor.Green,
      [PreApprovalStatus.Requested]: StatusColor.Red,
      [PreApprovalStatus.Saved]: StatusColor.Gray,
      [PreApprovalStatus.Denied]: StatusColor.Red,
      [PreApprovalStatus.Draft]: StatusColor.Gray,
    };
  }

}
