import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

// import { OfferStatus } from '../../../../core';

/**
 * Update offer status dialog component.
 */
@Component({
  selector: 'arb-upgrade-plan-dialog',
  templateUrl: './upgrade-plan-dialog.component.html',
  styleUrls: ['./upgrade-plan-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradePlanDialogComponent {

  
  // private readonly statuses = OfferStatus;

  /**
   * Form control for offer statuses.
   */
  // public readonly statusControl = new FormControl(this.accepted);

  constructor(public dialog: MatDialog,private router: Router) {}

  openDialog() {
    this.dialog.open(UpgradePlanDialogComponent);
  }

  gotoUpdatePlan(){

    this.router.navigate(['/dashboard/plans']);

  }

  // public viewOffer(): void {
    
  //       this.router.navigate(['', this.]);
  // }
  // constructor(

  //   private matDialogRef: MatDialogRef<UpgradePlanDialogComponent>,
  // ) { }

  /**
   * Submitted status.
   */
  // public get submitted(): OfferStatus {
    // return this.statuses.Submitted;
  }

  /**
   * Accepted status.
   */
  // public get accepted(): OfferStatus {
    // return this.statuses.Accepted;
  // }

  /**
   * Rejected status.
   */
  // public get rejected(): OfferStatus {
    // return this.statuses.Rejected;
  // }

  /**
   * Closes the dialog with selected status.
   */
  // public onSave(): void {
  //   this.matDialogRef.close(this.statusControl.value);
  // }

// }
