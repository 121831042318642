/**
 * Creates new `formData` from pased object.
 * @param object An object from which form data will be made.
 */
export function createFormData(object: Object): FormData {
  const formData = new FormData();
  console.log("form dataa");

  for (const param of Object.keys(object)) {
    if (object[param]) {
      if (object[param] instanceof Date) {
        formData.append(param, object[param].toISOString());
      } else if (object[param] instanceof Blob) {
        formData.append(param, object[param]);
      } else if (object[param] instanceof Array) {
        formData.append(param, JSON.stringify(object[param]));
      } else {
        formData.append(param, String(object[param]));
      }
    }
  }
  return formData;
}
