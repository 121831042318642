import { Full } from '../../helpers/full';

import { Occupancy } from '../occupancy';
import { PreApprovalStatus } from '../pre-approval-status';
import { ProgramType } from '../program-type';
import { PropertyType } from '../property-type';

/**
 * Pre approval information.
 */
export class BorrowerPreApproval {

  /**
   * Id.
   */
  public id: number;

  /**
   * Zip-package url.
   */
  public packageUrl: string;

  
  /**
   * Zip-package url.
   */
   public pdfUrl: string;

  /**
   * Max purchase price.
   */
  public maxPurchasePrice: number;

  /**
   * Down payment.
   */
  public downPayment: number;

  /**
   * Property type.
   */
  public propertyType: PropertyType;

  /**
   * Occupancy.
   */
  public occupancy: Occupancy;

  /**
   * FICO score.
   */
  public fico: number;

  /**
   * Program type.
   */
  public program: ProgramType;


  /* Down payment type (in % or $) */
  public downPaymentType: number;

  /* Purchase Price */
  public purchasePrice: number;

  /* Address */
  public address: string;

  /**
   * Status.
   */
  public status: PreApprovalStatus;

  /* Custom pre-approval letter */
  public isCustomLetter: number;
  
  /* Custom pre-approval letter */
  public createdAt: Date;

  constructor(data: Full<BorrowerPreApproval>) {
    this.id = data.id;
    this.packageUrl = data.packageUrl;
    this.pdfUrl = data.pdfUrl;
    this.maxPurchasePrice = data.maxPurchasePrice;
    this.downPayment = data.downPayment;
    this.propertyType = data.propertyType;
    this.occupancy = data.occupancy;
    this.fico = data.fico;
    this.program = data.program;
    this.status = data.status;
    this.downPaymentType = data.downPaymentType;
    this.purchasePrice = data.purchasePrice;
    this.address = data.address;
    this.isCustomLetter = data.isCustomLetter;
    this.createdAt = data.createdAt
  }

}
