import { Observable } from 'rxjs';

/**
 * Reads file and returns base64.
 * @param file File.
 */
export function readFile(file: Blob): Observable<string> {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Observable<string>(observer => {
    reader.onload = () => {
      observer.next(reader.result as string);
      observer.complete();
    };
    reader.onerror = error => observer.error(error);
  });
}
