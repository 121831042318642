import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../../app.config';

import { UpdateUserStatusDto } from './dto/public_api';

/**
 * Users service.
 */
@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(
    private config: AppConfig,
    private http: HttpClient,
  ) { }

  /**
   * Update status.
   * @param data Contains user id and new status.
   */
  public updateStatus(data: UpdateUserStatusDto): Observable<void> {
    const { status, user } = data;
    const url = `${this.config.apiUrl}/api/users/${user.id}/status`;
    const body = { status };
    return this.http.put<void>(url, body);
  }

  /**
   * Resends invite for user.
   * @param id User id.
   */
  public resendInvite(id: number): Observable<void> {
    const url = `${this.config.apiUrl}/api/users/${id}/resend-invite`;
    return this.http.post<void>(url, null);
  }

  /**
  * generate invite for user.
  * @param id User id 
  */
  public generateInvite(id: any): Observable<any> {
    const url = `${this.config.apiUrl}/api/users/${id}/generate-invite`;
    return this.http.get<any>(url);
  }

  public getUserType(): Observable<any> {
    const result = `${this.config.apiUrl}/api/users/getUserType`;
    return this.http.get(result);
  }
  public GetUserRole(): Observable<any> {
    const result = `${this.config.apiUrl}/api/users/GetUserRole`;
    return this.http.get(result, { responseType: 'text' });
  }

  public updateMappingStatus(realtor): Observable<any> {
    const result = `${this.config.apiUrl}/api/users/${realtor.id}/userMappingActivationStatus/${realtor.isMapped}`;
    return this.http.put(result, { responseType: 'text' });
  }

}
