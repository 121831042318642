import { BorrowerService } from './../../../../core/services/borrower.service';
import { Component, ChangeDetectionStrategy, OnInit,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map, tap } from 'rxjs/operators';
import { toReadablePropertyType, toReadableOccupancy, toReadableProgramType } from '../../../../core';
import { getDocument } from 'pdfjs-dist';

/**
 * Borrower letter page component.
 */
@Component({
  selector: 'arb-borrower-letter-page',
  templateUrl: './borrower-letter-page.component.html',
  styleUrls: ['./borrower-letter-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorrowerLetterPageComponent  {

  /**
   * Borrower stream.
   */
  public logoUrl = "/assets/logo.png?date=" + new Date();

  public readonly borrower$ = this.route.data
    .pipe(
      first(),
      map(({ borrower }) => borrower),
    );

  public borrowerCustomFields : any;
  public defaultFieldsShow={
    "Program type":true,
    "Fico":true,
    "Occupancy":true,
    "Property type":true,
    "Downpayment":true,
  }
  /**
   * Current date.
   */
  public currentDate: any = new Date();

  /**
   * Returns property type in readable format.
   */
  public readonly toReadablePropertyType = toReadablePropertyType;

  /**
   * Returns occupancy in readable format.
   */
  public readonly toReadableOccupancy = toReadableOccupancy;

  /**
   * Returns program type in readable format.
   */
  public readonly toReadableProgramType = toReadableProgramType;
  borrowerPdf: any;

  constructor(
    private route: ActivatedRoute,
    private bs: BorrowerService,
    private cdr:ChangeDetectorRef
  ) {
  }
  
  public ngOnInit(): void {
    this.initBorrower();
  }

  ngAfterViewInit(){
    document.getElementsByTagName('body')[0].classList.add("borrower-letter-page-cmp")
  }
  ngOnDestroy(){
    document.getElementsByTagName('body')[0].classList.remove("borrower-letter-page-cmp")
  }

  showCustomField(value, inputType) {
    if (value && inputType == "percentage") {
      return `${value}%`
    } if (value && inputType == "price") {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value) 
    } else if (value && inputType == "date") {
      return value
      // new Date().toLocaleDateString('en-us', {  year:"numeric", month:"short", day:"numeric"})
    } else if (value && inputType == "text") {
      return value
    } else {
      return value
    }
  }

  private initBorrower(): void {
    this.route.data.subscribe(async ({borrower})=>{
        if (borrower.loanOfficer.timeZone != null) {
          this.currentDate = this.currentDate.toLocaleString('en-US', { timeZone: borrower.loanOfficer.timeZone });
        }
        this.bs.getBorrowerCustomFields(borrower.id).subscribe(res=> {
          this.borrowerCustomFields = res;
          if (res.length) {
            res.map(i => {
              if (i.isDefault && this.defaultFieldsShow[i.customFieldName]) {
                this.defaultFieldsShow[i.customFieldName] = i.isChecked;
              }
            })
            this.cdr.markForCheck();
          }
          console.log(this.borrowerCustomFields);
        })
        let assetArray: string[] = [];
        let data = {}
        if (borrower.preApprovalAssets[6].fileUrl.split(".").pop() == "pdf") {
          assetArray = await this.getImageArray(borrower.preApprovalAssets[6].fileUrl)
        } 
        data = {
          assetArray: assetArray
        }
        this.borrowerPdf = data;
        console.log(this.borrowerPdf);
      })

  }

  private getImageArray(url): any {

    return new Promise(async(resolve, reject) => {

      var loadingTask = getDocument(url);
      await loadingTask.promise.then(async function (pdf) {

        var totalPages = pdf.numPages
        var data: string[] = [];

        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          let canvasdiv = document.createElement("canvas");
          canvasdiv.setAttribute("id", "canvas" + pageNumber);
         await pdf.getPage(pageNumber).then( async function (page) {

            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });


            // Prepare canvas using PDF page dimensions
            var context = canvasdiv.getContext('2d');

            canvasdiv.height = viewport.height;
            canvasdiv.width = viewport.width;

            // Render PDF page into canvas context
            if (!context) return
            var renderTask = page.render({ canvasContext: context, viewport: viewport });
           await renderTask.promise.then(function () {
              let t = canvasdiv.toDataURL('image/png')
              data.push(t)
            });
          });
        }
        resolve(data);
      }, function (reason) {
        // PDF loading error
        console.error(reason);
        resolve([]);

      });
    })
  }
}
