/**
 * All roles that a user can have.
 */
export enum UserRoleId {
    /**
     * Admin role.
     */
    Admin = 1,
  
    /**
     * Realtor role.
     */
    Realtor = 2,
  
    /**
     * Loan officer role.
     */
    LoanOfficer = 3,
  }
  
  
  