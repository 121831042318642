import { Observable } from 'rxjs';

import { publishReplay, refCount } from 'rxjs/operators';

/**
 * Analogue of shareReplay function which does not produce memory leaks.
 * @param bufferSize How many items to keep in memory.
 * @param windowTime
 */
export const weakShareReplay =
  <T>(bufferSize?: number, windowTime?: number) =>
    (source$: Observable<T>) =>
      source$.pipe(
        publishReplay(bufferSize, windowTime),
        refCount(),
      );
