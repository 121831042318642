import { FormGroup, AbstractControl } from '@angular/forms';

/**
 * Gets form control from form group.
 * Throws an error if control doesn't exist.
 * @param form Form group.
 * @param formControlName Form control name.
 */
export function getFormControl(form: FormGroup, formControlName: string): AbstractControl {
  const control = form.get(formControlName);
  if (control) {
    return control;
  }
  throw new Error(`${formControlName} control is not defined`);
}
