import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, EMPTY } from 'rxjs';
import { switchMap, filter, take, mapTo, catchError, map } from 'rxjs/operators';

import { AuthTokenInfo } from '../models/auth-token-info';
import { RequestHeader } from '../models/request-header';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

/**
 * Add the token interceptor.
 */
@Injectable({
  providedIn: 'root',
})
export class AddTokenInterceptor implements HttpInterceptor {

  private inflightAuthRequest$ = new BehaviorSubject<string | null>(null);
  private isTokenRefreshing = false;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) { }

  /**
   * Adds auth token.
   * @param req Request.
   * @param next Handler.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(RequestHeader.NoAuthorization)) {
      return next.handle(req.clone({
        headers: req.headers.delete(RequestHeader.NoAuthorization),
      }));
    }
    if (this.isTokenRefreshing) {
      return this.waitForRefreshToken(req, next);
    } else {
      return this.localStorageService
        .getTokens()
        .pipe(
          map(tokens => tokens ? new AuthTokenInfo({
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            expirationDate: new Date(tokens.expirationDate),
          }) : null),
          switchMap(authTokenInfo => {
            if (!authTokenInfo) {
              return of(null);
            }
            if (authTokenInfo.expiresSoon()) {
              return this.refreshToken(authTokenInfo.refreshToken);
            }
            return of(authTokenInfo.accessToken);
          }),
          switchMap(accessToken => {
            this.isTokenRefreshing = false;
            this.inflightAuthRequest$.next(accessToken);
            return next.handle(this.setAuthorizationHeader(req, accessToken));
          }),
        );
    }
  }

  private waitForRefreshToken(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    return this.inflightAuthRequest$
      .pipe(
        filter(accessToken => !!accessToken),
        take(1),
        switchMap(accessToken => handler.handle(this.setAuthorizationHeader(request, accessToken))),
      );
  }

  private setAuthorizationHeader(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    return req.clone({ headers: req.headers.set(RequestHeader.Authorization, `Bearer ${token}`) });
  }

  private refreshToken(refreshToken: string): Observable<string> {
    this.isTokenRefreshing = true;
    return this.authService.refreshToken(refreshToken)
      .pipe(
        switchMap(newTokens => this.localStorageService.setTokens(newTokens)
          .pipe(
            mapTo(newTokens.accessToken),
          ),
        ),
        catchError(e => this.handleRefreshError(e)),
      );
  }

  private handleRefreshError(error: HttpErrorResponse): Observable<never> {
    this.isTokenRefreshing = false;
    if (error.status === 400) {
      this.authService.logout();
    }
    return EMPTY;
  }

}
