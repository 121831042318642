import { Full } from '../helpers/full';

import { User } from './user';
import { UserStatus } from './user-status';

/**
 * Loan officer.
 */
export class LoanOfficer extends User {

  /**
   * Number of associated realtors.
   */
  public realtorsCount: number;

  /**
   * Default Pre Approval.
   */
   public DefaultPreApproval: string;
  /**
   * Company logo url.
   */
  public companyLogoUrl: string;

  /**
   * Signature url.
   */
  public signatureUrl: string;

  /**
   * Readable id.
   */
  public friendlyId: string;

  /**
   * Company name.
   */
  public companyName: string;
  /**
   * Title.
   */
  public title: string;

  public nmlsId : string;

  /**
   * Status.
   */
  public status: UserStatus;

 /**
   * Footer color.
   */
  public footerColor: string;

  /**
   * Header color.
   */
  public headerColor: string;

  public isCustom: boolean=false;

  public fieldCordinates: object;
  
  public timeZone: string;

  constructor(data: Full<LoanOfficer>) {
    super(data);
    this.realtorsCount = data.realtorsCount;
    this.companyLogoUrl = data.companyLogoUrl;
    this.signatureUrl = data.signatureUrl;
    this.friendlyId = data.friendlyId;
    this.companyName = data.companyName;
    this.footerColor = data.footerColor;
    this.headerColor = data.headerColor;
    this.status = data.status;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.updatedAt = data.updatedAt;
    this.nmlsId = data.nmlsId;
    this.DefaultPreApproval = data.DefaultPreApproval,
    this.isCustom= data.isCustom;
    this.fieldCordinates=data.fieldCordinates;
    this.timeZone=data.timeZone;
  }

}
