import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { listenControlChanges, UsersService } from '../../../../core';
import { RendererType } from '../../../models/document-editor/renderer/renderer-type';

/**
 * Document editor dialog component.
 */
@Component({
  selector: 'arb-document-editor-dialog',
  templateUrl: './document-editor-dialog.component.html',
  styleUrls: ['./document-editor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentEditorDialogComponent {

  userRole: string = '';
  /**
   * Files.
   */
  public files: File[] = [];

  ngOnInit() {
    this.loadCurrentuser();
  }
  loadCurrentuser() {
    this.userSerivce.GetUserRole()
      .subscribe((data) => {
        this.userRole = data;
        this.cdr.detectChanges();
      })
  }
  /**
   * Stream for triggering crop effect.
   */
  public readonly crop$ = new Subject<void>();

  /**
   * Undo stream.
   */
  public readonly undo$ = new Subject<void>();

  /**
   * Renderer type stream.
   */
  public readonly rendererType$ = new BehaviorSubject(RendererType.RectangleRenderer);

  /**
   * `true` if cropper is enabled.
   */
  public readonly cropperEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.Cropper),
    );

  /**
   * `true` if rectangle renderer is enabled.
   */
  public readonly rectangleRendererEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.RectangleRenderer),
    );
  public readonly rectangleRendererHighlighterEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.RectangleRendererHighlighter),
    );

  /**
   * Form control for agreement.
   */
  public readonly agreementControl = new FormControl(false);

  /**
   * Allow save stream.
   */
  public readonly allowSave$ = listenControlChanges<boolean>(this.agreementControl, 0); 

  /**
   * `true` if uploaded file is pdf.
   */
  public readonly isPdf = this.data.type === 'application/pdf';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: File,
    private userSerivce: UsersService,
    private matDialog: MatDialogRef<DocumentEditorDialogComponent>,
    private cdr: ChangeDetectorRef
  ) { }

  /**
   * Undo.
   */
  public undo(): void {
    this.undo$.next();
  }

  /**
   * Sets new files.
   * @param files New files.
   */
  public onFilesChange(files: File[]): void {
    this.files = files;
  }

  /**
   * Closes the dialog with changed image.
   */
  public save(): void {
    this.matDialog.close(this.files);
  }

  /**m
   * Disable editor.
   */
  public disableEditor(): void {
    this.rendererType$.next(RendererType.None);
  }

  /**
   * Enable cropper.
   */
  public enableCropper(): void {
    this.rendererType$.next(RendererType.Cropper);
  }

  /**
   * Enable rectangle renderer.
   */
  public enableRectangleRenderer(): void {
    this.rendererType$.next(RendererType.RectangleRenderer);
  }
  public enableRectangleRendererHighlighter(): void {
    this.rendererType$.next(RendererType.RectangleRendererHighlighter);
  }


  /**
   * Triggers crop stream.
   */
  public crop(): void {
    this.crop$.next();
  }

  /**
   * Disable editor when user opens new page.
   */
  public onPageChanged(): void {
    this.disableEditor();
  }

}
