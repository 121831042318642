import { Full } from '../../helpers/full';

import { AssetPart } from './asset-part';
import { AssetType } from './asset-type';

/**
 * Asset.
 */
export class Asset {

  /**
   * Id.
   */
  public id: number;

  /**
   * Asset type id.
   */
  public assetTypeId: AssetType;

  /**
   * Asset type name.
   */
  public assetTypeName: string;

  /**
   * File url.
   * If `isMultipart` is `true` then this should be url to `zip` file.
   * Otherwise url to single uploaded file.
   */
  public fileUrl: string;

  /**
   * Readable file name.
   */
  public fileName: string;

  /**
   * Last updated.
   */
  public lastUpdated: Date | null;

  /**
   * Record last updated user name.
   */
  public updatedByUserName: string;

  /**
   * `true` if an asset has multiple files.
   */
  public isMultipart: boolean;

  /**
   * List of files containet in asset.
   * Should be empty when only one file was uploaded.
   */
  public files: AssetPart[];

  /**
   * Alternate name.
   */
   public alternateName: string | null;

  /**
   * pre-approval asset sequence.
   */
  public sequence: number | null;

  constructor(data: Full<Asset>) {
    this.id = data.id;
    this.assetTypeId = data.assetTypeId;
    this.assetTypeName = data.assetTypeName;
    this.fileUrl = data.fileUrl;
    this.fileName = data.fileName;
    this.lastUpdated = data.lastUpdated;
    this.updatedByUserName = data.updatedByUserName;
    this.files = data.files;
    this.sequence = data.sequence;
    this.alternateName = data.alternateName;
  }

}
