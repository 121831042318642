import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../../app.config';
import { InviteRealtorData } from '../models/invite-realtor-data';
import { PaginationResult } from '../models/pagination-result';
import { PersonDetails } from '../models/person-details';
import { Realtor } from '../models/realtor';
import { RealtorSummary } from '../models/realtor-summary';
import { RealtorsSearchParams } from '../models/realtors-search-params';
import { Table } from '../models/table';
import { partitionTable } from '../../core'
import { EmployeeDetailsDto } from './dto/employee-details-dto';
import { RealtorDto } from './dto/realtor-dto';
import { RealtorSummaryDto } from './dto/realtor-summary-dto';
import { UpdateRealtorDto } from './dto/update-realtor-dto';

/**
 * Realtors service.
 */
@Injectable({
  providedIn: 'root',
})
export class RealtorsService {



  constructor(
    private config: AppConfig,
    private http: HttpClient,
  ) { }

  /**
   * Searches for realtors.
   * @param searchParams Params for search request.
   */
  public search(searchParams: RealtorsSearchParams): Observable<Table<RealtorSummary>> {
    const url = `${this.config.apiUrl}/api/realtors`;
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<RealtorSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(dto => this.mapToRealtorSummary(dto)),
        })),
      );
  }
  public searchR(realtorName: string): Observable<Table<RealtorSummary>> {
    const url = `${this.config.apiUrl}/api/realtors/search`;
    let params = new HttpParams().set("realtorName", "" + realtorName);
    return this.http
      .get<PaginationResult<RealtorSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(dto => this.mapToRealtorSummary(dto)),
        })),
      );
  }
  public getR(): Observable<Table<RealtorSummary>> {
    const url = `${this.config.apiUrl}/api/realtors`;
    return this.http
      .get<PaginationResult<RealtorSummaryDto>>(url)
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(dto => this.mapToRealtorSummary(dto)),
        })),
      );
  }
  public readonly realtors$ = this.createTotalBorrowerCountStream();

  private createTotalBorrowerCountStream(): Observable<RealtorSummary[]> {
    return this.getR().pipe(map(response => response.data))
  }

  /**
   * Searches for all realtors.
   * @param searchParams Params for search request.
   */
  public searchAll(searchParams: RealtorsSearchParams): Observable<Table<RealtorSummary>> {
    const url = `${this.config.apiUrl}/api/realtors/searchall`;
    console.log(searchParams);
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<RealtorSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(dto => this.mapToRealtorSummary(dto)),
        })),
      );
  }

  /**
   * Get Mapped Realtors.
   * @param searchParams Params for search request.
   */
   public getMappedRealtors(searchParams: RealtorsSearchParams): Observable<Table<RealtorSummary>> {
    const url = `${this.config.apiUrl}/api/realtors/getMappedRealtors`;
    console.log(searchParams);
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<RealtorSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(dto => this.mapToRealtorSummary(dto)),
        })),
      );
  }

    /**
   * Get Mapped LoanOfficers.
   * @param searchParams Params for search request.
   */
     public getMappedLoanOfficers(searchParams: RealtorsSearchParams): Observable<Table<RealtorSummary>> {
      const url = `${this.config.apiUrl}/api/loan-officers/getMappedLoanOfficers`;
      console.log(searchParams);
      let params = new HttpParams();
      for (const param of Object.keys(searchParams)) {
        if (searchParams[param]) {
          params = params.set(param, searchParams[param]);
        }
      }
      return this.http
        .get<PaginationResult<RealtorSummaryDto>>(url, { params })
        .pipe(
          map(res => ({
            paginationMetadata: res.metadata,
            data: res.data.map(dto => this.mapToRealtorSummary(dto)),
          })),
        );
    }

  private mapToRealtorSummary(dto: RealtorSummaryDto): RealtorSummary {
    return new RealtorSummary({
      firstName: dto.firstName,
      lastName: dto.lastName,
      id: dto.id,
      friendlyId: dto.friendlyId,
      status: dto.status,
      updatedAt: new Date(dto.updatedAt),
      borrowersCount: dto.numBorrowers,
      activeOffersCount: dto.numActiveOffers,
      updatedByUserName: dto.updatedByUserName,
      isMapped: dto.isMapped
    });
  }

  /**
   * Invite a realtor.
   * @param data Invite realtor data.
   */
  public invite(data: InviteRealtorData): Observable<void> {
    const url = `${this.config.apiUrl}/api/realtors/invite`;
    return this.http.post<any>(url, data);
  }

  /**
   * Get information about realtor by id.
   * @param id Realtor's id.
   */
  public getById(id: number): Observable<Realtor> {
    const url = `${this.config.apiUrl}/api/realtors/${id}`;
    return this.http
      .get<RealtorDto>(url)
      .pipe(
        map(realtorDto => this.mapToRealtor(realtorDto)),
      );
  }

  /**
   * Get realtor's profile info.
   */
  public getProfile(): Observable<Realtor> {
    const url = `${this.config.apiUrl}/api/realtors/my-profile`;
    return this.http
      .get<RealtorDto>(url)
      .pipe(
        map(dto => this.mapToRealtor(dto)),
      );
  }
  /* 
  Ignore this methods, this methods is only for reference
  public getRealtor(): Observable<RealtorData[]> {
     const url = `${this.config.apiUrl}/api/realtors`;
     return this.http
       .get<RealtorData[]>(url)
       .pipe(
         map((res:RealtorDtoTool[]) => res.map(this.mapToRealtorTool)),
       );
   }
   private mapToRealtorTool(reltorDtoTool:RealtorDtoTool): RealtorData
   {
     return new RealtorData({
       id: reltorDtoTool.id,
       firstName: reltorDtoTool.firstName,
       lastName: reltorDtoTool.lastName,
     });
   }*/
  private mapToRealtor(realtorDto: RealtorDto): Realtor {
    return new Realtor({
      loanOfficer: this.mapToEmployeeDetails(realtorDto.loanOfficerInfo),
      invitedBy: this.mapToEmployeeDetails(realtorDto.invitedByInfo),
      logoUrl: realtorDto.realtorLogoUrl,
      profileImageUrl: realtorDto.realtorPictureUrl,
      companyLogoUrl: realtorDto.companyLogoUrl,
      signatureUrl: realtorDto.signatureUrl,
      footerColor: realtorDto.footerColor ? realtorDto.footerColor.trim() : '',
      headerColor: realtorDto.headerColor ? realtorDto.headerColor.trim() : '',
      iconsColor: realtorDto.iconsColor ? realtorDto.iconsColor.trim() : '',
      friendlyId: realtorDto.friendlyId,
      companyName: realtorDto.companyName,
      officeAddress: realtorDto.realtorOfficeAddress,
      website: realtorDto.realtorWebsite,
      status: realtorDto.status,
      id: realtorDto.id,
      firstName: realtorDto.firstName,
      lastName: realtorDto.lastName,
      email: realtorDto.email,
      phoneNumber: realtorDto.phoneNumber,
      updatedAt: new Date(realtorDto.updatedAt),
      emailSignature: realtorDto.emailSignature,
      timeZone: realtorDto.timeZone,
      licenseNumber: realtorDto.licenseNumber
    });
  }

  private mapToEmployeeDetails(dto: EmployeeDetailsDto | null): PersonDetails | null {
    if (dto) {
      return new PersonDetails({
        id: dto.id,
        friendlyId: dto.friendlyId,
        companyName: dto.companyName,
        firstName: dto.firstName,
        lastName: dto.lastName,
        email: dto.email,
        phoneNumber: dto.phoneNumber,
        signatureUrl: dto.signatureUrl ? dto.signatureUrl : ''
        
      });
    }
    return null;
  }

  /**
   * Updates profile.
   * @param profile Updated realtor's profile.
   * @param id Realtor id.
   */
  public updateProfile(profile: UpdateRealtorDto, id?: number): Observable<void> {
    const url = id
      ? `${this.config.apiUrl}/api/realtors/${id}/byManageUser`
      : `${this.config.apiUrl}/api/realtors/my-profile`;
    const formData = new FormData();
    formData.append('companyName', profile.companyName);
    formData.append('footerColor', profile.footerColor);
    formData.append('headerColor', profile.headerColor);
    formData.append('iconsColor', profile.iconsColor);
    formData.append('firstName', profile.firstName);
    formData.append('lastName', profile.lastName);
    formData.append('email', profile.email);
    formData.append('phoneNumber', profile.phoneNumber);
    formData.append('realtorOfficeAddress', profile.officeAddress);
    formData.append('realtorWebsite', profile.website);
    formData.append('removeRealtorPicture', String(profile.pictureRemoved));
    formData.append('removeCompanyLogo', String(profile.companyLogoRemoved));
    formData.append('removeSignature', String(profile.signatureRemoved));
    formData.append('removeRealtorLogo', String(profile.logoRemoved));
    formData.append('emailSignature', profile.emailSignature);
    if (profile.realtorLogo) {
      formData.append('realtorLogo', profile.realtorLogo);
    }
    if (profile.companyLogo) {
      formData.append('companyLogo', profile.companyLogo);
    }
    if (profile.realtorPicture) {
      formData.append('realtorPicture', profile.realtorPicture);
    }
    if (profile.signaturePicture) {
      formData.append('signature', profile.signaturePicture);
    }
    formData.append('timeZone', profile.timeZone);
    formData.append('licenseNumber', profile.licenseNumber);
    return this.http.put<void>(url, formData);
  }

  /**
   * Updates profile.
   * @param emailSignature Updated realtor's profile.
   * 
   */
  public updateEmailSignature(emailSignature: string, id?: any): Observable<void> {
    const url = `${this.config.apiUrl}/api/realtors/my-email-signature`;
    const formData = new FormData();
    formData.append('emailSignature', emailSignature);
    formData.append('id', id);
    return this.http.put<void>(url, formData);
  }

  /**
  * generate invite for user.
  * @param id User id.
  */
  public generateInvite(id: any): Observable<any> {
    const url = `${this.config.apiUrl}/api/users/${id}/generate-invite`;
    return this.http.get<any>(url);
  }

  public getListOfAllrealtors(): Observable<any> {
    const url = `${this.config.apiUrl}/api/realtors`;
    return this.http.get<any>(url);
  }

  public updateLoanOfficerIdInRealtor(id: number, payload: any) {
    const url = `${this.config.apiUrl}/api/realtors/updateLoanOfficerIdInRealtor/${id}`;
    return this.http.put<any>(url, payload);
  }
}
