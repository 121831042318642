import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserStatus, toReadableUserStatus, RolesService } from '../../../../core';
import { StatusColor } from '../../../models/status-color';
import { UserStatusConfig } from '../../../models/user-status-config';

/**
 * Realtor status.
 */
@Component({
  selector: 'arb-realtor-status',
  templateUrl: './realtor-status.component.html',
  styleUrls: ['./realtor-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealtorStatusComponent {

  private readonly colors = this.createColors();
  private configValue = this.createDefaultConfig();
  public isShowGenerate = false;
  /**
   * All user statuses.
   */
  public readonly statuses = UserStatus;

  /**
   * Status text value.
   */
  public statusValue: UserStatus;

  /**
   * Status color.
   */
  public color: StatusColor;

  /**
   * Show only if current user is admin or LO and if small version is enabled.
   */
  public readonly showActivateAbility$ = this.createShowActivateAbilityStream();

  /**
   * Config.
   */
  @Input()
  public set config(value: UserStatusConfig) {
    this.configValue = { ...this.configValue, ...value };
  }
  /**
   * Config.
   */
  @Input()
  public set showGenerate(value: boolean) {
    this.isShowGenerate = value;
  }



  public get config(): UserStatusConfig {
    return this.configValue;
  }

  /**
   * Status.
   */
  @Input()
  public set status(value: UserStatus) {
    this.statusValue = value;
    this.color = this.colors[value];
  }

  public get status(): UserStatus {
    return this.statusValue;
  }

  /**
   * Emits new status.
   */
  @Output()
  public statusChange = new EventEmitter<UserStatus>();

  /**
   * Emits event ot resend invite.
   */
  @Output()
  public resendInviteChange = new EventEmitter<void>();

  /**
   * Emits event ot genrate invite.
   */
  @Output()
  public generateInviteChange = new EventEmitter<void>();

  constructor(
    private rolesService: RolesService,
  ) { }

  private createColors(): Record<UserStatus, StatusColor> {
    return {
      [UserStatus.Active]: StatusColor.Green,
      [UserStatus.Deactivated]: StatusColor.Gray,
      [UserStatus.Invited]: StatusColor.Blue,
    };
  }

  private createShowActivateAbilityStream(): Observable<boolean> {
    return this.rolesService
      .isRealtor$
      .pipe(
        /* TypeScript can't compare `boolean | undefined` and `boolean`. */
        map(isRealtor => !!this.config.enableActivating && !isRealtor),
      );
  }

  private createDefaultConfig(): UserStatusConfig {
    return {
      useRectangle: false,
      multiline: false,
      small: false,
      enableActivating: false,
      text: false
    };
  }

  /**
   * Activated.
   */
  public get activated(): boolean {
    return this.status === UserStatus.Active;
  }

  /**
   * Deactivated.
   */
  public get deactivated(): boolean {
    return this.status === UserStatus.Deactivated;
  }

  /**
   * Invited.
   */
  public get invited(): boolean {
    return this.status === UserStatus.Invited;
  }

  /**
   * Returns readable user status.
   */
  public get statusText(): string {
    return toReadableUserStatus(this.status);
  }

  /**
   * Emits new status.
   * @param status New status.
   */
  public changeStatus(status: UserStatus): void {
    this.statusChange.emit(status);
  }

  /**
   * Emits event to resend invite.
   */
  public resendInvite(): void {
    this.resendInviteChange.emit();
  }

  /**
   * Emit generate Event
   */
  public generateInvite(): void {
    this.generateInviteChange.emit();
  }
}
