import { withLatestFrom } from 'rxjs/operators';
import { async } from '@angular/core/testing';
import { flatMap, concatMap } from 'rxjs/operators';
import { Occupancy } from './../../../core/models/occupancy';
import { PropertyType } from './../../../core/models/property-type';
import { Observable } from 'rxjs';
import { RpaFileData } from './../../../offers/models/rpa-file-data';
import { AzureOcrService } from './../../../core/services/azure-ocr-service/index';
import { PDFDocumentProxy } from 'pdfjs-dist/webpack';
import { Borrower } from './../../../core/models/borrowers/borrower';
import { Point } from './../../../shared/models/document-editor/point';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Subject, defer, from } from 'rxjs';
import { tap, mapTo, switchMap, map, takeLast, delay } from 'rxjs/operators';
import { PersonDetailsLoanOfficer } from 'src/app/core/models/person-details-loanofficer';
import * as  pdfjsLib from 'pdfjs-dist'


import {
  ErrorMessage,
  BorrowerService,
  completedOrFailed,
  catchHttpErrorMessage,
  PreApprovalStatus,
  BorrowerPreApproval,
  Contact,
  PersonDetails,
  Asset,
  createImage,
} from '../../../core';

import { BorrowersPreApprovalService } from './borrowers-pre-approval.service';
import { AssetFileDto } from './dto/asset-file-dto';
import { PreApprovalDto } from './dto/pre-approval-dto';
const DEFAULT_BORROWER = new Borrower({
  firstName: '',
  lastName: '',
  id: 0,
  email: '',
  phoneNumber: '',
  friendlyId: '',
  nickname: '',
  copyOfCustomPreApproval: "",
  isCustom: false,
  fieldCordinates: [],
  isLoanOfficerIndependent: false,
  coBorrower: new Contact({
    firstName: '',
    email: '',
    phoneNumber: '',
    lastName: '',
  }),
  realtor: new PersonDetails({
    firstName: '',
    lastName: '',
    id: 0,
    companyName: '',
    email: '',
    phoneNumber: '',
    friendlyId: '',
  }),
  loanOfficer: new PersonDetailsLoanOfficer({
    firstName: '',
    lastName: '',
    id: 0,
    companyName: '',
    email: '',
    phoneNumber: '',
    friendlyId: '',
    isCustom: false,
    fieldCordinates: "",
    timeZone: ""
  }),
  preApproval: new BorrowerPreApproval({
    id: 0,
    packageUrl: '',
    pdfUrl: '',
    maxPurchasePrice: 0,
    downPayment: 0,
    propertyType: 0,
    occupancy: 0,
    fico: 0,
    program: 0,
    status: 1,
    downPaymentType: 2,
    purchasePrice: 0,
    address: '',
    isCustomLetter: 0,
    createdAt: new Date()
  }),
  preApprovalAssets: [],
  notes: [],
  deletedRole: 0,
});

/**
 * Service that manages borrower's data across borrower page.
 */
// @Injectable()

@Injectable({
  providedIn: 'root',
})
export class BorrowerPageService {
  private imageArray: any = [];
  private readonly borrowerValue$ = new BehaviorSubject<Borrower>(DEFAULT_BORROWER);
  private readonly errorValue$ = new Subject<ErrorMessage | null>();

  private readonly isLoadingValue$ = new BehaviorSubject(false);
  public customFieldsArrValue$ = new Subject<any>()
  private readonly isPreApprovalFormValidValue$ = new BehaviorSubject<boolean>(false);
  private readonly canDeactivateValue$ = new BehaviorSubject<boolean>(true);
  private readonly preApprovalFormValue$ = new BehaviorSubject<PreApprovalDto | null>(null);
  public customFields$: any = new BehaviorSubject([]);

  private readonly customPreApprovalLetterValue$ = new BehaviorSubject<boolean | null>(null);
  public callupdatebtn$ = new Subject<any>();

  public readonly isCustomPreApprovalLetter$ = this.customPreApprovalLetterValue$.asObservable();
  /**
   * Borrower stream.
   */
  public readonly borrower$ = this.borrowerValue$.asObservable();
  /**
   * Pdf stream.
   */
  public readonly pdf$: Observable<PDFDocumentProxy>;
  /**
   * Error stream.
   */
  public readonly error$ = this.errorValue$.asObservable();
  /**
     * Emits uploaded file.
     */
  @Output()
  public uploaded = new EventEmitter<RpaFileData>();
  public borrower;

  public defaultPreApproval = new Subject<any>();
  /**
   * Loading stream.
   */
  public readonly loading$ = this.isLoadingValue$.asObservable();

  private removeRpaFile = false;
  /**
   * Can deactivate borrower page stream.
   */
  public readonly canDeactivate$ = this.canDeactivateValue$.asObservable();
  private image: HTMLImageElement;
  /**
   * Emits `true` if form is valid.
   */
  public readonly isPreApprovalFormValid$ = this.isPreApprovalFormValidValue$.asObservable();
  public defailtPreApproval;

  public defaultCordinates;
  /**
   * Pre approval form value.Null if the form is not changed.
   */
  public readonly preApprovalValue$ = this.preApprovalFormValue$.asObservable();

  public readonly callbtn$ = this.callupdatebtn$.asObservable();
  private customFields = {
    Address: [],
    Downpayment: [],
    Fico: [],
    Occupancy: [],
    Programtype: [],
    Purchaseprice: [],
    Propertytype: [],
  };
  constructor(
    private borrowersService: BorrowerService,
    private borrowersPreApprovalService: BorrowersPreApprovalService,
    private azureocrservice: AzureOcrService,

  ) {

  }




  // This block of code needs Investigation because callupdatebrn$ is used
  public saveupdate(message: any): void {
    this.callupdatebtn$.next(message);
  }

  public updatebtn(): Observable<any> {
    return this.callupdatebtn$.asObservable();
  }
  // This block of code needs Investigation because callupdatebrn$ is used
  // public readonly callbtn$ = this.callupdatebtn$.asObservable();


  /**
   * Initialize borrower value.
   * @param value Borrower.
   */
  public initBorrower(value: Borrower): void {
    this.borrowerValue$.next(value);
  }

  /**
   * Updates pre approval information.
   * @param data Pre approval data.
   * @param borrowerId Borrower id.
   */
  public updatePreApproval(data: PreApprovalDto, borrowerId: number): Observable<void> {
    this.startRequest();
    return this.borrowersPreApprovalService
      .updatePreApproval(data, borrowerId)
      .pipe(

        // tap(async () => await setTimeout(()=>{
        //     this.getReInitialize(borrowerId);
        //   },1000*3)
        // ),
        tap(async () => await this.getReInitialize(borrowerId)),
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      )
  }

  public getReInitialize(borrowerId) {
    this.getPreApprovalAssetsPdf(borrowerId, borrowerId).pipe(
      switchMap(() => this.updateBorrower(borrowerId))).subscribe()
  }
  public getBorrowerById(borrowerId: number) {
    return this.borrowersService.getById(borrowerId)
  }

  public updateIsCustomLetterFlag(flag) {
    this.customPreApprovalLetterValue$.next(flag);
  }

  public updateBorrowerData(borrowerId) {
    this.updateBorrower(borrowerId);
  }

  /**
   * Updates pre approval status.
   * @param status New status.
   * @param borrowerId Borrower id.
   */
  public updatePreApprovalStatus(status: PreApprovalStatus, borrowerId: number): Observable<void> {
    this.startRequest();
    return this.borrowersPreApprovalService
      .updatePreApprovalStatus(status, borrowerId)
      .pipe(
        switchMap(() => this.updateBorrower(borrowerId)),
        catchHttpErrorMessage({ message$: this.errorValue$, callback: () => this.isLoadingValue$.next(false) }),
      );
  }

  private updateBorrower(borrowerId: number): Observable<void> {
    return this.borrowersService
      .getById(borrowerId)
      .pipe(
        tap(borrower => this.borrowerValue$.next(borrower)),
        mapTo(void 0),
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      );
  }

  /**
   * Uploads asset.
   * @param data Asset file dto.
   * @param borrowerId Borrower id.
   */
  public uploadAsset(data: AssetFileDto, borrowerId: number): Observable<void> {
    // this.startRequest();
    return this.borrowersPreApprovalService
      .uploadAsset(data, borrowerId)
      .pipe(
        switchMap(() => this.updateAssets(borrowerId)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      );
  }

  /**
   * Deletes asset.
   * @param assetId Asset id.
   * @param borrowerId Borrower id.
   */
  public deleteAsset(assetId: number, borrowerId: number): Observable<void> {
    this.startRequest();
    return this.borrowersPreApprovalService
      .deleteAsset(assetId, borrowerId)
      .pipe(
        switchMap(() => this.updateAssets(borrowerId)),
        catchHttpErrorMessage({ message$: this.errorValue$, callback: () => this.isLoadingValue$.next(false) }),
      );
  }

  private updateAssets(borrowerId: number): Observable<void> {
    return this.borrowersService
      .getById(borrowerId)
      .pipe(
        withLatestFrom(this.borrower$),
        map(([{ preApprovalAssets }, currentBorrower]) => Object.assign(currentBorrower, { preApprovalAssets })),
        tap(updatedBorrower => this.borrowerValue$.next(updatedBorrower)),
        mapTo(void 0),
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      );
  }

  public updateSupportingDocName(asset, borrowerId: number): Observable<void> {
    return this.borrowersPreApprovalService
      .updateSupportingDocName(asset, borrowerId)
      .pipe(
        switchMap(() => this.getPreApprovalAssetsPdf(borrowerId, borrowerId)),
        switchMap(() => this.updateBorrower(borrowerId)),
        switchMap(() => this.updateAssets(borrowerId)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      );
  }



  /**
   * Creates note.
   * @param note Note.
   * @param borrowerId Borrower id.
   */
  public createNote(note: string, borrowerId: number): Observable<void> {
    this.startRequest();
    return this.borrowersService
      .createNote(borrowerId, note)
      .pipe(
        switchMap(() => this.borrowersService.getById(borrowerId)
          .pipe(
            withLatestFrom(this.borrower$),
            map(([{ notes }, currentBorrower]) => Object.assign(currentBorrower, { notes })),
            tap(newBorrower => this.borrowerValue$.next(newBorrower)),
            mapTo(void 0),
            completedOrFailed(() => this.isLoadingValue$.next(false)),
            catchHttpErrorMessage({ message$: this.errorValue$ }),
          ),
        ),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      );
  }

  private startRequest(): void {
    this.isLoadingValue$.next(true);
    this.errorValue$.next(null);
  }

  /**
   * Shows error.
   * @param text Error.
   */
  public showError(text: string): void {
    this.errorValue$.next(new ErrorMessage(text));
  }

  /**
   * Updates valid state of pre approval form.
   * @param value Valid state.
   */
  public updatePreApprovalFormValidState(value: boolean): void {
    this.isPreApprovalFormValidValue$.next(value);
  }

  /**
   * Updates pre approval value state of pre approval form.
   * @param value pre approval form.
   */
  public updatePreApprovalFormValueState(value: PreApprovalDto | null): void {
    this.preApprovalFormValue$.next(value);
  }

  /**
   * Update can deactivate borrower page.
   * @param value can deactivate.
   */
  public updateCanDeactivate(value: boolean): void {
    this.canDeactivateValue$.next(value);
  }

  public getborrowerCustomFields(borrowerId: number) {
    return this.borrowersService.getBorrowerCustomFields(borrowerId).subscribe(res => {
      this.customFieldsArrValue$.next(res);
    })
  }

  public saveCustomFieldsValues(cusFieldsBody) {
    this.startRequest();
    return this.borrowersService.updateBorrowerCustomFields({ "customFieldValues": cusFieldsBody, id: 0 });
  }

  public getPreApprovalAssetsPdf(borrowerId: number, preApprovalId: number) {
    //debugger;
    this.startRequest();
    return this.borrowersPreApprovalService
      .getPreApprovalAssestsLink(borrowerId, preApprovalId)
      .pipe(
        tap(() => this.startRequest()),
        map((response: any) => {
          if (!response.borrowerPreApprovalPdfUrl) {
            this.errorValue$.next(new ErrorMessage("No Documents attached to this borrower"));
            return catchHttpErrorMessage({ message$: this.errorValue$ })
          }
          return response;
        }),
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      )
  }

  public getRealtorPreapprovalDemoLetter(realtorId: number) {
    this.startRequest();
    return this.borrowersPreApprovalService
      .getRealtorPreApprovalDocDemoLink(realtorId)
      .pipe(
        map((response: any) => {
          if (!response.borrowerPreApprovalPdfUrl) {
            this.errorValue$.next(new ErrorMessage("No Documents attached to this borrower"));
            return catchHttpErrorMessage({ message$: this.errorValue$ })
          }
          return response;
        }),
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      )
  }

  public getLoanOfficerPreApprovalAssetsPdf(loanOfficerId: number) {
    this.startRequest();
    return this.borrowersPreApprovalService
      .getLoanOfficerPreApprovalDocDemoLink(loanOfficerId)
      .pipe(
        map((response: any) => {
          if (!response.borrowerPreApprovalPdfUrl) {
            this.errorValue$.next(new ErrorMessage("No Documents attached to this borrower"));
            return catchHttpErrorMessage({ message$: this.errorValue$ })
          }
          return response;
        }),
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      )
  }

  public getCustomLoanOfficerPreApprovalAssetsPdf(loanOfficerId: number) {
    this.startRequest();
    return this.borrowersPreApprovalService
      .getCustomLoanOfficerPreApprovalDocDemoLink(loanOfficerId)
      .pipe(
        map((response: any) => {
          if (!response.borrowerPreApprovalPdfUrl) {
            this.errorValue$.next(new ErrorMessage("No Documents attached to this borrower"));
            return catchHttpErrorMessage({ message$: this.errorValue$ })
          }
          return response;
        }),
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      )
  }



  public sortPreApprovalAssets(preApprovalData) {
    this.startRequest();
    return this.borrowersPreApprovalService
      .sortPreApprovalAssets(preApprovalData)
      .pipe(
        completedOrFailed(() => this.isLoadingValue$.next(false)),
        catchHttpErrorMessage({ message$: this.errorValue$ }),
      )
  }
  public async createCustomePreApproval(PreApproval, borrowerId) {
    //debugger;
    // const canvasforImage = document.createElement("canvas");
    const cfresp = await this.borrowersService.getBorrowerCustomFields(borrowerId).toPromise();
    this.customFields$.next(cfresp);
    const canvasforImage = document.getElementById("customPreApprovalstatusButton") as HTMLCanvasElement
    const contextforImage = canvasforImage.getContext('2d') as CanvasRenderingContext2D;
    let scale = 1;
    const res = await this.getBorrowerById(borrowerId).toPromise();
    this.borrower = res;
    this.imageArray = [];
    let src = this.borrower.copyOfCustomPreApproval;
    let defaultCordinates: any = []
    let sortedProgramType = [

      { name: "30 Year Fixed", value: 1 },
      { name: "15 Year Fixed", value: 2 },
      { name: "30 Year Fixed Conventional", value: 10 },
      { name: "5/1 ARM", value: 3 },
      { name: "7/1 ARM", value: 4 },
      { name: "10/1 ARM", value: 5 },
      { name: "15/1 ARM", value: 11 },
      { name: "6-Month ARM", value: 16 },
      { name: "DSCR Investor", value: 14 },
      { name: "FHA 30 Year Fixed", value: 6 },
      { name: "FHA 15 Year Fixed", value: 7 },
      { name: "Non-QM", value: 13 },
      { name: "USDA 30 Year Fixed", value: 12 },
      { name: "VA 30 Year Fixed", value: 8 },
      { name: "VA 15 Year Fixed", value: 9 },
      { name: "CALHFA Dream For All", value: 15 }
    ];
    let ProgramType: any = sortedProgramType.find(type => type.value == PreApproval.program)
    var file: File
    var isCustom = this.borrower.isCustom;
    defaultCordinates = JSON.parse(this.borrower.fieldCordinates);
    if (PreApproval && isCustom) {
      const result = await this.getFile(src)

      file = result;
      var validImageTypes = ["image/gif", "image/jpeg", , "image/png"];
      if (validImageTypes.includes(file.type)) {
        this.uploaded.emit(new RpaFileData({ file, removeRpaFile: this.removeRpaFile, rpaFileExists: true }));
        createImage(file)
          .pipe(
            tap(img => {
              canvasforImage.width = 816;
              canvasforImage.height = 1056;
              this.image = img;
              // scale = img.width.toString().length < 4 ? +(Number(img.width) * 0.01).toFixed(1) : +(Number(img.width) * 0.001).toFixed(1)
            }),
            tap(async () => {
              contextforImage.drawImage(this.image, 0, 0, 816, 1056);
              contextforImage.beginPath();
              let customFields = this.customFields$.getValue();
              const aspectRatio = {
                x: canvasforImage.width / this.image.width, y: canvasforImage.height / this.image.height
              }
              for (let i = 0; i < defaultCordinates.length; i++) {
                const element = defaultCordinates[i];
                let keys = Object.keys(element)
                let key = keys[0];
                let topLeft = new Point(element[key][0] * aspectRatio.x, element[key][1] * aspectRatio.y);
                let topRight = new Point(element[key][2] * aspectRatio.x, element[key][3] * aspectRatio.y);
                let bottomRight = new Point(element[key][4] * aspectRatio.x, element[key][5] * aspectRatio.y);
                let bottomLeft = new Point(element[key][6] * aspectRatio.x, element[key][7] * aspectRatio.y);
                let width = topRight.x - topLeft.x
                let height = bottomLeft.y - topLeft.y
                contextforImage.fillStyle = "#ffffff";
                contextforImage.fillRect(topLeft.x, topLeft.y, width, height)
              }
              for (let i = 0; i < defaultCordinates.length; i++) {
                const element = defaultCordinates[i];
                let keys = Object.keys(element)
                let key = keys[0];
                let topLeft = new Point(element[key][0] * aspectRatio.x, element[key][1] * aspectRatio.y);
                let topRight = new Point(element[key][2] * aspectRatio.x, element[key][3] * aspectRatio.y);
                let bottomRight = new Point(element[key][4] * aspectRatio.x, element[key][5] * aspectRatio.y);
                let bottomLeft = new Point(element[key][6] * aspectRatio.x, element[key][7] * aspectRatio.y);
                let width = topRight.x - topLeft.x
                let height = bottomLeft.y - topLeft.y
                let field = customFields.find(cf => cf.code.replace(/[<#%>_]/g, '').toLowerCase() == key.toLowerCase()) || {};
                if (!Object.keys(field).length && !field.isChecked) {
                  continue;
                }
                var fontSize: number = field.fontSize;
                var fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                var fontColor = field.fontColor ? field.fontColor : "#000"
                var fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                var fontColor = field.fontColor ? field.fontColor : "#000"
                contextforImage.fillStyle = fontColor;
                contextforImage.textBaseline = "top";
                let t = (bottomLeft.y - topLeft.y).toFixed(0);
                contextforImage.font = `${t}px ${fontFamily}`;
                if (key.toLowerCase() == "address") {
                  if (PreApproval["address"] && PreApproval["address"].length) {
                    contextforImage.fillText(PreApproval["address"], topLeft.x, topLeft.y);
                  }
                  else
                    contextforImage.fillText("TBD", topLeft.x, topLeft.y);
                  contextforImage.stroke();
                }
                else if (key.toLowerCase() == "downpayment" && PreApproval["downPayment"]) {
                  if (PreApproval["downPaymentType"] == 2) {
                    contextforImage.fillText(this.showCustomField(PreApproval["downPayment"], 'price'), topLeft.x, topLeft.y);
                  } else {
                    contextforImage.fillText(this.showCustomField(PreApproval["downPayment"], 'percentage'), topLeft.x, topLeft.y);
                  }
                  contextforImage.stroke();
                }
                else if (key.toLowerCase() == "fico" && PreApproval["fico"]) {
                  contextforImage.fillText(PreApproval["fico"], topLeft.x, topLeft.y);
                  contextforImage.stroke();
                }
                else if (key.toLowerCase() == "occupancy" && PreApproval["occupancy"]) {
                  contextforImage.fillText(Occupancy[PreApproval["occupancy"]], topLeft.x, topLeft.y);

                  contextforImage.stroke();

                }
                else if (key.toLowerCase() == "programtype" && PreApproval["program"]) {
                  contextforImage.fillText(ProgramType.name, topLeft.x, topLeft.y);
                  contextforImage.stroke();
                }
                else if (key.toLowerCase() == "propertytype" && PreApproval.propertyType) {
                  contextforImage.fillText(PropertyType[PreApproval.propertyType], topLeft.x, topLeft.y);
                  contextforImage.stroke();

                }
                else if (key.toLowerCase() == "purchaseprice") {
                  if (PreApproval.purchasePrice)
                    contextforImage.fillText(this.showCustomField(PreApproval["purchasePrice"], 'price'), topLeft.x, topLeft.y);
                  else {
                    contextforImage.fillText(this.showCustomField(PreApproval["maxPurchasePrice"], 'price'), topLeft.x, topLeft.y);
                  }
                  contextforImage.stroke();

                }
                else if (key.toLowerCase() == "borrower") {
                  contextforImage.fillText(this.borrower.firstName + " " + this.borrower.lastName, topLeft.x, topLeft.y);
                  contextforImage.stroke();

                }
                else if ((key.toLowerCase() == "coborrower" && this.borrower.coBorrower.firstName != null) || (key.toLowerCase() == "coborrower" && this.borrower.coBorrower.lastName != null)) {
                  contextforImage.fillText(this.borrower.coBorrower.firstName + " " + this.borrower.coBorrower.lastName, topLeft.x, topLeft.y);
                  contextforImage.stroke();

                }
                else if (field.customeFieldValue) {
                  contextforImage.fillText(this.showCustomField(field.customeFieldValue, field.inputType), topLeft.x, topLeft.y);
                  contextforImage.stroke();
                }
              }
              canvasforImage.toBlob((blob: Blob) => {
                let image = new File([blob], "default_PreApproval.png", { type: "image/png" })
                this.borrowersPreApprovalService.uploadAsset({ id: this.borrower.id, assetTypeId: 7, files: [image] }, this.borrower.id).subscribe(responseFromUploadAsset => {
                  this.updateBorrowerData(this.borrower.id);
                  this.getPreApprovalAssetsPdf(this.borrower.id, this.borrower.id).subscribe()
                })
              })
            }))
          .subscribe();
      } else {
        let totalPages;
        this.imageArray = [];
        const doc = await pdfjsLib.getDocument(src).promise
        let numpages = doc.numPages;
        totalPages = numpages
        for (let index = 1; index <= numpages; index++) {
          const page = await doc.getPage(index)
          let pageNumer = page.pageNumber;
          let divisionElm = document.createElement("div");
          let createCanvas = document.createElement("canvas");
          createCanvas.setAttribute("id", "canvas-mergefields-" + pageNumer);
          let body = document.getElementsByClassName("canvas-container")[0];
          body.appendChild(divisionElm);
          divisionElm.appendChild(createCanvas);
          let canvas = <HTMLCanvasElement>document.getElementById("canvas-mergefields-" + pageNumer);
          let context: any = canvas.getContext("2d");
          canvas.width = 816;
          canvas.height = 1056;
          var unscaledViewport = page.getViewport({ scale: 1 });
          scale = Math.min((canvas.height / unscaledViewport.height), (canvas.width / unscaledViewport.width));
          let viewPort = page.getViewport({ scale: scale });
          // let viewPort = page.getViewport({ scale: canvas.width / page.getViewport({ scale: 1 }).width });
          //canvas.height = viewPort.height;
          console.log("----viewPort---", viewPort)
          context.clearRect(0, 0, canvas.width, canvas.height);
          await page.render({
            canvasContext: context,
            viewport: viewPort,
          })

          let imageData = canvas.toDataURL("image/png")
          let image = await this.getFile(imageData);
          let res = await createImage(image).toPromise()
          context = canvas.getContext('2d') as CanvasRenderingContext2D;
          if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height)
            context.drawImage(res, 0, 0, canvas.width, canvas.height)
            context.beginPath()
            let viewPortHeightDiff = 72 * viewPort.scale;

            for (let index = 0; index < defaultCordinates.length; index++) {
              const element = defaultCordinates[index];
              let keys = Object.keys(element);
              let key = keys[0]
              let data = element[key].cordinates;
              let pageNo = element[key].pageNo
              if (data && pageNo == pageNumer) {
                let topLeft = new Point((data[0] * viewPortHeightDiff), (data[1] * viewPortHeightDiff));
                let topRight = new Point((data[2] * viewPortHeightDiff), (data[3] * viewPortHeightDiff));
                let bottomRight = new Point((data[4] * viewPortHeightDiff), (data[5] * viewPortHeightDiff));
                let bottomLeft = new Point((data[6] * viewPortHeightDiff), (data[7] * viewPortHeightDiff));
                let width = topRight.x - topLeft.x
                let height = bottomLeft.y - topLeft.y
                context.fillStyle = "#ffffff";
                context.fillRect(topLeft.x - 2, topLeft.y - 2, width + 3.25, height + 2)
              }
            }

            for (let index = 0; index < defaultCordinates.length; index++) {
              const element = defaultCordinates[index];
              let keys = Object.keys(element);
              let key = keys[0]
              let data = element[key].cordinates;
              let pageNo = element[key].pageNo
              if (data && pageNo == pageNumer) {
                let topLeft = new Point((data[0] * viewPortHeightDiff), (data[1] * viewPortHeightDiff));
                let topRight = new Point((data[2] * viewPortHeightDiff), (data[3] * viewPortHeightDiff));
                let bottomRight = new Point((data[4] * viewPortHeightDiff), (data[5] * viewPortHeightDiff));
                let bottomLeft = new Point((data[6] * viewPortHeightDiff), (data[7] * viewPortHeightDiff));
                let width = topRight.x - topLeft.x
                let height = bottomLeft.y - topLeft.y
                let newTopLeftY = topLeft.y;
                let customFields = this.customFields$.getValue();

                customFields.map(field => {
                  let fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                  let fontColor = field.fontColor ? field.fontColor : "black"
                  let fontSize: number = field.fontSize;
                  // let fontFamily = field.fontFamily ? field.fontFamily : "Verdana";
                  // let fontColor = field.fontColor ? field.fontColor : "black"
                  context.fillStyle = fontColor;
                  // context.textBaseline = "center";
                  context.textBaseline = 'top';
                  //context.textAlign = 'middle';
                  let t = ((bottomLeft.y - newTopLeftY)).toFixed(2);
                  context.font = `${t}px ${fontFamily}` // `${+t/1.333}pt ${fontFamily}`;

                  if ((field.code.replace(/[<%#_>]/g, '').toLowerCase() == key.toLowerCase()) || (key.toLowerCase() == "ltv" && field.code.replace(/[<%#_>]/g, '').toLowerCase() == "itv"))

                    if (field.customeFieldValue) {
                      // console.log("---context.font---",field.code, `${fontSize}`,bottomLeft.y - newTopLeftY,(fontSize * 1.33));
                      context.fillText(this.showCustomField(field.customeFieldValue, field.inputType), topLeft.x, newTopLeftY);
                    }
                    else {
                      // console.log("---context.font---",field.code, `${Math.ceil(fontSize*1.33)}`);

                      if (key.toLowerCase() == "address") {
                        if (PreApproval["address"] && PreApproval["address"].length) {
                          context.fillText(PreApproval["address"], topLeft.x, newTopLeftY);
                        }
                        else
                          context.fillText("TBD", topLeft.x, newTopLeftY);
                      }
                      if (key.toLowerCase() == "downpayment" && PreApproval["downPayment"]) {
                        if (PreApproval["downPaymentType"] == 2) {
                          context.fillText(this.showCustomField(PreApproval["downPayment"], 'price'), topLeft.x, newTopLeftY);
                        } else {
                          context.fillText(this.showCustomField(PreApproval["downPayment"], 'percentage'), topLeft.x, newTopLeftY);
                        }
                      }
                      if (key.toLowerCase() == "fico" && PreApproval["fico"]) {
                        context.fillText(PreApproval["fico"], topLeft.x, newTopLeftY);
                      }
                      if (key.toLowerCase() == "occupancy" && PreApproval["occupancy"]) {
                        context.fillText(Occupancy[PreApproval["occupancy"]], topLeft.x, newTopLeftY);
                      }
                      if (key.toLowerCase() == "programtype" && PreApproval["program"]) {
                        context.fillText(ProgramType.name, topLeft.x, newTopLeftY);
                      }
                      if (key.toLowerCase() == "propertytype" && PreApproval.propertyType) {
                        context.fillText(PropertyType[PreApproval.propertyType], topLeft.x, newTopLeftY);
                      }
                      if (key.toLowerCase() == "purchaseprice") {

                        if (PreApproval.purchasePrice)
                          context.fillText(this.showCustomField(PreApproval["purchasePrice"], 'price'), topLeft.x, newTopLeftY);
                        else {
                          context.fillText(this.showCustomField(PreApproval["maxPurchasePrice"], 'price'), topLeft.x, newTopLeftY);
                        }
                      }
                      if (key.toLowerCase() == "borrower") {
                        context.fillText(this.borrower.firstName + " " + this.borrower.lastName, topLeft.x, newTopLeftY);
                      }
                      if ((key.toLowerCase() == "coborrower" && this.borrower.coBorrower.firstName != null) || (key.toLowerCase() == "coborrower" && this.borrower.coBorrower.lastName != null)) {
                        context.fillText(this.borrower.coBorrower.firstName + " " + this.borrower.coBorrower.lastName, topLeft.x, newTopLeftY);
                      }
                    }
                })
              }
            }

            context.stroke();

            canvas.toBlob(async (blob: Blob) => {
              this.imageArray.push(new File([blob], index + ".png", { type: "image/png" }))
              //console.log("Image Array",this.imageArray);
              //console.log("Image[0]",this.imageArray[0].name.split('.').slice(0, -1).join('.'));
              let sortedArray = this.imageArray.sort((a, b) => (Number(a.name.split(".")[0]) < Number(b.name.split(".")[0]) ? -1 : 1));
              //console.log("Sorted Array", sortedArray);
              if (this.imageArray.length == numpages) {
                await this.borrowersPreApprovalService.uploadAsset({ id: this.borrower.id, assetTypeId: 7, files: sortedArray }, this.borrower.id).subscribe(responseFromUploadAsset => {
                  //this.updateBorrower(this.borrower.id).subscribe()
                  //this.getPreApprovalAssetsPdf(this.borrower.id, this.borrower.id).subscribe()
                })
              }
            })
          }
        }
      }


    }

    return true;
  }

  private async getFile(src: string) {
    let file;
    try {
      const res = await fetch(src)
      const resData = await res.blob()
      let data = resData.type.split("/")
      let metadata = {
        type: resData.type
      };
      file = new File([resData], "preapproval_letter." + data[1], metadata);
      return file;
    } catch (error) {
      console.log("Error while updating supporting doc title", error);
    }
  }


  showCustomField(value, inputType) {
    if (inputType == "percentage" && value) {
      return `${value}%`
    } if (inputType == "price" && value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    } else if (inputType == "date" && value) {
      return value
      // new Date().toLocaleDateString('en-us', {  year:"numeric", month:"short", day:"numeric"})
    } else if (inputType == "text" && value) {
      return value
    } else {
      return "N/A"
    }
  }

  loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    })
  }
}
