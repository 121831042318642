import { RectangleCustom } from './../rectangleCustomFields';
import { Subject, Observable } from 'rxjs';

import { Editor } from '../editor';
import { Point } from '../point';
import { Rectangle } from '../rectangle';
import {
  toReadablePropertyType,
  toReadableOccupancy,
  toReadableProgramType
} from "../../../../core";
import {CurrencyPipe} from '@angular/common'

import { EditorRendererOptions } from './editor-renderer-options';

/**
 * Base class of editor renderer.
 */
export abstract class EditorRenderer {

  /**
   * Reference to the editor.
   */
  protected abstract readonly editor: Editor;

  /**
   * Emits point on mouse move event.
   */
  protected readonly mouseMove$: Subject<Point>;

  /**
   * Emits point on mouse down event.
   */
  protected readonly mouseDown$: Subject<Point>;

  /**
   * This is to unsubscribe from subscriptions.
   */
  protected readonly destroy$: Subject<void>;

  /**
   * This is to undo.
   */
  protected readonly undo$: Subject<void>;

  /**
   * A reference to the main canvas element.
   */
  protected readonly canvas: HTMLCanvasElement;

  /**
   * A reference to context of the main canvas.
   */
  protected readonly context: CanvasRenderingContext2D;

  /**
   * Max width of the main canvas element.
   */
  protected readonly canvasMaxWidth: number;

  /**
   * A flag that becomes `true` when current renderer type is `None`.
   */
  protected disabled: boolean;

  /**
   * Current image's width to canvas' max width ratio.
   */
  protected diffX: number;

  /**
   * Current image's height to canvas' height ratio.
   */
  protected diffY: number;

  /**
   * Start point of rectangle that will be rendered.
   */
  protected startPoint: Point;

  /**
   * End point of rectangle that will be rendered.
   */
  protected endPoint: Point;

  /**
   * `true` when user draws selection.
   */
  protected isSelectingInProgress: boolean;

  constructor(options: EditorRendererOptions) {
    this.mouseMove$ = new Subject();
    this.mouseDown$ = new Subject();
    this.destroy$ = new Subject();
    this.undo$ = new Subject();
    this.diffX = 1;
    this.diffY = 1;
    this.disabled = false;
    this.isSelectingInProgress = false;
    this.canvas = options.canvas;
    this.context = options.context;
    this.canvasMaxWidth = options.canvasMaxWidth;
  }

  /**
   * Renders specified rectangle with specified canvas context.
   * @param context Canvas context.
   * @param rect Rectangle.
   */
  protected renderRect(context: CanvasRenderingContext2D, rect: Rectangle): void {
    context.beginPath();
    context.rect(rect.x, rect.y, rect.width, rect.height);
    context.fillStyle = rect.style;
    context.strokeStyle = rect.style;
    context.globalCompositeOperation = "multiply";
    context.fill();
    context.stroke();
  }

  /**
   * Renders specified rectangle with specified canvas context.
   * @param context Canvas context.
   * @param rect Rectangle.
   */
   protected renderRectImg(context: CanvasRenderingContext2D, rect: RectangleCustom, customParams: any): void {
    context.beginPath();
    var htmlStringTitle:String[] = [];
    var htmlStringValue:String[] = [];
    var fontSize:number = customParams.fontSize ? Number(customParams.fontSize) : 16;
    var lineSpacing:number = customParams.lineSpacing ? Number(customParams.lineSpacing) : 16;
    var fontFamily =  customParams.fontStyle ?  customParams.fontStyle : "Verdana";
    var fontColor=customParams.textColor ?  customParams.textColor:"#00000"
    context.fillStyle = `${fontColor}`;
    context.font = `${fontSize}px ${fontFamily}`;
    if(customParams.preApproval.purchasePrice ){
      // htmlStringTitle.push(`Purchase Price:`);
      htmlStringValue.push(`${customParams.preApproval.purchasePrice}`); 
    }
    if(customParams.preApproval.address ){
      // htmlStringTitle.push(`Address:`);
      htmlStringValue.push(`${customParams.preApproval.address}`); 
    }
    if(customParams.preApproval.downPayment ){
      // htmlStringTitle.push(`Downpayment:`);
      htmlStringValue.push(`${customParams.preApproval.downPayment}`); 
    }
    if(customParams.preApproval.propertyType ){
      // htmlStringTitle.push(`Property Type:`);
      htmlStringValue.push(`${customParams.preApproval.propertyType}`); 
    }
    if(customParams.preApproval.occupancy ){
      // htmlStringTitle.push(`Occupancy:`);
      htmlStringValue.push(`${customParams.preApproval.occupancy}`); 
    }
    if(customParams.preApproval.fico ){
      // htmlStringTitle.push(`FICO:`);
      htmlStringValue.push(`${customParams.preApproval.fico}`); 
    }
    if(customParams.preApproval.program ){
      // htmlStringTitle.push(`Program Type:`);
      htmlStringValue.push(`${customParams.preApproval.program}`); 
    }
    htmlStringTitle.forEach((aElement:string, i:number) => {
      context.fillText(aElement, rect.x, rect.y + i * (fontSize + lineSpacing));
    });
    htmlStringValue.forEach((aElement:string, i:number) => {
      context.fillText(aElement, rect.x, rect.y + i * (fontSize + lineSpacing));
    });
    context.stroke();
    context.fillStyle = "#00000";
  }

  /**
   * Creates new file from canvas' data.
   */
  public convertToFile(): Observable<File> {
    return new Observable<File>(observer => {
      const options = { type: this.fileType };
      this.canvas.toBlob((blob: Blob) => {
        observer.next(new File([blob], this.fileName, options));
        observer.complete();
      });
    });
  }

  /**
   * Name of file that will be created from canvas.
   * Name of the source file is used by default.
   */
  protected get fileName(): string {
    return this.editor.file.name;
  }

  /**
   * Type of file that will be created from canvas.
   * Type of the source file is used by default.
   */
  protected get fileType(): string {
    return this.editor.file.type;
  }

  /**
   * Renders selection area with specified context.
   * @param context Canvas context.
   */
  protected drawSelection(context: CanvasRenderingContext2D, strokeColor: string = "black"): void {
    const rect = new Rectangle(this.startPoint, this.endPoint);
    context.beginPath();
    context.rect(rect.x, rect.y, rect.width, rect.height);
    context.strokeStyle = strokeColor;
    context.lineWidth = 2;
    context.stroke();
  }

  /**
   * Creates new point and triggers `mouseMove$` stream with that point.
   * @param x X coordinate.
   * @param y Y coordinate.
   */
  public onMouseMove(x: number, y: number): void {
    if (!this.disabled) {
      this.mouseMove$.next(new Point(x * this.diffX, y * this.diffY));
    }
  }

  /**
   * Sets `isSelectingInProgress` to `true` to make it possible to select area and draw a rectangle.
   * Also creates new point and sets that point to `startPoint` property.
   * @param x X coordinate.
   * @param y Y coordinate.
   */
  public onMouseDown(x: number, y: number): void {
    if (!this.disabled) {
      this.isSelectingInProgress = true;
      this.preCalculateDifference();
      this.startPoint = new Point(x * this.diffX, y * this.diffY);
      this.mouseDown$.next(this.startPoint);
    }
  }

  
  /**
   * Same Function as calculateDifference in image-retangle-renderer.ts
   * TODO: use a common function instead of duplicate
   */
  public preCalculateDifference(): void {
    if (this.canvas.width > this.canvasMaxWidth) {
      this.diffX = Number((this.canvas.width / this.canvasMaxWidth).toFixed(2));
      const realHeight = (this.canvasMaxWidth * this.canvas.height) / this.canvas.width;
      this.diffY = Number((this.canvas.height / realHeight).toFixed(2));
      this.diffX = this.diffX < 1 ? 1 : this.diffX;
      this.diffY = this.diffY < 1 ? 1 : this.diffY;
    }
  }

  /**
   * To unsubscribe from all subscriptions.
   */
  public destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Triggers `undo$` stream.
   */
  public undo(): void {
    this.undo$.next();
  }

  /**
   * Disables current renderer.
   */
  public abstract disable(): void;

  /**
   * On mouse up.
   */
  public abstract onMouseUp(x: number, y: number): void;

}
