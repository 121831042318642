import { Component, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'arb-url-invite',
  templateUrl: './url-invite.component.html',
  styleUrls: ['./url-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlInviteComponent {

  /**
   * Ref to input contains url.
   */
  @ViewChild('url')
  public inputEl: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) { }

  /**
   * Copies package url to clipboard.
   */
  public onCopy(): void {
    this.inputEl.nativeElement.select();
    document.execCommand('copy');
  }

}
