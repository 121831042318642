import { Full } from '../../helpers/full';

/**
 * Update loan officer dto.
 */
export class UpdateLoanOfficerDto {

  /**
   * Company name.
   */
  public companyName: string;

  /**
   * First name.
   */
  public firstName: string;

  /**
   * Last name.
   */
  public lastName: string;

  /**
   * Email.
   */
  public email: string;

  /**
   * Phone number.
   */
  public phoneNumber: string;

  /**
   * Profile picture.
   */
  public profilePicture: File | null;

  /**
   * Company logo.
   */
  public companyLogo: File | null;

  /**
   * Pre Approval Letter
   */
  public DefaultPreApproval: File  | null;

    /**
   * 'true' is Pre Approval Letter is removed
   */
  public  preApprovalRemoved:boolean;
  /**
   * Signature.
   */
  public signature: File | null;

  /**
   * `true` if profile picture is removed.
   */
  public profilePictureRemoved: boolean;

  /**
   * `true` if company logo is removed.
   */
  public companyLogoRemoved: boolean;

  /**
   * `true` if signature is removed.
   */
  public signatureRemoved: boolean;

    /**
   * Title.
   */
  public title?: string;

  /**
   * nmlsId.
   */
   public nmlsId?: string;

  public footerColor: string;
  
  public headerColor: string;

  public fieldCordinates:Object;

  public isCustom:Boolean;
  
  public timeZone: string;

  constructor(data: Full<UpdateLoanOfficerDto>) {
    this.companyName = data.companyName;
    this.footerColor = data.footerColor.replace('#', '');
    this.headerColor = data.headerColor.replace('#', '');
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.title = data.title;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.profilePicture = data.profilePicture;
    this.companyLogo = data.companyLogo;
    this.signature = data.signature;
    this.profilePictureRemoved = data.profilePictureRemoved;
    this.companyLogoRemoved = data.companyLogoRemoved;
    this.signatureRemoved = data.signatureRemoved;
    this.nmlsId = data.nmlsId
    this.isCustom=data.isCustom
    this.fieldCordinates=data.fieldCordinates
    // pre approval letter
    this.DefaultPreApproval = data.DefaultPreApproval;
  
    this.preApprovalRemoved = data.preApprovalRemoved;
    this.timeZone = data.timeZone
  }

}
