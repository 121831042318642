import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestHeader } from '../../core/models/request-header';
import { getFileNameFromUrl } from '../helpers/get-file-name-from-url';

/**
 * Service to get blob file.
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Get image file by url.
   * @param url
   */
  public getImageFileThumbnail(url: string): Observable<File | null> {
    const headers = new HttpHeaders()
      .set(RequestHeader.NoAuthorization, '')
      .set('content-type', 'image/jpeg');
      
    return this.httpClient.get(`${url}?lastmodified=${new Date()}`, { headers: headers, observe: 'response', responseType: 'blob' }).pipe(
      map(response => {
        if (response.body) {
          const contentTypeHeaders = response.headers.get('content-type') || 'image/jpeg';
          return  new File([response.body], getFileNameFromUrl(url), { type: contentTypeHeaders });
        }
        return null;
      }),
    );
  }
}
