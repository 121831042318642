import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, take, map, mapTo, tap, switchMap } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';

import { CurrentUser } from '../models/current-user';
import { Permission } from '../models/permission';
import { User } from '../models/user';
import { UserRole } from '../models/user-role';

import { AdminService } from './admin.service';
import { LoanOfficerService } from './loan-officer.service';
import { LocalStorageService } from './local-storage.service';
import { RealtorsService } from './realtors.service';
import { RolesService } from './roles.service';

interface UserGeneralInfo {
  id: number;
  role: UserRole;
  permissions: Permission[];
}

/**
 * Get current user info.
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {

  private readonly user$ = new BehaviorSubject<CurrentUser | null>(null);

  constructor(
    private localStorageService: LocalStorageService,
    private rolesService: RolesService,
    private adminService: AdminService,
    private realtorsService: RealtorsService,
    private loanOfficersService: LoanOfficerService,
    private config: AppConfig,
    private http: HttpClient,
  ) { }

  public role: UserRole;

  /**
   * Curret user stream.
   */
  public get currentUser$(): Observable<CurrentUser> {
    return this.user$
      .pipe(
        filter(user => !!user),
      ) as Observable<CurrentUser>;
  }

  /**
   * Set user.
   * @param user User.
   */
  public setUser(): Observable<null> {
    return this.localStorageService
      .getAccessToken()
      .pipe(
        take(1),
        filter(token => !!token),
        map((token: string) => this.getGeneralInfo(token)),
        tap(({ role }) => this.rolesService.addRole(role)),
        tap(role => this.role = role.role),
        switchMap(({ role, permissions }) => this.getProfile(role)
          .pipe(
            map(user => new CurrentUser({
              firstName: user.firstName,
              lastName: user.lastName,
              id: user.id,
              role,
              permissions,
              email: user.email,
              phoneNumber: user.phoneNumber,
              updatedAt: user.updatedAt,
              profileImageUrl: user.profileImageUrl,
              emailSignature: user.emailSignature,
              userType: user.userType
            })),
          ),
        ),
        tap(user => this.user$.next(user)),
        mapTo(null),
      );
  }

  private getGeneralInfo(token: string): UserGeneralInfo {
    const decoded = jwt_decode(token);
    return {
      role: decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
      permissions: decoded.Permission,
      id: Number(decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']),
    };
  }

  private getProfile(role: UserRole): Observable<User> {
    if (role === UserRole.Admin) {
      return this.adminService.getProfile();
    }
    if (role === UserRole.LoanOfficer) {
      return this.loanOfficersService.getProfile();
    }
    if (role === UserRole.Realtor) {
      return this.realtorsService.getProfile();
    }
    throw new Error(`Role ${role} is invalid`);
  }

  /**
   * Current user's role.
   */
  public get role$(): Observable<UserRole> {
    return this.currentUser$
      .pipe(
        map(({ role }) => role),
      );
  }

  public getUserType(): Observable<any> {
    if(this.role == UserRole.LoanOfficer) {
      const url = `${this.config.apiUrl}/api/loan-officers/my-profile`;
      return this.http.get(url);
    } else if(this.role == UserRole.Realtor) {
      const url = `${this.config.apiUrl}/api/realtors/my-profile`;
      return this.http.get(url);
    } else {
      const url = `${this.config.apiUrl}/api/admins/my-profile`;
      return this.http.get(url);
    }
  }
}
