import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, mapTo } from 'rxjs/operators';

import { AuthTokenInfo } from '../models/auth-token-info';

const AUTH_TOKENS = 'authTokens';

/**
 * Local storage service.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Set tokens in localStorage.
   * @param data Auth tokens.
   */
  public setTokens(data: AuthTokenInfo): Observable<null> {
    return this.setItem(AUTH_TOKENS, data);
  }

  private setItem<T>(key: string, item: T): Observable<null> {
    return of(item)
      .pipe(
        map(value => JSON.stringify(value)),
        tap(value => localStorage.setItem(key, value)),
        mapTo(null),
      );
  }

  /**
   * Get all tokens.
   */
  public getTokens(): Observable<AuthTokenInfo | null> {
    return this.getItem<AuthTokenInfo>(AUTH_TOKENS);
  }

  /**
   * Removes the auth tokens from localStorage.
   */
  public removeTokens(): Observable<null> {
    return this.removeItem(AUTH_TOKENS);
  }

  /**
   * Removes the item with the specified key.
   * @param key Key of the value to remove.
   */
  public removeItem(key: string): Observable<null> {
    return of(null)
      .pipe(
        tap(() => localStorage.removeItem(key)),
      );
  }

  /**
   * Get access token.
   */
  public getAccessToken(): Observable<string | null> {
    return this.getItem<AuthTokenInfo>(AUTH_TOKENS)
      .pipe(
        map((data: AuthTokenInfo) => {
          if (data) {
            return data.accessToken;
          }
          return null;
        }),
      );
  }

  /**
   * Get refresh token.
   */
  public getRefreshToken(): Observable<string | null> {
    return this.getItem<AuthTokenInfo>(AUTH_TOKENS)
      .pipe(
        map((data: AuthTokenInfo) => {
          if (data) {
            return data.refreshToken;
          }
          return null;
        }),
      );
  }

  private getItem<T>(key: string): Observable<T | null> {
    return of(key)
      .pipe(
        map(value => localStorage.getItem(value)),
        map(value => value ? JSON.parse(value) as T : null),
      );
  }

}
