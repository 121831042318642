/**
 * User status.
 */
export enum SupportTicketStatus {

  /**
   * Invited.
   */
  Pending = "PENDING",

  /**
   * Processing.
   */
  Processing = "PROCESSING",

  /**
   * Completed.
   */
  Completed = "COMPLETED",

}

const statuses: Record<SupportTicketStatus, string> = {
  [SupportTicketStatus.Processing]: 'Processing',
  [SupportTicketStatus.Completed]: 'Completed',
  [SupportTicketStatus.Pending]: 'Pending',
};

/**
 * Returns readable user status.
 * @param status Not readable status.
 */
export function toReadableTicketStatus(status: SupportTicketStatus): string {
  return statuses[status];
}
