/**
 * Status color.
 */
export enum StatusColor {

  /**
   * Red.
   */
  Red,

  /**
   * Gray.
   */
  Gray,

  /**
   * Blue.
   */
  Blue,

  /**
   * Green.
   */
  Green,

  /**
   * Yellow.
   */
  Yellow,

}
