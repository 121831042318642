/**
 * User status.
 */
export enum UserStatus {

  /**
   * Invited.
   */
  Invited = 1,

  /**
   * Active.
   */
  Active,

  /**
   * Deactivated.
   */
  Deactivated,

}

const statuses: Record<UserStatus, string> = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Deactivated]: 'Deactivated',
  [UserStatus.Invited]: 'Invited',
};

/**
 * Returns readable user status.
 * @param status Not readable status.
 */
export function toReadableUserStatus(status: UserStatus): string {
  return statuses[status];
}
