import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { OfferStatus } from '../../../../core';
import { StatusColor } from '../../../models/status-color';

/**
 * Offer status.
 */
@Component({
  selector: 'arb-offer-status',
  templateUrl: './offer-status.component.html',
  styleUrls: ['./offer-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferStatusComponent {

  private colors = this.createColors();

  /**
   * Status text value.
   */
  public statusValue: string;

  /**
   * Status color.
   */
  public color: StatusColor;

  /**
   * Status.
   */
  @Input()
  public set status(value: OfferStatus) {
    this.statusValue = OfferStatus[value];
    this.color = this.colors[value];
  }

  private createColors(): Record<OfferStatus, StatusColor> {
    return {
      [OfferStatus.Accepted]: StatusColor.Green,
      [OfferStatus.Draft]: StatusColor.Yellow,
      [OfferStatus.Rejected]: StatusColor.Red,
      [OfferStatus.Submitted]: StatusColor.Blue,
    };
  }

  /**
   * Is color green.
   */
  public get green(): boolean {
    return this.color === StatusColor.Green;
  }

  /**
   * Is color yellow.
   */
  public get yellow(): boolean {
    return this.color === StatusColor.Yellow;
  }

  /**
   * Is color blue.
   */
  public get blue(): boolean {
    return this.color === StatusColor.Blue;
  }

  /**
   * Is color red.
   */
  public get red(): boolean {
    return this.color === StatusColor.Red;
  }

}
