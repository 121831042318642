import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, Injectable, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserPaymentTableColumn } from "../../../models/User-Payment-table-column";
//import { MatDialog } from '@angular/material';
import { OrderService } from 'src/app/core/services/order.service';
import { BehaviorSubject, combineLatest, concat, Observable, Subject } from 'rxjs';
import { ConfirmDialogData, MatButtonColor} from '../../../../shared';
import { DialogService } from 'src/app/shared/services/dialog.service';
// import { ConfirmDialogData, MatButtonColor, DialogService} from '../../../../shared';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ErrorMessage, Message, SuccessMessage } from 'src/app/core';
import { FormControl } from '@angular/forms';
import { UserPaymentTableSecondColumn } from 'src/app/shared/models/User-Payment-table-second-column';
//import{ PaginationMetadata, UserStatus,DestroyableBase,listenControlChanges,UpdateUserStatusDto,Table} from "../../../../core";

import {
  SortConfig,
  SortDirection,
  UserStatus,
  listenControlChanges,
  DestroyableBase,
} from '../../../../core';
// import { UserpaymentSearchParams } from 'src/app/core/models/userPayment-search-params';
export interface PeriodicElement {
  name: string;
  started: Date;
  ended: Date;
  Canclesubs: string;
}


const COLUMNS: UserPaymentTableColumn[] = [
  "name",
  "productName",
  "started",
  "ended",
  "Canclesubs"
];
const SECOND_HEADER_COLUMNS: UserPaymentTableSecondColumn[] = [
  // 'search_by_name',
  'empty',
  'empty',
  'empty',
  // 'select_status',
  
];
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'arb-User-Payment-table',
  styleUrls: ['User-Payment-table.component.scss'],
  templateUrl: 'User-Payment-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UserPaymentTableComponent extends DestroyableBase implements OnInit  {


 /**
   * Form control for realtor name input.
   */
  public readonly nameControl = new FormControl();

 /**
   * Second header columns.
   */



  /**
   * Value for all statuses.
   * If it is `null` then mat-select doesn't set it as default value.
   */
   public readonly allStatuses = 0;

   public tableIsEmpty = false;

  /**
   * Form control for realtor status select.
   */
  public readonly UserPaymentStatusControl = new FormControl(this.allStatuses); 

    /**
   * All user statuses.
   */
     public readonly statuses = UserStatus;

    
  /**
   * Emits new params for search request.
   */
  // @Output()
  // public searchChange = new EventEmitter<UserpaymentSearchParams>();



  @Input()
  public secondHeaderColumns = SECOND_HEADER_COLUMNS;

  private startingAfter:string = ""
  private limit = 10;  
  public displayedColumn: string[] = COLUMNS;
  public readonly loading$ = new BehaviorSubject(false);
  private readonly pageChange$ = new BehaviorSubject(1);
  private sortConfigValue = new SortConfig<UserPaymentTableColumn>('name', SortDirection.Asc);
  private readonly sortChange$ = new BehaviorSubject(SortConfig.fromConfig(this.sortConfigValue));


  dataSource = new MatTableDataSource(ELEMENT_DATA);
  tablerow: PeriodicElement[] = [];
  public readonly message$ = new Subject<Message | null>();
  currentUser:any;

  constructor( 
   // public dialog: MatDialog, 
    public orderService: OrderService,
    @Inject(DialogService) 
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    // private realtorsService:RealtorsService,
    ) {
    super()
  }
  

  search: string = ""
  searchThis(params) {
    this.search = params.name
    this.LoadSubscription()
  }
  loadmore() {
    this.LoadSubscription()
  }

  public ngOnInit() {
    this.listenSearchParamsChanges();
    this.LoadSubscription(); 
  }
  
  LoadSubscription() {
    this.loading$.next(true);
    this.orderService.LoadSubscription(this.startingAfter,this.limit).subscribe((response: any) => {      
      // this.dataSource.data.concat(response.data)
      let temp:any[] = []
      for (let index = 0; index < this.dataSource.filteredData.length; index++) {
        const element = this.dataSource.filteredData[index];
        temp.push(element)        
      }
      let temp2 = new MatTableDataSource(response.data);
      for (let index = 0; index < temp2.filteredData.length; index++) {
        const element = temp2.filteredData[index];
        temp.push(element)        
      }

      this.dataSource = new MatTableDataSource(temp);
      this.startingAfter = response.data[9].id
      
      
      this.cdr.detectChanges();
      this.loading$.next(false);
      // console.log(response.data);
     

    })
  }
 
// here code for search 
private listenSearchParamsChanges(): void {
  const search$ = this.createSearchStream();
  
  combineLatest(
    search$,
    this.pageChange$,
    
  )
    .pipe(
      map(([[name, sortChange], page]) => ({ name, sortChange })),
      // tap(params => this.searchChange.emit(params)),
      tap(params => this.searchThis(params)),
      takeUntil(this.destroy$),

    )
    .subscribe();
}


private createSearchStream(): Observable<[string, string]> {
  
  return combineLatest(
    listenControlChanges<string>(this.nameControl),
    // listenControlChanges<UserStatus>(this.realtorStatusControl),
    // listenControlChanges<UserStatus>(this.UserPaymentStatusControl),
    this.sortChange$
  )
    .pipe(
      tap(() => this.pageChange$.next(1)),
    );
}


// Here code for select

  public async  CancelSubscription(element)
  {
    
    const data = this.cancelConfirmDialogData();
    const itemDeleted = await this.dialogService.openConfirmDialog(data).toPromise();
    if(itemDeleted) {
      
      this.orderService.cancelSubscription(element.id,element.customer.id,element.customer.email).toPromise().then(
        (res) => {
          this.message$.next(new SuccessMessage('Subscription Cancelled'));
          setTimeout(() => 3000); 
          this.LoadSubscription();
          this.cdr.markForCheck();
        },
        (err) => {
          this.message$.next(new ErrorMessage('Error: Oops, something went wrong'));
          setTimeout(() => 3000); 
        }
      )
    }

  }


  @ViewChild(MatSort) sort: MatSort;

  

  private cancelConfirmDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: "Cancel Subscription?",
      subtitle:
        "Are you sure you want to cancel the subscription",
      acceptButtonColor: MatButtonColor.Warn,
      acceptButtonText: "Yes",
      rejectButtonText: "No",
    });
  }

  public getSubscriptionStatusName(element){
    return (element.status != "active" && element.status != "trialing") ? element.status : 'Cancel';
  }
}