import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConfirmDialogData } from '../../../models/confirm-dialog-data';
@Component({
  selector: 'arb-submit-offer-invite',
  templateUrl: './submit-offer-invite.component.html',
  styleUrls: ['./submit-offer-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitOfferInviteComponent implements OnInit {

  constructor(  @Inject(MAT_DIALOG_DATA)
  public data: any,
  private dialogRef: MatDialogRef<SubmitOfferInviteComponent>,) { }

  ngOnInit() {
  }
/**
   * Close dialog.
   * @param value Value that will be returned after closing.
   */
  public close(value: string): void {
    this.dialogRef.close(value);
  }
}
