import { Full } from '../../helpers/full';

/**
 * Asset part.
 */
export class AssetPart {

  /**
   * Id.
   * This is necessary to add `trackBy` function.
   */
  public id: number;

  /**
   * File name.
   */
  public name: string;

  /**
   * File url.
   */
  public url: string;

  constructor(data: Full<AssetPart>) {
    this.name = data.name;
    this.url = data.url;
  }

}
