/**
 * Asset type.
 */
export enum AssetType {

  /**
   * Proof of founds.
   */
  ProofOfFounds = 1,

  /**
   * Credit report scores.
   */
  CreditReportScores,

  /**
   * DULP approval.
   */
  DulpApproval,

  /**
   * Cover Letter.
   */
  CoverLetter,
  
  /**
   * Supporting document 1.
   */
  SupportingDocument1,

  
  /**
   * Pre approval letter.
  */
 PreApprovalLetter,
 
 /**
  * Custom Pre approval letter.
 */
  PreApprovalCustom,
  
  /**
   * Supporting document 2.
   */
  SupportingDocument2 = 9,

}
