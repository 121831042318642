import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { InfoDialogData } from '../../../models/info-dialog-data';

/**
 * Only shows some info.
 */
@Component({
  selector: 'arb-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: InfoDialogData,
  ) { }

}
