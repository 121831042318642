import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap, first } from 'rxjs/operators';

import { catchHttpErrorMessage, DestroyableBase, ErrorMessage, ToolboxNewItem, ToolboxService } from '../../../../core';

/**
 * Document editor dialog component.
 */
@Component({
  selector: 'arb-toolbox-item-add-dialog',
  templateUrl: './toolbox-item-add-dialog.component.html',
  styleUrls: ['./toolbox-item-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolboxItemAddDialogComponent extends DestroyableBase implements OnInit {

  private readonly submit$ = new Subject<void>();

  /**
   * List of the categories.
   */
  public readonly categories$ = this.toolboxService.categories$;

  /**
   * Add item form.
   */
  public readonly addItemForm = this.createAddItemForm();

  /**
   * Error message stream.
   */
  public readonly error$ = new Subject<ErrorMessage | null>();

  /**
   * .ctor
   * @param toolboxService Toolbox business logic.
   * @param formBuilder Form constructor.
   * @param matDialog Dialog logic.
   */
  constructor(
    private toolboxService: ToolboxService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialogRef<ToolboxItemAddDialogComponent>,
  ) {
    super();
  }

  /**
   * Callback after binding the inputs.
   */
  public ngOnInit(): void {
    this.subscribeToSubmit();
    this.setFirstCategoryAsDefault();
  }

  private createAddItemForm(): FormGroup {
    return this.formBuilder.group({
      category: [false, Validators.required],
      text: ['', Validators.required],
    });
  }

  private subscribeToSubmit(): void {
    this.submit$
      .pipe(
        filter(() => this.addItemForm.valid),
        map(() => this.addItemForm.value),
        map(({ category, text }) => new ToolboxNewItem({ category, text })),
        switchMap(data =>
          this.toolboxService.addItem(data)
            .pipe(
              tap(() => this.close(true)),
              catchHttpErrorMessage({ message$: this.error$ }),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setFirstCategoryAsDefault(): void {
    this.categories$
      .pipe(
        first(),
        filter(categories => !!categories && !!categories[0]),
        tap(([ firstCategory ]) => this.categoryControl.setValue(firstCategory)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private get categoryControl(): AbstractControl {
    const control = this.addItemForm.get('category');
    if (control) {
      return control;
    }
    throw new Error('Category control is not defined');
  }

  /**
   * Closes the dialog form with the specified result.
   * @param result Whether an item has been added.
   */
  public close(result: boolean): void {
    this.matDialog.close(result);
  }

  /**
   * Submits the form.
   */
  public submit(): void {
    this.submit$.next();
  }

}
