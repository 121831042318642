import { Offer, Borrower, Full } from '../../../../core';

/**
 * Offer details.
 */
export class OfferDetails {

  /**
   * Offer.
   */
  public offer: Offer;

  /**
   * Borrower.
   */
  public borrower: Borrower;

  constructor(data: Full<OfferDetails>) {
    this.offer = data.offer;
    this.borrower = data.borrower;
  }

}
