/**
 * Info that required for authorization.
 */
export class AuthInfo {

  /**
   * Email.
   */
  public email: string;

  /**
   * Password.
   */
  public password: string;

  constructor(email: string, password: string) {
    this.email = email.trim();
    this.password = password;
  }

}
