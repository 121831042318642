import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';



@Component({
  selector: 'arb-User-Payment-info-table',
  styleUrls: ['User-Payment-Info-table.component.scss'],
  templateUrl: 'User-Payment-Info-table.component.html',
})
export class UserPaymentInfoTableComponent implements AfterViewInit {
 

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
   
  }
  

 

  }
  





