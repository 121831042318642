/**
 * Down payment type.
 */
export enum DownPaymentType {

  /**
   * Percent.
   */
  Percent = 1,

  /**
   * Fixed amount.
   */
  FixedAmount = 2,

}
