import { PreApprovalStatus } from './../../../../core/models/pre-approval-status';
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NEVER, Observable } from 'rxjs';
import { map, switchMap, tap, first } from 'rxjs/operators';
import { getDocument } from "pdfjs-dist";

import {
  Borrower,
  BorrowerService,
  DownPaymentType,
  Offer,
  toReadableOccupancy,
  toReadableProgramType,
  toReadablePropertyType,
  RolesService, RealtorsService
} from '../../../../core';

import { LoanOfficerProfileResolver } from './../../../../loan-officers/services/loan-officer-profile.resolver';

import {
  BehaviorSubject,
} from "rxjs";
import { resolve } from 'url';


const DEFAULT_COLOR = "ef7824";

@Component({
  selector: 'arb-loanofficer-custom-letter-preview',
  templateUrl: './loanofficer-custom-letter-preview.component.html',
  styleUrls: ['./loanofficer-custom-letter-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [LoanOfficerProfileResolver],
})
export class LoanofficerCustomLetterPreviewComponent implements OnInit {
  public realtor$ = new BehaviorSubject({});
  public fontSize = "9pt";

  public loanOfficer$: any;



  constructor(
    private activatedRoute: ActivatedRoute,
    private rolesService: RolesService,
    private realtorsService: RealtorsService
  ) { }

  public ngOnInit(): void {
    this.initLoanOfficer();
    // this.getRealtor();
  }

  private initLoanOfficer(): void {
    var that = this;
    that.activatedRoute.data
      .pipe(
        tap(({ loanOfficer }) => {
         
        
      }),
      ).subscribe(async ({loanOfficer})=>{
        console.log("loan officer", loanOfficer)
        let assetArray: string[] = [];
        let data = {}
        if (loanOfficer.DefaultPreApproval && loanOfficer.DefaultPreApproval.split(".").pop() == "pdf") {
          assetArray = await this.getImageArray(loanOfficer.DefaultPreApproval)
        } else if(loanOfficer.DefaultPreApproval) {
          assetArray = [loanOfficer.DefaultPreApproval]
        }
        data = {
          headerColor: loanOfficer.headerColor ? `#${loanOfficer.headerColor}` : `#${DEFAULT_COLOR}`,
          footerColor: loanOfficer.footerColor ? `#${loanOfficer.footerColor}` : `#${DEFAULT_COLOR}`,
          title: loanOfficer.title,
          email: loanOfficer.email,
          officeAddress: '',
          firstName: loanOfficer.firstName,
          lastName: loanOfficer.lastName,
          companyName: loanOfficer.companyName,
          companyLogoUrl: loanOfficer.companyLogoUrl,
          website: '',
          phoneNumber: loanOfficer.phoneNumber,
          assetArray: assetArray
        }
        this.setFontSize(data);
        this.loanOfficer$ = data;
      })

  }


  private getImageArray(url): any {

    return new Promise(async(resolve, reject) => {

      var loadingTask = getDocument(url);
      await loadingTask.promise.then(async function (pdf) {

        var totalPages = pdf.numPages
        var data: string[] = [];

        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          let canvasdiv = document.createElement("canvas");
          canvasdiv.setAttribute("id", "canvas" + pageNumber);
         await pdf.getPage(pageNumber).then( async function (page) {

            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });


            // Prepare canvas using PDF page dimensions
            var context = canvasdiv.getContext('2d');

            canvasdiv.height = viewport.height;
            canvasdiv.width = viewport.width;

            // Render PDF page into canvas context
            if (!context) return
            var renderTask = page.render({ canvasContext: context, viewport: viewport });
           await renderTask.promise.then(function () {
              let t = canvasdiv.toDataURL('image/png')
              data.push(t)
            });
          });
        }
        resolve(data);
      }, function (reason) {
        // PDF loading error
        console.error(reason);
        resolve([]);

      });
    })
  }


  private setFontSize(data) {
    const size =
      data.firstName.length + data.lastName.length + data.companyName.length;
    this.fontSize = size < 50 ? "9pt" : size < 70 ? "7.5pt" : "7pt";
  }
  private getRealtorById(realtorId): any {
    return this.realtorsService.getById(realtorId).pipe(
      tap((realtor) => {
        realtor.headerColor = `#${realtor.headerColor || DEFAULT_COLOR}`;
        realtor.footerColor = `#${realtor.footerColor || DEFAULT_COLOR}`;
      })
    );
  }

}
