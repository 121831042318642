import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { take, tap } from "rxjs/operators";
declare const findValues: any;

/**
 * Azure Ocr service.
 */
@Injectable({
    providedIn: 'root',
  })
export class AzureOcrService { 
    private readonly subscriptionKey = "585281962ba14c2083225a25ca50c91e";
    private readonly endpoint = "https://bop-ocr.cognitiveservices.azure.com/";
    constructor(private http: HttpClient) {
    }
    public convertTextToImage(data:string)
    {
        let imageData = this.makeblob(data.replace("data:image/jpeg;base64,",""),'image/jpeg', null);
        let dataFile = new File([imageData], "data", {type: imageData.type}); 
        return dataFile;
    }
    public extractTextfromImage(data: string) {
        // let url = this.endpoint + "vision/v2.1/ocr?language=unk&detectOrientation=true";
        let url = this.endpoint + "vision/v2.1/ocr?language=unk&detectOrientation=true";
        let headers = new HttpHeaders({
                "Content-Type": "application/octet-stream",
                "Ocp-Apim-Subscription-Key": this.subscriptionKey
            })
        let options = { headers: headers };
        let paramsdata = this.makeblob(data.replace("data:image/jpeg;base64,",""),'image/jpeg', null)
        return  this.http.post(url, paramsdata, options);
    }
    public extractTextfromImageAnalyze(data: string) {
        // let url = this.endpoint + "vision/v2.1/ocr?language=unk&detectOrientation=true";
        let url = this.endpoint + "vision/v2.1/read/core/asyncBatchAnalyze";
        let headers = new HttpHeaders({
                "Content-Type": "application/octet-stream",
                "Ocp-Apim-Subscription-Key": this.subscriptionKey
            })
        let options = { headers: headers };
        let paramsdata = this.makeblob(data.replace("data:image/jpeg;base64,",""),'image/jpeg', null);
        return this.http.post(url, paramsdata, options);
    }
    public makeblob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = window.atob(b64Data);
        var byteArrays: any = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    /**
     * getExtractedData
     */
    public getExtractedData(imgSrc:string, extractingFields:any) {
        console.log("this is getxtracted Data");
        let val = 1;
        let url = "https://bop-ocr.cognitiveservices.azure.com/vision/v2.0/read/core/asyncBatchAnalyze";
        let headers = new HttpHeaders({
            "Content-Type": "application/octet-stream",
            "Ocp-Apim-Subscription-Key": this.subscriptionKey
        })
        // return this.http.post(url,paramsdata,{ headers: headers, observe: 'response' }).pipe(
        //     tap(response => {
        //         var get_data_url = String(response.headers.get("operation-location")) ;
        //         console.log("get_data_url :: ",get_data_url)
        //         let options_get_data = {
        //             headers: new HttpHeaders({
        //                 "Ocp-Apim-Subscription-Key": this.subscriptionKey
        //             })
        //         };
        //         return this.http.get(get_data_url, options_get_data) ;
        //     }),
        //       //call 5 times
        //       take(10)
        //     //   map(ev => ev.clientX),
        // ).subscribe();
            // let options = { headers: headers, observe:"body" };
            // let paramsdata = this.makeblob(imgSrc.replace("data:application/pdf;base64,",""),'application/pdf', null);
            // let paramsdata = imgSrc;
            let paramsdata = this.makeblob(imgSrc.replace("data:image/jpeg;base64,",""),'image/jpeg', null);
            return new Promise((resolve, reject) => {
                this.http.post(url,paramsdata,{ headers: headers, observe: 'response' }
                ).subscribe( (response) => {
                    var get_data_url = String(response.headers.get("operation-location")) ;
                    console.log("get_data_url :: ",get_data_url)
                    var searchForResult = () => {
                         this.http.get(get_data_url, {headers: new HttpHeaders({
                            "Ocp-Apim-Subscription-Key": this.subscriptionKey
                        })}
                        ).subscribe( (response: any) => {
                            console.log("response", response);
                            console.log("response.status", response.status)
                            if (response.status == "Running") {
                                setTimeout(() => {
                                    searchForResult();
                                }, 1000);
                            } else {
                                var data = response;
                                var jsonContent = {
                                    ocrData: {}
                                };
                                let final_data:any = [];
                                let height = data.recognitionResults[0].height;
                                let width = data.recognitionResults[0].width;
                                console.log(data.recognitionResults);
                                for (let i = 0; i < data.recognitionResults[0]["lines"].length; i++) {
                                    for (
                                        let j = 0;
                                        j < data.recognitionResults[0]["lines"][i]["words"].length;
                                        j++
                                    ) {
                                        //console.log(data.recognitionResults[0]['lines'][i]['words'][j]);
                                        let temp_obj:any = {};
                                        temp_obj["d"] =
                                            data.recognitionResults[0]["lines"][i]["words"][j]["text"];
                                        let co_ordinates:any = [];
                                        let azure_bb =
                                            data.recognitionResults[0]["lines"][i]["words"][j]["boundingBox"];
                                        co_ordinates.push({ x: azure_bb[0] / width, y: azure_bb[1] / height });
                                        co_ordinates.push({ x: azure_bb[2] / width, y: azure_bb[3] / height });
                                        co_ordinates.push({ x: azure_bb[4] / width, y: azure_bb[5] / height });
                                        co_ordinates.push({ x: azure_bb[6] / width, y: azure_bb[7] / height });
                                        temp_obj["b"] = co_ordinates;
                                        final_data.push(temp_obj);
                                    }
                                }
                                // for (let k = 0; k < data.recognitionResults.length; k++) {
                                //     let height = data.recognitionResults[k].height;
                                //     let width = data.recognitionResults[k].width;
                                //     for (let i = 0; i < data.recognitionResults[k]["lines"].length; i++) {
                                //         for (
                                //             let j = 0;
                                //             j < data.recognitionResults[k]["lines"][i]["words"].length;
                                //             j++
                                //         ) {
                                //             //console.log(data.recognitionResults[0]['lines'][i]['words'][j]);
                                //             let temp_obj:any = {};
                                //             temp_obj["d"] =
                                //                 data.recognitionResults[k]["lines"][i]["words"][j]["text"];
                                //             let co_ordinates:any = [];
                                //             let azure_bb =
                                //                 data.recognitionResults[k]["lines"][i]["words"][j]["boundingBox"];
                                //             co_ordinates.push({ x: azure_bb[0] / width, y: azure_bb[1] / height });
                                //             co_ordinates.push({ x: azure_bb[2] / width, y: azure_bb[3] / height });
                                //             co_ordinates.push({ x: azure_bb[4] / width, y: azure_bb[5] / height });
                                //             co_ordinates.push({ x: azure_bb[6] / width, y: azure_bb[7] / height });
                                //             temp_obj["b"] = co_ordinates;
                                //             final_data.push(temp_obj);
                                //         }
                                //     }
                                // }
                                var foundData = [];
                                var resultsObj = {};
                                jsonContent.ocrData = final_data;

                                console.log("json content", jsonContent.ocrData);
                                var result = findValues(jsonContent.ocrData, extractingFields, {});
                                
                            
                                resolve(result);
                            }
                            // if (response.hasOwnProperty("status")) {
                            //     setTimeout(() => {
                            //         searchForResult();
                            //     }, 1000);
                            // } else {
                            // }
                        });
                    }
                    searchForResult();
                    // setTimeout(() => {
                    // }, 10000);
                });
            })
            return
    }

    public getExtractedData2(imgSrc:string, extractingFields:any) {
        console.log("this is getxtracted Data");
        let val = 1;
        let url = "https://bop-ocr.cognitiveservices.azure.com/vision/v2.0/read/core/asyncBatchAnalyze";
        let headers = new HttpHeaders({
            "Content-Type": "application/octet-stream",
            "Ocp-Apim-Subscription-Key": this.subscriptionKey
        })
        // return this.http.post(url,paramsdata,{ headers: headers, observe: 'response' }).pipe(
        //     tap(response => {
        //         var get_data_url = String(response.headers.get("operation-location")) ;
        //         console.log("get_data_url :: ",get_data_url)
        //         let options_get_data = {
        //             headers: new HttpHeaders({
        //                 "Ocp-Apim-Subscription-Key": this.subscriptionKey
        //             })
        //         };
        //         return this.http.get(get_data_url, options_get_data) ;
        //     }),
        //       //call 5 times
        //       take(10)
        //     //   map(ev => ev.clientX),
        // ).subscribe();
            // let options = { headers: headers, observe:"body" };
            // let paramsdata = this.makeblob(imgSrc.replace("data:application/pdf;base64,",""),'application/pdf', null);
            let paramsdata = imgSrc;
            // let paramsdata = this.makeblob(imgSrc.replace("data:image/jpeg;base64,",""),'image/jpeg', null);
            return new Promise((resolve, reject) => {
                this.http.post(url,paramsdata,{ headers: headers, observe: 'response' }
                ).subscribe( (response) => {
                    var get_data_url = String(response.headers.get("operation-location")) ;
                    console.log("get_data_url :: ",get_data_url)
                    var searchForResult = () => {
                         this.http.get(get_data_url, {headers: new HttpHeaders({
                            "Ocp-Apim-Subscription-Key": this.subscriptionKey
                        })}
                        ).subscribe( (response: any) => {
                            console.log("response", response);
                            console.log("response.status", response.status)
                            if (response.status == "Running") {
                                setTimeout(() => {
                                    searchForResult();
                                }, 1000);
                            } else {
                                var data = response;
                                var jsonContent = {
                                    ocrData: {}
                                };
                                //let final_data:any = [];
                                // let height = data.recognitionResults[0].height;
                                // let width = data.recognitionResults[0].width;
                                // console.log(data.recognitionResults);
                                // for (let i = 0; i < data.recognitionResults[0]["lines"].length; i++) {
                                //     for (
                                //         let j = 0;
                                //         j < data.recognitionResults[0]["lines"][i]["words"].length;
                                //         j++
                                //     ) {
                                //         //console.log(data.recognitionResults[0]['lines'][i]['words'][j]);
                                //         let temp_obj:any = {};
                                //         temp_obj["d"] =
                                //             data.recognitionResults[0]["lines"][i]["words"][j]["text"];
                                //         let co_ordinates:any = [];
                                //         let azure_bb =
                                //             data.recognitionResults[0]["lines"][i]["words"][j]["boundingBox"];
                                //         co_ordinates.push({ x: azure_bb[0] / width, y: azure_bb[1] / height });
                                //         co_ordinates.push({ x: azure_bb[2] / width, y: azure_bb[3] / height });
                                //         co_ordinates.push({ x: azure_bb[4] / width, y: azure_bb[5] / height });
                                //         co_ordinates.push({ x: azure_bb[6] / width, y: azure_bb[7] / height });
                                //         temp_obj["b"] = co_ordinates;
                                //         final_data.push(temp_obj);
                                //     }
                                // }
                                const INCHTOPIXELVALUE = 96;
                                var resultList: any = [];
                                for (let k = 0; k < data.recognitionResults.length; k++) {
                                    let final_data:any = [];
                                    let height = data.recognitionResults[k].height * INCHTOPIXELVALUE;
                                    let width = data.recognitionResults[k].width * INCHTOPIXELVALUE;
                                    for (let i = 0; i < data.recognitionResults[k]["lines"].length; i++) {
                                        for (
                                            let j = 0;
                                            j < data.recognitionResults[k]["lines"][i]["words"].length;
                                            j++
                                        ) {
                                            //console.log(data.recognitionResults[0]['lines'][i]['words'][j]);
                                            let temp_obj:any = {};
                                            temp_obj["d"] =
                                                data.recognitionResults[k]["lines"][i]["words"][j]["text"];
                                            let co_ordinates:any = [];
                                            let azure_bb =
                                                data.recognitionResults[k]["lines"][i]["words"][j]["boundingBox"];
                                            co_ordinates.push({ x: (azure_bb[0] * INCHTOPIXELVALUE) / width, y: (azure_bb[1] * INCHTOPIXELVALUE) / height });
                                            co_ordinates.push({ x: (azure_bb[2] * INCHTOPIXELVALUE) / width, y: (azure_bb[3] * INCHTOPIXELVALUE) / height });
                                            co_ordinates.push({ x: (azure_bb[4] * INCHTOPIXELVALUE) / width, y: (azure_bb[5] * INCHTOPIXELVALUE) / height });
                                            co_ordinates.push({ x: (azure_bb[6] * INCHTOPIXELVALUE) / width, y: (azure_bb[7] * INCHTOPIXELVALUE) / height });
                                            temp_obj["b"] = co_ordinates;
                                            final_data.push(temp_obj);
                                        }
                                    }
                                    jsonContent.ocrData = final_data;

                                    // console.log("json content", jsonContent.ocrData);
                                    var result = findValues(jsonContent.ocrData, extractingFields, {});
                                    resultList.push(result)

                                }
                                var foundData = [];
                                var resultsObj = {};
                                
                            
                                resolve(resultList);
                            }
                            // if (response.hasOwnProperty("status")) {
                            //     setTimeout(() => {
                            //         searchForResult();
                            //     }, 1000);
                            // } else {
                            // }
                        });
                    }
                    searchForResult();
                    // setTimeout(() => {
                    // }, 10000);
                });
            })
            return
    }
   
    public getExtractedCustomDataFromImage(imgSrc:any) {
        console.log("this is getxtracted Data");
        let val = 1;
        let url = "https://bop-ocr.cognitiveservices.azure.com/vision/v2.0/read/core/asyncBatchAnalyze";
        let headers = new HttpHeaders({
            "Content-Type": "application/octet-stream",
            "Ocp-Apim-Subscription-Key": this.subscriptionKey
        })
      
            let paramsdata = imgSrc;
            return new Promise((resolve, reject) => {
                this.http.post(url,imgSrc,{ headers: headers, observe: 'response' }
                ).subscribe( (response) => {
                    var get_data_url = String(response.headers.get("operation-location")) ;
                    console.log("get_data_url :: ",get_data_url)
                    var searchForResult = () => {
                         this.http.get(get_data_url, {headers: new HttpHeaders({
                            "Ocp-Apim-Subscription-Key": this.subscriptionKey
                        })}
                        ).subscribe( (response: any) => {
                            console.log("response", response);
                            console.log("response.status", response.status)
                            if (response.status == "Running") {
                                setTimeout(() => {
                                    searchForResult();
                                }, 1000);
                            } else {
                                var data = response;
                                resolve(data.recognitionResults[0]);
                            }
                        });
                    }
                    searchForResult();
                    // setTimeout(() => {
                    // }, 10000);
                });
            })
            return
    }
    
    public getExtractedDataforCustomePreApprovalPdf(imgSrc:any) {
        console.log("this is getxtracted Data",imgSrc);
        let val = 1;
        let url = "https://bop-ocr.cognitiveservices.azure.com/vision/v2.0/read/core/asyncBatchAnalyze";
        let headers = new HttpHeaders({
            "Content-Type": "application/octet-stream",
            "Ocp-Apim-Subscription-Key": this.subscriptionKey
        })
        
            let paramsdata = imgSrc;
            // let paramsdata = this.makeblob(imgSrc.replace("data:image/jpeg;base64,",""),'image/jpeg', null);
            return new Promise((resolve, reject) => {
                this.http.post(url,paramsdata,{ headers: headers, observe: 'response' }
                ).subscribe( (response) => {
                    var get_data_url = String(response.headers.get("operation-location")) ;
                    console.log("get_data_url :: ",get_data_url)
                    var searchForResult = () => {
                         this.http.get(get_data_url, {headers: new HttpHeaders({
                            "Ocp-Apim-Subscription-Key": this.subscriptionKey
                        })}
                        ).subscribe( (response: any) => {
                            console.log("response", response);
                            console.log("response.status", response.status)
                            if (response.status == "Running") {
                                setTimeout(() => {
                                    searchForResult();
                                }, 1000);
                            } else {
                                resolve(response.recognitionResults);
                            }
                        });
                    }
                    searchForResult();
                });
            })
            return
        }
}