import { Point } from './point';

/**
 * Rectangle.
 */
export class Rectangle {

  private styleValue: string;

  /**
   * Start x coordinate.
   */
  public x: number;

  /**
   * Start y coordinate.
   */
  public y: number;

  /**
   * With rectangle.
   */
  public width: number;

  /**
   * Height rectangle.
   */
  public height: number;

  constructor(start: Point, end: Point, style: string = '#000000') {
    this.styleValue = style;
    this.x = start.x;
    this.y = start.y;
    this.width = end.x - start.x;
    this.height = end.y - start.y;
  }

  /**
   * Style.
   */
  public get style(): string {
    return this.styleValue;
  }

}
