import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service that controls global progress bar.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalProgressService {

  private readonly isLoadingValue$ = new BehaviorSubject(false);

  /**
   * Returns state of global progress.
   */
  public readonly isLoading$ = this.isLoadingValue$.asObservable();

  /**
   * Starts loading.
   */
  public startLoading(): void {
    this.isLoadingValue$.next(true);
  }

  /**
   * Stops loading.
   */
  public stopLoading(): void {
    this.isLoadingValue$.next(false);
  }

}
