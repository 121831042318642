import { Full } from '../../helpers/full';

/**
 * Toolbox category.
 */
export class ToolboxCategory {

  /**
   * Id.
   */
  public id: number;

  /**
   * Name.
   */
  public name: string;

  constructor(data: Full<ToolboxCategory>) {
    this.id = data.id;
    this.name = data.name;
  }
}
