import { Full } from '../helpers/full';

import { Person } from './person';

/**
 * User.
 */
export abstract class User extends Person {

  /**
   * Email.
   */
  public email: string;

  public nmlsId?:string;

  /**
   * Phone number.
   */
  public phoneNumber: string;

  /**
   * Update or creation date.
   */
  public updatedAt: Date;

  /**
   * Profile image url.
   */
  public profileImageUrl: string;

  public emailSignature?: string;
  public title?: string;
  public userType?: any;
  public timeZone?: any;
  public licenseNumber?: any;

  constructor(data: Full<User>) {
    super(data);
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.updatedAt = data.updatedAt;
    this.profileImageUrl = data.profileImageUrl;
    this.emailSignature = data.emailSignature ? data.emailSignature : "";
    this.title = data.title ? data.title : "";
    this.nmlsId = data.nmlsId ? data.nmlsId :"";
    this.userType = data.userType;
    this.timeZone = data.timeZone ? data.timeZone : "";
    this.licenseNumber = data.licenseNumber ? data.licenseNumber : "";
  }

}
