import { BehaviorSubject } from 'rxjs';
import { Subject, Observable } from 'rxjs';

import { Editor } from './editor';
import { EditorOptions } from './editor-options';
import { EditorRenderer } from './renderer/editor-renderer';
import { EditorRendererOptions } from './renderer/editor-renderer-options';
import { ImageCropperRenderer } from './renderer/image-cropper-renderer';
import { ImageRectangleRenderer } from './renderer/image-retangle-renderer';
import { ImageCustomPreapprovalRenderer } from './renderer/image-custom-preapproval-renderer';

import { RendererType } from './renderer/renderer-type';

/**
 * Image editor.
 */
export class ImageEditor extends Editor {

  /**
   * @inheritdoc
   */
  protected renderer: EditorRenderer;

  public customParams$: Observable<any>;

  public items :any=[]

  /**
   * @inheritdoc
   */
  public readonly filesChange$: Subject<File[]>;

  constructor(options: EditorOptions) {
    super(options);
    this.filesChange$ = new Subject();
    this.customParams$ = options.customParams$;
    this.items= options.items;
  }

  /**
   * @inheritdoc
   */
  public setRenderer(rendererType: RendererType): void {
    if (this.renderer) {
      this.renderer.destroy();
    }
    switch (rendererType) {
      case RendererType.Cropper:
        this.renderer = new ImageCropperRenderer(this, this.rendererOptions);
        break;
      case RendererType.RectangleRenderer:
        this.renderer = new ImageRectangleRenderer(this, this.rendererOptions);
        break;
      case RendererType.RectangleRendererHighlighter:
        this.renderer = new ImageRectangleRenderer(this, this.rendererOptions, true);
        break;  
      case RendererType.PreApprovalContentRenderer:
        this.renderer = new ImageCustomPreapprovalRenderer(this, this.rendererOptions, false, this.customParams$);
        break;        
      case RendererType.None:
        this.renderer.disable();
        break;
      default:
        throw new Error('Unexpected renderer type');
    }
  }

  private get rendererOptions(): EditorRendererOptions {
    return {
      canvas: this.canvas,
      context: this.context,
      canvasMaxWidth: this.canvasMaxWidth,
    };
  }

  /**
   * Triggers the crop effect of the current renderer if current renderer is instance of `ImageCropperRenderer`.
   */
  public crop(): void {
    if (this.renderer instanceof ImageCropperRenderer) {
      this.renderer.crop();
    }
  }

}
