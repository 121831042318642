import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Offer } from '../models/offers/offer';
import { completedOrFailed } from '../rxjs-operators/completed-or-failed';

import { GlobalProgressService } from './global-progress.service';
import { OfferService } from './offer.service';

/**
 * Offer resolver.
 */
@Injectable({
  providedIn: 'root',
})
export class OfferResolver implements Resolve<Offer> {

  constructor(
    private offersService: OfferService,
    private globalProgressService: GlobalProgressService,
  ) { }

  /**
   * Gets info about offer by id.
   * @param route Current route.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Offer> {
    const id = Number(route.paramMap.get('id'));
    this.globalProgressService.startLoading();
    return this.offersService
      .getById(id)
      .pipe(
        completedOrFailed(() => this.globalProgressService.stopLoading()),
      );
  }

}
