import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * All the names of the icons.
 */
const ICONS_NAME = [
  "attach_file",
  "mail",
  "mail_sent",
  "mail_open",
  'arrow_down',
  'search',
  'men_badge',
  'message_badge',
  'profile',
  'add_user',
  'new_offer',
  'blue_man',
  'copy',
  'delete',
  'edit',
  'edit_outline',
  'green_man',
  'messages',
  'pdf',
  'contingencies',
  'contigency_loan',
  'deposit',
  'down_payment',
  'escrow_holder',
  'financing',
  'header',
  'lender',
  'offer_price',
  'quotes',
  'timer',
  'total',
  'ready',
  'red_man',
  'visibility',
  'create',
  'create_black',
  'create_white',
  'create_yellow',
  'create_yellowWhite',
  'undo',
  'insert_photo',
  'enable_crop',
  'tick_green',
  'disable_crop',
  'delete_outline',
  're-assign',
  'ticket-created',
  'comment',
  'draw',
  'highlighter',
  'edit_search',
  'edit_white',
  'edit_black',
  'file_download',
  'sraiset',
  'drag_indicator',
  'icons8-info',
  'icon_info',
  'settings',
  'pc',
  'update',
  'right-arrow',
  'bop-logo',
  'bop-logo-white',
  'close_icon',
  'success_check',
  'arrow-right-solid',

];

/**
 * The path to the icons.
 */
const PATH = 'assets/icons';

/**
 * Registers additional application icons.
 */
@Injectable({
  providedIn: 'root',
})
export class IconsService {

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) { }

  /**
   * Register all icons.
   */
  public registerMaterialIcons(): void {
    for (const iconName of ICONS_NAME) {
      const url = this.sanitizer.bypassSecurityTrustResourceUrl(`${PATH}/${iconName}.svg`);
      this.iconRegistry.addSvgIcon(iconName, url);
    }
  }

}
