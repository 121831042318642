import { CanActivate } from '@angular/router';
import { take } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { Observable, fromEvent, BehaviorSubject } from 'rxjs';
import { tap, takeUntil, last } from 'rxjs/operators';

import { DestroyableBase } from '../../../../core';
import { ImageEditor } from '../../../models/document-editor/image-editor';
import { RendererType } from '../../../models/document-editor/renderer/renderer-type';

/**
 * Image editor component.
 */
@Component({
  selector: 'arb-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditorComponent extends DestroyableBase implements OnInit, AfterViewInit {

  private editor: ImageEditor;

  public items: any = []
  /**
   * Max width of canvas.
   */
  public readonly canvasMaxWidth = Math.round(window.innerWidth * 0.6);

  /**
   * Padding of container.
   * In pixels.
   */
  public readonly containerPadding = 20;

  public typeFlag: any;

  @Output() typeFlagEvent = new EventEmitter<any>();
  /**
   * Stream of renderer type to switch between renderer modes.
   */
  @Input()
  public rendererType$: Observable<RendererType>;

  @Input()
  public coordinatesList: Observable<any>
  /**
   * Crop stream to trigger crop effect.
   */
  @Input()
  public crop$: Observable<void>;

  /**
   * Uploaded file.
   */
  @Input()
  public file: File;


  /**
   * Pdf file.
   */
  @Input()
  public customParams$: Observable<any>;

  /**
   * Undo stream.
   */
  @Input()
  public undo$: Observable<void>;

  /**
   * Emits new files.
   */
  @Output()
  public filesChange = new EventEmitter<File[]>();

  @Output()
  public changesCordinateList = new EventEmitter<any>();

  /**
   * Reference to canvas element.
   */
  @ViewChild('canvas')
  public canvasRef: ElementRef<HTMLCanvasElement>;

  /**
   * Reference to container element.
   */
  @ViewChild('container')
  public containerRef: ElementRef<HTMLElement>;

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    const canvas = this.canvasRef.nativeElement;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    this.editor = new ImageEditor({
      canvas,
      context,
      file: this.file,
      canvasMaxWidth: this.canvasMaxWidth,
      customParams$: this.customParams$,
      items: this.items
    });

    this.listenFilesChange();
    this.listenUndo();
    this.listenRendererTypeChanges();
    this.listenCrop();
  }

  /**
   * @inheritdoc
   */
  // tslint:disable-next-line: use-life-cycle-interface
  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.editor.destroy();
  }
  public allowDrop(ev) {
    ev.preventDefault();
  }
  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.captureEvents(this.canvasRef.nativeElement);
  }

  public drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    let DataEelem = document.getElementById(data) as HTMLElement;
    let key = DataEelem.innerText
    console.log("key ", key)
    let value
    let text
    if (key == "Purchase Price") {
      value = { "purchasePrice": "<%Purchaseprice>" }
      text = "<%Purchaseprice>";
    }
    else if (key == "Address") {
      value = { "address": "<%Address>" }
      text = "<%Address>"
    }
    else if (key == "Downpayment") {
      value = { "downPayment": "<%Downpayment>" }
      text = "<%Downpayment>"
    }
    else if (key == "Property Type") {
      value = { propertyType: "<%Propertytype>" }
      text = "<%Propertytype>"
    }
    else if (key == "Occupancy") {
      value = { occupancy: "<%Occupancy>" }
      text = "<%Occupancy>"
    }
    else if (key == "Fico") {
      value = { fico: "<%Fico>" }
      text = "<%Fico>"
    }
    else if (key == "Program Type") {
      value = { program: "<%Programtype>" }
      text = "<%Programtype>"
    }
    const canvas = this.canvasRef.nativeElement;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.textAlign = "left";
    this.customParams$.pipe(take(1)).subscribe(res => {
      res.preApproval = { ...value };
      var fontSize: number = res.fontSize ? Number(res.fontSize) : 16;
      var fontFamily = res.fontStyle ? res.fontStyle : "Verdana";
      var fontColor = res.textColor ? res.textColor : "#00000"
      context.fillStyle = fontColor;
      context.font = `${fontSize}px ${fontFamily}`;
      const actualCordinates = {
        x: ev.layerX * canvas.width/canvas.clientWidth, y: ev.layerY * canvas.height/canvas.clientHeight
      }
      context.fillText(text, actualCordinates.x, actualCordinates.y);
      context.stroke();
      let item = { customparams: { ...res }, cordinates: { X: actualCordinates.x, Y: actualCordinates.y } }
      this.items.push(item)
      if (this.items.length) {
        this.typeFlagEvent.emit(false)
        this.changesCordinateList.emit(this.items)
      }
    })

  }
  /**
   * Run events outside the angular zone.
   */
  private captureEvents(canvasEl: HTMLCanvasElement): void {
    fromEvent<PointerEvent>(canvasEl, 'pointerdown').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onMouseDown(event));
    fromEvent<PointerEvent>(canvasEl, 'pointermove').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onMouseMove(event));
    fromEvent<PointerEvent>(canvasEl, 'pointerup').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onMouseUp(event));
    fromEvent<PointerEvent>(this.containerRef.nativeElement, 'pointermove').pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => this.onContainerMouseMove(event));
  }

  private listenFilesChange(): void {
    this.editor
      .filesChange$
      .pipe(
        tap(files => this.filesChange.emit(files)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }


  private listenUndo(): void {
    this.undo$
      .pipe(
        tap(() => this.editor.undo()),
        tap(() => this.editor.items.length==0 &&  this.typeFlagEvent.emit(true)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenRendererTypeChanges(): void {
    this.rendererType$
      .pipe(
        tap(rendererType => this.typeFlag = rendererType),
        tap(rendererType => this.editor.setRenderer(rendererType)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenCrop(): void {
    this.crop$
      .pipe(
        tap(() => this.editor.crop()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * Emits mousemove event.
   * @param event Mouse event.
   */
  public onContainerMouseMove(event: MouseEvent): void {
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseMove(x - this.containerPadding, y - this.containerPadding);
  }

  /**
   * Emits mousemove event.
   * @param event Mouse event.
   */
  public onMouseMove(event: MouseEvent): void {
    event.stopPropagation();
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseMove(x, y);
  }

  /**
   * Emits mousedown event.
   * @param event Mouse event.
   */
  public onMouseDown(event: MouseEvent): void {
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseDown(x, y);
  }

  /**
   * Emits mouseup event.
   * @param event Mouse event.
   */
  public onMouseUp(event: MouseEvent): void {
    const { offsetX: x, offsetY: y } = event;
    this.editor.onMouseUp(x, y);
    if (this.typeFlag == RendererType.PreApprovalContentRenderer) {
      this.typeFlagEvent.emit(false);
    }
  }

}
