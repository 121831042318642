import { Full } from '../../core';

import { BaseDialogData } from './base-dialog-data';
import { MatButtonColor } from './mat-button-color';

/**
 * Confirm dialog data.
 */
export class ConfirmDialogData extends BaseDialogData {

  /**
   * Accept button color.
   */
  public acceptButtonColor: MatButtonColor;

  /**
   * Accept button text.
   */
  public acceptButtonText: string;

  /**
   * Accept button color.
   */
  public rejectButtonColor?: MatButtonColor;

  /**
   * Accept button text.
   */
  public rejectButtonText?: string;

  /**
   * If true then buttons will be thiner and longer.
   */
  public thinButtons?: boolean;

  public optionalBtn?: boolean = false;

  public optionalButtonText?: string = "";

  constructor(data: Full<ConfirmDialogData>) {
    super(data.title, data.subtitle);
    this.acceptButtonColor = data.acceptButtonColor;
    this.acceptButtonText = data.acceptButtonText;
    this.rejectButtonColor = data.rejectButtonColor;
    this.optionalBtn = data.optionalBtn ? data.optionalBtn : false;
    this.optionalButtonText = data.optionalButtonText ? data.optionalButtonText : "";
    if (data.rejectButtonText) {
      this.rejectButtonText = data.rejectButtonText;
    } else {
      this.rejectButtonText = 'Cancel';
    }
    this.thinButtons = data.thinButtons;
  }

}
