import { Pipe, PipeTransform } from '@angular/core';

import { FAQItem } from '../../core/models/faq/faq-item';

/**
 * Keeps only the items belong to the specified category.
 */
@Pipe({
  name: 'FilterByFaqId',
})
export class FilterByFaqIdPipe implements PipeTransform {
  /**
   * Keeps only the items belong to the specified category.
   * @param items List of all the items.
   * @param categoryId Id of the category to filter by.
   */
  public transform(items: FAQItem[] | null, faqID: number): FAQItem[] {
    if (items === null) {
      return [];
    }
    return items.filter((item: FAQItem) => item.faqID === faqID);
  }
}
