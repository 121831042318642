import { Full } from '../helpers/full';

import { Named } from './named';

/**
 * Info about each person.
 */
export class Person extends Named {

  /**
   * Id.
   */
  public id: number;

  /**
   * @constructor.
   */
  constructor(person: Full<Person>) {
    super(person);
    this.id = person.id;
  }

}
