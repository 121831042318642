import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'arb-offer-asset-upload',
  templateUrl: './offer-asset-upload.component.html',
  styleUrls: ['./offer-asset-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferAssetUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
