import { Full } from '../helpers/full';

import { Person } from './person';
import { UserStatus } from './user-status';
/**
 * General info about realtor.
 */
export class RealtorSummary extends Person {

  /**
   * Readable id.
   */
  public friendlyId: string;

  /**
   * Status.
   */
  public status: UserStatus;

  /**
   * Update or creation date.
   */
  public updatedAt: Date;

  /**
   * Number of associated borrowers.
   */
  public borrowersCount: number;

  /**
   * Number of active offers.
   */
  public activeOffersCount: number;

  /**
   * Username of user who last updated the record.
   */
  public updatedByUserName: string;

  public isMapped: boolean;

  constructor(data: Full<RealtorSummary>) {
    super({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
    });
    this.friendlyId = data.friendlyId;
    this.borrowersCount = data.borrowersCount;
    this.activeOffersCount = data.activeOffersCount;
    this.status = data.status;
    this.updatedAt = data.updatedAt;
    this.updatedByUserName = data.updatedByUserName;
    this.isMapped = data.isMapped;
  }

}
