import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

/**
 * Method validates if all control of the input form group are valid.
 * @param form of control which should be marked.
 */
export function markAllFormFieldsAsTouched(form: AbstractControl): void {
  form.markAsTouched({ onlySelf: true });
  if (form instanceof FormArray || form instanceof FormGroup) {
    Object.values(form.controls).forEach(markAllFormFieldsAsTouched);
  }
}
