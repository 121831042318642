import { Full, PreApprovalStatus } from '../../core';

import { ConfirmDialogData } from './confirm-dialog-data';

/**
 * Update status dialog data.
 */
export class UpdateStatusDialogData extends ConfirmDialogData {

  /**
   * Statuses.
   */
  public statuses: PreApprovalStatus[];

  constructor(data: Full<UpdateStatusDialogData>) {
    super(data);
    this.statuses = data.statuses;
  }

}
