import { Component, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

/**
 * Offer package url dialog.
 */
@Component({
  selector: 'arb-offer-package-url-dialog',
  templateUrl: './offer-package-url-dialog.component.html',
  styleUrls: ['./offer-package-url-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferPackageUrlDialogComponent {

  /**
   * Ref to input contains url.
   */
  @ViewChild('url')
  public inputEl: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public packageUrl: string,
  ) { }

  /**
   * Copies package url to clipboard.
   */
  public onCopy(): void {
    this.inputEl.nativeElement.select();
    document.execCommand('copy');
  }

}
