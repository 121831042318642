import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { mapTo, map } from 'rxjs/operators';

import { AppConfig } from '../../app.config';
import { LoanOfficer } from '../models/loan-officer';
import { LoanOfficerSummary } from '../models/loan-officer-summary';
import { PaginationResult } from '../models/pagination-result';
import { Table } from '../models/table';

import { InviteLoanOfficerDto } from './dto/invite-loan-officer-dto';
import { LoanOfficerDto } from './dto/loan-officer-dto';
import { LoanOfficerSummaryDto } from './dto/loan-officer-summary-dto';
import { LoanOfficersSearchParams } from './dto/loan-officers-search-params';
import { UpdateLoanOfficerDto } from './dto/update-loan-officer-dto';

/**
 * Loan officer service.
 */
@Injectable({
  providedIn: 'root',
})
export class LoanOfficerService {
  /**
   * .ctor
   * @param config Config.
   * @param http Http Client.
   */
  constructor(
    private config: AppConfig,
    private http: HttpClient,
  ) { }

  public loadingParent$ = new BehaviorSubject(false);
  public enablePreview$ = new BehaviorSubject(false);
  /**
   * Invite a loan officer.
   * @param data Invite loan officer data.
   */
  public invite(data: InviteLoanOfficerDto): Observable<void> {
    const url = `${this.config.apiUrl}/api/loan-officers/invite`;
    return this.http
      .post<void>(url, data)
      .pipe(
        mapTo(void 0),
      );
  }

  /**
   * Retrieves detailed information about Loan Officer.
   * @param id Loan officer's id.
   */
  public getById(id: number): Observable<LoanOfficer> {
    const url = `${this.config.apiUrl}/api/loan-officers/${id}`;
    return this.http
      .get<LoanOfficerDto>(url)
      .pipe(
        map(dto => this.mapToLoanOfficer(dto)),
      );
  }

  /**
   * Get loan officer's profile info.
   * This is need only for loan officer to enter the profile.
   */
  public getProfile(): Observable<LoanOfficer> {
    const url = `${this.config.apiUrl}/api/loan-officers/my-profile`;
    return this.http
      .get<LoanOfficerDto>(url)
      .pipe(
        map(dto => this.mapToLoanOfficer(dto)),
      );
  }

  private mapToLoanOfficer(dto: LoanOfficerDto): LoanOfficer {
    return new LoanOfficer({
      id: dto.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      title: dto.title,
      nmlsId: dto.nmlsId,
      footerColor: dto.footerColor ? dto.footerColor.trim() : '',
      headerColor: dto.headerColor ? dto.headerColor.trim() : '',
      realtorsCount: dto.numRealtors,
      profileImageUrl: dto.profilePictureUrl,
      companyLogoUrl: dto.companyLogoUrl,
      DefaultPreApproval: dto.defaultPreApproval,
      signatureUrl: dto.signatureUrl,
      friendlyId: dto.friendlyId,
      companyName: dto.companyName,
      status: dto.status,
      email: dto.email,
      phoneNumber: dto.phoneNumber,
      updatedAt: new Date(dto.updatedAt),
      isCustom: dto.isCustom,
      fieldCordinates: dto.fieldCordinates,
      timeZone: dto.timeZone
    });
  }

  /**
   * Searches loan officers by parameters.
   * @param searchParams Parameters for searching.
   */
  public search(searchParams: LoanOfficersSearchParams,key:string): Observable<Table<LoanOfficerSummary>> {
    let url = `${this.config.apiUrl}/api/loan-officers`;
    if (key.length) {
       url = `${this.config.apiUrl}/api/loan-officers?loanOfficerName=${key}`;
    }
    const params = this.createHttpSearchParams(searchParams);
    return this.http
      .get<PaginationResult<LoanOfficerSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(this.mapToLoanOfficerSummary),
        })),
      );
  }

  /**
   * Searches loan officers by parameters.
   * @param searchParams Parameters for searching.
   */
  public searchByRealtor(searchParams: LoanOfficersSearchParams): Observable<Table<LoanOfficerSummary>> {
    const url = `${this.config.apiUrl}/api/loan-officers/searchbyrealtor`;
    const params = this.createHttpSearchParams(searchParams);
    return this.http
      .get<PaginationResult<LoanOfficerSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(this.mapToLoanOfficerSummary),
        })),
      );
  }

  public getMappedLoanOfficers(searchParams: LoanOfficersSearchParams): Observable<Table<LoanOfficerSummary>> {
    const url = `${this.config.apiUrl}/api/loan-officers/getMappedLoanOfficers`;
    const params = this.createHttpSearchParams(searchParams);
    return this.http
      .get<PaginationResult<LoanOfficerSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(this.mapToLoanOfficerSummary),
        })),
      );
  }




  /**
   * Update profile.
   * @param profile Profile.
   */
  public updateProfile(profile: UpdateLoanOfficerDto, id?: number): Observable<void> {
    const url = id
      ? `${this.config.apiUrl}/api/loan-officers/${id}`
      : `${this.config.apiUrl}/api/loan-officers/my-profile`;
    const formData = new FormData();
    formData.append('companyName', profile.companyName);
    formData.append('firstName', profile.firstName);
    formData.append('lastName', profile.lastName);
    formData.append('email', profile.email);
    formData.append('title', String(profile.title));
    formData.append('phoneNumber', profile.phoneNumber);
    formData.append('removeProfilePicture', String(profile.profilePictureRemoved));
    formData.append('removeCompanyLogo', String(profile.companyLogoRemoved));
    formData.append('removeSignature', String(profile.signatureRemoved));
    
      formData.append('isCustom', Object(profile.isCustom ? profile.isCustom:false));
    if (profile.fieldCordinates){
      formData.append('fieldCordinates', Object(JSON.stringify(profile.fieldCordinates)));
    }

    // pre approval
    formData.append('removePreApprovalLetter', String(profile.preApprovalRemoved))
    //
    formData.append('footerColor', profile.footerColor);
    formData.append('headerColor', profile.headerColor);
    formData.append('nmlsId', String(profile.nmlsId));
    if (profile.profilePicture && !profile.profilePictureRemoved) {
      formData.append('profilePicture', profile.profilePicture);
    }
    if (profile.companyLogo && !profile.companyLogoRemoved) {
      formData.append('companyLogo', profile.companyLogo);
    }
    if (profile.signature && !profile.signatureRemoved) {
      formData.append('signature', profile.signature);
    }
    if (profile.DefaultPreApproval) {
      formData.append('DefaultPreApproval', profile.DefaultPreApproval)
    }
    //timezone
    formData.append('timezone',profile.timeZone);
    return this.http.put<void>(url, formData);
  }

  public updateProfile1(profile: UpdateLoanOfficerDto, id?: number): Observable<void> {
    const url = id
      ? `${this.config.apiUrl}/api/loan-officers/${id}/byManagedUser`
      : `${this.config.apiUrl}/api/loan-officers/my-profile`;
    const formData = new FormData();
    formData.append('companyName', profile.companyName);
    formData.append('firstName', profile.firstName);
    formData.append('lastName', profile.lastName);
    formData.append('email', profile.email);
    formData.append('title', String(profile.title));
    formData.append('phoneNumber', profile.phoneNumber);
    formData.append('removeProfilePicture', String(profile.profilePictureRemoved));
    formData.append('removeCompanyLogo', String(profile.companyLogoRemoved));
    formData.append('removeSignature', String(profile.signatureRemoved));
    
      formData.append('isCustom', Object(profile.isCustom ? profile.isCustom:false));
    if (profile.fieldCordinates){
      formData.append('fieldCordinates', Object(JSON.stringify(profile.fieldCordinates)));
    }

    // pre approval
    formData.append('removePreApprovalLetter', String(profile.preApprovalRemoved))
    //
    formData.append('footerColor', profile.footerColor);
    formData.append('headerColor', profile.headerColor);
    formData.append('timeZone', profile.timeZone);
    formData.append('nmlsId', String(profile.nmlsId));
    
    if (profile.profilePicture && !profile.profilePictureRemoved) {
      formData.append('profilePicture', profile.profilePicture);
    }
    if (profile.companyLogo && !profile.companyLogoRemoved) {
      formData.append('companyLogo', profile.companyLogo);
    }
    if (profile.signature && !profile.signatureRemoved) {
      formData.append('signature', profile.signature);
    }
    if (profile.DefaultPreApproval) {
      formData.append('DefaultPreApproval', profile.DefaultPreApproval)
    }
    return this.http.put<void>(url, formData);
  }

  private createHttpSearchParams(searchParams: LoanOfficersSearchParams): HttpParams {
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return params;
  }

  private mapToLoanOfficerSummary(dto: LoanOfficerSummaryDto): LoanOfficerSummary {
    return new LoanOfficerSummary({
      id: dto.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      realtorsCount: dto.numRealtors,
      friendlyId: dto.friendlyId,
      borrowersCount: dto.numBorrowers,
      activeOffersCount: dto.numActiveOffers,
      preApprovalsCount: dto.numPreApprovals,
      status: dto.status,
      updatedAt: new Date(dto.updatedAt),
      updatedUserName: dto.updatedByUserName,
      isMapped: dto.isMapped,
      invitedBy: dto.invitedBy
    });
  }

  getListOfLoanOfficers(): Observable<any> {
    const url = `${this.config.apiUrl}/api/loan-officers`;
    return this.http.get<any>(url);
  }


  addCustomFieldsForLoanOfficer(customFieldsData): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      responseType: 'text' as 'json'
    };
    const url = `${this.config.apiUrl}/api/loan-officers/customFieldsAdd`;
    return this.http.post<any>(url, customFieldsData, options);
  }

  addCustomFieldForLo(customFieldsData): Observable<any> {
    const url = `${this.config.apiUrl}/api/loan-officers/singleCustomFieldsAdd`;
    return this.http.post<any>(url, customFieldsData);
  }

  getCustomFieldsByLoanOfficerId(loId): Observable<any> {
    const url = `${this.config.apiUrl}/api/loan-officers/getCustomeFields/${loId}`;
    return this.http.get<any>(url);
  }

  updateCustomFieldsForLoanOfficer(customFieldsData): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      responseType: 'text' as 'json'
    };
    const url = `${this.config.apiUrl}/api/loan-officers/customFieldsUpdate`;
    return this.http.put<any>(url, customFieldsData, options);
  }
  deleteCustomFieldsForLoanOfficer(id): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      responseType: 'text' as 'json'
    };
    const url = `${this.config.apiUrl}/api/loan-officers/deletecustomfields/${id}`;
    return this.http.delete(url, options)
  }
  /**
   * getPdfFromDocxFile
   */
  public getPdfFromDocxFile(DocFile:File) {
    const formData = new FormData();
    formData.append('isError','false');
    formData.append('error','');
    formData.append('pdfUrl','');
    formData.append('DocFile',DocFile);
    const url = `${this.config.apiUrl}/api/authTokens/docToPdf/upload`;
    return this.http.post<any>(url, formData);
  }

  /**
   * Searches for realtors.
   * @param searchParams Params for search request.
   */
   public searchLoanOfficersByRealtor(searchParams: LoanOfficersSearchParams): Observable<Table<LoanOfficerSummary>> {
    const url = `${this.config.apiUrl}/api/loan-officers/searchLoanOfficersByRealtor`;
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<LoanOfficerSummaryDto>>(url, { params })
      .pipe(
        map(res => ({
          paginationMetadata: res.metadata,
          data: res.data.map(dto => this.mapToLoanOfficerSummary(dto)),
        })),
      );
  }
}
