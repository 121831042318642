import { Full } from '../../helpers/full';

import { MessageStatus } from './message-status';

/**
 * Base message class.
 */
export class Message {

  /**
   * Text.
   */
  public text: string;

  /**
   * Status.
   */
  public status: MessageStatus;

  constructor(data: Full<Message>) {
    this.text = data.text;
    this.status = data.status;
  }

}
