import { Component, ChangeDetectionStrategy, Input,ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/core';

import { StatusColor } from '../../../models/status-color';

/**
 * Base status component.
 */
@Component({
  selector: 'arb-base-status',
  templateUrl: './base-status.component.html',
  styleUrls: ['./base-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BaseStatusComponent implements OnInit, AfterViewInit {

  @ViewChild('btnContent', { read: ElementRef }) btnContent: ElementRef;
  constructor(
    private currentUserService: CurrentUserService
  ) { }

  public isHide:boolean;
  // public isShow:boolean = false;
  public user$ = this.currentUserService.currentUser$;
  public user;
  ngOnInit() {
    // return true if there is a content
    // this.isShow = !!this.btnContent.nativeElement.innerHTML;
    this.user$.subscribe((data) => {
      this.user = data.role;
      // console.log(this.user)
    })
  }

  ngAfterViewInit() {
    // console.log((this.btnContent.nativeElement.childNodes));
    this.check();
  }

  // To check if there is content in second div element and hide it
  check() {
    if(this.btnContent.nativeElement.childNodes.length === 2) {
      if(this.btnContent.nativeElement.childNodes[1].childNodes.length < 2) {
        this.isHide = true;
      }
    } 
    // console.log(this.isHide);
    if((this.user === 'LoanOfficer' || this.user === 'Realtor') && (this.isHide)) {
      (document.getElementById("element") as HTMLElement).classList.add('hide');
    } 
  }

  /**
   * If true then status will be displayed in a rectangle.
   * If not then a circle will be displayed.
   */
  @Input()
  public useRectangle: boolean;

  /**
   * If true then main content and secondary content will be displayed in separate lines.
   */
  @Input()
  public multiline: boolean;

  /**
   * True if a small version of the status is enabled.
   */
  @Input()
  public small: boolean;

  /**
   * Text.
   */
  @Input()
  public text: string;

  /**
   * Status color.
   */
  @Input()
  public color: StatusColor;

  /**
   * Is color green.
   */
  public get green(): boolean {
    return this.color === StatusColor.Green;
  }

  /**
   * Is color gray.
   */
  public get gray(): boolean {
    return this.color === StatusColor.Gray;
  }

  /**
   * Is color blue.
   */
  public get blue(): boolean {
    return this.color === StatusColor.Blue;
  }

  /**
   * Is color red.
   */
  public get red(): boolean {
    return this.color === StatusColor.Red;
  }

}
