import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import {
  Borrower,
  BorrowerService,
  DownPaymentType,
  Offer,
  toReadableOccupancy,
  toReadableProgramType,
  toReadablePropertyType,
} from '../../../../core';

import { OfferDetails } from './offer-details';

/**
 * Offer letter page.
 */
@Component({
  selector: 'arb-offer-letter-page',
  templateUrl: './offer-letter-page.component.html',
  styleUrls: ['./offer-letter-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferLetterPageComponent {

  /**
   * Stream with all related data (offer, borrower).
   */
  public readonly offerDetails$ = this.createOfferDetailsStream();
  public logoUrl = "/assets/logo.png?date="+ new Date();

  /**
   * Current date.
   */
  public currentDate : any = new Date();

  /**
   * Returns property type in readable format.
   */
  public readonly toReadablePropertyType = toReadablePropertyType;

  /**
   * Returns occupancy in readable format.
   */
  public readonly toReadableOccupancy = toReadableOccupancy;

  /**
   * Returns program type in readable format.
   */
  public readonly toReadableProgramType = toReadableProgramType;

  /**
   * All downpayment types.
   */
  public readonly downPaymentTypes = DownPaymentType;

  constructor(
    private route: ActivatedRoute,
    private borrowersService: BorrowerService,
  ) { }

  private createOfferDetailsStream(): Observable<OfferDetails> {
    return this.route.data.pipe(
      take(1),
      map(({ offer }) => offer),
      tap((data) => {
        if (data && data.realtor && data.realtor.timeZone) {
          this.currentDate = this.currentDate.toLocaleString('en-US', { timeZone: data.realtor.timeZone })
        }
      }),
      switchMap((offer: Offer) => this.getBorrower(offer.borrower.id).pipe(
        map((borrower) => new OfferDetails({ offer, borrower })),
      )),
    );
  }

  private getBorrower(id: number): Observable<Borrower> {
    return this.borrowersService.getById(id);
  }
}
