import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { weakShareReplay } from '../rxjs-operators/weak-share-replay';

import { ToolboxCategory } from '../models/toolbox/toolbox-category';
import { ToolboxItem } from '../models/toolbox/toolbox-item';
import { ToolboxNewItem } from '../models/toolbox/toolbox-new-item';
import { ToolboxEditItem } from '../models/toolbox/toolbox-edit-item';

import { ToolboxCategoryDto } from './dto/toolbox/toolbox-category-dto';
import { ToolboxItemDto } from './dto/toolbox/toolbox-item-dto';
import { ToolboxNewItemDto } from './dto/toolbox/toolbox-new-item-dto';
import { ToolboxEditItemDto } from './dto/toolbox/toolbox-edit-item-dto';

/**
 * Toolbox business logic.
 */
@Injectable({
  providedIn: 'root',
})

export class ToolboxService {
  /**
   * List of all the toolbox categories.
   */
   public realtorId:number;
  
  public categories$ = this.getCategories()
    .pipe(
      weakShareReplay(1),
    );
  
  
  public items$ =this.getItems();
  
  /**
   * .ctor
   * @param config Global app config.
   * @param http Http client.
   */
  constructor(
    private config: AppConfig,
    private http: HttpClient
  ) { }

  /**
   * Get all toolbox items.
   */
  public getItems(): Observable<ToolboxItem[]> {
    const url = `${this.config.apiUrl}/toolbox`;
    return this.http
      .get<ToolboxItemDto[]>(url)
      .pipe(
        map(res => res.map(this.mapToToolboxItem)),
      );
  }
  public getItemsRealtor(id:number): Observable<ToolboxItem[]> {
    const url = `${this.config.apiUrl}/toolbox/${id}`;
    return this.http
      .get<ToolboxItemDto[]>(url)
      .pipe(
        map(res => res.map(this.mapToToolboxItem)),
      );
  }
  public sortItem(itemData: any): Observable<void> 
  {
    const url = `${this.config.apiUrl}/toolbox/sortItem`;
    return this.http.post<void>(`${this.config.apiUrl}/toolbox/sortItem`,itemData);

  }

  /**
   * Stores a new item.
   * @param data New item's info.
   */
  public addItem(data: ToolboxNewItem): Observable<void> {
    return this.http
      .post<void>(`${this.config.apiUrl}/toolbox`, this.mapToToolboxNewItemDto(data));
  }
  public addItemForAdmin(id:number,data:ToolboxNewItem):Observable<void>
  {
    return this.http
      .post<void>(`${this.config.apiUrl}/toolbox/admin/${id}`, this.mapToToolboxNewItemDto(data));
  }

    /**
   * Stores a new item.
   * @param data New item's info.
   */
  public addDefault(userId: number, data: ToolboxNewItem): Observable<void> {
    return this.http
      .post<void>(`${this.config.apiUrl}/toolbox/${userId}`, this.mapToToolboxNewItemDto(data));
  }


  /**
   * Edits the specified item.
   * @param id Id of the editing item.
   */

  public editItem(id: number ,data: ToolboxEditItem): Observable<void> {
    return this.http
      .put<void>(`${this.config.apiUrl}/toolbox/${id}`, this.mapToToolboxEditItemDto(data));
  }
  
  public editAdminItem(id: number ,data: ToolboxEditItem): Observable<void> {
    return this.http
      .put<void>(`${this.config.apiUrl}/toolbox/admin/${id}`, this.mapToToolboxEditItemDto(data));
  }
  /**
   * Deletes the specified item.
   * @param id Id of the deleting item.
   */
  public deleteItem(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.config.apiUrl}/toolbox/${id}`, {});
  }

  public deleteAdminItem(id:number): Observable<void> {
    return this.http
      .delete<void>(`${this.config.apiUrl}/toolbox/admin/${id}`, {});
  }
  /**
   *@param id Get list of all the categories.
   */
  private getCategories(): Observable<ToolboxCategory[]> {
    const url = `${this.config.apiUrl}/toolbox/categories`;
    console.log(url);
    return this.http
      .get<ToolboxCategory[]>(url)
      .pipe(
        map((res: ToolboxCategoryDto[]) => res.map(this.mapToToolboxCategory)),
      );
  }
  public getCategoriesRealtor(id:number): Observable<ToolboxCategory[]> {
    const url = `${this.config.apiUrl}/toolbox/${id}`;
    console.log(url);
    return this.http
      .get<ToolboxCategory[]>(url)
      .pipe(
        map((res: ToolboxCategoryDto[]) => res.map(this.mapToToolboxCategory)),
      );
  }

  private mapToToolboxNewItemDto(item: ToolboxNewItem): ToolboxNewItemDto {
    return {
      toolBoxCategoryId: item.category.id,
      text: item.text,
    };
  }

  private mapToToolboxEditItemDto(item: ToolboxEditItem): ToolboxEditItemDto {
    return {
      toolBoxCategoryId: item.category.id,
      text: item.text,
    };
  }
  
  private mapToToolboxItem(dto: ToolboxItemDto): ToolboxItem {
    return new ToolboxItem({
      id: dto.id,
      text: dto.text,
      categoryId: dto.toolBoxCategoryId,
      toolboxSequence:dto.toolBoxSequence,
    });
  }

  private mapToToolboxCategory(dto: ToolboxCategoryDto): ToolboxCategory {
    return new ToolboxCategory({
      id: dto.id,
      name: dto.name,
    });
  }
 
}
