import { Full } from '../../helpers/full';

import { ToolboxCategory } from './toolbox-category';

/**
 * Toolbox new item.
 */
export class ToolboxEditItem {
  /**
   * Text.
   */
  public text: string;

  /**
   * Category the new item belongs to.
   */
  public category: ToolboxCategory;

  /**
   * .ctor
   * @param data Initialization date.
   */
  constructor(data: Full<ToolboxEditItem>) {
    this.text = data.text;
    this.category = data.category;
  }
}
