import { OfferAssetUploadComponent } from './components/pages/offer-asset-upload/offer-asset-upload.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSpinner } from "@angular/material";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { ClipboardModule } from "ngx-clipboard";
import { NgxPermissionsModule } from "ngx-permissions";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatAutocompleteModule,MatInputModule} from '@angular/material';
import * as components from "./components/public_api";
import * as directives from "./directives/public_api";
import { MaterialModule } from "./material/material.module";
import { EnumKeyValuePipe } from "./pipes/enum-key-value.pipe";
import { FilterByCategoryPipe } from "./pipes/filter-by-category.pipe";
import { FilterByFaqIdPipe } from "./pipes/filter-by-faqid.pipe";
import * as pipes from "./pipes/public_api";
import { SubmitOfferInviteComponent } from './components/dialogs/submit-offer-invite/submit-offer-invite.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

/* This declarations shouldn't be exported. */
const innerDeclarations = [];
 
const outerDeclarations = [
  components.AssistantsTableComponent,
  components.FooterComponent,
  
  components.BaseStatusComponent,
  components.BorrowersTableComponent,
  components.LoanOfficersTableComponent,
  components.UserPaymentTableComponent,
  components.UserPaymentInfoTableComponent,
  components.OffersTableComponent,
  components.PaginationComponent,
  components.RealtorStatusComponent,
  components.SupportStatusComponent,
  components.RealtorsTableComponent,
  components.QuerysTableComponent,
  components.SupportTableComponent,
  components.AuthorizedLayoutComponent,
  components.HeaderComponent,
  components.OnboardingHeaderComponent,
  components.StatusBarComponent,
  components.BorrowerStatusComponent,
  components.OfferStatusComponent,
  components.BaseDialogComponent,
  components.LoanOfficerStatusComponent,
  components.AccountImageComponent,
  components.AccountInfoComponent,
  components.BaseAccountComponent,
  components.MessageComponent,
  components.OfferTemplatePageComponent,
  components.PreApprovalDocumentTemplatePageComponent,
  components.ToolboxPageComponent,
  components.ToolboxAdminPageComponent,
  components.ToolboxAdminComponent,
  components.OfferLetterPageComponent,
  components.DocumentEditorPageComponent,
  components.ToolboxComponent,
  components.LoanofficerCustomLetterPreviewComponent,
  components.BorrowerCustomLetterComponent,
  components.BorrowerLetterPageComponent,
  components.PdfEditorComponent,
  components.ImageEditorComponent,
  components.PdfPagePreviewComponent,
  components.PdfEditorComponent,
  components.ViewerPdfPageComponent,
  components.ViewerPdfComponent,
  components.BOPLetterPageComponent,
  components.OfferPreviewLetterPageComponent,
  components.OfferAssetUploadComponent,
  directives.ButtonProgressDirective,
  directives.SearchInputDirective,
  directives.AdminOnlyDirective,
  directives.LoanOfficerOnlyDirective,
  directives.RealtorOnlyDirective,
  pipes.EnumKeyValuePipe,
  pipes.FilterByCategoryPipe,
  pipes.FilterByFaqIdPipe,
  pipes.BorrowerLabelPipe,
];

const dialogComponents = [
  components.BaseDialogComponent,
  components.InfoDialogComponent,
  components.ConfirmDialogComponent,
  components.DenyPreApprovalDialogComponent,
  components.PreApprovalChangeStatusDialogComponent,
  components.ToolboxItemAddDialogComponent,
  components.ToolboxItemAdminAddDialogComponent,
  components.ToolboxItemEditDialogComponent,
  components.DocumentEditorDialogComponent,
  components.DocumentEditorDialogCustomPreapprovalComponent,
  components.UpdateOfferStatusDialogComponent,
  components.OfferPdfUrlDialogComponent,
  components.OfferSavedDialogComponent,
  components.DeactivateUserDialogComponent,
  components.OfferPackageUrlDialogComponent,
  components.AccountDocumentEditorDialogComponent,
  components.OfferAnalyticsDialogComponent,
  components.SubmitOfferInviteComponent,
  components.UrlInviteComponent,
  components.QueryItemAddDialogComponent,
  components.QueryItemEditDialogComponent,
  components.RaiseTicketDialogComponent,
  components.PermissionDialogComponent,
  components.SignaturePadComponent,
  components.ToolboxItemAdminEditDialogComponent,
  components.UpgradePlanDialogComponent,
  components.EditOfferDialogComponent,
  components.SupportingDocumentDialogComponent,
  components.CancleSubscriptionDialogComponent,
  components.ProductsConfigDialogComponent,
  components.CustomFieldInputComponent
];
// import { MenuchangeDirective } from "./directives/menuchange.directive";
/**
 * Global shared module.
 */
@NgModule({
  declarations: [
    ...innerDeclarations,
    ...outerDeclarations,
    ...dialogComponents,
    EnumKeyValuePipe,
    FilterByCategoryPipe,
    FilterByFaqIdPipe,
    SubmitOfferInviteComponent,
    // MenuchangeDirective,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgSelectModule,
    CKEditorModule,
    DragDropModule,
    MatAutocompleteModule,
    MatInputModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    NgxPermissionsModule,
    ...outerDeclarations,
  ],
  entryComponents: [MatSpinner, ...dialogComponents],
})
export class SharedModule {}
