import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { PDFPageProxy } from 'pdfjs-dist/webpack';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap, takeUntil, switchMap, mapTo, take, filter } from 'rxjs/operators';

import { DestroyableBase } from '../../../../core';
import { ConfirmDialogData } from '../../../models/confirm-dialog-data';
import { PdfEditor } from '../../../models/document-editor/pdf-editor';
import { MatButtonColor } from '../../../models/mat-button-color';
import { DialogService } from '../../../services/dialog.service';

/**
 * Pdf page preview component.
 */
@Component({
  selector: 'arb-pdf-page-preview',
  templateUrl: './pdf-page-preview.component.html',
  styleUrls: ['./pdf-page-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPagePreviewComponent extends DestroyableBase implements OnInit {



 checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  private readonly changePage$ = new Subject<number>();

  private pageNumberValue: number;

  /**
   * Loading stream.
   */
  public readonly isLoadingCanvas$ = new BehaviorSubject<boolean>(true);

  /**
   * Form control to select preview for saving.
   */
  public readonly previewSelectedControl = new FormControl();

  /**
   * `true` if preview is selected.
   */

  public isSelected$: Observable<boolean>;

  /**
   * Number of the page which preview represents this component.
   */
  @Input()
  public set pageNumber(value: number) {
    this.pageNumberValue = value + 1;
  }

  public get pageNumber(): number {
    return this.pageNumberValue;
  }

  @Input()
  public isChecked: Boolean = false;

  /**
   * Page object to perform first page render.
   */
  @Input()
  public page: PDFPageProxy;

  /**
   * Reference to pdf editor.
   */
  @Input()
  public editor: PdfEditor;

  /**
   * Reference to canvas.
   */
  @ViewChild('canvas')
  public canvasRef: ElementRef<HTMLCanvasElement>;

  /**
   * Reference to canvas.
   */
  @ViewChild('upperCanvas')
  public upperCanvasRef: ElementRef<HTMLCanvasElement>;

  /**
   * File.
   */
  @Input()
  public file: File;

  @Input()
  /**
   * sideScroll
   */
   sideSelect:boolean =false

  constructor(private dialogService: DialogService) {
    super();
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.isSelected$ = this.createIsSelectedStream();
    const canvas = this.canvasRef.nativeElement;
    const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
    const upperCanvas = this.upperCanvasRef.nativeElement;
    const upperCanvasContext = upperCanvas.getContext('2d') as CanvasRenderingContext2D;
    const viewport = this.page.getViewport({ scale: 1.5 });
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    upperCanvas.width = viewport.width;
    upperCanvas.height = viewport.height;
    this.page.render({ viewport, canvasContext }).promise.then(() => {
      const options = { type: 'image/png' };
      canvas.toBlob((blob: Blob) => {
        /* Name doesn't matter here. */
        this.isLoadingCanvas$.next(false);
        const file = new File([blob], this.file.name, options);
        this.editor.addRenderedPageInfo(this.pageNumber, {
          file,
          rectangles: [],
          previewCanvas: canvas,
          previewContext: canvasContext,
          previewUpperCanvas: upperCanvas,
          previewUpperContext: upperCanvasContext,
        });
      });
    });
    this.listenPreviewToggling();
    this.subscribeToChangePage();
    this.fillPreviewSelectedControl();
  }

  ngOnChanges(changes: any) {
    if(changes.isChecked) {
      this.previewSelectedControl.setValue(changes.isChecked.currentValue, { emitEvent: true });
    }
  }

  private fillPreviewSelectedControl(): void {
    this.isLoadingCanvas$.pipe(
      filter(isLoading => !isLoading),
      switchMap(() => this.editor.pages$),
      take(1),
      takeUntil(this.destroy$),
    ).subscribe(
      pages => {
        const isSinglePage = pages.length === 1;
        if (isSinglePage) {
          this.previewSelectedControl.setValue(true, { emitEvent: true });
        }
      },
    );
    this.previewSelectedControl.setValue(this.isChecked, { emitEvent: true });
  }

  private subscribeToChangePage(): void {
    this.changePage$.pipe(
      switchMap(() => this.createDeactivateEditorDialog()),
      takeUntil(this.destroy$),
    ).subscribe(() => this.editor.setPage(this.pageNumber));
  }

  private createDeactivateEditorDialog(): Observable<null> {
    if (!this.editor.canDeactivate) {
      return this.dialogService.openConfirmDialog(this.createConfirmDialogData()).pipe(
        switchMap(answer => {
          this.editor.canDeactivate = true;
          if (answer) {
            return this.editor.crop();
          } else {
            return of(null);
          }
        }),
        take(1),
        mapTo(null),
      );
    }
    return of(null);
  }

  private createConfirmDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title:
        'Do you want to apply cropper?',
      subtitle: '',
      acceptButtonColor: MatButtonColor.Primary,
      acceptButtonText: 'Yes',
      rejectButtonText: 'No',
    });
  }

  private createIsSelectedStream(): Observable<boolean> {
    return this.editor
      .currentPageIndex$
      .pipe(
        map(pageIndex => pageIndex === this.pageNumber),
      );
  }

  private listenPreviewToggling(): void {
    this.previewSelectedControl.valueChanges
      .pipe(
        tap(isSelected => {
          if (isSelected) {
            this.editor.addPageToSaveList(this.pageNumber);
          } else {
            this.editor.deletePageFromSaveList(this.pageNumber);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * Renders page of the preview.
   */
  public openPage(): void {
    this.changePage$.next(this.pageNumber);
  }

}
