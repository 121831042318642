import { Full } from '../helpers/full';

/**
 * Base class for objects with first name and last name.
 */
export class Named {

  /**
   * First name.
   */
  public firstName: string;

  /**
   * Last name.
   */
  public lastName: string;

  constructor(data: Full<Named>) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }

  /**
   * Returns full name.
   */
  public get fullName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }
    return `${this.firstName || ''}${this.lastName || ''}`;
  }

}
