import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { LoanOfficerService, LoanOfficer, GlobalProgressService, completedOrFailed } from '../../core';

/**
 * Loan officer profile resolver.
 */
@Injectable({
  providedIn: 'root',
})
export class LoanOfficerProfileResolver implements Resolve<LoanOfficer> {

  constructor(
    private loanOfficerService: LoanOfficerService,
    private globalProgressService: GlobalProgressService,
  ) { }

  /**
   * Fetches loan officer's profile info.
   */
  public resolve(): Observable<LoanOfficer> {
    this.globalProgressService.startLoading();
    return this.loanOfficerService
      .getProfile()
      .pipe(
        completedOrFailed(() => this.globalProgressService.stopLoading()),
      );
  }

}
