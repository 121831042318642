import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Message, MessageStatus } from '../../../../core';

/**
 * Message component.
 */
@Component({
  selector: 'arb-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {

  /**
   * All message statuses.
   */
  public readonly statuses = MessageStatus;

  /**
   * Message stream.
   */
  @Input()
  public message$: Observable<Message | null>;

}
