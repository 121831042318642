import { readFile } from './read-file';

/**
 * Creates image from uploaded file.
 * @param file Uploaded file.
 */
export class CKeditorImageAdapter {
  private loader: any;
  private server: any;
  constructor( loader ) {
      // The file loader instance to use during the upload.
      this.loader = loader;
  }

  // Starts the upload process.
  upload() {
      // Update the loader's progress.
      // this.server.onUploadProgress( data => {
      //     this.loader.uploadTotal = data.total;
      //     this.loader.uploaded = data.uploaded;
      // } );

      // Return a promise that will be resolved when the file is uploaded.
      return this.loader.file
          .then(
            file => {
              return new Promise(function (resolve , reject)  {
                var reader = new FileReader();
                reader.onload = function(event: any) {
                  resolve({
                    default: event.target.result,
                  })
                };
                reader.onerror = function (error:any) {
                  reject(error);
                }
                reader.readAsDataURL(file);
                console.log("file", file);

                
              })
            
            }
           );
  }

  // Aborts the upload process.
  abort() {
      // Reject the promise returned from the upload() method.
      this.server.abortUpload();
  }
}
