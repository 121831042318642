/**
 * Renderer type.
 */
export enum RendererType {

  /**
   * Cropper.
   */
  Cropper,

  /**
   * Rectangle renderer.
   */
  RectangleRenderer,
  RectangleRendererHighlighter,
  PreApprovalContentRenderer,
  /**
   * None.
   */
  None,

}
