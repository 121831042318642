import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { UserStatus, toReadableUserStatus, RolesService } from '../../../../core';
import { StatusColor } from '../../../models/status-color';
import { UserStatusConfig } from '../../../models/user-status-config';

/**
 * Loan officer status component.
 */
@Component({
  selector: 'arb-loan-officer-status',
  templateUrl: './loan-officer-status.component.html',
  styleUrls: ['./loan-officer-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanOfficerStatusComponent {

  private readonly colors = this.createColors();
  private statusConfigValue = this.createConfig();
  private statusValue: UserStatus;

  /**
   * All statuses.
   */
  public statuses = UserStatus;

  /**
   * Status color.
   */
  public color: StatusColor;

  public btnConfig: boolean = false;

  public isShow: boolean = false;

  @Input()
  public isShowResend;
  /**
   * Status config.
   */
  @Input()
  public set config(value: UserStatusConfig) {
    this.statusConfigValue = { ...this.statusConfigValue, ...value };
  }

  public get config(): UserStatusConfig {
    return this.statusConfigValue;
  }

  /**
   * Status.
   */
  @Input()
  public set status(value: UserStatus) {
    this.updateStatus(value);
  }

  /**
   * Emits new status.
   */
  @Output()
  public statusChange = new EventEmitter<UserStatus>();

  /**
   * Emits event to resend invite.
   */
  @Output()
  public resendInvite = new EventEmitter<void>();

  /**
   * Emits event to generate invite.
   */
  @Output()
  public generateInvite = new EventEmitter<void>();


  /**
   * lo Btn Config to show button
   */
  @Input() set loBtnConfig(value: boolean) {

    this.btnConfig = value;
  }
  @Input() set isShowInTable(value: boolean) {
    this.isShow = value;
  }

  constructor(
    private rolesService: RolesService,
  ) { }

  private createColors(): Record<UserStatus, StatusColor> {
    return {
      [UserStatus.Active]: StatusColor.Green,
      [UserStatus.Deactivated]: StatusColor.Gray,
      [UserStatus.Invited]: StatusColor.Blue,
    };
  }

  private createConfig(): UserStatusConfig {
    return {};
  }

  private updateStatus(status: UserStatus): void {
    this.statusValue = status;
    this.color = this.colors[status];
  }

  /**
   * Returns `true` if status is `Activated`.
   */
  public get activated(): boolean {
    return this.statusValue === UserStatus.Active;
  }

  /**
   * Returns `true` if status is `Deactivated`.
   */
  public get deactivated(): boolean {
    return this.statusValue === UserStatus.Deactivated;
  }

  /**
   * Returns `true` if status is `Invited`.
   */
  public get invited(): boolean {
    return this.statusValue === UserStatus.Invited;
  }

  /**
   * Returns readable string status.
   */
  public get statusText(): string {
    return toReadableUserStatus(this.statusValue);
  }

  /**
   * Emits new status.
   * @param status New status.
   */
  public changeStatus(status: UserStatus): void {
    this.statusChange.emit(status);
  }

  /**
   * Emits loan officer's id to resend invite.
   */
  public onResendInvite(): void {
    this.resendInvite.emit();
  }
  public onGenerateInvite(): void {
    this.generateInvite.emit();
  }

}
