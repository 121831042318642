import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Sort } from '@angular/material';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { ConfirmDialogData } from '../../../models/confirm-dialog-data';
import { UserRoleId } from '../../../../core/models/user-role-ids';
import { MatButtonColor } from '../../../models/mat-button-color';
import { DialogService } from '../../../services/dialog.service';
import { BorrowerService, ErrorMessage } from '../../../../core';
import {
  BorrowerSummary,
  PreApprovalStatus,
  OfferStatus,
  PageEvent,
  PaginationMetadata,
  Table,
  toReadableBorrowerStatus,
  toReadableOfferStatus,
  SortDirection,
  BorrowersSearchParams,
  SortConfig,
  DestroyableBase,
  partitionTable,
  listenControlChanges,
  UserRole,
  RolesService,
  Message,
  SuccessMessage,
} from '../../../../core';

import { BorrowersColumnName } from '../../../models/borrowers-column-name';
import { BorrowersSecondHeaderColumnName } from '../../../models/borrowers-second-header-column-name';

/**
 * Columns table.
 */
const COLUMNS:BorrowersColumnName[] = [
  'id',
  'borrower',
  'coborrower',
  'loanOfficer',
  'realtor',
  'preapprovalstatus',
  'offerstatus',
  "action",
  'lastupdated',
];

const COLUMNS_FOR_LOAN_OFFICER:BorrowersColumnName[] = [
  'borrower',
  'coborrower',
  'realtor',
  'preapprovalstatus',
  'offerstatus',
  "action",
  'lastupdated',
];

const COLUMNS_FOR_REALTOR:BorrowersColumnName[] = [
  'borrower',
  'coborrower',
  'loanOfficer',
  'preapprovalstatus',
  'offerstatus',
  "action",
  'lastupdated',
];

/**
 * Second header table columns.
 */
const SECOND_HEADER_COLUMNS: BorrowersSecondHeaderColumnName[] = [
  'empty',
  'search_name',
  'search_co_borrower_name',
  'search_name_loan_officer',
  'search_name_realtor',
  'select_approval_status',
  'select_offer_status',
  'empty',
  'empty',
];

const SECOND_HEADER_COLUMNS_FOR_LOAN_OFFICER: BorrowersSecondHeaderColumnName[] = 
[
  'search_name',
  'search_co_borrower_name',
  'search_name_realtor',
  'select_approval_status',
  'select_offer_status',
  'empty',
  'empty',
];

const SECOND_HEADER_COLUMNS_FOR_REALTOR: BorrowersSecondHeaderColumnName[] = 
[
  'search_name',
  'search_co_borrower_name',
  'search_name_loan_officer',
  'select_approval_status',
  'select_offer_status',
  'empty',
  'empty',
];

/**
 * Borrowers table.
 */
@Component({
  selector: 'arb-borrowers-table',
  templateUrl: './borrowers-table.component.html',
  styleUrls: ['./borrowers-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorrowersTableComponent extends DestroyableBase implements OnInit {

  public readonly message$ = new Subject<Message | null>();

  private readonly sort$ = new Subject<string>();

  /**
   * Borrowers' stream.
   */
  public borrowers$: Observable<BorrowerSummary[]>;

   /**
   * User Role Ids Admin = 1, Realtor = 2, Loan officer = 3
   */
  public userRoleIds$: any = UserRoleId;

  /**
   * This is for displaying default `All Statuses` option.
   */
  public readonly allStatuses = null;

  /**
   * All borrower statuses.
   */
  public preApprovalStatuses = PreApprovalStatus;

  /**
   * All realtors statuses.
   */
  public offerStatuses = OfferStatus;

  /**
   * Selected offer status.
   */
  public selectedOfferStatus: OfferStatus | null = this.allStatuses;

  /**
   * Form control for borrower's name.
   */
  public borrowerControl = new FormControl();

  /**
   * Form control for co-borrower's.
   */
  public coBorrowerControl = new FormControl();

  /**
   * Stream with page index.
   */
  public pageEvent$ = new BehaviorSubject<number>(1);
  /**
   * Stream with pagination metadata.
   */
  public paginationMetadata$: Observable<PaginationMetadata>;

  /**
   * Get a readable representation of a status.
   */
  public toBorrowerStatusText = toReadableBorrowerStatus;

  /**
   * Get a readable offer representation of a status..
   */
  public toOfferStatusText = toReadableOfferStatus;

  public tableIsEmpty;

  /**
   * Form control for realtor.
   */
  public realtorControl = new FormControl();

  /**
   * Form control for loan officer.
   */
  public loanOfficerControl = new FormControl();

  /**
   * Form control for pre approval status.
   * The default value have to be empty string.
   * It's the only way to show default `All Statuses` option.
   */
  public preApprovalStatusControl = new FormControl('');

  /**
   * Form control for offer status.
   */
  public offerStatusControl = new FormControl('');

  /**
   * Form control for pre approval status.
   * The default value have to be empty string.
   * It's the only way to show default `All Statuses` option.
   */
  @Input()
  public sortConfig = new SortConfig<BorrowersColumnName>('borrower', SortDirection.Asc);

  /**
   * Flag for pagination display.
   */
  @Input()
  public pagination: boolean;

  /**
   * Stream with pagination and data source.
   */
  @Input()
  public table$: Observable<Table<BorrowerSummary>>;

  /**
   * Displayed columns.
   */
  @Input()
  public displayedColumns = COLUMNS;

  /**
   * Second header columns.
   */
  @Input()
  public secondHeaderColumns = SECOND_HEADER_COLUMNS;

  /**
   * If `true` then information about associated realtor or loan officer will be displayed.
   */
  @Input()
  public displayUpdatedByUserName: boolean;

  /**
   * True if a small version of table is enabled.
   */
  @Input()
  public small: boolean;

  @Input()
  public UserRole = UserRole;

  @Input() 
  public status: PreApprovalStatus;

  /**
   * Search borrowers change.
   */
  @Output()
  public searchChange = new EventEmitter<BorrowersSearchParams>();

  /**
   * @inheritdoc
   */
  public readonly isAdmin$ = this.rolesService.isAdmin$;

  public readonly isLoanOfficer$=this.rolesService.isLoanOfficer$;

  public readonly isRealtor$=this.rolesService.isRealtor$;
  
  public isRealtor: boolean;
  public isLoanOfficer:boolean;

   constructor(
    private rolesService: RolesService,
    private dialogService: DialogService,
    public borrowerService: BorrowerService,
  ) {
    super();
  }
  public ngOnInit(): void {
    this.rolesService.isRealtor$.subscribe(res => {
      this.isRealtor = res;
    });

    this.rolesService.isLoanOfficer$.subscribe(res=>{
      this.isLoanOfficer=res;
    })

    this.rolesService.isAdmin$.subscribe(isAdmin => {
      if(!isAdmin && this.isLoanOfficer) {
        this.displayedColumns = COLUMNS_FOR_LOAN_OFFICER
        this.secondHeaderColumns = SECOND_HEADER_COLUMNS_FOR_LOAN_OFFICER
      }
      if(!isAdmin && this.isRealtor){
        this.displayedColumns = COLUMNS_FOR_REALTOR
        this.secondHeaderColumns = SECOND_HEADER_COLUMNS_FOR_REALTOR
      }
    });
  
    this.listenSearchParamsChange();
    this.emitDefaultSortValueStream();
    [this.borrowers$, this.paginationMetadata$] = partitionTable(this.table$);
  }

  public ngOnChanges(): void {
    this.passStatusTotable(this.status);
    // console.log("Status value", this.status);
    this.table$.subscribe(res =>{

      if(res.data && (res.data.length>0))
      {
        this.tableIsEmpty=false;
      }
      else
      {
        this.tableIsEmpty=true;
      }

    });
  
  }

  public get statusControl(): AbstractControl | null {
    return this.preApprovalStatusControl as AbstractControl
  }

  passStatusTotable(status) {
      if(this.statusControl != null && status != undefined){
        this.statusControl.patchValue(status);
      }
  }

  private emitDefaultSortValueStream(): void {
    this.sort$.next(SortConfig.config(this.sortConfig.column, this.sortConfig.direction));
  }

  private listenSearchParamsChange(): void {
    const search$ = this.createSearchStream();
    combineLatest(
      search$,
      this.pageEvent$,
    )
      .pipe(
        /* To prevent twice emiting when `search$` was changed.
        When it emits at first time the parent component starts loading and new search request.
        At the second time parent component cancells prev request (switchMap) and `finalize` operator turns off progress bar immediately.*/
        debounceTime(0),
        map(([[preApprovalStatus, offerStatus, borrowerName, coBorrower, realtor, loanOfficer, orderBy], page]) => ({
          borrowerName,
          coBorrower,
          preApprovalStatus,
          realtor,
          loanOfficer,
          offerStatus,
          orderBy,
          page,
        }) as BorrowersSearchParams),
        tap(params => this.searchChange.emit(params)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private createSearchStream(): Observable<[PreApprovalStatus, OfferStatus, string, string, string, string, string]> {
    return combineLatest(
      listenControlChanges<PreApprovalStatus>(this.preApprovalStatusControl, 0),
      listenControlChanges<OfferStatus>(this.offerStatusControl, 0),
      listenControlChanges<string>(this.borrowerControl),
      listenControlChanges<string>(this.coBorrowerControl),
      listenControlChanges<string>(this.realtorControl),
      listenControlChanges<string>(this.loanOfficerControl),
      this.sort$,
    )
      .pipe(
        tap(() => this.resetPagination()),
      ) as Observable<[PreApprovalStatus, OfferStatus, string, string, string, string, string]>;
  }

  /**
   * On page change.
   * @param pageEvent page event.
   */
  public onPageChange(pageEvent: PageEvent): void {
    this.pageEvent$.next(pageEvent.pageIndex);
  }

  /**
   * On sort change.
   * @param sortEvent sort event.
   */
  public onSortChange(sortEvent: Sort): void {
    this.sort$.next(SortConfig.fromEvent(sortEvent));
  }

  private resetPagination(): void {
    this.pageEvent$.next(1);
  }

  /**
   * Delete offer button.
   * @param offer Offer.
   */
  public async handleDeleteBorrower(itemId: number) {
    
    const data = this.createDeleteBorrowerDialogData();
    const deleted = await this.dialogService.openConfirmDialog(data).toPromise();
    if (deleted) {
      this.borrowerService.delete(itemId).subscribe(item => {
        if(this.isRealtor) {
          this.message$.next(new SuccessMessage('Client deleted successfully'));
        } else {
          this.message$.next(new SuccessMessage('Borrower deleted successfully'));
        }
        this.clearMessage();
        this.pageEvent$.next(0);
        return item;  
      },(err) => {
        this.message$.next(new ErrorMessage('Oops, something went wrong'))
        this.clearMessage();
      });
    }
  }

  clearMessage() {
    setTimeout(() => {
      this.message$.next(null);
    }, 3000);
  }
  
  private createDeleteBorrowerDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: this.isRealtor ? 'Delete Client?' : 'Delete Borrower?',
      subtitle: this.isRealtor ? 'Are you sure you want to delete this client?' : 'Are you sure you want to delete this Borrower?',
      acceptButtonColor: MatButtonColor.Warn,
      acceptButtonText: 'Delete',
      rejectButtonText: 'Cancel',
    });
  }

  public getUserRole(borrower) {
    try {
      if(borrower.deletedRole == this.userRoleIds$.LoanOfficer) {
        return borrower.loanOfficer.firstName + ' ' + borrower.loanOfficer.lastName;
      }
      else if(borrower.deletedRole == this.userRoleIds$.Realtor) {
        return borrower.realtor.firstName + ' ' + borrower.realtor.lastName; 
      }
      else {
        return ""
      }
    }
    catch(ex) {
      console.log("exception occured at", ex);
    }
  }

}

