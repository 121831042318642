import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

/**
 * Deactivate user dialog component.
 */
@Component({
  selector: 'arb-deactivate-user-dialog',
  templateUrl: './deactivate-user-dialog.component.html',
  styleUrls: ['./deactivate-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeactivateUserDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public fullName: string,
    private dialogRef: MatDialogRef<DeactivateUserDialogComponent>,
  ) { }

  /**
   * Closes the dialog with `true` value.
   */
  public deactivate(): void {
    this.dialogRef.close(true);
  }

}
