/**
 * Color of material button.
 */
export enum MatButtonColor {

  /**
   * Default color.
   */
  Default = 'default',

  /**
   * Primary color.
   */
  Primary = 'primary',

  /**
   * Warn color.
   */
  Warn = 'warn',

}
