import { Full } from '../../helpers/full';


export class QueryNewItem {
  /**
   * Text.
   */
  public name: string;

 
  /**
   * .ctor
   * @param data Initialization date.
   */
  constructor(data: Full<QueryNewItem>) {
    this.name = data.name;
  }
}
