import { UpdateRealtorDto } from './../../../../core/services/dto/update-realtor-dto';

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnInit 
} from "@angular/core";
import { Subject,combineLatest, merge, Observable, BehaviorSubject, pipe } from "rxjs";
import { switchMap, share ,tap,map,take, takeUntil} from "rxjs/operators";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ActivatedRoute } from '@angular/router';

import {
  ToolboxService,
  NotificationService,
  ToolboxItem,
  CKeditorImageAdapter,
  RealtorsService,
  catchHttpErrorMessage,
  DestroyableBase,
  completedOrFailed,
  Message,
  SuccessMessage,
  ToolboxNewItem,
  CurrentUserService,
  RealtorSummary,
  partitionTable,
  Table,
  UserStatus,
  ErrorMessage
} from "../../../../core";
import { ConfirmDialogData } from "../../../models/confirm-dialog-data";
import { MatButtonColor } from "../../../models/mat-button-color";
import { DialogService } from "../../../services/dialog.service";
import { ToolboxCategory } from '../../../../core/models/toolbox/toolbox-category';

/**
 * Toolbox component.
 */
@Component({
  selector: "arb-toolbox",
  templateUrl: "./toolbox.component.html",
  styleUrls: ["./toolbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolboxComponent extends DestroyableBase implements OnInit {
  private readonly forceReload$ = new Subject<void>();
  private readonly initialItems$ = this.toolboxService.getItems();
  private readonly reload$ = this.createReloadStream();
  // private readonly reloadRealtor$=this.createReloadRealtorStream();

  public readonly disableOnAddDefault$ = new Subject<boolean>();

  /**
   * List of all the toolbox items.
   */
  public  items$ = this.createItemsStream();
  
  /**
   * List of all the toolbox categories.
   */

  
  public readonly categories$ = this.toolboxService.categories$;
  

  /**
   * emailSignature
   */
  public emailSignature: string = "";
 /**
  * RealtorProfile
   */
  public realtorProfile;
  public realtorId:number;
  
  public readonly isLoading$ = new BehaviorSubject(false);

  
  
  
/**
   * Success or error message stream.
   */
  public readonly message$ = new Subject<Message | null>();
  
  private readonly update$ = new Subject<UpdateRealtorDto>();
  /*public readonly realtors$ = this.createRealtorsStream();
  private createRealtorsStream(): Observable<RealtorSummary[]> {
    return pipe(map(params=>()))
  }
  */

  // public readonly realtor$ = this.createRealtorStream();

  /**
   * If `true` then button will be displayed.
   * Otherwise just text will be displayed.
   */
  @Input()
  public useButton = true;
  
  @Input()
  public hideBtns: any = [];

  @Output() emittedToolboxTemplateType = new EventEmitter();

  public CKEDITOR_INST;
  public CKEDITOR_CONFIG= {
    image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ],

        styles: [
            // This option is equal to a situation where no style is applied.
            'full',

            // This represents an image aligned to the left.
            'alignLeft',

            // This represents an image aligned to the right.
            'alignRight'
        ]
    }
};
public Editor = ClassicEditor;
  constructor(
    private toolboxService: ToolboxService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private realtorService:RealtorsService,
    private currentUserService: CurrentUserService
  ) {
   super();

  }



public realtorCategories$;

/*public select_realtorId;
public onSelect(value):void
{
  this.select_realtorId=value;
  //this.realtorCategories$=this.toolboxService.getCategoriesRealtor(this.select_realtorId);
  this.items$=this.toolboxService.getItemsRealtor(this.select_realtorId);
  console.log(this.select_realtorId);
}*/
/*private createTotalBorrowerCountStream(): Observable<RealtorSummary[]> {
  return  this.realtorService.getR().pipe(take(1),map(response => response.data))  
}*/
/*
private createRealtorsStream(): Observable<RealtorSummary[]> {
  this.forceReload$.next();
  return merge(this.currentUserService.currentUser$
    .pipe(
      take(1),
      tap(() => this.isLoading$.next(true)),
      map(({ id }) => id),
      switchMap(data => combineLatest(
        this.realtorService.search({}),
      ).pipe(completedOrFailed(() => this.isLoading$.next(false)),)  
      ),
      map(([{ data },]) => data),share(),
      ),this.reloadRealtor$);
}
public realtor$:Observable<RealtorSummary[]>;*/


ngOnInit() {
  // this.loadRealtor();
  this.loadRealtorId();
  //this.realtor$=this.createRealtorsStream();

  this.items$.subscribe((data) => {
    if (data.length == 0) {
      this.addDefaultData();
    }
  });
  this.disableOnAddDefault$.next(false);
  // this.listenProfileUpdating();
}

  private addDefaultData() {
      this.realtorService.getProfile().pipe(
        map((realtor)=>{
          this.realtorId=realtor.id;
        }),
        tap(async (data) => {
          await this.toolboxService.addDefault(this.realtorId,  new ToolboxNewItem({ category: new ToolboxCategory({id:3, name:"dummy"}), text:"dummy" })).toPromise();
          this.forceReload$.next();      
        })
      ).subscribe();
  }
  public isShowBtn(btn: any) {
    return this.hideBtns.indexOf(btn) > -1;
  }
  // private createReloadRealtorStream(): Observable<RealtorSummary[]> {
  //   return this.forceReload$.pipe(
  //     switchMap(() => this.realtorService.getR().pipe(take(1),map(response => response.data))),
  //   );
  // }
  private createReloadStream(): Observable<ToolboxItem[]> {
    return this.forceReload$.pipe(
      switchMap(() => this.toolboxService.getItems())
    );
  }

  private createItemsStream(): Observable<ToolboxItem[]> {
    return merge(this.initialItems$, this.reload$).pipe(share());
  }

  /**
   * Shows the add a new item form. Reloads the item list after adding a new item.
   */
  public async openAddDialog(): Promise<void> {
    const itemAdded = await this.dialogService
      .openToolboxAddDialog()
      .toPromise();
    if (itemAdded) {
      this.forceReload$.next();
      this.message$.next(new SuccessMessage('New item added successfully'));
      // setTimeout(() => {
      //   this.message$.next();
      // }, 2000);
    }
  }

  /**
   * Shows the add a new item form. Reloads the item list after adding a new item.
   */
  public async openAddDefaultItems(): Promise<void> {
    const data = this.createConfirmInsertDialogData();
    const addDefault = await this.dialogService
      .openConfirmDialog(data)
      .toPromise();
    if (addDefault) {
      await this.toolboxService.addDefault(this.realtorId,  new ToolboxNewItem({ category: new ToolboxCategory({id:3, name:"dummy"}), text:"dummy" })).toPromise().then(
        () => {
          this.disableOnAddDefault$.next(true);
          this.forceReload$.next();
          this.message$.next(new SuccessMessage('Default items added successfully'))
          // setTimeout(() => {
          //   this.message$.next();
          // }, 2000);
        },
        () => {
          this.disableOnAddDefault$.next(false);
          this.message$.next(new ErrorMessage('Oops, something went wrong'))
        }
      );
      // this.disableOnAddDefault$.next(true);
    }
  }  

  /**
   * Shows the deleting confirmation dialog.
   * @param itemId Id of the deleting item.
   */
  public async openDeleteDialog(itemId: number, category: any): Promise<void> {
    const data = this.createConfirmDialogData();
    const deleted = await this.dialogService
      .openConfirmDialog(data)
      .toPromise();
    if (deleted) {
      await this.toolboxService.deleteItem(itemId).toPromise().then(
        () => {
          this.disableOnAddDefault$.next(false);
          this.forceReload$.next();
          this.message$.next(new SuccessMessage('Toolbox item deleted successfully'));
          // setTimeout(() => {
          //   this.message$.next();
          // }, 2000);
        },
        (err) => {
          // console.log(err);
          this.message$.next(new ErrorMessage('Oops, something went wrong'))
        }
      );
    }
    category = { ...category, expanded: false };
  }

  /**
   * Shows the edit dialog
   * @param itemId Id of the editing item item.
   *
   */
  public async openEditDialog(
    itemId: number,
    itemText: string,
    itemCategoryId: number,
    category: any
  ): Promise<void> {
    const itemEdited = await this.dialogService
      .openToolboxEditDialog(itemId, itemText, itemCategoryId)
      .toPromise();
    if (itemEdited) {
      this.forceReload$.next();
      this.message$.next(new SuccessMessage('Toolbox item updated successfully'));
      // setTimeout(() => {
      //   this.message$.next();
      // }, 2000);
    }
    category = { ...category, expanded: false };
  }

  /**
   * Informs the user that the item's text has been successfully copied to the clipboard.
   */
  public onCopySuccess(): void {
    this.notificationService.showNotification(
      "Toolbox text copied! Paste it into a field on the left."
    );
  }

  private createConfirmDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: "Delete Item?",
      subtitle:
        "Are you sure you want to delete this item? It will no" +
        "\nlonger be accessible in your toolbox.",
      acceptButtonColor: MatButtonColor.Warn,
      acceptButtonText: "Delete",
      rejectButtonText: "Cancel",
    });
  }
  private createConfirmInsertDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: "Add Default Item?",
      subtitle:
        "Are you sure you want to add default items in toolbox? (Existing items will NOT be deleted)",
      acceptButtonColor: MatButtonColor.Primary,
      acceptButtonText: "Add",
      rejectButtonText: "Cancel",
    });
  }

  public emitOfferTemplate(offer): void {
    this.emittedToolboxTemplateType.emit(offer);
  }

  public initializeEditor(params): void {
    this.CKEDITOR_INST = params;
    this.CKEDITOR_INST.setData(this.emailSignature);
    params.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      return new CKeditorImageAdapter( loader );
    };
  }

  public handleBodyChange({ editor }: any) {
    this.emailSignature = editor.getData();
  }
  private listenProfileUpdating(): void {
    this.update$
      .pipe(
        tap(() => this.message$.next(null)),
        switchMap(data =>
          this.realtorService.updateEmailSignature(data.emailSignature, this.realtorId)
            .pipe(
              tap(() => this.message$.next(new SuccessMessage('Profile has been successfully updated'))),
              completedOrFailed(() => this.message$.next(null)),
              catchHttpErrorMessage({message$: this.message$}),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public updateEmailSignature2(){
    this.update$.pipe(
        tap(() =>{ this.message$.next(null)}),
        map(form => new UpdateRealtorDto({
          companyName:this.realtorProfile.companyName,
          footerColor:this.realtorProfile.footerHexColor,
          headerColor:this.realtorProfile.headerHexColor,
          iconsColor :this.realtorProfile.iconsHexColor,
          firstName: this.realtorProfile.firstName,
          lastName: this.realtorProfile.lastName,
          email: this.realtorProfile.email,
          phoneNumber: this.realtorProfile.phoneNumber,
          officeAddress: this.realtorProfile.officeAddress,
          website: this.realtorProfile.website,
          realtorLogo:this.realtorProfile.realtorLogo,
          companyLogo:this.realtorProfile.companyLogo,
          realtorPicture:this.realtorProfile.realtorPicture,
          signaturePicture:this.realtorProfile.signaturePicture,
          pictureRemoved:this.realtorProfile.realtorPictureRemoved,
          companyLogoRemoved:this.realtorProfile.companyLogoRemoved,
          signatureRemoved:this.realtorProfile.signaturePictureRemoved,
          logoRemoved:this.realtorProfile.realtorLogoRemoved,
          emailSignature: this.emailSignature,
          timeZone: this.realtorProfile.timeZone
        })),
        tap(data =>
          this.realtorService.updateProfile(data)
            .pipe(
              tap(() => this.message$.next(new SuccessMessage('Email Signature has been successfully updated'))),
              completedOrFailed(()=>{
                this.message$.next(null);
              }),
              catchHttpErrorMessage({message$: this.message$}),
            ),
        ),
        takeUntil(this.destroy$),
      ).subscribe();
   
  }
  public updateEmailSignature(): void {
    this.realtorProfile.emailSignature= this.emailSignature;
    this.update$.next(this.realtorProfile);
  }

  public loadRealtorId(){
    this.realtorService.getProfile().pipe(
      map((realtor)=>{
        this.realtorId=realtor.id;
      })
    ).subscribe()
  }
  public loadRealtor(){
    this.realtorService.getProfile().pipe(
      tap((data)=>console.log(data)),
      map((realtor)=>{
        this.emailSignature = realtor.emailSignature;
        this.realtorProfile=realtor;
        this.realtorId=realtor.id;
        this.CKEDITOR_INST.setData(this.emailSignature);
      })
    ).subscribe()
  }

  public viewSampleOffer(){
    const uri = "https://arbor-production-bucket.s3-us-west-2.amazonaws.com/offers/newdemo+client_offer_.pdf";
    var link = document.createElement('a');
    link.setAttribute('href',uri );
    link.setAttribute('target', "_blank");
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
