import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

/**
 * Search input directive.
 */
@Directive({
  selector: 'input[arbSearchInput]',
})
export class SearchInputDirective implements OnInit, OnDestroy {

  private input: HTMLInputElement;
  private callback: () => void;
  private unlistenFn: Function;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.input = this.el.nativeElement;
    this.renderer.addClass(this.input, 'search-input');
    this.callback = this.handleChange.bind(this);
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.unlistenFn = this.renderer.listen(this.input, 'input', this.callback);
  }

  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.unlistenFn();
  }

  private handleChange(): void {
    if (this.input.value) {
      this.hideSearchIcon();
    } else {
      this.showSearchIcon();
    }
  }

  private showSearchIcon(): void {
    this.renderer.removeClass(this.input, 'hide-search-icon');
  }

  private hideSearchIcon(): void {
    this.renderer.addClass(this.input, 'hide-search-icon');
  }

}
