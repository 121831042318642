// import { MatDialogRef } from '@angular/material';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { listenControlChanges } from 'src/app/core';
// import {MatDialogModule } from '@angular/material/dialog';
// import { OfferStatus } from '../../../../core';

/**
 * Update offer status dialog component.
 */
@Component({
  selector: 'edit-offer-dialog',
  templateUrl: './edit-offer-dialog.component.html',
  styleUrls: ['./edit-offer-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditOfferDialogComponent { 


  public customPreApprovalBtnFlag = true;
  public readonly agreementControl = new FormControl(false);
  
  public readonly allowSave$ = listenControlChanges<boolean>(this.agreementControl, 0);
  public files: File[] = [];


  // private readonly statuses = OfferStatus;

  /**
   * Form control for offer statuses.
   */
  // public readonly statusControl = new FormControl(this.accepted);

  constructor(public dialog: MatDialog,private router: Router ) {}

  openDialog() {
    this.dialog.open(EditOfferDialogComponent);
  }

  gotoUpdatePlan(){

    this.router.navigate(['/dashboard/plans']);

  }

  // public save(): void {
  //   this.MatDialog.close(this.files);
  // }

  // public viewOffer(): void {
    
  //       this.router.navigate(['', this.]);
  // }
  // constructor(

  //   private matDialogRef: MatDialogRef<UpgradePlanDialogComponent>,
  // ) { }

  /**
   * Submitted status.
   */
  // public get submitted(): OfferStatus {
    // return this.statuses.Submitted;
  }

  /**
   * Accepted status.
   */
  // public get accepted(): OfferStatus {
    // return this.statuses.Accepted;
  // }

  /**
   * Rejected status.
   */
  // public get rejected(): OfferStatus {
    // return this.statuses.Rejected;
  // }

  /**
   * Closes the dialog with selected status.
   */
  // public onSave(): void {
  //   this.matDialogRef.close(this.statusControl.value);
  // }

// }
