import { Subject, merge, BehaviorSubject, Observable } from 'rxjs';
import { filter, tap, takeUntil, switchMap, map, mapTo, startWith, debounceTime, switchMapTo } from 'rxjs/operators';

import { createImage, weakShareReplay } from '../../../../core';
import { PdfEditor } from '../pdf-editor';
import { Point } from '../point';
import { Rectangle } from '../rectangle';

import { PdfEditorRendererOptions } from './pdf-editor-renderer-options';
import { PdfRenderer } from './pdf-renderer';

/**
 * Pdf cropper renderer.
 */
export class PdfCropperRenderer extends PdfRenderer {

  private readonly circleRadius: number;
  private readonly crop$: Subject<void>;
  private readonly cropped$: Observable<File>;
  private readonly cropperStyle: string;
  private topLeftSelected: boolean;
  private topRightSelected: boolean;
  private bottomLeftSelected: boolean;
  private bottomRightSelected: boolean;
  private cropArea: Rectangle;
  private currentImage: HTMLImageElement;
  private prevImage: HTMLImageElement;

  /**
   * Is cropped area changed.
   */
  public isCroppedAreaChanged = false;

  constructor(editor: PdfEditor, options: PdfEditorRendererOptions) {
    super(editor, options);
    this.circleRadius = 10;
    this.cropperStyle = 'orange';
    this.crop$ = new Subject();
    this.cropped$ = this.createCroppedStream();
    this.init(this.currentPageInfo.file);
    this.listenMouseDown();
    this.listenMouseMove();
    this.listenFilesChange();
  }

  private init(file: File): void {
    createImage(file)
      .pipe(
        tap(img => {
          this.cropArea = new Rectangle(new Point(0, 0), new Point(img.width, img.height));
          this.prevImage = img;
          this.currentImage = img;
          this.canvas.width = this.cropArea.width;
          this.canvas.height = this.cropArea.height;
        }),
        tap(() => this.calculateDifference()),
        tap(() => this.render()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private render(): void {
    this.context.drawImage(this.currentImage, 0, 0);
    this.drawCropArea();
    this.drawCircles();
  }

  private drawCircles(): void {
    this.drawCircle(this.cropArea.x, this.cropArea.y);
    this.drawCircle(this.cropArea.x + this.cropArea.width, this.cropArea.y);
    this.drawCircle(this.cropArea.x, this.cropArea.y + this.cropArea.height);
    this.drawCircle(this.cropArea.x + this.cropArea.width, this.cropArea.y + this.cropArea.height);
  }

  private drawCircle(x: number, y: number): void {
    this.context.fillStyle = this.cropperStyle;
    this.context.beginPath();
    this.context.arc(x, y, this.circleRadius, 0, 2 * Math.PI);
    this.context.fill();
  }

  private drawCropArea(): void {
    this.context.beginPath();
    this.context.rect(this.cropArea.x, this.cropArea.y, this.cropArea.width, this.cropArea.height);
    this.context.strokeStyle = this.cropperStyle;
    this.context.lineWidth = 4;
    this.context.stroke();
  }

  private calculateDifference(): void {
    this.diffX = Number((this.canvas.width / this.canvasMaxWidth).toFixed(2));
    const realHeight = (this.canvasMaxWidth * this.canvas.height) / this.canvas.width;
    this.diffY = Number((this.canvas.height / realHeight).toFixed(2));
    this.diffX = this.diffX < 1 ? 1 : this.diffX;
    this.diffY = this.diffY < 1 ? 1 : this.diffY;
  }

  private listenMouseDown(): void {
    this.mouseDown$
      .pipe(
        tap(point => {
          this.topLeftSelected = this.isCloseEnought(point.x, this.cropArea.x)
            && this.isCloseEnought(point.y, this.cropArea.y);
          this.topRightSelected = this.isCloseEnought(point.x, this.cropArea.x + this.cropArea.width)
            && this.isCloseEnought(point.y, this.cropArea.y);
          this.bottomLeftSelected = this.isCloseEnought(point.x, this.cropArea.x)
            && this.isCloseEnought(point.y, this.cropArea.y + this.cropArea.height);
          this.bottomRightSelected = this.isCloseEnought(point.x, this.cropArea.x + this.cropArea.width)
            && this.isCloseEnought(point.y, this.cropArea.y + this.cropArea.height);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private isCloseEnought(p1: number, p2: number): boolean {
    return Math.abs(p1 - p2) < this.circleRadius;
  }

  private listenMouseMove(): void {
    this.mouseMove$
      .pipe(
        filter(() => this.topLeftSelected || this.topRightSelected || this.bottomLeftSelected || this.bottomRightSelected),
        tap(point => {
          if (this.topLeftSelected) {
            this.cropArea.width += this.cropArea.x - point.x;
            this.cropArea.height += this.cropArea.y - point.y;
            this.cropArea.x = point.x;
            this.cropArea.y = point.y;
          }
          if (this.topRightSelected) {
            this.cropArea.width = Math.abs(this.cropArea.x - point.x);
            this.cropArea.height += this.cropArea.y - point.y;
            this.cropArea.y = point.y;
          }
          if (this.bottomLeftSelected) {
            this.cropArea.width += this.cropArea.x - point.x;
            this.cropArea.height = Math.abs(this.cropArea.y - point.y);
            this.cropArea.x = point.x;
          }
          if (this.bottomRightSelected) {
            this.cropArea.width = Math.abs(this.cropArea.x - point.x);
            this.cropArea.height = Math.abs(this.cropArea.y - point.y);
          }
        }),
        /* If you decrease width and height of the rectangle less than `circleRadius` pixels and unpress your mouse */
        /* Then the rectangle becomes not resizible. But if you keep resizing, everything is fine. */
        filter(() => this.cropArea.width >= this.circleRadius && this.cropArea.height >= this.circleRadius),
        tap(() => this.isCroppedAreaChanged = true),
        tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
        tap(() => this.render()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private createCroppedStream(): Observable<File> {
    return this.crop$
      .pipe(
        tap(() => {
          this.canvas.width = this.cropArea.width;
          this.canvas.height = this.cropArea.height;
        }),
        tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
        tap(() => this.calculateDifference()),
        /* Crop selected area. */
        tap(() => this.context.drawImage(
          this.currentImage,
          this.cropArea.x,
          this.cropArea.y,
          this.cropArea.width,
          this.cropArea.height,
          0,
          0,
          this.cropArea.width,
          this.cropArea.height,
        )),
        /* If we do `this.prevImage = this.currentImage` and later `this.currentImage = someNewImage` */
        /* `this.prevImage` will link to `someNewImage`. This is a hack to create new link. */
        tap(() => {
          const img = new Image(this.currentImage.width, this.currentImage.height);
          img.src = this.currentImage.src;
          this.prevImage = img;
        }),
        switchMap(() => this.convertToFile()),
        switchMap(file => createImage(file)
          .pipe(
            tap(img => this.currentImage = img),
            tap(() => this.cropArea = new Rectangle(new Point(0, 0), new Point(this.canvas.width, this.canvas.height))),
            tap(() => this.drawCropArea()),
            tap(() => this.drawCircles()),
            mapTo(file),
          ),
        ),
        weakShareReplay(1),
      );
  }

  private listenFilesChange(): void {

    const undo$ = this.undo$
      .pipe(
        tap(() => this.cropArea = new Rectangle(new Point(0, 0), new Point(this.prevImage.width, this.prevImage.height))),
        tap(() => {
          this.canvas.width = this.cropArea.width;
          this.canvas.height = this.cropArea.height;
        }),
        tap(() => this.calculateDifference()),
        tap(() => this.currentImage = this.prevImage),
        tap(() => this.context.drawImage(this.currentImage, 0, 0)),
        switchMap(() => this.convertToFile()),
        tap(() => this.drawCropArea()),
        tap(() => this.drawCircles()),
        startWith(null),
      );
    merge(
      this.cropped$,
      undo$,
    )
      .pipe(
        /* Because `undo$` starts with `null`. */
        filter(file => !!file),
        switchMap((file: File) => createImage(file)
          .pipe(
            tap(() => this.previewContext.clearRect(0, 0, this.previewCanvas.width, this.previewCanvas.height)),
            tap(() => this.previewUpperContext.clearRect(0, 0, this.previewUpperCanvas.width, this.previewUpperCanvas.height)),
            tap(img => this.previewContext.drawImage(img, 0, 0)),
            tap(() => this.rectangles = []),
            tap(() => this.context.strokeStyle = 'black'),
            tap(() => this.calculateDifference()),
            map(() => this.getFilesToSave()),
            tap(() => {
              this.upperCanvas.width = this.canvas.width;
              this.upperCanvas.height = this.canvas.height;
            }),
            tap(files => this.editor.filesChange$.next(files)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    super.destroy();
    createImage(this.currentPageInfo.file)
      .pipe(
        tap(() => this.context.strokeStyle = 'black'),
        tap(img => this.context.drawImage(img, 0, 0)),
        switchMap(() => this.convertToFile()),
        tap(file => this.currentPageInfo.file = file),
      )
      .subscribe();
  }

  /**
   * Makes crop effect.
   */
  public crop(): Observable<void> {
    this.crop$.next();
    this.isCroppedAreaChanged = false;
    return this.cropped$.pipe(
      switchMapTo(this.editor.filesChange$),
      mapTo(void 0),
    );
  }

  /**
   * @inheritdoc
   */
  public disable(): void {
    this.disabled = true;
    createImage(this.currentPageInfo.file)
      .pipe(
        tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
        tap(img => this.context.drawImage(img, 0, 0)),
      )
      .subscribe();
  }

  /**
   * @inheritdoc
   */
  public onMouseUp(): void {
    if (!this.disabled) {
      this.topLeftSelected = this.topRightSelected = this.bottomLeftSelected = this.bottomRightSelected = false;
    }
  }

}
