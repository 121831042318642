// "id": 0,
// "name": "string",
// "published": 0,
// "createdBy": 0,
// "visibleToRealtor": 0,
// "visibleToLO": 0,
// "visibleToAdmin": 0
import { Full } from '../../helpers/full';

export class QuerySummary {
  
  id:number;

  name:string;

  createdBy:number;

  visibleToRealtor:number;

  visibleToLO:number;

  visibleToAdmin:number


  constructor(data:Full<QuerySummary>){
    this.id=data.id;

    this.name=data.name;

    this.createdBy=data.createdBy;

    this.visibleToRealtor=data.visibleToRealtor;

    this.visibleToLO=data.visibleToLO;

    this.visibleToAdmin=data.visibleToAdmin
  }
}