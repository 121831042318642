import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core';

@Component({
  selector: 'arb-doc-redirect-page',
  templateUrl: './doc-redirect-page.component.html',
  styleUrls: ['./doc-redirect-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocRedirectPageComponent implements OnInit {

  constructor(public activatedRoute: ActivatedRoute,
    public http: HttpClient,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) { }
  public key;
  public borrowerId;
  public url = "";
  public isVisible=false;
  ngOnInit() {
    console.log("url", window.location.href);
    this.url = window.location.href;
    setTimeout(() => {
      this.isVisible=true;
      this.cdr.markForCheck()
    },4000);
    this.getUrlToken()
  }

  public getUrlToken() {
    this.activatedRoute.paramMap.subscribe(params => {
      let key = params.get('key');
      let id = params.get('id');
      this.authService.mobileAuth(key)
        .subscribe(data => {
          localStorage.setItem('authTokens', data);
          window.open(`${window.location.origin}/borrowers/${id}?src=mobile`,'_self')
        })
    });
  }
}
