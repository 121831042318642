import { Component, ChangeDetectionStrategy, Inject, ViewChild, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatInput, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

// import { OfferStatus } from '../../../../core';

/**
 * Update offer status dialog component.
 */
@Component({
  selector: 'supporting-document-dialog',
  templateUrl: './supporting-document-dialog.component.html',
  styleUrls: ['./supporting-document-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportingDocumentDialogComponent implements AfterViewInit { 
  
  @ViewChild('inputElement') input: ElementRef;
  alternateName;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<SupportingDocumentDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {}


  ngAfterViewInit() {
    this.input.nativeElement.focus();
    this.cdr.markForCheck();
  }

  /**
   * Close dialog.
   * @param value Value that will be returned after closing.
   */
   public close(value: boolean): void {
    let data = {
      flag: value,
      newName: this.alternateName
    }
    this.dialogRef.close(data);
  }

}
