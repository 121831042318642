import { ErrorMessage, Message, SuccessMessage, SupportService } from "../../../../core";
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatDialogRef } from "@angular/material";

@Component({
  selector: 'arb-permission-dialog',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionDialogComponent implements OnInit {

    public selectedUser;
    public FAQEdit;
    public SupportEdit;
    public submit$ = new Subject();
    public readonly message$ = new Subject<Message | null>();

    public permissionUser;

    constructor(
        private supportService: SupportService,
        private matDialog: MatDialogRef<PermissionDialogComponent>,
    ) {}
    ngOnInit() {
        this.selectedUser = "SelectUser";
        this.FAQEdit = false;
        this.SupportEdit = false;

        this.permissionUser = this.supportService.getPermissionUsers();
    }

    // public subscribeToSubmit() {
    //     this.submit$
    //         .pipe(      
    //         map(form => ({
    //             userId: this.selectedUser,
    //             faqEdit: this.FAQEdit, 
    //             supportEdit: this.SupportEdit     
    //         })),
    //         switchMap(data => {
    //             return this.supportService.createPermission(data)
    //         })
    //         )
    //         .subscribe((res) => console.log(res));  
    // }

    /**
   * Closes the dialog form with the specified result.
   * @param result Whether an item has been added.
   */
    public close(result: boolean): void {
        this.matDialog.close(result);
    }

    public addPermission() {
        if(this.selectedUser == "SelectUser") {
            this.message$.next(new ErrorMessage('Please select user'))
        } else {
            // this.submit$.next();
            let data = {
                userId: this.selectedUser,
                faqEdit: +this.FAQEdit, 
                supportEdit: +this.SupportEdit 
            }
            this.supportService.createPermission(data).subscribe((res) => {
                console.log(res);
                this.message$.next(new SuccessMessage('New permission added'))
                this.selectedUser = "SelectUser";
                this.FAQEdit = false;
                this.SupportEdit = false;
                this.close(true);
            }, (err) => {
                if(err) {
                    this.message$.next(new ErrorMessage('Oops, Something went wrong'));
                }
            })
        }
    }
}