import { PersonDetailsLoanOfficer } from './../models/person-details-loanofficer';
import {
  HttpClient,
  HttpParams,
  HttpHandler,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AssetFileDto } from "src/app/borrowers/borrower-page/services/dto/asset-file-dto";

import { AppConfig } from "../../app.config";
import { createFormData } from "../helpers/create-form-data";
import { Contact } from "../models/contact";
import { AssociatedBorrower } from "../models/offers/associated-borrower";
import { AssociatedRealtor } from "../models/offers/associated-realtor";
import { CreateOffer } from "../models/offers/create-offer";
import { Offer } from "../models/offers/offer";
import { OfferStatus } from "../models/offers/offer-status";
import { OfferSummary } from "../models/offers/offer-summary";
import { OffersSearchParams } from "../models/offers/offers-search-params";
import { UpdateOffer } from "../models/offers/update-offer";
import { PaginationResult } from "../models/pagination-result";
import { Person } from "../models/person";
import { PersonDetails } from "../models/person-details";
import { Table } from "../models/table";

import { OfferDto } from "./dto/offers/offer-dto";
import { OfferSummaryDto } from "./dto/offers/offer-summary-dto";
import { SubmitOfferResponseDto } from "./dto/offers/submit-offer-response-dto";

/**
 * Offers business logic.
 */
@Injectable({
  providedIn: "root",
})
export class OfferService {
  constructor(private config: AppConfig, private http: HttpClient) {}

  public ExtractTextfromImage(file: File) {
    const url = `${this.config.apiUrl}/api/offers/extractTextfromImage`;
    const formData = new FormData();
    formData.append("image", file);    
     return this.http.put<void>(url, formData);    
  }
  /**
   * Search for offers.
   * @param searchParams Search params.
   */
  public search(
    searchParams: OffersSearchParams
  ): Observable<Table<OfferSummary>> {
    const url = `${this.config.apiUrl}/api/offers`;
    let params = new HttpParams();
    for (const param of Object.keys(searchParams)) {
      if (searchParams[param]) {
        params = params.set(param, searchParams[param]);
      }
    }
    return this.http
      .get<PaginationResult<OfferSummaryDto>>(url, { params })
      .pipe(
        map((res) => ({
          paginationMetadata: res.metadata,
          data: res.data.map(this.mapToOfferSummary),
        }))
      );
  }



  private mapToOfferSummary(dto: OfferSummaryDto): OfferSummary {
    return new OfferSummary({
      id: dto.id,
      friendlyId: dto.friendlyId,
      submittedAt: dto.dateSubmitted ? new Date(dto.dateSubmitted) : null,
      borrower: new Person({
        id: dto.borrowerId,
        firstName: dto.borrowerFirstName,
        lastName: dto.borrowerLastName,
      }),
      address: dto.address,
      city: dto.city,
      state: dto.state,
      zip: dto.zip,
      rpaUrl: dto.rpaUrl,
      pdfUrl: dto.pdfUrl,
      packageUrl: dto.packageUrl,
      price: dto.price,
      financing:dto.financing,
      status: dto.status,
      packageBitlyUrl: dto.packageBitlyUrl,
      analytics: dto.analytics,
    });
  }

  /**
   * Updates total notes.
   * @param totalNotes Total notes.
   * @param offer Offer that will be updated.
   */
  public updateTotalNotes(totalNotes: string, offer: Offer): Observable<void> {
    const updatedOffer = new UpdateOffer({
      agentName: offer.agentName,
      agentEmail: offer.agentEmail,
      agentPhoneNo: offer.agentPhoneNo,
      borrowerId: offer.borrower.id,
      useRealtorPicture: false,
      subject: offer.subject,
      borrowerQuote: offer.borrowerQuote,
      letter: offer.letter,
      letterConclusion: offer.letterConclusion,
      price: offer.price,
      priceNotes: offer.priceNotes,
      deposit: offer.deposit,
      depositNotes: offer.depositNotes,
      downPayment: offer.downPayment,
      downPaymentType: offer.downPaymentType,
      downPaymentNotes: offer.downPaymentNotes,
      financing: offer.financing,
      total: offer.total,
      lender: offer.lender,
      escrowCompany: offer.escrowCompany,
      responseDeadline: offer.responseDeadline,
      totalNotes,
      address: offer.address,
      city: offer.city,
      state: offer.state,
      zip: offer.zip,
      propertyType: offer.propertyType,
      coverLetterPhoto: null,
      rpaFile: null,
      recipientName: offer.recipientName,
      contingency: offer.contingency,
      contingencyLoan: offer.contingencyLoan,
      contingencyGeneral: offer.contingencyGeneral,
      removeCoverLetterPhoto: false,
      removeRpaFile: false,
      buyerType: offer.buyerType,
      removeAdditionalDocument1: false,
      removeAdditionalDocument2: false
    });
    return this.update(updatedOffer, offer.id);
  }

  /**
   * Updates offer.
   * @param offer Updated offer.
   * @param id Offer id.
   */
  public update(offer: UpdateOffer, id: number): Observable<void> {
    const url = `${this.config.apiUrl}/api/offers/${id}`;
    const formData = createFormData(offer);
    console.log("formData =", formData);
    return this.http.put<void>(url, formData);
    // return new Observable<void>();
  }

  /**
   * Creates new offer.
   * @param offer New offer.
   */
  public create(offer: CreateOffer): Observable<number> {
    const url = `${this.config.apiUrl}/api/offers`;
    const formData = createFormData(offer);
    console.log("formData =", formData);

    return this.http
      .post<{ id: number }>(url, formData)
      .pipe(map(({ id }) => id));
  }

  /**
   * Gets offer by id.
   * @param id Offer id.
   */
  public getById(id: number): Observable<Offer> {
    const url = `${this.config.apiUrl}/api/offers/${id}`;
    return this.http.get<OfferDto>(url).pipe(
      map(
        (dto) =>
          new Offer({
            agentName: dto.agentName,
            agentEmail: dto.agentEmail,
            agentPhoneNo: dto.agentPhoneNo,
            id: dto.id,
            friendlyId: dto.friendlyId,
            status: dto.status,
            letterPhotoUrl: dto.coverLetterPhotoUrl || "",
            borrower: new AssociatedBorrower({
              id: dto.borrowerId,
              nickname: dto.borrowerName,
              firstName: dto.mainBorrowerInfo.firstName,
              lastName: dto.mainBorrowerInfo.lastName,
              email: dto.mainBorrowerInfo.email,
              phoneNumber: dto.mainBorrowerInfo.phoneNumber,
              isLoanOfficerIndependent: dto.mainBorrowerInfo.isLoanOfficerIndependent
            }),
            coBorrower: new Contact({
              firstName: dto.coBorrowerInfo.firstName,
              lastName: dto.coBorrowerInfo.lastName,
              email: dto.coBorrowerInfo.email,
              phoneNumber: dto.coBorrowerInfo.phoneNumber,
            }),
            realtor: new AssociatedRealtor({
              id: dto.realtorInfo.id,
              friendlyId: dto.realtorInfo.friendlyId,
              companyName: dto.realtorInfo.companyName,
              firstName: dto.realtorInfo.firstName,
              lastName: dto.realtorInfo.lastName,
              email: dto.realtorInfo.email,
              phoneNumber: dto.realtorInfo.phoneNumber,
              officeAddress: dto.realtorOfficerAddress,
              website: dto.realtorWebsite,
              licenseNumber: dto.realtorInfo.licenseNumber,
            }),
            loanOfficer: new PersonDetailsLoanOfficer({
              id: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.id : 0,
              friendlyId: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.friendlyId : '',
              companyName: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.companyName : '',
              companyLogoUrl: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.companyLogoUrl : '',
              firstName: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.firstName : '',
              lastName: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.lastName : '',
              email: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.email : '',
              phoneNumber: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.phoneNumber : '',
              fieldCordinates: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.fieldCordinates : '',
              isCustom: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.isCustom : false,
              timeZone: dto.mainBorrowerInfo.isLoanOfficerIndependent == false ? dto.loanOfficerInfo.timeZone : '',
            }),
            recipientName: dto.recipientName,
            subject: dto.subject,
            borrowerQuote: dto.borrowerQuote,
            letter: dto.letter,
            letterConclusion: dto.letterConclusion,
            price: dto.price,
            priceNotes: dto.priceNotes,
            deposit: dto.deposit,
            depositNotes: dto.depositNotes,
            downPayment: dto.downPayment,
            downPaymentType: dto.downPaymentType,
            downPaymentNotes: dto.downPaymentNotes,
            financing: dto.financing,
            total: dto.total,
            lender: dto.lender,
            escrowCompany: dto.escrowCompany,
            responseDeadline: new Date(dto.responseDeadline),
            contingency: dto.contingency,
            contingencyLoan: dto.contingencyLoan,
            contingencyGeneral: dto.contingencyGeneral,
            totalNotes: dto.totalNotes,
            address: dto.address,
            city: dto.city,
            state: dto.state,
            zip: dto.zip,
            propertyType: dto.propertyType,
            rpaUrl: dto.rpaUrl,
            dateSubmitted: new Date(dto.dateSubmitted),
            packageUrl: dto.packageUrl,
            isRealtorPictureUsed: dto.isRealtorPictureUsed,
            preApprovalUrl: dto.preApprovalUrl,
            realtorOfferSignature: dto.realtorOfferSignature,
            loanOfficerOfferSignature: dto.loanOfficerOfferSignature,
            pdfUrl: dto.pdfUrl,
            packageBitlyUrl: dto.packageBitlyUrl,
            analytics: dto.analytics,
            buyerType: dto.buyerType
          })
      )
    );
  }

  /**
   * Updated status.
   * @param id Offer id.
   * @param status New status.
   */
  public updateStatus(id: number, status: OfferStatus): Observable<void> {
    const url = `${this.config.apiUrl}/api/offers/${id}/status`;
    // return new Observable<void>();
    return this.http.put<void>(url, { status });
  }

  /**
   * Updated status.
   * @param id Offer id.
   * @param data JSON Data.
   */
  public sendEmail(id: number, data: any): Observable<void> {
    function createFormDataImproved(object: Object): FormData {
      const formData = new FormData();
      for (const param of Object.keys(object)) {
        if (object[param]) {
          if (object[param] instanceof Date) {
            formData.append(param, object[param].toISOString());
          } else if (object[param] instanceof Blob) {
            formData.append(param, object[param]);
          } else if (object[param] instanceof Array) {
            object[param].forEach((aArrayElement, arrayIndex) => {
              var arrayParam = param;
              if (aArrayElement instanceof Date) {
                formData.append(arrayParam, aArrayElement.toISOString());
              } else if (aArrayElement instanceof Blob) {
                formData.append(arrayParam, aArrayElement);
              } else {
                formData.append(arrayParam, String(aArrayElement));
              } // only one level array support for now.
            });
          } else {
            formData.append(param, String(object[param]));
          }
        }
      }
      return formData;
    }
    data.offerId = id;
    const formData = createFormDataImproved(data);
    // console.log(formData);
    // return new Observable<void>();
    const url = `${this.config.apiUrl}/api/offers/sendEmail`;
    return this.http.post<void>(url, formData);
  }

  /**
   * Updated status.
   * @param data JSON Data.
   */
  public createMailTemplate(data: any): Observable<void> {
    const formData = createFormData(data);
    // console.log(formData);
    // return new Observable<void>();
    const url = `${this.config.apiUrl}/api/offers/emailTemplate`;
    return this.http.post<void>(url, data);
  }

  /**
   * Updated status.
   * @param data JSON Data.
   */
  public updateMailTemplate(data: any): Observable<void> {
    var id = data.id;
    // const formData = createFormData(data);
    // console.log(formData);
    // return new Observable<void>();
    const url = `${this.config.apiUrl}/api/offers/emailTemplate/${id}`;
    return this.http.put<void>(url, data);
  }

  /**
   * Updated status.
   */
  public fetchMailTemplates(): Observable<void> {
    // console.log(formData);
    // return new Observable<void>();
    const url = `${this.config.apiUrl}/api/offers/getEmailTemplates`;
    return this.http.get<void>(url);
  }

  /**
   * Updated status.
   */
  public getMailTemplatesById(id: number): Observable<void> {
    // console.log(formData);
    // return new Observable<void>();
    const url = `${this.config.apiUrl}/api/offers/getEmailTemplate/${id}`;
    return this.http.get<void>(url);
  }

  /**
   * Generates a PDF.
   * @param id Offer id.
   */
  public generatePackage(id: number): Observable<string> {
    const url = `${this.config.apiUrl}/api/offers/${id}/generate-package`;
    return this.http
      .post<SubmitOfferResponseDto>(url, {})
      .pipe(map(({ packageUrl }) => packageUrl));
  }

  /**
   * Get Offer Analytics.
   * @param id Offer id.
   */
  public getAnalyticsByOfferId(id: number): Observable<any> {
    const url = `${this.config.apiUrl}/api/offers/getAnalyticsByOfferId/${id}`;
    let params = new HttpParams();
    return this.http.get<any>(url, { params });
  }

  
  /**
   * Get Offer Analytics.
   * @param id Offer id.
   */
   public getOfferAssets(id: number): Observable<any> {
    const url = `${this.config.apiUrl}/api/offers/getOfferAssetsByOfferId/${id}`;
    let params = new HttpParams();
    return this.http.post<any>(url, { params });
  }

  

  /**
   * Delete Offer.
   * @param id Offer id.
   */
  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.config.apiUrl}/api/offers/${id}`, {});
  }

  public uploadAsset(assetFileDto: AssetFileDto | null, offerId: number): Observable<void> {
    if (assetFileDto == null) return of();
    const formData = new FormData();
    formData.append('id', assetFileDto.id.toString());
    formData.append('assetTypeId', assetFileDto.assetTypeId.toString());
    for (const file of assetFileDto.files) {
      formData.append('files', file, this.mapFileNameToFileNameDto(file.name));
    }
    const url = `${this.config.apiUrl}/api/offers/${offerId}/support-documents/assets/${assetFileDto.assetTypeId}/file`;
    return this.http.put<void>(url, formData);
  }

  private mapFileNameToFileNameDto(fileName: string): string {
    const maxLength = 58;
    const arrayOfLinesSeparatedByDots = fileName.split('.');
    const name = arrayOfLinesSeparatedByDots.slice(0, -1).join('.');
    const fileExtension = arrayOfLinesSeparatedByDots[arrayOfLinesSeparatedByDots.length - 1];
    return `${name.slice(0, maxLength)}.${fileExtension}`;
  }
}
