import {Component,ChangeDetectionStrategy} from '@angular/core';

@Component({
    selector: 'arb-adminpage-toolbox',
    templateUrl: './toolbox-admin-page.component.html',
    styleUrls: ['./toolbox-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolboxAdminPageComponent {
    
}