import { ChangeDetectorRef, Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgxPermissionsConfigurationService, NgxPermissionsDirective, NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

import { UserRole } from '../../core';

/**
 * Short *ngxPermissionsOnly="userRole.Realtor".
 */
@Directive({
  selector: '[arbRealtorOnly]',
})
export class RealtorOnlyDirective extends NgxPermissionsDirective {

  constructor(
    el: TemplateRef<any>,
    permissionsService: NgxPermissionsService,
    configurationService: NgxPermissionsConfigurationService,
    rolesService: NgxRolesService,
    viewContainer: ViewContainerRef,
    changeDetector: ChangeDetectorRef) {
    super(
      permissionsService,
      configurationService,
      rolesService,
      viewContainer,
      changeDetector,
      el,
    );
    this.ngxPermissionsOnly = UserRole.Realtor;
  }
}
