import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Application's main configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfig {

  /**
   * Url to the API endpoint (without trailing slash).
   */
  public readonly apiUrl = environment.apiEndpoint;

  /**
   * Production mode.
   */
  public readonly production = environment.production;

  public readonly stripeKey=environment.stripeKey;
}
