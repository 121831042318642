import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// import { PeriodicElement } from "src/app/shared";
import { PeriodicElement } from "../../shared";

import { AppConfig } from '../../app.config';
import { CreateOrder } from "../models/create-order";
import { CouponResDTO, ProductDTO } from "../models/CustomerDTO";

@Injectable({
    providedIn: 'root',
})

export class OrderService {
    constructor(
        private config: AppConfig,
        private http: HttpClient,
    ) { }

    public createOrder(data: CreateOrder): Observable<any> {
        const url = `${this.config.apiUrl}/api/order/createOrders`;
        return this.http.post(url, data);
    }


    public getPaymentToken(paymentInfo): Observable<any> {
        // const url = `${this.config.apiUrl}/api/payment?amount=${paymentInfo.amount}&plan=${paymentInfo.plan}`;
        const url = `${this.config.apiUrl}/api/payment`;
        return this.http.post(url, paymentInfo);
    }

    public emailUnsubscribeData(encryptedId): Observable<any> {
        console.log(encryptedId);
        const url = `${this.config.apiUrl}/api/emailSubscription/emailUnsubscribeData/${encryptedId}`;
        return this.http.put(url, { encryptedId: encryptedId }, { responseType: "text" });
    }
    public emailResubscribeData(encryptedId): Observable<any> {
        console.log(encryptedId);
        const url = `${this.config.apiUrl}/api/emailSubscription/emailResubscribeData/${encryptedId}`;
        return this.http.put(url, { encryptedId: encryptedId }, { responseType: "text" });
    }



    public mySubscription(paymentInfo): Observable<any> {
        console.log(paymentInfo);
        const url = `${this.config.apiUrl}/api/payment/mySubscription`;
        return this.http.post(url, paymentInfo, {});
    }

    public upgradeSubscription(paymentInfo): Observable<any> {
        console.log(paymentInfo);
        const url = `${this.config.apiUrl}/api/payment/upgradeSubscription`;
        return this.http.post(url, paymentInfo, { responseType: "text" });
    }

    public updateUserSubscriptionPlan(paymentInfo): Observable<any> {
        console.log(paymentInfo);
        const url = `${this.config.apiUrl}/api/payment/updateUserSubscriptionPlan`;
        return this.http.post(url, paymentInfo, { responseType: "text" });
    }



    public getPaymentTokenV2(paymentInfo): Observable<any> {
        console.log(paymentInfo);
        const url = `${this.config.apiUrl}/api/payment/onboarding`;
        return this.http.post(url, paymentInfo, { responseType: "text" });
    }

    public checkTokenValidity(promoCode: string, amount: number): Observable<CouponResDTO> {
        const url = `${this.config.apiUrl}/api/payment/CheckCouponCode?productPrice=${amount}&name=${promoCode}`;
        return this.http.get<CouponResDTO>(url);
    }
    public LoadSubscription(startingAfter,limit): Observable<PeriodicElement[]> {
       
        // const url = `${this.config.apiUrl}/api/payment/Subscriptions/adminView`;
        // const url = `${this.config.apiUrl}/api/payment/Subscriptions/adminView?startingAfter=10&limit=23"`;
        // const url = `${this.config.apiUrl}/api/payment/Subscriptions/adminView?StartingAfter=sub_1KHm6bLfEtqn2sC1da5LRpln`;
        const url = `${this.config.apiUrl}/api/payment/Subscriptions/adminView?startingAfter=${startingAfter}`; 
        // const url = `${this.config.apiUrl}/api/payment/Subscriptions/adminView?startingAfter=${startingAfter} &limit=${limit}`;

        return this.http.get<PeriodicElement[]>(url);
    }

    public cancelSubscription(subscriptionId,customerId,email): Observable<PeriodicElement[]> {
        const url = `${this.config.apiUrl}/api/payment/subscription/cancel/${subscriptionId}/${customerId}/${email}`;
        return this.http.get<PeriodicElement[]>(url);
    }

    public FetchProduct(name): Observable<ProductDTO> {
        const url = `${this.config.apiUrl}/api/payment/Product/Fetch/${name}`;
        return this.http.get<ProductDTO>(url);
    }

    public FetchProductId(user:string,plan:string,payannually:string): Observable<any> {
        
        const url = `${this.config.apiUrl}/api/payment/onboardingPlan/${user}/${plan}/${payannually}`;
        return this.http.get(url,{ responseType: 'text' });
    }

    public updatePaymentStatus(data): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/updatePaymentStatus`;
        return this.http.put(url, data);
    }

    public inviteUser(postObj): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/invite`;
        return this.http.post(url, postObj, { responseType: 'text' });
    }

    // public getvarifyeEmail(paymentInfo): Observable<any> {
    //     const url = `${this.config.apiUrl}/api/payment?amount=${paymentInfo.amount}&plan=${paymentInfo.plan}`;
    //    return this.http.get(url, {responseType: 'text'});
    // }

    public verifyEmail(email): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/checkEmail`;
        return this.http.post(url, email, { responseType: "text" });
    }

    public createStripeUser(user): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/Customer`;
        return this.http.post(url, user, { responseType: "text" });
    }

    public productDetailsForUpgrade(productId: string): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/Product/FetchById/${productId}`;
        return this.http.get<any>(url);
    }
    public priceDetailsForUpgrade(productId: string): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/Prices/${productId}`;
        return this.http.get<any>(url);
    }
    public getProductsConfig(): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/getProductsConfig`;
        return this.http.get<any>(url);
    }
    public updateProductConfigLimit(data) {
        const url = `${this.config.apiUrl}/api/payment/editProductsConfigLimit`;
        return this.http.put(url, data)
    }
    public getAllProducts() {
        const url = `${this.config.apiUrl}/api/payment/products`;
        return this.http.get<any>(url);
    }
    public createproductConfig(data) {
        const url = `${this.config.apiUrl}/api/payment/createProductConfig`;
        return this.http.post(url, data)
    }
    public deleteproductsConfig(id) {
        const url = `${this.config.apiUrl}/api/payment/deleteProductConfig?id=${id}`;
        return this.http.delete(url, id)
    }
    public getEmailSubscription(): Observable<any> {
        const url = `${this.config.apiUrl}/api/emailSubscription`;
        return this.http.get<any>(url);
    }
    public editEmailSubscription(data) {
        const url = `${this.config.apiUrl}/api/emailSubscription/editsubscribe`;
        return this.http.put(url,data);
    }
    public getCustomerByEmailAddress(emailAddress: string): Observable<any> {
        const url = `${this.config.apiUrl}/api/payment/Customers/getByEmailAddress?emailAddress=${emailAddress}`;
        return this.http.get<any>(url);
    }
}
