import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Borrower, BorrowerService, completedOrFailed, GlobalProgressService } from '../../../core';

/**
 * Borrower resolver.
 */
@Injectable({
  providedIn: 'root',
})
export class BorrowerResolver implements Resolve<Borrower> {

  constructor(
    private globalProgressService: GlobalProgressService,
    private borrowerService: BorrowerService,
  ) {}

  /**
   * Gets id from params and fetches info about borrower.
   * @param route Route snapshot.
   */
  public resolve(route: ActivatedRouteSnapshot): Observable<Borrower> {
    const id = Number(route.paramMap.get('id'));
    this.globalProgressService.startLoading();
    return this.borrowerService.getById(id).pipe(
      completedOrFailed(() => this.globalProgressService.stopLoading()),
    );
  }
}
