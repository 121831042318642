import { Point } from './../point';
import { RectangleCustom } from './../rectangleCustomFields';
import { Subject, fromEvent, combineLatest, Observable } from 'rxjs';
import { tap, takeUntil, filter, startWith, map, concatMap } from 'rxjs/operators';

import { createImage } from '../../../../core';
import { ImageEditor } from '../image-editor';
import { Rectangle } from '../rectangle';

import { EditorRenderer } from './editor-renderer';
import { EditorRendererOptions } from './editor-renderer-options';

/**
 * Image rectangle renderer.
 */
export class ImageCustomPreapprovalRenderer extends EditorRenderer {

  private readonly mouseUp$: Subject<void>;
  private rectangles: RectangleCustom[];
  private image: HTMLImageElement;
  private isHighlighter: Boolean;
  private customParams$: Observable<any>;
  private customParams: any = {preApproval:{}};
  private textArray: any=[]

  /**
   * @inheritdoc
   */
  protected readonly editor: ImageEditor;

  constructor(editor: ImageEditor, options: EditorRendererOptions, isHighlighter: Boolean = false, customParams$: Observable<any>) {
    super(options);
    this.rectangles = [];
    this.editor = editor;
    this.isHighlighter = isHighlighter;
    this.customParams$ = customParams$;
    customParams$.subscribe((value)=> {
      this.customParams = {...value};
      if(this.editor.items.length){
        this.editor.items[this.editor.items.length-1].customparams={...value}
        this.renderRectangles()
      }
    });

    this.mouseUp$ = new Subject();
    this.init(editor.file);
    this.listenAreaSelecting();
    this.listenFilesChanges();
    this.listenCustomParamsChange();
    this.listenMoseDown();

  }
  private listenCustomParamsChange(): void {
    this.customParams$
      .pipe(
        map((val) => {
          this.customParams = val
        }),
        tap(res=>{
          console.log("val",res)
        
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private init(file: File): void {
    createImage(file)
      .pipe(
        tap(img => {
          this.canvas.width = img.width;
          this.canvas.height = img.height;
          this.image = img;
        }),
        tap(() => this.context.drawImage(this.image, 0, 0)),
        tap(() => this.calculateDifference()),
        /* To trigger `filesChange$` to emit renderer file. */
        tap(() => this.mouseUp$.next()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private calculateDifference(): void {
    if (this.canvas.width > this.canvasMaxWidth) {
      this.diffX = Number((this.canvas.width / this.canvasMaxWidth).toFixed(2));
      const realHeight = (this.canvasMaxWidth * this.canvas.height) / this.canvas.width;
      this.diffY = Number((this.canvas.height / realHeight).toFixed(2));
      this.diffX = this.diffX < 1 ? 1 : this.diffX;
      this.diffY = this.diffY < 1 ? 1 : this.diffY;
    }
  }

  private listenAreaSelecting(): void {
    this.mouseMove$
      .pipe(
        filter(() => !this.disabled && this.isSelectingInProgress),
        tap(() => this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)),
        tap(() => this.context.drawImage(this.image, 0, 0)),
        tap(endPoint => {
          this.endPoint = endPoint
          this.editor.items[this.editor.items.length-1].cordinates.X=endPoint.x;
          this.editor.items[this.editor.items.length-1].cordinates.Y=endPoint.y;
        }),
        tap((res:Point ) => {
          this.startPoint=res;
        }),
        tap(()=> this.renderRectangles()),
        // tap(() => (this.isHighlighter ? this.drawSelection(this.context, "#ffff00") : this.drawSelection(this.context))),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private listenFilesChanges(): void {
    const undo$ = this.undo$
      .pipe(
        filter(() => this.editor.items.length > 0),
        tap(() => this.editor.items.pop()),
        tap(()=>{
          this.context.clearRect(0,0,this.canvas.width,this.canvas.height);
          this.context.drawImage(this.image,0,0);
          this.renderRectangles();
        }),
        startWith(null),
      );
    combineLatest(
      undo$,
      this.mouseUp$,
    )
      .pipe(
        concatMap(() => this.convertToFile()),
        tap(file => this.editor.file = file),
        tap(file => this.editor.filesChange$.next([file])),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

 private listenMoseDown(){
   this.mouseDown$.subscribe(res=>{
    //  let canvasCordinates=this.canvas.getBoundingClientRect()
  
     let curelement
     for (let i = 0; i < this.editor.items.length; i++) {
       let element = this.editor.items[i];
       if((element.cordinates.X-100 < res.x  && element.cordinates.X+50 > res.x  && element.cordinates.Y-50 < res.y  && element.cordinates.Y+10 > res.y )  ){
        console.log("element",element)
          let index=this.editor.items.indexOf(element);
          console.log("index",index)
          curelement=this.editor.items.splice(index,1);
          console.log("items",this.editor.items)
          this.editor.items.push(element)
          // console.log("curelement",curelement)
      }
     }
    //  this.editor.items.push(curelement);
     console.log("items after unshift",this.editor.items)
   })
 }

  private renderRectangles(): void {  
    this.context.clearRect(0,0,this.canvas.width,this.canvas.height)  	
    this.context.drawImage(this.image, 0, 0);	
    for (let i = 0; i < this.editor.items.length; i++) {
      const element = this.editor.items[i];
      this.context.fillStyle=element.customparams.textColor ? element.customparams.textColor :"black"
      const rectText = new RectangleCustom(element.cordinates.X,element.cordinates.Y);
      this.renderRectImg(this.context, rectText, element.customparams);
    }
  
    
  }

  /**
   * @inheritdoc
   */
  public disable(): void {
    this.disabled = true;
  }

  /**
   * @inheritdoc
   */
  public onMouseUp(): void {
    if (!this.disabled && this.isSelectingInProgress) {
      this.isSelectingInProgress = false;
      this.renderRectangles()
      this.mouseUp$.next();
    }
  }

}
