import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { EMPTY, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorMessage } from '../models/message/error-message';

export const SERVER_HTTP_ERROR = 'Oops, something went wrong';

interface HttpError {
  field: string;
  messages: string[];
}

interface CatchErrorOptions {
  message$?: Subject<ErrorMessage | null>;
  callback?: (error: ErrorMessage) => void;
}

/**
 * Catches http error and sets error message for form in `httpError` field.
 */
export const catchHttpError = <T>(form: FormGroup) => catchError<T, T>((e: HttpErrorResponse) => {
  form.setErrors({
    httpError: getHttpError(e),
  });
  return EMPTY;
});

/**
 * Catches http error and emits error message.
 * @param options Options.
 */
export const catchHttpErrorMessage = <T>(options: CatchErrorOptions) => catchError<T, T>((e: HttpErrorResponse) => {
  const { message$, callback } = options;
  const error = new ErrorMessage(getHttpError(e));
  if (message$) {
    message$.next(error);
  }
  if (callback) {
    callback(error);
  }
  return EMPTY;
});

function getHttpError(e: HttpErrorResponse): string {
  if (!(e instanceof HttpErrorResponse)) {
    throw Error('Argument must be HttpErrorResponse');
  }
  const clientError = e.status >= 400 && e.status < 500;
  return clientError && e.error
    ? Array.isArray(e.error.errors) && e.error.errors.length > 0
      ? getFirstError(e.error.errors)
      : e.error.message
    : SERVER_HTTP_ERROR;
}

function getFirstError(errors: HttpError[]): string {
  const e = errors.find(error => error.messages.length > 0);
  if (e) {
    return e.messages[0];
  }
  return SERVER_HTTP_ERROR;
}
