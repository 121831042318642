import { Component, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { Offer } from '../../../../core';

/**
 * Offer package url dialog.
 */
@Component({
  selector: 'arb-offer-pdf-url-dialog',
  templateUrl: './offer-pdf-url-dialog.component.html',
  styleUrls: ['./offer-pdf-url-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferPdfUrlDialogComponent {

  /**
   * Ref to input contains url.
   */
  @ViewChild('url')
  public inputEl: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public offer: Offer,
  ) {
  }

  /**
   * Copies package url to clipboard.
   */
  public onCopy(): void {
    this.inputEl.nativeElement.select();
    document.execCommand('copy');
  }
}
