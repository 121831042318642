import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, mapTo } from 'rxjs/operators';

import { AppConfig } from '../../app.config';
import { AuthInfo } from '../models/auth-info';
import { AuthTokenInfo } from '../models/auth-token-info';
import { RequestHeader } from '../models/request-header';

import { CurrentUserService } from './current-user.service';
import { ForgotPasswordDto } from './dto/forgot-password-dto';
import { LoginResponseDto } from './dto/login-response-dto';
import { ResetPasswordDto } from './dto/reset-password-dto';
import { LocalStorageService } from './local-storage.service';
import { RolesService } from './roles.service';

/**
 * Auth service.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private router: Router,
    private rolesService: RolesService,
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private config: AppConfig,
  ) { }

  /**
   * Authorize.
   * @param data Authorization info.
   */
  public authorize(data: AuthInfo): Observable<void> {
    const url = `${this.config.apiUrl}/auth/login`;
    const headers = new HttpHeaders({ [RequestHeader.NoAuthorization]: ''});
    return this.http
      .post<LoginResponseDto>(url, data, { headers })
      .pipe(
        map(dto => new AuthTokenInfo({
          accessToken: dto.accessToken,
          refreshToken: dto.refreshToken,
          expirationDate: new Date(Date.now() + dto.expiresIn * 1000),
        })),
        switchMap(tokens => this.localStorageService.setTokens(tokens)),
        switchMap(() => this.currentUserService.setUser()),
        mapTo(void 0),
      );
  }

  /**
   * Reset password.
   * @param data Info needed to reset the forgotten password.
   */
  public resetPassword(data: ResetPasswordDto): Observable<void> {
    const url = `${this.config.apiUrl}/auth/reset-password`;
    const headers = new HttpHeaders({ [RequestHeader.NoAuthorization]: ''});
    return this.http
      .post<{}>(url, data, { headers })
      .pipe(
        switchMap(() => {
          return this.authorize(new AuthInfo(data.email, data.newPassword));
        }),
      );
  }

  /**
   * Send request to get a link to change the forgotten password.
   * @param data Info needed to request to change the forgotten password.
   */
  public forgotPassword(data: ForgotPasswordDto): Observable<void> {
    const url = `${this.config.apiUrl}/auth/forgot-password`;
    const headers = new HttpHeaders({ [RequestHeader.NoAuthorization]: ''});
    return this.http.post<void>(url, data, { headers });
  }

  /**
   * Logout.
   * @todo Improve this when API will be ready.
   */
  public logout(): void {
    this.rolesService.flushRoles();
    localStorage.clear();
    this.router.navigate(['login']);
  }

  /**
   * Returns `true` if user is authorized.
   */
  public isAuthorized(): Observable<boolean> {
    return this.localStorageService
      .getAccessToken()
      .pipe(
        map(token => !!token),
      );
  }

  /**
   * Refreshes the tokens.
   * @param refreshToken Refresh token.
   */
  public refreshToken(refreshToken: string): Observable<AuthTokenInfo> {
    const url = `${this.config.apiUrl}/auth/refresh-token`;
    const headers = new HttpHeaders({ [RequestHeader.NoAuthorization]: '' });
    return this.http
      .post<LoginResponseDto>(url, { refreshToken }, { headers })
      .pipe(
        map(dto => new AuthTokenInfo({
          accessToken: dto.accessToken,
          refreshToken: dto.refreshToken,
          expirationDate: new Date(Date.now() + dto.expiresIn * 1000),
        })),
      );
  }

   /**
   * Mobile View
   */
    public mobileAuth(key): Observable<any> {
      console.log(key);
      const url = `${this.config.apiUrl}/auth/mobileAthontincation/${key}`;
      return this.http.put(url, { key: key }, { responseType: "text" });
  }

}
