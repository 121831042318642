import { Full } from '../../core';

import { Named } from './named';

/**
 * Personal contact into.
 */
export class Contact extends Named {

  /**
   * Email.
   */
  public email: string;

  /**
   * Phone number.
   */
  public phoneNumber: string;

  constructor(data: Full<Contact>) {
    super({
      firstName: data.firstName,
      lastName: data.lastName,
    });
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
  }

}
