/**
 * Header names.
 */
export enum RequestHeader {

  /**
   * Authorization header's name.
   */
  Authorization = 'Authorization',

  /**
   * Indicates that the request does not require an authorization token.
   */
  NoAuthorization = 'NoAuthorization',

}
