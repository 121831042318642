import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * Toolbox page component.
 */
@Component({
  selector: 'arb-toolbox-page',
  templateUrl: './toolbox-page.component.html',
  styleUrls: ['./toolbox-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolboxPageComponent { }
