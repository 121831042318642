import { OfferAssetUploadComponent } from './shared/components/pages/offer-asset-upload/offer-asset-upload.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { DocRedirectPageComponent } from './borrowers/borrower-page/components/doc-redirect-page/doc-redirect-page.component';

import { BorrowerResolver } from './borrowers/borrower-page/services/borrower.resolver';
import { LoanOfficerProfileResolver } from './loan-officers/services/loan-officer-profile.resolver';

import { UserRole, OfferResolver } from './core';
import {
  AuthorizedLayoutComponent,
  OfferTemplatePageComponent,
  PreApprovalDocumentTemplatePageComponent,
  ToolboxPageComponent,
  ToolboxAdminComponent,
  BorrowerLetterPageComponent,
  OfferLetterPageComponent,
  BOPLetterPageComponent,
  OfferPreviewLetterPageComponent,
  LoanofficerCustomLetterPreviewComponent,
  BorrowerCustomLetterComponent
} from './shared';

import { AimePricingComponent } from './onboarding/aime-pricing/aime-pricing.component';
import { DocumentEditorPageComponent } from './shared/components/pages/document-editor-page/document-editor-page.component';

const roles: UserRole[] = [UserRole.Admin, UserRole.LoanOfficer, UserRole.Realtor];
  
const routes: Routes = [
  // public
  
  {
    path: "pub_borrowers/:id/:key",
    component: DocRedirectPageComponent
  },
  {
    path: 'onboarding',
    loadChildren: './onboarding/onboarding.module#OnboardingModule',
  },
  {
    path: 'aime',
    loadChildren: './onboarding/onboarding.module#OnboardingModule',
   },
  {
    path: 'offer-template/:id',
    component: OfferTemplatePageComponent,
    resolve: {
      offer: OfferResolver,
    }
  },
  {
    path: 'preapproval-document-template/:id',
    component: PreApprovalDocumentTemplatePageComponent,
    resolve: {
      borrower: BorrowerResolver,
    },
  },
  {
    path: 'preapproval-document-test-preview/:id',
    component: BOPLetterPageComponent
  },
  {
    path: 'offer-document-test-preview/:id',
    component: OfferPreviewLetterPageComponent
  },
  {
    path: 'borrower-letter/:id',
    component: BorrowerLetterPageComponent,
    resolve: {
      borrower: BorrowerResolver,
    },
  },
  {
    path: "custom-letter/:id",
    component: BorrowerCustomLetterComponent,
    resolve: {
      borrower: BorrowerResolver,
    },
  },
  {
    path: 'lo-custom-preview-page/:id',
    component: LoanofficerCustomLetterPreviewComponent,
    resolve: {
      loanOfficer: LoanOfficerProfileResolver,
    }
  },
  {
    path: 'offer-letter/:id',
    component: OfferLetterPageComponent,
    resolve: {
      offer: OfferResolver,
    },
  },
  {
    path: "offer-asset-upload/:id",
    component: OfferAssetUploadComponent,
  },
  {
    path: 'asset-template/:borrowerId/:assetTypeId',
    loadChildren: './asset-template/asset-template.module#AssetTemplateModule',
  },
  {
    path: 'editor',
    component: DocumentEditorPageComponent,
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
  },
  {
    path: 'signup',
    loadChildren: './sign-up/sign-up.module#SignUpModule',
  },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: roles,
        redirectTo: './login',
      },
    },
    children: [
      {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        canLoad: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: [UserRole.Admin],
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'realtors',
        loadChildren: './realtors/realtors.module#RealtorsModule',
      },
      {
        path: 'realtors/:id',
        loadChildren: './realtors/realtor-page/realtor-page.module#RealtorPageModule',
      },
    
      {
        path: 'loan-officers2',
        loadChildren: './loan-officers2/loan-officers2.module#LoanOfficers2Module',
      },
      {
        path: 'borrowers',
        loadChildren: './borrowers/borrowers.module#BorrowersModule',
      },
      {
        path: 'borrowers/:id',
        loadChildren: './borrowers/borrower-page/borrower-page.module#BorrowerPageModule',
      },
      {
        path: 'loan-officers',
        loadChildren: './loan-officers/loan-officers.module#LoanOfficersModule',
      },

      {
        path: 'offers',
        loadChildren: './offers/offers.module#OffersModule',
      },
      
      {
        path: 'offers/:id',
        loadChildren: './offers/offer-page/offer-page.module#OfferPageModule',
      },
      {
        path: 'mail_templates',
        loadChildren: './mail-template-editor/mail-template.module#MailTemplateModule',
      },
      {
        path: 'faq',
        loadChildren: './faq/faq.module#FAQModule',
      },
      {
        path: 'support',
        // loadChildren: './faq/faq.module#FAQModule',
        loadChildren: './support/support.module#SupportModule',
      },
      {
        path: 'loan-officers',
        loadChildren: './loan-officers/loan-officers.module#LoanOfficersModule',
      },
      {
        path: 'toolbox',
        component: ToolboxPageComponent,
      },
      { 
          path: 'admin-toolbox',
          component: ToolboxAdminComponent,
        
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard',
      },
    ],
  },
];

/**
 * Root routing module.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
