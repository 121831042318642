import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap, exhaustMap } from 'rxjs/operators';

import {
  BorrowerNoteType,
  BorrowerService,
  catchHttpErrorMessage,
  completedOrFailed,
  DestroyableBase,
  Message,
  PreApprovalStatus,
} from '../../../../core';

import { DenyPreApprovalDialogData } from './deny-pre-approval-dialog-data';

/**
 * Dialog with deny pre approval status.
 */
@Component({
  selector: 'arb-deny-pre-approval-dialog',
  templateUrl: './deny-pre-approval-dialog.component.html',
  styleUrls: ['./deny-pre-approval-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DenyPreApprovalDialogComponent extends DestroyableBase {

  /**
   * Error message stream.
   */
  public readonly httpError$ = new Subject<Message | null>();

  /**
   * Shows progress bar when request is in progress.
   */
  public readonly loading$ = new BehaviorSubject(false);

  /**
   * Form control for pre-approval borrower status.
   */
  public messageControl = new FormControl(null, Validators.required);

  private readonly submit$ = new Subject<string>();

  constructor(
    private borrowerService: BorrowerService,
    @Inject(MAT_DIALOG_DATA)
    public data: DenyPreApprovalDialogData,
    private dialogRef: MatDialogRef<DenyPreApprovalDialogComponent>,
  ) {
    super();
    this.subscribeToSubmit();
  }

  private subscribeToSubmit(): void {
    this.submit$.pipe(
      tap(() => this.loading$.next(true)),
      tap(() => this.httpError$.next(null)),
      exhaustMap(note => this.borrowerService.createNote(this.data.borrowerId, note, BorrowerNoteType.DeniedPreApproval).pipe(
        completedOrFailed(() => this.loading$.next(false)),
        catchHttpErrorMessage({message$: this.httpError$}),
      )),
      tap(() => this.dialogRef.close(PreApprovalStatus.Denied)),
      takeUntil(this.destroy$),
    )
      .subscribe();
  }

  /**
   * Close dialog.
   * @param value Value that will be returned after closing.
   */
  public close(value: PreApprovalStatus | null): void {
    this.dialogRef.close(value);
  }

  /**
   * Submit deny message and close dialog.
   */
  public submitMessage(): void {
    this.submit$.next(this.messageControl.value);
  }
}
