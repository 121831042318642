import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OrderService } from 'src/app/core/services/order.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { element } from 'protractor';
import { UpdateProductsConfig } from 'src/app/core/models/update-product-config';
import { catchHttpErrorMessage, completedOrFailed, ErrorMessage, Message, SuccessMessage } from 'src/app/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'arb-products-config-dialog',
  templateUrl: './products-config-dialog.component.html',
  styleUrls: ['./products-config-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsConfigDialogComponent implements OnInit {
  public submit$ = new Subject();
  public readonly message$ = new Subject<Message | null>();
  Isadd: boolean = true;
  productsdata: any;
  result: any;
  filtered: any;
  public updateproductsConfigData: UpdateProductsConfig;
  //public productsConfigData: UpdateProductsConfig[] = [];
  public productsConfigData:any[];
  constructor(
    private formBuilder: FormBuilder,
    public orderService: OrderService,
    private matDialog: MatDialogRef<ProductsConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.action == "Add" && this.data.editdata) {
      this.Isadd = true;
      this.productsConfigData = this.data.editdata;
    }
    if (this.data.editdata && this.data.action == "Edit") {
      this.Isadd = false;
      this.editProductsConfig.controls.inviteslimitedit.setValue(this.data.editdata.noOfInvites);
    }

    this.orderService.getAllProducts().subscribe((response: any) => {
      
      if (response.data)
        this.result = response.data;
      let productId = this.productsConfigData.reduce((a, o) => (a.push(o.productId), a), []);
      let uniqueProductId = productId.filter((c, index) => {
        return productId.indexOf(c) === index;
      });
      var finalResult = this.result.filter((productconf) => !uniqueProductId.includes(productconf.id));
      if (this.data.selectedplan == "LO")
        this.productsdata = finalResult.filter(item => item.metadata.ROLE == "LOAN_OFFICER");
      else if (this.data.selectedplan == "RL")
        this.productsdata = finalResult.filter(item => item.metadata.ROLE == "REALTOR");
    });
  }

  public addProductsConfig: FormGroup = new FormGroup({
    productName: new FormControl("", Validators.required),
    duration: new FormControl("", Validators.required),
    inviteslimit: new FormControl("", Validators.required),
    plantype:new FormControl("", Validators.required)
  });
  public editProductsConfig: FormGroup = new FormGroup({
    inviteslimitedit: new FormControl("", Validators.required),
  });
  /**
* Closes the dialog form with the specified result.
* @param result Whether an item has been added.
*/
  public close(result: boolean): void {
    this.matDialog.close(result);
  }

  saveproductConfig() {

    const data = {
      productId: this.addProductsConfig.value.productName,
      userRole: "",
      interval: this.addProductsConfig.value.duration,
      noOfInvites: this.addProductsConfig.value.inviteslimit,
      plantype: this.addProductsConfig.value.plantype,
      name:""
    }
    var exists = this.productsConfigData.find(item => item.productId == data.productId);
    if (exists) {
      this.message$.next(new ErrorMessage('Product Configuration alredy exists!'));
    }
    else {
      let value = this.productsdata.find(item => item.id == data.productId);
      if (value)
      {
      data.userRole = value.metadata.ROLE;
      data.name = value.name;
    }
      this.orderService.createproductConfig(data).subscribe((res) => {
        console.log(res);
        this.message$.next(new SuccessMessage('New Product Config Inserted successfully'))
        this.close(true);
      }, (err) => {
        if (err) {
          this.message$.next(new ErrorMessage('Oops, Something went wrong'));
        }
      });
    }
    //var exists = this.productsConfigData.find()

    console.log(data);
  }
  editProductsConfigLimit() {
    const data = {
      id: this.data.editdata.id,
      productId: this.data.editdata.productId,
      userRole: this.data.editdata.userRole,
      interval: this.data.editdata.interval,
      noOfInvites: this.editProductsConfig.value.inviteslimitedit,
      plantype: this.data.editdata.plantype
    };
    this.updateproductsConfigData = data;
    this.orderService.updateProductConfigLimit(this.updateproductsConfigData).subscribe((res) => {
      console.log(res);
      this.message$.next(new SuccessMessage('Limit Updated Successfully'))
      this.close(true);
    }, (err) => {
      if (err) {
        this.message$.next(new ErrorMessage('Oops, Something went wrong'));
      }
    });
  }
}
