import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { toReadableBorrowerStatus } from '../../../../core';
import { UpdateStatusDialogData } from '../../../models/update-status-dialog-data';

/**
 * Dialog with selection of approval status.
 */
@Component({
  selector: 'arb-pre-approval-change-status-dialog',
  templateUrl: './pre-approval-change-status-dialog.component.html',
  styleUrls: ['./pre-approval-change-status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PreApprovalChangeStatusDialogComponent {

  /**
   * Returns status in readable format.
   */
  public readonly toReadableBorrowerStatus = toReadableBorrowerStatus;

  /**
   * Form control for pre-approval borrower status.
   */
  public statusControl = new FormControl(null, Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: UpdateStatusDialogData,
    private dialogRef: MatDialogRef<PreApprovalChangeStatusDialogComponent>,
  ) { }

  /**
   * Close dialog.
   * @param value accepted or rejected dialog.
   */
  public close(value: boolean): void {
    if (value) {
      this.dialogRef.close(this.statusControl.value);
    } else {
      this.dialogRef.close(null);
    }
  }

}
