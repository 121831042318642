import { ChangeDetectionStrategy, Component, ViewEncapsulation, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { map, switchMap, filter, tap, takeUntil } from 'rxjs/operators';

import { AuthService, CurrentUserService, UserRole, DestroyableBase, toReadableUserRole, UsersService } from '../../../../core';
import { ConfirmDialogData } from '../../../models/confirm-dialog-data';
import { MatButtonColor } from '../../../models/mat-button-color';
import { DialogService } from '../../../services/dialog.service';

/**
 *  Current user status bar.
 */
@Component({
  selector: 'arb-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBarComponent extends DestroyableBase implements OnInit, AfterViewInit {

  private readonly logout$ = new Subject<void>();
  private readonly  PaymentSummary$ = new Subject<void>();

  public userRoles = UserRole;
 
  /**
   * Current user stream.
   */
  public readonly user$ = this.currentUserService.currentUser$;

  /**
   * Account url stream.
   */
  public readonly accountUrl$ = this.createAccountUrlStream();
  

  public readonly helpUrl$ = this.createHelpUrlStream();

  public readonly queryType$ = this.createQueryTypeUrlStream();
  public readonly permission$ = this.createPermissionUrlStream()
  public readonly configuration$ = this.createConfigurationStream();
  public readonly paymentSummary$ = this.createPaymentSummaryStream();
  public readonly paymentInfo$ = this.createPaymentInfoStream();
  public readonly plan$ = this.createPlanUrlStream();
  public readonly productconfiguration$ = this.createProductConfigurationUrlStream();
  public readonly emailsubscription$ = this.createEmailSubscriptionUrlStream();
  public user;

  /**
   * To readable user role.
   */
  public readonly toReadableUserRole = toReadableUserRole;
  public showPermission: boolean = false;
  public userType: number;
  public userTypeText: string = "Arbor User";
  public badgeColor;
  public hideUpgradePlan: boolean = false;

  constructor(
    private currentUserService: CurrentUserService,
    private dialogService: DialogService,
    private authService: AuthService,
    private userService: UsersService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.subscribeToLogout();
    this.user$.subscribe(res => {
      this.user = res.role;
      if(this.user === "Admin") {
        this.showPermission = true;
      }
    });
    
    this.userService.getUserType().subscribe(res => {
      // console.log("User Type", res);
      if(res == null) {
        // this.userTypeText = "Arbor User";
        this.hideUpgradePlan = true;
        this.cdr.markForCheck();
      } 
      else if(res > 0) {
        // this.userTypeText = "Free User";
        this.cdr.markForCheck();
      } 
      else {
        // this.userTypeText = "Paid User";
        this.cdr.markForCheck();
      }

      if(res == null) {
        this.hideUpgradePlan = true;
      }
    })
    
  }

  

  ngAfterViewInit() {
    
  }

  private createAccountUrlStream(): Observable<string> {
    return this.user$
      .pipe(
        map(({ role }) => {
          switch (role) {
            case UserRole.Admin:
              return '/admin/account';
            case UserRole.LoanOfficer:
              return '/loan-officers/account';
            case UserRole.Realtor:
              return '/realtors/account';
            default:
              throw new Error('Invalid role');
          }
        }),
      );
  }

  private createHelpUrlStream(): Observable<string> {
    return this.user$
      .pipe(
        map(({ role }) => {
          switch (role) {
            case UserRole.Admin:
              return '/faq';
            case UserRole.LoanOfficer:
              return '/faq';
            case UserRole.Realtor:
              return '/faq';
            default:
              throw new Error('Invalid role');
          }
        }),
      );
  }

  private createQueryTypeUrlStream() : Observable<string>{
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            return '/support/queryType';
          case UserRole.LoanOfficer:
            return '/support/queryType';
          case UserRole.Realtor:
            return '/support/queryType';
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }

  private createProductConfigurationUrlStream(): Observable<string> {
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            return '/support/productsConfig';
          case UserRole.LoanOfficer:
            throw new Error('Invalid role');
          case UserRole.Realtor:
            throw new Error('Invalid role');
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }
  private createEmailSubscriptionUrlStream(): Observable<string> {
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            return '/support/emailsubscribe';
          case UserRole.LoanOfficer:
            throw new Error('Invalid role');
          case UserRole.Realtor:
            throw new Error('Invalid role');
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }
  private createPermissionUrlStream(): Observable<string> {
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            return '/support/permission';
          case UserRole.LoanOfficer:
            throw new Error('Invalid role');
          case UserRole.Realtor:
            throw new Error('Invalid role');
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }

  private createConfigurationStream(): Observable<string> {
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            return '/support/configuration';
          case UserRole.LoanOfficer:
            throw new Error('Invalid role');
          case UserRole.Realtor:
            throw new Error('Invalid role');
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }
  
  private createPaymentSummaryStream(): Observable<string> {
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            return '/dashboard/payments/plans/user-payment';
          case UserRole.LoanOfficer:
            return '/dashboard/payments/plans/user-payment';
          case UserRole.Realtor:
            return '/dashboard/payments/plans/user-payment';
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }

  private createPaymentInfoStream(): Observable<string> {
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            return '/dashboard/payments/plans/user-payment';
          case UserRole.LoanOfficer:
            return '/dashboard/payments/plans/user-payment';
          case UserRole.Realtor:
            return '/dashboard/payments/plans/user-payment';
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }

  private createPlanUrlStream(): Observable<string> {
    return this.user$
    .pipe(
      map(({ role }) => {
        switch (role) {
          case UserRole.Admin:
            throw new Error('Invalid role');
          case UserRole.LoanOfficer:
            return '/dashboard/payments/plans/view-plan-lo';
          case UserRole.Realtor:
            return '/dashboard/payments/plans/view-plan-rl';
          default:
            throw new Error('Invalid role');
        }
      }),
    );
  }


  private subscribeToLogout(): void {
    this.logout$
      .pipe(
        map(() => this.createConfirmDialogData()),
        switchMap(data => this.dialogService.openConfirmDialog(data)),
        filter(res => res),
        tap(() => this.authService.logout()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }


  

  private createConfirmDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: 'Logout',
      subtitle: 'Are you sure you want to log out?',
      acceptButtonColor: MatButtonColor.Primary,
      acceptButtonText: 'Yes, Log out',
    });
  }

  /**
   * Logout.
   */
  public logout(): void {
    this.logout$.next();
  }

  // public PaymentSummary : void {
  //   this.PaymentSummary$.next();
  // }


}
