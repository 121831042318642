import { Pipe, PipeTransform } from '@angular/core';

import { RolesService, UserRole } from '../../core';

/**
 * Borrower label pipe.
 */
@Pipe({
  name: 'borrowerLabel',
})
export class BorrowerLabelPipe implements PipeTransform {

  constructor(private rolesService: RolesService) { }

  /**
   * Returns borrower's label depending on the current role.
   */
  public transform(label: string, args?: string): string {
    const plural = args === 'plural';
    const labelLower = label.toLowerCase();
    const isRealtor = this.rolesService.getRole() === UserRole.Realtor;
    if (labelLower.includes('borrower') && isRealtor) {
      const res = plural ? 'Clients' : 'Client';
      return label.replace(/borrowers?/i, res);
    }
    return label;
  }

}
