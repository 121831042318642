import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConfirmDialogData } from '../../../models/confirm-dialog-data';

/**
 * Confirm dialog component.
 */
@Component({
  selector: 'arb-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogData,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) { }

  /**
   * Close dialog.
   * @param value Value that will be returned after closing.
   */
  public close(value: boolean|string): void {
    this.dialogRef.close(value);
  }

}
