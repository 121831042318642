import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SupportTicketStatus, toReadableTicketStatus, RolesService } from '../../../../core';
import { StatusColor } from '../../../models/status-color';
import { UserStatusConfig } from '../../../models/user-status-config';

/**
 * Realtor status.
 */
@Component({
  selector: 'arb-support-status',
  templateUrl: './support-status.component.html',
  styleUrls: ['./support-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportStatusComponent {

  private readonly colors = this.createColors();
  private configValue = this.createDefaultConfig();
  public isShowGenerate=false;
  /**
   * All user statuses.
   */
  public readonly statuses = SupportTicketStatus;

  /**
   * Status text value.
   */
  public statusValue: SupportTicketStatus;

  /**
   * Status color.
   */
  public color: StatusColor;

  /**
   * Show only if current user is admin or LO and if small version is enabled.
   */
  public readonly showActivateAbility$ = this.createShowActivateAbilityStream();

  /**
   * Config.
   */
  @Input()
  public set config(value: UserStatusConfig) {
    this.configValue = { ...this.configValue, ...value };
  }
  /**
   * Config.
   */
  @Input()
  public set showGenerate(value: boolean) {
    this.isShowGenerate = value;
  }



  public get config(): UserStatusConfig {
    return this.configValue;
  }

  /**
   * Status.
   */
  @Input()
  public set status(value: SupportTicketStatus) {
    this.statusValue = value;
    this.color = this.colors[value];
  }

  public get status(): SupportTicketStatus {
    return this.statusValue;
  }

  /**
   * Emits new status.
   */
  @Output()
  public statusChange = new EventEmitter<SupportTicketStatus>();

  /**
   * Emits event ot resend invite.
   */
  @Output()
  public resendInviteChange = new EventEmitter<void>();

  /**
   * Emits event ot genrate invite.
   */
  @Output()
  public generateInviteChange= new EventEmitter<void>();

  constructor(
    private rolesService: RolesService,
  ) { }

  private createColors(): Record<SupportTicketStatus, StatusColor> {
    return {
      [SupportTicketStatus.Completed]: StatusColor.Green,
      [SupportTicketStatus.Pending]: StatusColor.Gray,
      [SupportTicketStatus.Processing]: StatusColor.Blue,
    };
  }

  private createShowActivateAbilityStream(): Observable<boolean> {
    return this.rolesService
      .isRealtor$
      .pipe(
        /* TypeScript can't compare `boolean | undefined` and `boolean`. */
        map(isRealtor => !!this.config.enableActivating && !isRealtor),
      );
  }

  private createDefaultConfig(): UserStatusConfig {
    return {
      useRectangle: false,
      multiline: false,
      small: false,
      enableActivating: false,
      text:false
    };
  }

  /**
   * Activated.
   */
  public get completed(): boolean {
    return this.status === SupportTicketStatus.Completed;
  }

  /**
   * Deactivated.
   */
  public get pending(): boolean {
    return this.status === SupportTicketStatus.Pending;
  }

  /**
   * Invited.
   */
  public get processing(): boolean {
    return this.status === SupportTicketStatus.Processing;
  }

  /**
   * Returns readable user status.
   */
  public get statusText(): string {
    return toReadableTicketStatus(this.status);
  }

  /**
   * Emits new status.
   * @param status New status.
   */
  public changeStatus(status: SupportTicketStatus): void {
    this.statusChange.emit(status);
  }

  /**
   * Emits event to resend invite.
   */
  public resendInvite(): void {
    this.resendInviteChange.emit();
  }

  /**
   * Emit generate Event
   */
  public generateInvite():void{
    this.generateInviteChange.emit();
  }
}
