/**
 * Property type.
 */
export enum PropertyType {

  /**
   * Detached.
   */
  Detached = 1,

  /**
   * Attached.
   */
  Attached,

  /**
   * Condominium.
   */
  Condominium,

  /**
   * High Rise Condo.
   */
  HighRiseCondo,

  /**
   * Detached Condo.
   */
  DetachedCondo,

  /**
   * PUD.
   */
  PUD,
  
  /**
   * Cooperative.
   */
  Cooperative,
  
  /**
   * Manufactured.
   */
  Manufactured,
  
  /**
   * Manufactured Condo/PUD/Co-Op.
   */
  ManufacturedCondoOrPUDOrCoOp,
  
  /**
   * Manufactured Singlewide..
   */
  ManufacturedSinglewide,
  
  /**
   * Manufactured Multiwide.
   */
  ManufacturedMultiwide,
  
  /**
   * Other.
   */
  Other,
  
  /**
   * Vacant Land.
   */
  VacantLand,
  
  /**
   * Manufactured MH Advantage.
   */
  ManufacturedMHAdvantage,

}

const types: Record<PropertyType, string> = {
  [PropertyType.Detached]: 'Detached',
  [PropertyType.Attached]: 'Attached',
  [PropertyType.Condominium]: 'Condominium',
  [PropertyType.HighRiseCondo]: 'High Rise Condo',
  [PropertyType.DetachedCondo]: 'Detached Condo',
  [PropertyType.PUD]: 'PUD',
  [PropertyType.Cooperative]: 'Cooperative',
  [PropertyType.Manufactured]: 'Manufactured',
  [PropertyType.ManufacturedCondoOrPUDOrCoOp]: 'ManufacturedCondo/PUD/CoOp',
  [PropertyType.ManufacturedSinglewide]: 'Manufactured Singlewide',
  [PropertyType.ManufacturedMultiwide]: 'Manufactured Multiwide',
  [PropertyType.Other]: 'Other',
  [PropertyType.VacantLand]: 'Vacant Land',
  [PropertyType.ManufacturedMHAdvantage]: 'Manufactured MH Advantage',
};

/**
 * Returns type in readable format.
 * @param type Type as number.
 */
export function toReadablePropertyType(type: PropertyType): string {
  return types[type];
}
