/**
 * Offer status.
 */
export enum OfferStatus {
  /**
   * Draft.
   */
  Draft = 1,

  /**
   * Submitted.
   */
  Submitted,

  /**
   * Accepted.
   */
  Accepted,

  /**
   * Rejected.
   */
  Rejected,
}

const OFFER_STATUS_TEXT: Record<OfferStatus, string> = {
  [OfferStatus.Draft]: 'Draft',
  [OfferStatus.Submitted]: 'Submitted',
  [OfferStatus.Accepted]: 'Accepted',
  [OfferStatus.Rejected]: 'Rejected',
};

/**
 * Get a readable representation of a status.
 * @param value Offer status.
 */
export function toReadableOfferStatus(value: OfferStatus): string {
  return OFFER_STATUS_TEXT[value] || '';
}
