import { Component, ChangeDetectionStrategy, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { listenControlChanges } from '../../../../core';
import { RendererType } from '../../../models/document-editor/renderer/renderer-type';

/**
 * Document editor dialog component.
 */
@Component({
  selector: 'arb-account-document-editor-dialog',
  templateUrl: './account-document-editor-dialog.component.html',
  styleUrls: ['./account-document-editor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDocumentEditorDialogComponent implements OnInit {

  /**
   * Files.
   */
  public files: File[] = [];

  /**
   * Stream for triggering crop effect.
   */
  public readonly crop$ = new Subject<void>();

  /**
   * Undo stream.
   */
  public readonly undo$ = new Subject<void>();

  /**
   * Renderer type stream.
   */
  public readonly rendererType$ = new BehaviorSubject(RendererType.RectangleRenderer);

  /**
   * `true` if cropper is enabled.
   */
  public readonly cropperEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.Cropper),
    );

  /**
   * `true` if rectangle renderer is enabled.
   */
  public readonly rectangleRendererEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.RectangleRenderer),
    );

  /**
   * Form control for agreement.
   */
  public readonly agreementControl = new FormControl(false);

  /**
   * Allow save stream.
   */
  public readonly allowSave$ = listenControlChanges<boolean>(this.agreementControl, 0);

  /**
   * `true` if uploaded file is pdf.
   */
  public readonly isPdf = this.data.file.type === 'application/pdf';

    /**
   * File object.
   */
  public file: File[];

     /**
   * File object.
   */
  public dialogHeader: string = "Privacy Editor";
  public dialogInstruction: string = "Highlight areas you wish to omit from the document for privacy reasons.";
  public isShowAgreementControl: Boolean = true;
  public sideScroll:boolean=true
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private matDialog: MatDialogRef<AccountDocumentEditorDialogComponent>,
  ) { }


  ngOnInit() {
    this.file = this.data.file;
    this.dialogHeader = this.data.params.dialogHeader;
    this.dialogInstruction = this.data.params.dialogInstruction;
    this.isShowAgreementControl = this.data.params.isShowAgreementControl;
    this.sideScroll = this.data.params.sideScroll == false ? false:true;
  }

  /**
   * Undo.
   */
  public undo(): void {
    this.undo$.next();
  }

  /**
   * Sets new files.
   * @param files New files.
   */
  public onFilesChange(files: File[]): void {
    this.files = files;
  }

  /**
   * Closes the dialog with changed image.
   */
  public save(): void {
    this.crop$.next();
    this.matDialog.close(this.files);
  }
  public cancel(){
    this.matDialog.close(null);
  }

  /**
   * Disable editor.
   */
  public disableEditor(): void {
    this.rendererType$.next(RendererType.None);
  }

  /**
   * Enable cropper.
   */
  public enableCropper(): void {
    this.rendererType$.next(RendererType.Cropper);
  }

  /**
   * Enable rectangle renderer.
   */
  public enableRectangleRenderer(): void {
    this.rendererType$.next(RendererType.RectangleRenderer);
  }

  /**
   * Triggers crop stream.
   */
  public crop(): void {
    this.crop$.next();
  }

  /**
   * Disable editor when user opens new page.
   */
  public onPageChanged(): void {
    this.disableEditor();
  }

}
