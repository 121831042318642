import { Full } from '../../helpers/full';
import { PersonDetails } from '../person-details';

/**
 * Associated realtor.
 */
export class AssociatedRealtor extends PersonDetails {

  /**
   * Office address.
   */
  public officeAddress: string;

  /**
   * Website.
   */
  public website: string;
  
  /**
   * License Number.
   */
  public licenseNumber: string;
  
  constructor(data: Full<AssociatedRealtor>) {
    super(data);
    this.officeAddress = data.officeAddress;
    this.website = data.website;
    this.licenseNumber = data.licenseNumber;
  }

}
