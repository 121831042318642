import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * Base dialog component.
 */
@Component({
  selector: 'arb-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {

  /**
   * Title.
   */
  @Input()
  public title: string;

  /**
   * Subtitle.
   */
  @Input()
  public subtitle: string;

}
