import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/**
 * Base account page component.
 */
@Component({
  selector: 'arb-base-account',
  templateUrl: './base-account.component.html',
  styleUrls: ['./base-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseAccountComponent {

  /**
   * Assistants.
   */
  @Input()
  public assistants: unknown[] = [];

  /**
   * Account title.
   */
  @Input()
  public accountTitle: string;

  public isVisibleOnlyToLO = true

  ngOnInit(){
    this.isVisibleOnlyToLO = window.location.pathname.includes('/realtors') || window.location.pathname.includes('/signup') || window.location.pathname.includes('/admin/account')? false:true ;
  }
}
