import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap, first } from 'rxjs/operators';

import { catchHttpErrorMessage, DestroyableBase, ErrorMessage,QueryNewItem, SupportService } from '../../../../core';

/**
 * Document editor dialog component.
 */
@Component({
  selector: 'arb-query-item-add-dialog',
  templateUrl: './query-item-add-dialog.component.html',
  styleUrls: ['./query-item-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryItemAddDialogComponent extends DestroyableBase implements OnInit {

  private readonly submit$ = new Subject<void>();


  /**
   * Add item form.
   */
  public readonly addItemForm = this.createAddItemForm();

  /**
   * Error message stream.
   */
  public readonly error$ = new Subject<ErrorMessage | null>();

  /**
   * .ctor
   * @param toolboxService Toolbox business logic.
   * @param formBuilder Form constructor.
   * @param matDialog Dialog logic.
   */
  constructor(
    private supportService: SupportService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialogRef<QueryItemAddDialogComponent>,
  ) {
    super();
  }

  /**
   * Callback after binding the inputs.
   */
  public ngOnInit(): void {
    this.subscribeToSubmit();
  }

  private createAddItemForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  private subscribeToSubmit(): void {
    this.submit$
      .pipe(
        filter(() => this.addItemForm.valid),
        map(() => this.addItemForm.value),
        map(({  name }) => new QueryNewItem({ name })),
        switchMap(data =>
          this.supportService.addQuery(data)
            .pipe(
              tap(() => this.close(true)),
              catchHttpErrorMessage({ message$: this.error$ }),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

 

  /**
   * Closes the dialog form with the specified result.
   * @param result Whether an item has been added.
   */
  public close(result: boolean): void {
    this.matDialog.close(result);
  }

  /**
   * Submits the form.
   */
  public submit(): void {
    this.submit$.next();
  }

}
