import { startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap, filter } from 'rxjs/operators';

import { listenControlChanges } from '../../../../core';
import { RendererType } from '../../../models/document-editor/renderer/renderer-type';

/**
 * Document editor dialog component.
 */
@Component({
  selector: 'arb-document-editor-dialog-custom-preapproval',
  templateUrl: './document-editor-dialog-custom-preapproval.component.html',
  styleUrls: ['./document-editor-dialog-custom-preapproval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentEditorDialogCustomPreapprovalComponent {

  /**
   * Files.
   */
  public files: File[] = [];
  public items:[]
  public currentText="select value";
  public fontSize: number = 16;
  public fontStyle: string = "Verdana";
  public fontColor:string ="";
 public  myControl = new FormControl();
 public  options: string[] = [
   'Arial',
   'Arial Black', 
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Times New Roman',
    'Didot',
    'Georgia',
    'Monaco'
  ];
  filteredOptions: Observable<string[]>;
  public custFields
  private params
  public spacing: number = 10;

 public texColor=""

  /**
   * Stream for triggering crop effect.
   */
  public readonly crop$ = new Subject<void>();
  
  /**
   * Undo stream.
   */
  public readonly undo$ = new Subject<void>();

  /**
   * Renderer type stream.
   */
  public readonly rendererType$ = new BehaviorSubject(RendererType.PreApprovalContentRenderer);

  public customPreApprovalBtnFlag = true;

    /**
   * `true` if customPreapproval is enabled.
   */
  public readonly customPreapproval$ = this.rendererType$
  .pipe(
    map(type => type === RendererType.PreApprovalContentRenderer),
  );

  /**
   * `true` if cropper is enabled.
   */
  public readonly cropperEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.Cropper),
    );

  /**
   * `true` if rectangle renderer is enabled.
   */
  public readonly rectangleRendererEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.RectangleRenderer),
    );
    public readonly rectangleRendererHighlighterEnabled$ = this.rendererType$
    .pipe(
      map(type => type === RendererType.RectangleRendererHighlighter),
    );

  /**
   * Form control for agreement.
   */
  public readonly agreementControl = new FormControl(false);

  /**
   * Allow save stream.
   */
  public readonly allowSave$ = listenControlChanges<boolean>(this.agreementControl, 0);

  /**
   * `true` if uploaded file is pdf.
   */
  public readonly isPdf = this.data.file.type === 'application/pdf';

  public file: File[];

     /**
   * File object.
   */
  public customParams$ = new BehaviorSubject({preApproval:{}});
  
  public coordinates$=new BehaviorSubject([])
  
  public customPreApprovalBtn$ =  new BehaviorSubject(false);

  public currentField={purchasePrice:"",address:"",downPayment:"",propertyType:"",occupancy:"",fico:"",program:""}

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private matDialog: MatDialogRef<DocumentEditorDialogCustomPreapprovalComponent>,
  ) { }
  cancel() {
    // closing itself and sending data to parent component
    console.log("called cancel")
    this.matDialog.close({files:[],coordinates:[]})
  }
  ngOnInit() {
    this.file = this.data.file;
    // setTimeout(() => {
      this.custFields={
        "purchase Price": "<%Purchaseprice>",
        "Address": "<%Address>",
        "DownPayment": "<%Downpayment>",
        "property Type": "<%Propertytype>",
        "Occupancy": "<%Occupancy>",
        "FICO": "<%Fico>",
        "Program Type": "<%Programtype>",
      }
      this.data.customParams.preApproval={purchasePrice:"",address:"",downPayment:"",propertyType:"",occupancy:"",fico:"",program:""}
      this.customParams$.next(this.data.customParams);

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      tap(value => this.handlefontStyleChange(value)),
      map(value => this._filter(value))
    );
  }
  
public onCordinatesChange(event){
  this.items=event;
}
  
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  /**
   * Undo.
   */
  public undo(): void {
    this.undo$.next();
  }

  /**
   * Sets new files.
   * @param files New files.
   */
  public onFilesChange(files: File[]): void {
    this.files = files;
  }

  /**
   * Closes the dialog with changed image.
   */
  public save(): void {
    this.matDialog.close({files:this.files});
  }
  public changeFontColor(event) {
    this.texColor=event.target.value
    this.data.customParams.textColor =event.target.value;
    this.customParams$.next(this.data.customParams);
  }
  /**m
   * Disable editor.
   */
  public disableEditor(): void {
    this.rendererType$.next(RendererType.None);
    
  }
  public drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }
  /**
   * Enable cropper.
   */
  public enableCropper(): void {
    this.rendererType$.next(RendererType.Cropper);
  }
  /**
   * Enable cropper.
   */
   public handlefontStyleChange(value): void {
    this.data.customParams.fontStyle = value;
    this.customParams$.next(this.data.customParams);
    this.fontStyle = value;
  }
  
  /**
   * Enable cropper.
   */
   public handlefontSizeChange(value): void {
    this.data.customParams.fontSize = value;
    this.customParams$.next(this.data.customParams);
    this.fontSize = value;
  }

  /**
   * Enable cropper.
   */
   public handleSpacingChange(value): void {
     this.data.customParams.lineSpacing = value;
     this.customParams$.next(this.data.customParams);
     this.spacing = value;
  }


  

  /**
   * Enable rectangle renderer.
   */
  public enableRectangleRenderer(): void {
    this.rendererType$.next(RendererType.RectangleRenderer);
  }
  public enableRectangleRendererHighlighter(): void {
    this.rendererType$.next(RendererType.RectangleRendererHighlighter);
  }

  public enableCustomePreapproval(): void {
    this.rendererType$.next(RendererType.PreApprovalContentRenderer);
    this.customPreApprovalBtn$.next(true);
  }
  


  /**
   * Triggers crop stream.
   */
  public crop(): void {
    this.crop$.next();
  }

  /**
   * Disable editor when user opens new page.
   */
  public onPageChanged(): void {
    this.disableEditor();
  }

  public disableCustomPreApprovalBtn(): void {
   
    this.customPreApprovalBtn$.next(false);
    
  }

  getTypeFlagEvent(tyepFlag:any){
      this.customPreApprovalBtnFlag =  tyepFlag;
  }

}
