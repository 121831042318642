import { Full } from '../../../../core';
import { ConfirmDialogData } from '../../../models/confirm-dialog-data';

/**
 * Deny Pre approval dialog data.
 */
export class DenyPreApprovalDialogData extends ConfirmDialogData {

  /**
   * Confirm dialog data.
   */
  public borrowerId: number;

  constructor(data: Full<DenyPreApprovalDialogData>) {
    super(data);
    this.borrowerId = data.borrowerId;
  }

}
