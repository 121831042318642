import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

/**
 * Time in milliseconds before a snack bar message will be closed.
 */
const DEFAULT_DURATION = 5000;

/**
 * Notification service.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /**
   * .ctor
   * @param snackBar Snack bar business logic.
   */
  constructor(
    private snackBar: MatSnackBar,
  ) { }

  /**
   * Shows a notification message.
   * @param message Message to be shown
   */
  public showNotification(message: string): void {
    this.snackBar.open(
      message,
      undefined,
      {
        duration: DEFAULT_DURATION,
      },
    );
  }
}
