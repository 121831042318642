import { Full } from '../../helpers/full';
import { Contact } from '../contact';

/**
 * Associated borrower.
 */
export class AssociatedBorrower extends Contact {

  /**
   * Id.
   */
  public id: number;

  /**
   * Borrower's nickname.
   */
  public nickname: string;
  
  /**
   * Is Loan Officer Independent
   */
  public isLoanOfficerIndependent: Boolean;

  constructor(data: Full<AssociatedBorrower>) {
    super(data);
    this.id = data.id;
    this.nickname = data.nickname;
    this.isLoanOfficerIndependent = data.isLoanOfficerIndependent;
  }

}
