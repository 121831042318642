import { Message } from './message';
import { MessageStatus } from './message-status';

/**
 * Error message.
 */
export class ErrorMessage extends Message {

  constructor(message: string) {
    super({
      text: message,
      status: MessageStatus.Error,
    });
  }

}
