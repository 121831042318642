import {Component} from '@angular/core';
import { UpdateRealtorDto } from './../../../../core/services/dto/update-realtor-dto';

import {
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnInit 
} from "@angular/core";
import { Subject,combineLatest, merge, Observable, BehaviorSubject, pipe } from "rxjs";
import { switchMap, share ,tap,map,take, takeUntil} from "rxjs/operators";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ActivatedRoute } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import {
  ToolboxService,
  NotificationService,
  ToolboxItem,
  CKeditorImageAdapter,
  RealtorsService,
  catchHttpErrorMessage,
  DestroyableBase,
  completedOrFailed,
  Message,
  SuccessMessage,
  ToolboxNewItem,
  CurrentUserService,
  RealtorSummary,
  partitionTable,
  Admin,
  Table,
  User,
  UserStatus,
  AdminService,
  ErrorMessage,
  CurrentUser,
  RolesService,
  UserRole,
  RealtorsSearchParams,listenControlChanges,
  UsersService
} from "../../../../core";
import { ConfirmDialogData } from "../../../models/confirm-dialog-data";
import { MatButtonColor } from "../../../models/mat-button-color";
import { DialogService } from "../../../services/dialog.service";
import { ToolboxCategory } from '../../../../core/models/toolbox/toolbox-category';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { async } from '@angular/core/testing';
import { FilterByCategoryPipe } from 'src/app/shared/pipes/filter-by-category.pipe';
import { throwToolbarMixedModesError } from '@angular/material';
import {startWith,debounceTime} from 'rxjs/operators';

import {FormControl} from '@angular/forms';


@Component({
    selector: 'arb-admin-toolbox',
    templateUrl: './toolbox-admin.component.html',
    styleUrls: ['./toolbox.component.scss']
})
export class ToolboxAdminComponent extends DestroyableBase implements OnInit  {
  private readonly forceReload$ = new Subject<void>();
  public adminId=5;
  public loadAdminId()
  {
  this.adminService.getProfile().pipe(
      map((data)=>{
      this.adminId=data.id;
      })
    ).subscribe()
  }
  private readonly initialItems$ = this.toolboxService.getItemsRealtor(this.adminId);
  
  private readonly reload$ = this.createReloadStream();
  
  private readonly reloadRealtor$=this.createReloadRealtorStream();

  public readonly disableOnAddDefault$ = new Subject<boolean>();
  
  public user$;
  /**
   * List of all the toolbox items.
   */
  public  items$= this.createItemStream();
  
  public admin$=this.createAdminStream();
  
  private createAdminStream():Observable<Admin[]>
  {
    this.forceReload$.next();
    return merge(this.currentUserService.currentUser$.pipe(
      take(1),
      map(({ id }) => id),
      switchMap(data => combineLatest(
        this.adminService.getProfile().pipe(map(data=>data)),
      )  
      ),
      share(),
      ));
  }  /**
   * List of all the toolbox categories.
   */

  selectOption(e: MatAutocompleteSelectedEvent) {
    if(e.option.id != '')
    this.select_realtorId=parseInt(e.option.id);
    this.items$=this.toolboxService.getItemsRealtor(this.select_realtorId);

 }

  public readonly categories$ = this.toolboxService.categories$;
  
  private currentSearchParams: RealtorsSearchParams;

  /**
   * emailSignature
   */
  private readonly searchChange$ = new Subject<RealtorsSearchParams>();

  public emailSignature: string = "";
 /**
  * RealtorProfile
   */
  public realtorProfile;
  public realtorId:number;
  
  public readonly isLoading$ = new BehaviorSubject(false);

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  
  
/**
   * Success or error message stream.
   */
  public readonly message$ = new Subject<Message | null>();
  
  private readonly update$ = new Subject<UpdateRealtorDto>();
  /*public readonly realtors$ = this.createRealtorsStream();
  private createRealtorsStream(): Observable<RealtorSummary[]> {
    return pipe(map(params=>()))
  }
  */

  // public readonly realtor$ = this.createRealtorStream();

  /**
   * If `true` then button will be displayed.
   * Otherwise just text will be displayed.
   */
  @Input()
  public useButton = true;
  
  @Input()
  public hideBtns: any = [];

  private readonly pageChange$ = new BehaviorSubject(1);

  public isFound=false;
  @Output() emittedToolboxTemplateType = new EventEmitter();
/**
   * Emits new params for search request.
   */
  @Output()
  public searchChange = new EventEmitter<RealtorsSearchParams>();

  public CKEDITOR_INST;
  public CKEDITOR_CONFIG= {
    image: {
        // You need to configure the image toolbar, too, so it uses the new style buttons.
        toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ],

        styles: [
            // This option is equal to a situation where no style is applied.
            'full',

            // This represents an image aligned to the left.
            'alignLeft',

            // This represents an image aligned to the right.
            'alignRight'
        ]
    }
};
public Editor = ClassicEditor;
  constructor(
    private toolboxService: ToolboxService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private realtorService:RealtorsService,
    private adminService:AdminService,
    private currentUserService: CurrentUserService,

  ) {
   super();

  }
  private refreshListOfItem() {
    if(this.select_realtorId == 0)
    {
    this.select_realtorId=this.adminId;
    this.items$=this.toolboxService.getItemsRealtor(this.select_realtorId);
    }
    else
    {
      this.items$=this.toolboxService.getItemsRealtor(this.select_realtorId);
    }
  }
  drop(event: CdkDragDrop<string[]>,id:number) {
    this.isLoading$.next(true);
    this.items$.subscribe(async (items) => {
    var filterItems=items.filter((data)=> data.categoryId == id);
    moveItemInArray(filterItems, event.previousIndex, event.currentIndex);
    var itemsId=filterItems.map((data)=> data.id);
    const itemEdited = await this.toolboxService
    
      .sortItem({List: itemsId,
                userId:this.select_realtorId,
              })
      .toPromise().then(res => {
        this.refreshListOfItem()
        this.isLoading$.next(false)
      });
    })
    this.isLoading$.next(false);
    //  send data to db
    
}
private async addDefaultData() {
  await this.toolboxService.addDefault(this.realtorId,  new ToolboxNewItem({ category: new ToolboxCategory({id:3, name:"dummy"}), text:"dummy" })).toPromise();
  this.forceReload$.next();      
}

public realtorCategories$;
public select_realtorId=0;
public onSelect(value):void
{
  this.select_realtorId=value;
  this.useButton=true;
  //this.realtorCategories$=this.toolboxService.getCategoriesRealtor(this.select_realtorId);
  this.items$=this.toolboxService.getItemsRealtor(this.select_realtorId);
}
private createTotalBorrowerCountStream(): Observable<RealtorSummary[]> {
  return  this.realtorService.getR().pipe(take(1),map(response => response.data))  
}

private createRealtorsStream(): Observable<RealtorSummary[]> {
  this.forceReload$.next();
  return merge(this.currentUserService.currentUser$
    .pipe(
      take(1),
      tap(() => this.isLoading$.next(true)),
      map(params => ({ ...params, pageSize: 10 })),
      switchMap(data => combineLatest(
        this.realtorService.search(data),
      ).pipe(completedOrFailed(() => this.isLoading$.next(false)),)  
      ),
      map(([{ data },]) => data),share(),
      ),this.reloadRealtor$);
}

public realtor$:Observable<RealtorSummary[]>;
public cdkDragDisable: boolean = true;
public sample$:Observable<any>;

ngOnInit() {
  // this.loadRealtor();
  this.loadRealtorId();
  this.realtor$=this.createRealtorsStream();
  this.myControl.valueChanges
  .pipe(
  debounceTime(200),
).subscribe(val => this.realtor$  = this.searchRepos(val).pipe((completedOrFailed(() => this.isLoading$.next(false)))));
  this.cdkDragDisable = true;
  this.items$.subscribe((data) => {
    if (data.length == 0) {
      this.addDefaultData();
    }
  });
  this.disableOnAddDefault$.next(false);
  //this.listenProfileUpdating();
}

searchRepos(params: string)
{
  
  params =params ? params.toLowerCase():"";
  this.isLoading$.next(true);
  return this.realtorService.searchR(params).pipe(map(({ data }) => { if(data.length == 0) { this.isFound =true;
} else { this.isFound=false;} return data }),completedOrFailed(() => this.isLoading$.next(false)));
}
private createSearchStream(): Observable<string> {
  return listenControlChanges<string>(this.myControl);
  
}

  public isShowBtn(btn: any) {
    return this.hideBtns.indexOf(btn) > -1;
  }
  private createReloadRealtorStream(): Observable<RealtorSummary[]> {
    return this.forceReload$.pipe(
      switchMap(() => this.realtorService.getR().pipe(take(1),map(response => response.data))),
    );
  }
  private createItemStream(): Observable<ToolboxItem[]>
  {
    this.loadAdminId();
    return this.toolboxService.getItemsRealtor(this.adminId);
  }
  private createReloadStream(): Observable<ToolboxItem[]> {
    return this.forceReload$.pipe(
      switchMap(() => this.toolboxService.getItems())
    );
  }
  
  private createItemsStream(): Observable<ToolboxItem[]> {
    return merge(this.initialItems$, this.reload$).pipe(share());
  }

  /**
   * Shows the add a new item form. Reloads the item list after adding a new item.
   */
  

  public async openAddDialog(): Promise<void> {
    
   if(this.select_realtorId == 0)
   {
    const itemAdded = await this.dialogService
    .openAdminToolboxAddDialog(this.adminId)
    .toPromise();
    
  if (itemAdded) {
    this.forceReload$.next();
    this.refreshListOfItem();
    this.message$.next(new SuccessMessage('New item added successfully'));
    // setTimeout(() => {
    //   this.message$.next();
    // }, 2000);
    }
  }
   else
   {
      const itemAdded = await this.dialogService
      .openAdminToolboxAddDialog(this.select_realtorId)
      .toPromise();
    if (itemAdded) {
      this.forceReload$.next();
      this.refreshListOfItem();
      this.message$.next(new SuccessMessage('New item added successfully'));
      // setTimeout(() => {
      //   this.message$.next();
      // }, 2000);
    
   }
  }
  }

  /**
   * Shows the add a new item form. Reloads the item list after adding a new item.
   */
  public async openAddDefaultItems(): Promise<void> {
    if(this.select_realtorId == 0)
    {
      this.select_realtorId=5;
    }
    const data = this.createConfirmInsertDialogData();
    const addDefault = await this.dialogService
      .openConfirmDialog(data)
      .toPromise();
    if (addDefault) {
      await this.toolboxService.addDefault(this.select_realtorId,  new ToolboxNewItem({ category: new ToolboxCategory({id:3, name:"dummy"}), text:"dummy" })).toPromise().then(
        () => {
          this.disableOnAddDefault$.next(true);
          this.forceReload$.next();
          this.refreshListOfItem();
          this.message$.next(new SuccessMessage('Default items added successfully'))
          // setTimeout(() => {
          //   this.message$.next();
          // }, 2000);
        },
        () => {
          this.disableOnAddDefault$.next(false);
          this.message$.next(new ErrorMessage('Oops, something went wrong'))
        }
      );
      // this.disableOnAddDefault$.next(true);
    }
  }  

  /**
   * Shows the deleting confirmation dialog.
   * @param itemId Id of the deleting item.
   */
  public async openDeleteDialog(itemId: number, category: any): Promise<void> {
    const data = this.createConfirmDialogData();
    const deleted = await this.dialogService
      .openConfirmDialog(data)
      .toPromise();
    if (deleted) {
      await this.toolboxService.deleteAdminItem(itemId).toPromise().then(
        () => {
          this.disableOnAddDefault$.next(false);
          this.forceReload$.next();
          this.refreshListOfItem();
          this.message$.next(new SuccessMessage('Toolbox item deleted successfully'));
          // setTimeout(() => {
          //   this.message$.next();
          // }, 2000);
        },
        (err) => {
          // console.log(err);
          this.message$.next(new ErrorMessage('Oops, something went wrong'))
        }
      );
    }
    category = { ...category, expanded: false };
  }

  /**
   * Shows the edit dialog
   * @param itemId Id of the editing item item.
   *
   */
  public async openEditDialog(
    itemId: number,
    itemText: string,
    itemCategoryId: number,
    category: any
  ): Promise<void> {
    const itemEdited = await this.dialogService
      .openToolboxAdminEditDialog(itemId, itemText, itemCategoryId)
      .toPromise();
    if (itemEdited) {
      this.forceReload$.next();
      this.refreshListOfItem();
      this.message$.next(new SuccessMessage('Toolbox item updated successfully'));
      // setTimeout(() => {
      //   this.message$.next();
      // }, 2000);
    }
    category = { ...category, expanded: false };
  }

  /**
   * Informs the user that the item's text has been successfully copied to the clipboard.
   */
  public onCopySuccess(): void {
    this.notificationService.showNotification(
      "Toolbox text copied! Paste it into a field on the left."
    );
  }

  private createConfirmDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: "Delete Item?",
      subtitle:
        "Are you sure you want to delete this item? It will no" +
        "\nlonger be accessible in your toolbox.",
      acceptButtonColor: MatButtonColor.Warn,
      acceptButtonText: "Delete",
      rejectButtonText: "Cancel",
    });
  }
  private createConfirmInsertDialogData(): ConfirmDialogData {
    return new ConfirmDialogData({
      title: "Add Default Item?",
      subtitle:
        "Are you sure you want to add default items in toolbox? (Existing items will NOT be deleted)",
      acceptButtonColor: MatButtonColor.Primary,
      acceptButtonText: "Add",
      rejectButtonText: "Cancel",
    });
  }

  public emitOfferTemplate(offer): void {
    this.emittedToolboxTemplateType.emit(offer);
  }

  public initializeEditor(params): void {
    this.CKEDITOR_INST = params;
    this.CKEDITOR_INST.setData(this.emailSignature);
    params.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      return new CKeditorImageAdapter( loader );
    };
  }

  public handleBodyChange({ editor }: any) {
    this.emailSignature = editor.getData();
  }
  private listenProfileUpdating(): void {
    this.update$
      .pipe(
        tap(() => this.message$.next(null)),
        switchMap(data =>
          this.realtorService.updateEmailSignature(data.emailSignature, this.realtorId)
            .pipe(
              tap(() => this.message$.next(new SuccessMessage('Profile has been successfully updated'))),
              completedOrFailed(() => this.message$.next(null)),
              catchHttpErrorMessage({message$: this.message$}),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public updateEmailSignature2(){
    this.update$.pipe(
        tap(() =>{ this.message$.next(null)}),
        map(form => new UpdateRealtorDto({
          companyName:this.realtorProfile.companyName,
          footerColor:this.realtorProfile.footerHexColor,
          headerColor:this.realtorProfile.headerHexColor,
          iconsColor : this.realtorProfile.iconsHexColor,
          firstName: this.realtorProfile.firstName,
          lastName: this.realtorProfile.lastName,
          email: this.realtorProfile.email,
          phoneNumber: this.realtorProfile.phoneNumber,
          officeAddress: this.realtorProfile.officeAddress,
          website: this.realtorProfile.website,
          realtorLogo:this.realtorProfile.realtorLogo,
          companyLogo:this.realtorProfile.companyLogo,
          realtorPicture:this.realtorProfile.realtorPicture,
          signaturePicture:this.realtorProfile.signaturePicture,
          pictureRemoved:this.realtorProfile.realtorPictureRemoved,
          companyLogoRemoved:this.realtorProfile.companyLogoRemoved,
          signatureRemoved:this.realtorProfile.signaturePictureRemoved,
          logoRemoved:this.realtorProfile.realtorLogoRemoved,
          emailSignature: this.emailSignature,
        })),
        tap(data =>
          this.realtorService.updateProfile(data)
            .pipe(
              tap(() => this.message$.next(new SuccessMessage('Email Signature has been successfully updated'))),
              completedOrFailed(()=>{
                this.message$.next(null);
              }),
              catchHttpErrorMessage({message$: this.message$}),
            ),
        ),
        takeUntil(this.destroy$),
      ).subscribe();
   
  }
  public updateEmailSignature(): void {
    this.realtorProfile.emailSignature= this.emailSignature;
    this.update$.next(this.realtorProfile);
  }

  public loadRealtorId(){
    this.realtorService.getProfile().pipe(
      map((realtor)=>{
        this.realtorId=realtor.id;
      })
    ).subscribe()
  }
  public loadRealtor(){
    this.realtorService.getProfile().pipe(
      tap((data)=>console.log(data)),
      map((realtor)=>{
        this.emailSignature = realtor.emailSignature;
        this.realtorProfile=realtor;
        this.realtorId=realtor.id;
        this.CKEDITOR_INST.setData(this.emailSignature);
      })
    ).subscribe()
  }

  public viewSampleOffer(){
    const uri = "https://arbor-production-bucket.s3.us-west-2.amazonaws.com/offers/BOP+Sample+Offer.pdf?date=" + Date.now();
    var link = document.createElement('a');
    link.setAttribute('href',uri );
    link.setAttribute('target', "_blank");
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
