import { Full } from '../../helpers/full';
import { Person } from '../person';

import { OfferStatus } from './offer-status';

/**
 * Offer summary.
 */
export class OfferSummary {

  /**
   * Id.
   */
  public id: number;

  /**
   * Friendly id.
   */
  public friendlyId: string;

  /**
   * Date submitted.
   */
  public submittedAt: Date | null;

  /**
   * Associated borrower.
   */
  public borrower: Person;

  /**
   * Address.
   */
  public address: string;

  /**
   * City.
   */
  public city: string;

  /**
   * State.
   */
  public state: string;

  /**
   * Zip.
   */
  public zip: string;

  /**
   * RPA URL.
   */
  public rpaUrl: string;

  /**
   * URL to a PDF with all documents.
   */
  public packageUrl: string;

  /**
   * Price.
   */
  public price: number;

  /**
   * Status.
   */
  public status: OfferStatus;

  /**
   * Pdf url.
   */
  public pdfUrl: string;



  public analytics: any;

  /* financing  */
  public financing: number;

  /**
   * PackageBitlyUrl pdf url.
   */
  public packageBitlyUrl: string;

  constructor(data: Full<OfferSummary>) {
    this.id = data.id;
    this.friendlyId = data.friendlyId;
    this.submittedAt = data.submittedAt;
    this.borrower = data.borrower;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.rpaUrl = data.rpaUrl;
    this.packageUrl = data.packageUrl;
    this.price = data.price;
    this.financing = data.financing;
    this.status = data.status;
    this.pdfUrl = data.pdfUrl;
    this.packageBitlyUrl = data.packageBitlyUrl;
    this.analytics = data.analytics;
  }

}
