import { Full } from '../../core';

import { BaseDialogData } from './base-dialog-data';

/**
 * Info dialog data.
 */
export class InfoDialogData extends BaseDialogData {

  /**
   * Confirm text.
   */
  public confirmText?: string;

  constructor(data: Full<InfoDialogData>) {
    super(data.title, data.subtitle);
    this.confirmText = data.confirmText || 'OK';
  }

}
