import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    OnInit,
  } from "@angular/core";
  import { ActivatedRoute } from "@angular/router";
  import {
    Observable,
    combineLatest,
    ReplaySubject,
    BehaviorSubject,
  } from "rxjs";
  import { tap, map, first, takeUntil, filter, switchMap } from "rxjs/operators";
  
  ///services/borrower-page.service
  
  import { RolesService,LoanOfficerService, CurrentUserService } from "../../../../core";
  
  const DEFAULT_COLOR = "ef7824";
/**
 * Borrower letter page component.
 */
@Component({
  selector: 'bop-letter-page',
  templateUrl: './bop-letter.component.html',
  styleUrls: ['./bop-letter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BOPLetterPageComponent {

    public readonly isAdmin$ = this.rolesService.isAdmin$;
    public data$ = new BehaviorSubject({});
    public readonly user$ = this.currentUserService.currentUser$;
    public fontSize = "9pt";
    public isRealtor = false;
    /**
     * If `true` then pre approval form is valid.
     */
    public readonly isPreApprovalFormValid$ = new BehaviorSubject(false);
  
    constructor(
      private activatedRoute: ActivatedRoute,
    private loanOfficerService: LoanOfficerService,
    private rolesService: RolesService,
      private currentUserService: CurrentUserService,
    ) {}
  
    private getCurrentUser():void {
      this.user$.pipe(map((user)=> {
        console.log("user", user);
        // this.user = user;
        // this.dynamictemplateList.forEach((aList) => {
        //   if (aList.name == "Email Signature") {
        //     aList.sampleValue = this.user.emailSignature;
        //   }
        // })
        
        
      })).subscribe();
     }
    public ngOnInit(): void {
      // this.initBorrower();
      var that = this;
      // this.getRealtor();
      this.getCurrentUser()
      this.loanOfficerService
      .getProfile()
      .pipe(
          tap((loanOfficer) => { 
            console.log("loanOfficer",loanOfficer);
            loanOfficer.headerColor = `#${loanOfficer.headerColor || DEFAULT_COLOR}`;
            loanOfficer.footerColor = `#${loanOfficer.footerColor || DEFAULT_COLOR}`;
            let data = {...loanOfficer}
            that.setFontSize(data);
            that.data$.next(data);
        
        
        
        })
      ).subscribe();
    }
  
    private initBorrower(): void {
      var that = this;
      that.activatedRoute.data
        .pipe(
          first(),
          tap(({ realtor, loanOfficer }) => {
            if (realtor) {
                console.log("{ realtor }", realtor);
                realtor.headerColor = `#${realtor.headerColor || DEFAULT_COLOR}`;
                realtor.footerColor = `#${realtor.footerColor || DEFAULT_COLOR}`;
                realtor.iconsColor = `#${realtor.footerColor || DEFAULT_COLOR}`;
                let data = {...realtor}
                that.setFontSize(data);
                that.data$.next(data);
                return data;
            } else if (loanOfficer) {
                console.log(" {LO} ", loanOfficer);
                loanOfficer.headerColor = `#${loanOfficer.headerColor || DEFAULT_COLOR}`;
                loanOfficer.footerColor = `#${loanOfficer.footerColor || DEFAULT_COLOR}`;
                let data = {...loanOfficer}
                that.setFontSize(data);
                that.data$.next(data);
                return data;
            }
          })
          //  takeUntil(this.destroy$),
        )
        .subscribe();
    }
  
    private setFontSize(data) {
      const size =
        data.firstName.length + data.lastName.length + data.companyName.length;
      this.fontSize = size < 50 ? "9pt" : size < 70 ? "7.5pt" : "7pt";
    }
}
