import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../../app.config';
import { Admin } from '../models/admin';

import { AdminDto } from './dto/admin-dto';
import { UpdateAdminDto } from './dto/update-admin-dto';

/**
 * Admin service.
 */
@Injectable({
  providedIn: 'root',
})
export class AdminService {

  constructor(
    private config: AppConfig,
    private http: HttpClient,
  ) { }

  /**
   * Returns admin profile info.
   */
  public getProfile(): Observable<Admin> {
    const url = `${this.config.apiUrl}/api/admins/my-profile`;
    return this.http
      .get<AdminDto>(url)
      .pipe(
        map(dto => new Admin({
          id: dto.id,
          firstName: dto.firstName,
          lastName: dto.lastName,
          email: dto.email,
          phoneNumber: dto.phoneNumber,
          updatedAt: new Date(dto.updatedAt),
          /* Now admin doesn't have a profile image. Probably will have in the future. */
          profileImageUrl: '',
        })),
      );
  }

  /**
   * Updates profile.
   * @param profile Updated profile.
   */
  public updateProfile(profile: UpdateAdminDto): Observable<void> {
    const url = `${this.config.apiUrl}/api/admins/my-profile`;
    return this.http.put<void>(url, profile);
  }

}
