import { LoanOfficerService } from 'src/app/core';
import { Location } from '@angular/common';
import {
  Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter, HostListener,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap, takeUntil, exhaustMap, switchMap, timeout, delay } from 'rxjs/operators';

import {
  UserRole,
  User,
  AccountInfoForm,
  AuthService,
  ForgotPasswordDto,
  DestroyableBase,
  completedOrFailed,
  Message,
  Realtor,
  LoanOfficer,
} from '../../../../core';
import { InfoDialogData } from '../../../models/public_api';
import { DialogService } from '../../../services/dialog.service';

import { UserForm } from './user-form';

/**
 * Account info component.
 */
@Component({
  selector: 'arb-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInfoComponent extends DestroyableBase implements OnInit {

  private readonly submit$ = new Subject<void>();
  private readonly changePassword$ = new Subject<void>();
  private userValue: User;
  public isMobileView: boolean = true;
  public windowWidth;
  /**
   * Loading stream.
   */
  public readonly loading$ = new BehaviorSubject(false);

  /**
   * Submitted.
   */
  public submitted: boolean;

  /**
   * User role.
   */
  public userRole = UserRole;

  /**
   * User form.
   */
  public userForm: FormGroup;

  /**
   * User form.
   */
  public timeZones = [
    { name: "Eastern Standard Time", value: "America/New_York" },
    { name: "Central Standard Time", value: "America/Chicago" },
    { name: "Mountain Standard Time", value: "America/Denver" },
    { name: "Pacific Standard Time", value: "America/Los_Angeles" },
    { name: "Hawaii Standard Time", value: "America/Honolulu" },
    { name: "Alaska Standard Time", value: "America/Anchorage" },
  ];

  /**
   * User info.
   */
  @Input()
  public set user(value: User) {
    this.userValue = value;
    this.userForm = this.createUserForm(value);
  }

  /**
   * Profile updating stream.
   */
  @Input()
  public profileUpdating$: Observable<boolean>;

  /**
   * Success or error message stream.
   */
  @Input()
  public message$: Observable<Message | null>;

  /**
   * Saved.
   */
  @Output()
  public saved = new EventEmitter<AccountInfoForm>();
  @Output() public sidenavToggle = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private dialogService: DialogService,
    private authService: AuthService,
    private loanOfficerService: LoanOfficerService
  ) {
    super();
    this.windowWidth = window.innerWidth;
    this.isMobileView = this.windowWidth < 1150 ? true : false;
  }

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.listenSubmit();
    this.listenPasswordChanging();

  }
  @HostListener("window:resize", ["$event"]) toggleMobileView(windowEvent) {
    this.isMobileView = windowEvent.target.innerWidth < 1150 ? true : false;
  }
  private createUserForm(user: User): FormGroup {
    const userForm: UserForm = {
      firstName: [user.firstName, [Validators.required, Validators.maxLength(30)]],
      lastName: [user.lastName, [Validators.required, Validators.maxLength(30)]],
      email: [user.email, [Validators.required, Validators.email, Validators.maxLength(256)]],
      phoneNumber: [user.phoneNumber === "null" ? null : user.phoneNumber],
      nmlsId: [user.nmlsId],

    };
    if (user instanceof LoanOfficer) {
      userForm.title = [user.title];
      userForm.nmlsId = [user.nmlsId === "null" ? null : user.nmlsId];
      userForm.timeZone = [user.timeZone]
    }
    if (user instanceof Realtor) {
      userForm.officeAddress = [user.officeAddress];
      userForm.website = [user.website];
      userForm.timeZone = [user.timeZone]
      userForm.licenseNumber = [user.licenseNumber]
    }
    return this.formBuilder.group(userForm);
  }

  private listenSubmit(): void {
    this.submit$
      .pipe(
        tap(() => { this.loading$.next(true); this.loanOfficerService.loadingParent$.next(true) }),
        delay(5000),
        tap(() => this.submitted = true),
        filter(() => this.userForm.valid),
        map(() => this.userForm.value as AccountInfoForm),
        tap(value => this.saved.emit(value)),
        takeUntil(this.destroy$),
        tap(() => { this.loading$.next(false); this.loanOfficerService.loadingParent$.next(false) }),
      )
      .subscribe();
  }

  private listenPasswordChanging(): void {
    this.changePassword$
      .pipe(
        filter(() => this.validateEmail()),
        tap(() => this.loading$.next(true)),
        map(() => new ForgotPasswordDto(this.emailValue)),
        switchMap(data =>
          this.authService.forgotPassword(data)
            .pipe(
              map(() => new InfoDialogData({
                title: 'Password Reset',
                subtitle: 'Please check your email for a link to reset your password',
              })),
              exhaustMap(dialogData => this.dialogService.openInfoDialog(dialogData)),
              completedOrFailed(() => this.loading$.next(false)),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private validateEmail(): boolean {
    if (this.emailControl && this.emailControl.valid) {
      return true;
    }
    /* To display email error. */
    this.submitted = true;
    return false;
  }

  private get emailValue(): string {
    if (this.emailControl) {
      return this.emailControl.value;
    }
    throw new Error('Email control is null');
  }

  private get emailControl(): AbstractControl | null {
    return this.userForm.get('email');
  }

  /**
   * Returns `true` if user is realtor.
   */
  public get isRealtor(): boolean {
    return this.userValue instanceof Realtor;
  }
  /**
   * Returns `true` if user is realtor.
   */
  public get isLoanOfficer(): boolean {
    return this.userValue instanceof LoanOfficer;
  }

  /**
   * Cancel.
   */
  public cancel(): void {
    this.location.back();
  }

  /**
   * Submit.
   */
  public submit(): void {
    this.loading$.next(true)
    this.submit$.next();
  }

  /**
   * Change password.
   */
  public changePassword(): void {
    this.changePassword$.next();
  }

}
